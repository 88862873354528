export class ManyToManyIdMap {
  // TODO: evaluate using Map instead of Set for values (for faster access resolution of many-to-many relationships)
  private map: Map<string, Set<string>>;

  constructor(map: Map<string, Set<string>> = new Map<string, Set<string>>()) {
    this.map = map;
  }

  public get(key: string): Set<string> {
    return this.map.get(key);
  }

  public add(key: string, value: string): void {
    this.addValue(key, value);
    this.addValue(value, key);
  }

  public addMultiple(key: string, values: string[]): void {
    this.addValues(key, values);
    for (const value of values) {
      this.addValues(value, [key]);
    }
  }

  public set(key: string, value: string): void {
    this.setValue(key, value);
    this.addValue(value, key);
  }

  public setMultiple(key: string, values: string[]): void {
    this.setValues(key, values);
    for (const value of values) {
      this.addValues(value, [key]);
    }
  }

  public has(key: string): boolean {
    return this.map.has(key);
  }

  public delete(key: string): void {
    if (this.map.has(key)) {
      const values = this.map.get(key);
      for (const value of values) {
        if (this.map.has(value)) {
          if (this.map.get(value).size === 1) {
            this.map.delete(value);
          } else {
            this.map.get(value).delete(key);
          }
        }
      }
      this.map.delete(key);
    }
  }

  public deleteMultiple(keys: string[]): void {
    for (const key of keys) {
      this.delete(key);
    }
  }

  private addValue(key: string, value: string): void {
    if (this.map.has(key)) {
      this.map.get(key).add(value);
    } else {
      this.setValue(key, value);
    }
  }

  private addValues(key: string, values: string[]): void {
    if (this.map.has(key)) {
      const valueSet = this.map.get(key);
      for (const value of values) {
        valueSet.add(value);
      }
      this.map.set(key, valueSet);
    } else {
      this.setValues(key, values);
    }
  }

  private setValues(key: string, values: string[]): void {
    this.map.set(key, new Set(values));
  }

  private setValue(key: string, value: string): void {
    this.map.set(key, new Set([value]));
  }
}

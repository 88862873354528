import { v4 as uuid } from 'uuid';
import { Socket } from 'socket.io-client';
import { UploadOption } from '~/models/UploadOption';
import { MutantContentView } from '~/models/views/MutantContentView';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { DragInfo } from '~/models/views/DragInfo';
import { DragElementOffsetValues } from '~/models/views/DragElementOffsetValues';
import { Background } from '~/models/views/Background';
import { ObjectId } from '~/models/ObjectId';
import { ContextMenuState } from '~/store/context/state';
import { CloudState } from '~/store/cloud/state';
import { UserState } from '~/store/user/state';
import { ProfileState } from '~/store/profile/state';
import { FolderState } from '~/store/folder/state';
import { SelectionState } from '~/store/selection/state';
import { FileState } from '~/store/file/state';
import { DetailViewState } from '~/store/detailView/state';
import { MagnifyState } from '~/store/magnify/state';
import { LinkState } from '~/store/link/state';
import { HomepageState } from '~/store/homepage/state';
import { UploadMenuState } from '~/store/uploadMenu/state';
import { StorageKey } from '~/models/storage/StorageKey';
import { FloatingMenu } from '~/models/FloatingMenu';
import { FooterTab } from '~/models/FooterTab';
import { Feature } from '~/models/Feature';
import { DroppedOnViewEvent } from '~/models/DroppedOnViewEvent';
import { DropInfo } from '~/models/views/DropInfo';
import { CloudMenuRoute } from '~/models/CloudMenuRoute';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import { Notification } from '~/models/Notification';
import { CloudMenuOptionTab } from '~/models/CloudMenuOptionTab';
import { LayoutType } from '~/models/LayoutType';
import { MenuEntry } from '~/models/MenuEntry';
import { isSharedLinkUrl } from '~/store/helper';
import { PipelineInfoLabel } from '~/models/pipeline/FileProcessingPipeline';
import { DesktopReleaseData } from '~/store/actions';

export enum Orientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export interface ViewProgress {
  progressSteps: { label: PipelineInfoLabel; step: number; percentage: number }[];
  totalSteps: number;
}

export interface RootState {
  showFolderTitle: boolean;
  isLightMode: boolean;
  isNavigationLaneOpen: boolean;
  activeElementId: string;
  isMinimalView: boolean;
  isSharedRoute: boolean,
  windowWidth: number;
  windowHeight: number;
  showOriginals: boolean;
  betaLandingPageVisited: boolean;
  activeHomepageMenuEntry: MenuEntry,
  currentLayoutType: LayoutType;
  isMobile: boolean;
  workerSupport: boolean;
  isMobileWidth: boolean;
  notifications: { [key in string]?: Notification };
  orientation: Orientation;
  transparency: { menus: number },
  interactiveFooterHeight: number;
  lastKnownModified: number;
  showCaptions: boolean;
  showResolutions: boolean;
  activeCloudMenuTab: CloudMenuTab;
  activeCloudMenuOptionTab: CloudMenuOptionTab;
  activeFeatures: Map<Feature, boolean>;
  options: { upload: UploadOption };
  viewProgress: {[pipelineId: string]: ViewProgress};
  isOffline: boolean;
  isInitialized: boolean;
  isConnectedToWebsocket: boolean;
  socket: Socket;
  originId: string;
  eventHistory: SocketEvent<any>[];
  showTooltips: boolean;
  menuRoute: CloudMenuRoute;
  menuOpen: boolean;
  droppedOnView: DroppedOnViewEvent;
  dragInfo: DragInfo;
  dropInfo: DropInfo;
  currentDragOffsets: DragElementOffsetValues;
  currentDragSize: { width: number; height: number };
  layoutInitialized: boolean;
  isLayoutDragged: boolean;
  adjustWindowsToDrag: boolean;
  isMenuFloating: boolean;
  isMenuExpanded: boolean;
  showCover: boolean;
  menuWidth: number;
  cloudMenuRightOpen: boolean;
  cloudMenuRightWidth: number;
  cloudSidePaneWidth: number;
  cloudSidePaneOpen: boolean;
  cloudMenuRightWidthCached: number;
  floatingMenus: FloatingMenu[];
  screenBackground: string;
  mainViewHeaderHeight: number;
  backgrounds: Background[];
  recentObjectIds: ObjectId[];
  customObjectIds: ObjectId[];
  activeFooterTab: FooterTab;
  registeredContentViews: Map<string, MutantContentView>;
  desktopReleaseData: DesktopReleaseData;
  context: ContextMenuState;
  cloud: CloudState;
  user: UserState;
  profile: ProfileState;
  folder: FolderState;
  selection: SelectionState;
  file: FileState;
  detailView: DetailViewState;
  magnify: MagnifyState;
  link: LinkState;
  homepage: HomepageState;
  uploadMenu: UploadMenuState;
}

function getAndSetStorageWidthFor(storageKey: StorageKey, cssVariable: string) {
  const cloudMenuOptions = JSON.parse(localStorage.getItem(storageKey));
  const isOpen = cloudMenuOptions?.isOpen;
  const width = cloudMenuOptions?.width;
  let returnWidth = 0;
  if (width != null) {
    if (isOpen) {
      document.documentElement.style.setProperty(cssVariable, width + 'px');
    } else {
      document.documentElement.style.setProperty(cssVariable, '0');
    }
    returnWidth = width;
  } else if (isOpen) {
    document.documentElement.style.setProperty(cssVariable, '350px');
    returnWidth = 350;
  } else {
    document.documentElement.style.setProperty(cssVariable, '0');
  }
  // Default width should be set because floating options are visible from the start
  if (storageKey === StorageKey.RIGHT_MENU && isOpen == null && width == null) {
    return 400;
  }
  return returnWidth;
}

function getStorageIsOpenFor(storageKey: StorageKey) {
  const cloudMenuOptions = JSON.parse(localStorage.getItem(storageKey));
  const isOpen = cloudMenuOptions?.isOpen;
  return isOpen != null ? isOpen : false;
}

export function initializeOrientation() {
  if (window.innerHeight / window.innerWidth < 1) {
    return Orientation.LANDSCAPE;
  } else {
    return Orientation.PORTRAIT;
  }
}

function setInteractiveFooterHeight() {
  const footerHeight = localStorage.getItem(StorageKey.INTERACTIVE_FOOTER_HEIGHT) ? parseInt(localStorage.getItem(StorageKey.INTERACTIVE_FOOTER_HEIGHT)) : 120;
  if (localStorage.getItem(StorageKey.SHORTCUTS_IS_OPEN)) {
    document.documentElement.style.setProperty('--tabs-lane-height', footerHeight + 'px');
  }
  return footerHeight;
}

const ORIGIN_ID = uuid();

const state = (): RootState =>
  ({
    showFolderTitle: true,
    isLightMode: false,
    isNavigationLaneOpen: localStorage.getItem(StorageKey.SHORTCUTS_IS_OPEN) ? localStorage.getItem(StorageKey.SHORTCUTS_IS_OPEN) === 'true' : false,
    activeElementId: '',
    isMinimalView: false,
    isSharedRoute: isSharedLinkUrl(),
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    showOriginals: localStorage.getItem(StorageKey.SHOW_ORIGINALS) ? localStorage.getItem(StorageKey.SHOW_ORIGINALS) === 'true' : false,
    betaLandingPageVisited: localStorage.getItem(StorageKey.BETA_LANDING_VISITED) ? localStorage.getItem(StorageKey.BETA_LANDING_VISITED) === 'true' : false,
    currentLayoutType: localStorage.getItem(StorageKey.CURRENT_LAYOUT_TYPE) ? localStorage.getItem(StorageKey.CURRENT_LAYOUT_TYPE) : LayoutType.FILMSTRIP,
    activeHomepageMenuEntry: MenuEntry.MUTANT_ALPHA,
    isMobile: false,
    workerSupport: true,
    isMobileWidth: false,
    notifications: {},
    orientation: initializeOrientation(),
    transparency: { menus: localStorage.getItem('menus.opacity') ? parseFloat(localStorage.getItem('menus.opacity')) : 0.1 },
    interactiveFooterHeight: setInteractiveFooterHeight(),
    lastKnownModified: Date.now(),
    showResolutions: localStorage.getItem('showResolution') ? localStorage.getItem('showResolution') === 'true' : true,
    showCaptions: localStorage.getItem('showCaptions') ? localStorage.getItem('showCaptions') === 'true' : true,
    activeCloudMenuTab: JSON.parse(localStorage.getItem(StorageKey.RIGHT_MENU))?.activeTab ? JSON.parse(localStorage.getItem(StorageKey.RIGHT_MENU))?.activeTab as keyof typeof CloudMenuTab : CloudMenuTab.RECENT,
    activeCloudMenuOptionTab: JSON.parse(localStorage.getItem(StorageKey.RIGHT_MENU))?.activeOptionTab ? JSON.parse(localStorage.getItem(StorageKey.RIGHT_MENU))?.activeOptionTab as keyof typeof CloudMenuOptionTab : CloudMenuOptionTab.VIEW,
    activeFeatures: new Map([[Feature.OFFLINE_MODE, true]]),
    viewProgress: {},
    isOffline: false,
    isInitialized: false,
    isConnectedToWebsocket: false,
    socket: null,
    originId: ORIGIN_ID,
    eventHistory: [],
    showTooltips: true,
    droppedOnView: null,
    dragInfo: null,
    dropInfo: null,
    currentDragOffsets: null,
    currentDragSize: null,
    layoutInitialized: false,
    isLayoutDragged: false,
    adjustWindowsToDrag: false,
    showCover: false,
    menuRoute: 'cloud',
    menuOpen: getStorageIsOpenFor(StorageKey.CLOUD_MENU),
    menuWidth: getAndSetStorageWidthFor(StorageKey.CLOUD_MENU, '--cloud-menu-width'),
    cloudMenuRightOpen: getStorageIsOpenFor(StorageKey.RIGHT_MENU),
    cloudMenuRightWidthCached: 300,
    cloudMenuRightWidth: getAndSetStorageWidthFor(StorageKey.RIGHT_MENU, '--cloud-menu-right-width'),
    cloudSidePaneWidth: getAndSetStorageWidthFor(StorageKey.SIDE_PANE, '--side-pane-width'),
    cloudSidePaneOpen: getStorageIsOpenFor(StorageKey.SIDE_PANE),
    isMenuFloating: localStorage.getItem(StorageKey.MENU_FLOATING)
      ? localStorage.getItem(StorageKey.MENU_FLOATING) === 'true'
      : false,
    isMenuExpanded: true,
    floatingMenus: [],
    screenBackground: localStorage.getItem(StorageKey.SCREEN_BACKGROUND),
    mainViewHeaderHeight: 0,
    backgrounds: [],
    recentObjectIds: [],
    customObjectIds: [],
    activeFooterTab: localStorage.getItem(StorageKey.SHORTCUTS_ACTIVE_LANE) ? localStorage.getItem(StorageKey.SHORTCUTS_ACTIVE_LANE) : FooterTab.SELECTION,
    registeredContentViews: new Map<string, MutantContentView>(),
  } as RootState);

export default state;

import { AssetFilter } from '~/models/asset/AssetFilter';
import { Asset } from '~/models/Asset';

export const byUsesSubstituteForMissingVersions = (assetSubs: Asset[]): AssetFilter<Asset> => assets => {
  const alteredAssets = assets;
  assetSubs.forEach(assetSub => {
    if (!assets.some(asset => asset.version === assetSub.version)) {
      alteredAssets.push(assetSub);
    }
  });
  return alteredAssets;
};

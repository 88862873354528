import { HomepageSlide, HomepageState, SongInfo } from '~/store/homepage/state';

export default {
  isPlaying: (state: HomepageState): boolean => {
    return state.songs.some(song => song.isPlaying);
  },
  currentSong: (state: HomepageState): SongInfo => {
    return state.songs.find(song => song.isSelected);
  },
  nextSong: (state: HomepageState): SongInfo => {
    let currentSongIdx = 0;
    for (const song of state.songs) {
      currentSongIdx++;
      if (song.isSelected) {
        break;
      }
    }
    return currentSongIdx < state.songs.length - 1 ? state.songs[currentSongIdx + 1] : state.songs[0];
  },
  currentSlide: (state: HomepageState): HomepageSlide => {
    return state.slides.find(slide => slide.no === state.activeSlide);
  },
};

import { Component, Vue } from 'nuxt-property-decorator';
import { User } from '~/models/user/User';

@Component
export default class RoleAwareness extends Vue {
  public get currentUser(): User {
    return this.$store.getters['user/currentUser'];
  }

  public get isProUser(): boolean {
    return this.$store.getters['user/isProUser'];
  }

  public get isUser(): boolean {
    return this.$store.getters['user/isUser'];
  }

  public get isAlphaUser(): boolean {
    return this.$store.getters['user/isAlphaUser'];
  }

  public get isGuestUser() {
    return this.$store.getters['user/isGuest'];
  }

  public get isPayingRole(): boolean {
    return this.isUser;
  }

  public get isUserWithoutDataLimitation(): boolean {
    return this.$store.getters['user/isUserWithoutDataLimitation'];
  }
}

import { ContextMenu, ContextMenuState, ContextMenuType } from '~/store/context/state';

export default {
  isOpen: (state: ContextMenuState) => (menuType: ContextMenuType): boolean => {
    return state.menus.some(m => m.type === menuType && m.isOpen);
  },
  menu: (state: ContextMenuState) => (menuType: ContextMenuType): ContextMenu<any> | null => {
    return state.menus.find(m => m.type === menuType);
  },
};

import { v4 as uuid } from 'uuid';
// eslint-disable-next-line import/named
import { AxiosInstance } from 'axios';
import { EventEmitter } from './EventEmitter';
import { PipelineItem } from '~/models/PipelineItem';
import { ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';

export interface UploadQueueItem {
  item: PipelineItem;
}

enum BatchUploaderStatus {
  PAUSED = 'paused',
  ACTIVE = 'active',
}

export interface BatchQueryParams {
  orders: string[],
  itemIds: string[],
  sizes?: string[],
  versions: string[],
  files: (File | Blob)[]
  folderTags: string[],
  replaceThumbs?: string[],
  replaceItem?: string[],
}

export abstract class BatchUploader extends EventEmitter {
  protected uploadQueue: UploadQueueItem[] = [];
  private processInterval = null;
  private activeWorkers: string[] = [];
  private status: BatchUploaderStatus = BatchUploaderStatus.ACTIVE;
  private stopProcessingTimeout = null;
  private abortController = new AbortController();

  constructor(private api: AxiosInstance, private maxWorkerCount: number = 3) {
    super();
  }

  public cancelOutstandingWork() {
    this.abortController.abort();
    this.uploadQueue = [];
    this.abortController = new AbortController();
  }

  public pause() {
    this.status = BatchUploaderStatus.PAUSED;
    clearInterval(this.processInterval);
    this.processInterval = null;
  }

  public resume() {
    this.status = BatchUploaderStatus.ACTIVE;
    this.setupWorkers();
  }

  public upload(items: PipelineItem[]) {
    this.addQueueItems(items.map(i => ({ item: i })));
  }

  protected abstract addQueueItems(queueItems: UploadQueueItem[]): void;

  protected abstract buildBatch(): UploadQueueItem[];

  protected setupWorkers() {
    if (this.processInterval == null) {
      this.processInterval = setInterval(() => this.manageWorkers(), 500);
      this.manageWorkers();
    }
  }

  private manageWorkers() {
    if (this.uploadQueue.length === 0) {
      this.stopProcessingTimeout = setTimeout(() => {
        clearInterval(this.processInterval);
        this.processInterval = null;
      }, 2000);
    } else {
      clearTimeout(this.stopProcessingTimeout);
      while (this.activeWorkers.length < this.maxWorkerCount && this.uploadQueue.length > 0) {
        const workerId = uuid();
        this.activeWorkers.push(workerId);
        this.startBatchWorker(workerId);
      }
    }
  }

  private async startBatchWorker(id: string) {
    const batch = this.buildBatch();
    if (batch.length > 0) {
      await this.processBatch(batch);
      if (this.activeWorkers.includes(id)) {
        this.startBatchWorker(id);
      }
    } else {
      this.activeWorkers = this.activeWorkers.filter(workerId => workerId !== id);
    }
  }

  private async processBatch(batch: UploadQueueItem[]): Promise<void> {
    const formData = new FormData();
    const queryParams = new URLSearchParams();
    queryParams.append('folder-id', batch[0].item.folderId);
    const { orders, itemIds, sizes, versions, files, folderTags, replaceThumbs, replaceItem } = this.buildQueryParamsForBatch(batch);
    if (orders.length) {
      queryParams.append('orders', orders.join(','));
    }
    if (itemIds.length) {
      queryParams.append('item-ids', itemIds.join(','));
    }
    if (sizes.length) {
      queryParams.append('sizes', sizes.join(','));
    }
    if (versions.length) {
      queryParams.append('versions', versions.join(','));
    }
    if (folderTags.length) {
      queryParams.append('folder-tags', folderTags.join(','));
    }
    if (replaceItem.length && batch.some(b => b.item.replaceItem === true)) {
      queryParams.append('replace-item', replaceItem.join(','));
    }
    if (replaceThumbs.length) {
      queryParams.append('replace-thumbs', replaceThumbs.join(','));
    }
    for (const file of files) {
      formData.append('files', file);
    }
    // TODO: add error handling
    const query = queryParams.toString();
    try {
      // @ts-ignore
      await this.api.post(`/items/upload?${query}`, formData, { signal: this.abortController.signal });
      this.emitUploaded(batch);
    } catch (error) {
      this.emitUploaded(batch, error);
    }
  }

  abstract emitUploaded(batch: UploadQueueItem[], error?: Error);

  abstract buildQueryParamsForBatch(batch: UploadQueueItem[]): BatchQueryParams;

  buildQueryParamsForBatchCommon(batch: UploadQueueItem[], version: (item: PipelineItem) => number, file: (item: PipelineItem) => File, size: (batchItem: PipelineItem) => string): BatchQueryParams {
    // We need to check if replace option is set for one item, otherwise we get inconsistencies
    return batch.map(batchItem => {
      const includesOriginalUpload = batchItem.item.eventsToProcess.includes(PipelineCommandType.UPLOAD_ORIGINALS);
      const includesThumbnailUpload = batchItem.item.eventsToProcess.includes(PipelineCommandType.UPLOAD_THUMBNAILS);
      const currentVersion = version(batchItem.item);
      const shouldAddReplaceItem = batch.some(b => b.item.replaceItem);
      const shouldAddReplaceThumb = batch.some(b => b.item.replaceThumbs);
      const originalVersionOrThumbnailWithoutOriginal = (currentVersion === ORIGINAL_ASSET_VERSION)
        || (currentVersion !== RAW_ASSET_VERSION && currentVersion !== ORIGINAL_ASSET_VERSION && !includesOriginalUpload);
      const replaceItem = batchItem.item.replaceItem
      && ((!includesThumbnailUpload && currentVersion === ORIGINAL_ASSET_VERSION)
        || (currentVersion !== RAW_ASSET_VERSION && currentVersion !== ORIGINAL_ASSET_VERSION))
        ? 1
        : 0;
      const query: { order: number, id: string, size: string, version: number, file: File, folderTag: string, replaceThumbs?: number, replaceItem?: number } = {
        order: batchItem?.item.order,
        id: batchItem?.item.id,
        size: size(batchItem.item),
        version: currentVersion,
        file: file(batchItem.item),
        folderTag: batchItem?.item.folderTagId,
      };
      if (shouldAddReplaceThumb) {
        query.replaceThumbs = batchItem.item.replaceThumbs && originalVersionOrThumbnailWithoutOriginal ? 1 : 0;
      }
      if (shouldAddReplaceItem) {
        query.replaceItem = replaceItem;
      }
      return query;
    })
      .reduce((agg, curr) => ({
        orders: Number.isInteger(curr.order) ? [...agg.orders, curr.order] : agg.orders,
        itemIds: curr.id != null ? [...agg.itemIds, curr.id] : agg.itemIds,
        sizes: curr.size != null ? [...agg.sizes, curr.size] : agg.sizes,
        versions: curr.version != null ? [...agg.versions, curr.version] : agg.versions,
        files: curr.file != null ? [...agg.files, curr.file] : agg.files,
        folderTags: curr.folderTag != null ? [...agg.folderTags, curr.folderTag] : agg.folderTags,
        replaceItem: curr.replaceItem != null ? [...agg.replaceItem, curr.replaceItem] : agg.replaceItem,
        replaceThumbs: curr.replaceThumbs != null ? [...agg.replaceThumbs, curr.replaceThumbs] : agg.replaceThumbs,
      }), { orders: [], itemIds: [], sizes: [], versions: [], files: [], folderTags: [], replaceThumbs: [], replaceItem: [] });
  };
}

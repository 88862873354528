import { FileProcessingPipeline } from '~/models/pipeline/FileProcessingPipeline';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';

export class FileProcessingPipelineBuilder {
  private id: string;
  private commands: PipelineCommandInstruction[];

  withId(id: string): FileProcessingPipelineBuilder {
    this.id = id;
    return this;
  }

  withCommands(commands: PipelineCommandInstruction[]): FileProcessingPipelineBuilder {
    this.commands = commands;
    return this;
  }

  build(): FileProcessingPipeline {
    return new FileProcessingPipeline(this.id, this.commands);
  }
}

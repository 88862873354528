import _cloneDeep from 'lodash.clonedeep';
import { SortableView } from '~/models/views/SortableView';
import { ViewType } from '~/models/views/ViewType';
import Snippet from '~/models/Snippet';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { ContactSheetBuilder } from '~/models/views/contactSheet/ContactSheetBuilder';

export class ContactSheet implements SortableView {
  public type = ViewType.CONTACT_SHEET;

  constructor(
    public snippets: Snippet[],
    public width: number,
    public height: number,
    public columnCount: number,
    public marginBetweenItems: number,
    public textSize: number,
    public minWidth: number,
    public maxWidth: number,
    public maxColumnCount: number,
    public minColumnCount: number
  ) {}

  resize(newWidth: number, _height: number): void {
    const scaleFactor = newWidth / this.width;
    const newItemWidth = ContactSheetBuilder.calculateSideWith(newWidth, this.marginBetweenItems, this.columnCount);
    this.width = newWidth;
    if (newItemWidth >= this.minWidth && newItemWidth <= this.maxWidth) {
      this.snippets = this.snippets.map((i) => {
        i.height = i.height * scaleFactor;
        i.width = i.width * scaleFactor;
        i.item.setViewPosition({
          width: i.item.viewPosition.width * scaleFactor,
          height: i.item.viewPosition.height * scaleFactor,
          top: i.item.viewPosition.top * scaleFactor,
          left: i.item.viewPosition.left * scaleFactor,
        });
        return i;
      });
      this.height = this.height * scaleFactor;
    } else if (newItemWidth < this.minWidth) {
      let columnCountCounter = this.columnCount;
      let correctItemWidth = newItemWidth;
      while (correctItemWidth < this.minWidth && columnCountCounter > this.minColumnCount) {
        columnCountCounter--;
        correctItemWidth = ContactSheetBuilder.calculateSideWith(newWidth, this.marginBetweenItems, columnCountCounter);
      }
      this.setPropertiesForNewColumnCount(correctItemWidth, columnCountCounter, newWidth);
    } else if (newItemWidth > this.maxWidth) {
      let columnCountCounter = this.columnCount;
      let correctItemWidth = ContactSheetBuilder.calculateSideWith(newWidth, this.marginBetweenItems, columnCountCounter);
      while (correctItemWidth > this.maxWidth && columnCountCounter < this.maxColumnCount) {
        columnCountCounter++;
        correctItemWidth = ContactSheetBuilder.calculateSideWith(newWidth, this.marginBetweenItems, columnCountCounter);
      }
      this.setPropertiesForNewColumnCount(correctItemWidth, columnCountCounter, newWidth);
    }
  }

  private setPropertiesForNewColumnCount(correctItemWidth: number, columnCountCounter: number, newWidth: number): void {
    this.height = ContactSheetBuilder.calculateHeightOfSnippet(correctItemWidth, this.textSize);
    this.columnCount = columnCountCounter;
    const partialContactSheet = new ContactSheetBuilder()
      .setBaseContactSheet(this)
      .setColumnCount(columnCountCounter)
      .setViewWidth(newWidth)
      .setItems(this.snippets.map(s => s.item.item))
      .buildPartialContactSheet(0);
    this.snippets = partialContactSheet.snippets;
  }

  // This should be actual partial sorting, right now it recalculates the whole contact sheet
  preCalculatePartialSorting(sortItemsEvent: SortItemsEvent): Snippet[] {
    const snippets: Snippet[] = _cloneDeep(this.snippets);
    const snippetsImpactedBySortWithFinalOrderPositions = Snippet.sortSnippetsBySortEvent(snippets, sortItemsEvent);
    const partialContactSheet = new ContactSheetBuilder()
      .setBaseContactSheet(this)
      .setItems(
        snippetsImpactedBySortWithFinalOrderPositions.map((s) => s.item.item)
      )
      .buildPartialContactSheet(0);
    return partialContactSheet.snippets.filter((snippet) =>
      sortItemsEvent.items.some((i) => i.id === snippet.item.id)
    );
  }
}

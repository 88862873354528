import { MessageType } from '~/models/MessageTypes';

export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}
export enum NotificationPosition {
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}
export enum NotificationId {
  SERVER_UNREACHABLE = 'SERVER_UNREACHABLE',
  SERVER_REACHABLE = 'SERVER_REACHABLE',
  SERVER_ERROR = 'SERVER_ERROR',
}
export interface Notification {
  id?: string,
  message: MessageType | string,
  type: NotificationType,
  duration?: number | 'permanent'
  position?: NotificationPosition,
}

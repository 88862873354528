import { ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import Item from '~/models/item/Item';
import { ShortcutType } from '~/models/ShortcutType';
import { UnitSize } from '~/models/UnitSize';
import { RootState } from '~/store/state';
import { MagnifyState, MagnifyViewContentType } from '~/store/magnify/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { sortItemsBySortingOption } from '~/models/item/sortItemsBySortingOption';

export default {
  magnifiedItems: (state: MagnifyState, _getters: any, _rootState: RootState, rootGetters) => (windowId: string): Item[] => {
    let items = [];
    if (state.viewContent.type === MagnifyViewContentType.CURRENT_SELECTION) {
      items = rootGetters['selection/globalSelectionItems'];
    } else if (state.viewContent.type === MagnifyViewContentType.FOLDER) {
      items = rootGetters['cloud/viewItemsMap'][windowId].items;
    }
    items = sortItemsBySortingOption(items, state.sortedBy);
    return items;
  },
  magnifyTitle: (state: MagnifyState, _getters: any, _rootState: RootState, rootGetters): string => {
    if (state.viewContent.type === MagnifyViewContentType.CURRENT_SELECTION) {
      return rootGetters['selection/globalSelection']?.name;
    }
    if (state.viewContent.type === MagnifyViewContentType.FOLDER) {
      return rootGetters['cloud/currentMainView']?.name;
    }
    return '';
  },
  magnifiedFolderId: (state: MagnifyState, getters: any, _rootState: RootState, rootGetters): string => {
    if (getters.isFolderMagnified) {
      const mainView = rootGetters['cloud/currentMainView'];
      return mainView.isSingleFolderView ? mainView.folderId : null;
    }
    if (getters.isShortcutMagnified) {
      return state.viewContent.shortcut.type === ShortcutType.FOLDER ? state.viewContent.shortcut.id : null;
    }
    return null;
  },
  magnifiedSelectionId: (state: MagnifyState, getters: any, _rootState: RootState, rootGetters): string => {
    if (getters.isFolderMagnified) {
      const mainView = rootGetters['cloud/currentMainView'];
      return mainView.isSingleSelectionView ? mainView.selectionId : null;
    }
    if (getters.isSelectionMagnified) {
      const currentSelection = rootGetters['selection/globalSelection'];
      return currentSelection ? currentSelection.id : null;
    }
    if (getters.isShortcutMagnified) {
      return state.viewContent.shortcut.type === ShortcutType.SELECTION ? state.viewContent.shortcut.id : null;
    }
    return null;
  },
  isFolderMagnified: (state: MagnifyState): boolean => {
    return state.viewContent.type === MagnifyViewContentType.FOLDER;
  },
  isSelectionMagnified: (state: MagnifyState): boolean => {
    return state.viewContent.type === MagnifyViewContentType.CURRENT_SELECTION;
  },
  isShortcutMagnified: (state: MagnifyState): boolean => {
    return state.viewContent.type === MagnifyViewContentType.SHORTCUT;
  },
  magnifyHeight: (state: MagnifyState): number => {
    return state.height + state.FOOTER_HEIGHT + state.HEADER_HEIGHT;
  },
  magnifyDownloadSize: (_state: MagnifyState, rootGetters: any): UnitSize => {
    // TODO: move download size calculation out to separate function
    const sizeInBytes = rootGetters['cloud/window'](ViewIdentifier.MAIN_VIEW).magnifiedItems.reduce((accumulatedSize: number, currentItem: Item) => {
      const originalAsset = currentItem.assets && currentItem.assets.find(asset => asset.version === ORIGINAL_ASSET_VERSION);
      const originalAssetSize = originalAsset ? originalAsset.size : 0;
      return accumulatedSize + originalAssetSize;
    }, 0);
    if (sizeInBytes > 1000000000) {
      return {
        size: sizeInBytes / 1000000000,
        unit: 'GB',
      };
    }
    if (sizeInBytes > 0) {
      return {
        size: sizeInBytes / 1000000,
        unit: 'MB',
      };
    }
    return {
      size: 0,
      unit: 'MB',
    };
  },
};

import { AccessRight } from '~/models/AccessRight';
import { FolderItem } from '~/models/item/FolderItem';
import { SlimSelection } from '~/models/SlimSelection';
import { ViewType } from '~/models/views/ViewType';
import { Owner } from '~/models/selection/Owner';
import { ChangeSet } from '~/models/ChangeSet';
import { FolderTag } from '~/models/tags/FolderTag';

export default interface Folder {
  id: string;
  deviceId?: string;
  description?: string;
  subtitle?: string;
  parentId?: string;
  name: string;
  slug: string;
  isShared?: boolean;
  accessId?: string;
  accessCode?: string;
  isMultiFolder?: boolean; // folder is multi-folder if it contains data for multiple folders (multiple folders are currently selected by the user)
  isActive?: boolean; // folder is active if it is currently displayed in a view
  isPublic: boolean;
  isPublicWritable: boolean;
  isTransferFolder: boolean;
  isTransferFolderForConnection: string;
  owner: Owner;
  selections: SlimSelection[]
  itemCount?: number;
  sizeInCloud?: string;
  tags?: FolderTag[];
  activeUsers?: { id: string, origin: string }[];
  created: string;
  modified: string;
  accessRights: AccessRight[];
  items?: FolderItem[];
  isSynced?: boolean;
  viewType?: ViewType;
  itemChangeSet?: ChangeSet<FolderItem>;
}

export function getUniqueName(name: string, names: string[]) {
  let nextInt = 1;
  let newName = name;
  while (names.some(n => n.split(' ').join('').toLowerCase() === newName.split(' ').join('').toLowerCase())) {
    newName = `${name} ${nextInt}`;
    nextInt++;
  }
  return newName;
}

import axios, { AxiosRequestConfig } from 'axios';
import { getCookieValueFor } from '~/models/Cookie';
import { CookieKey } from '~/models/CookieKey';
import { WebConfig } from '~/Config';

export class TokenRefresher {
  public static XSRF_TOKEN_HEADER = 'x-xsrf-token';
  public static REFRESH_TOKEN_THRESHOLD_IN_MS = 30_000;
  private static SAVE_OFFSET_AFTER_REFRESH_TOKEN_THRESHOLD = TokenRefresher.REFRESH_TOKEN_THRESHOLD_IN_MS + 1_000;
  private static REFRESH_TOKEN_GLOBAL_LOCK_ID = 'globalRefreshTokenLock'; // must be the same across all apps running in the same domain scope (webapp, admin-dashboard, etc)
  private static LINK_TOKEN_GLOBAL_LOCK_ID = 'globalLinkTokenLock'; // must be the same across all apps using shared links and running in the same domain scope
  cachedExpireUserTokenTime = null;
  cachedExpireLinkTokenTime = null;
  tryRefreshingAuthTokenAgainHandler = null;
  tryRefreshingLinkTokenAgainHandler = null;

  constructor(private userOriginId: string = '') {
  }

  public resetCache(): void {
    this.cachedExpireUserTokenTime = null;
    this.cachedExpireLinkTokenTime = null;
  }

  public async refreshExpiredTokens(sharedLinkAccessId?: string) {
    if (this.shouldRefreshUserTokenCached() && this.shouldActuallyRefreshUserToken()) {
      await this.refreshAuthToken();
      clearTimeout(this.tryRefreshingAuthTokenAgainHandler);
      this.tryRefreshingAuthTokenAgainHandler = null;
    }
    if (this.shouldRefreshLinkTokenCached(sharedLinkAccessId) && this.shouldActuallyRefreshLinkToken(sharedLinkAccessId)) {
      await this.refreshLinkToken({
        accessId: sharedLinkAccessId,
        client_id: 'web',
      });
      clearTimeout(this.tryRefreshingLinkTokenAgainHandler);
      this.tryRefreshingLinkTokenAgainHandler = null;
    }
    if (!this.tryRefreshingAuthTokenAgainHandler) {
      this.setupAuthTokenRefresherAfterTimeout();
    }
    if (sharedLinkAccessId != null && !this.tryRefreshingLinkTokenAgainHandler) {
      this.setupLinkTokenRefresherAfterTimeout(sharedLinkAccessId);
    }
  }

  // Used by axios and websocket to refresh the auth token when is expired
  public async refreshAuthToken() {
    await this.refreshToken(TokenRefresher.REFRESH_TOKEN_GLOBAL_LOCK_ID, '/auth/token');
  }

  // Used by axios to refresh the link token when is expired
  public async refreshLinkToken(payload: { accessId: string, client_id: string }) {
    await this.refreshToken(TokenRefresher.LINK_TOKEN_GLOBAL_LOCK_ID, '/links/auth', payload);
  }

  private getParsedCookieTime(cookieValue: string): number {
    return cookieValue != null ? new Date(parseInt(cookieValue, 10)).getTime() : null;
  }

  private shouldRefreshUserToken(expiresAt: number) {
    if (expiresAt) {
      const tokenHasToBeRefreshedInMs = Date.now() + TokenRefresher.REFRESH_TOKEN_THRESHOLD_IN_MS - expiresAt;
      if (tokenHasToBeRefreshedInMs > 0) {
        this.cachedExpireUserTokenTime = null;
        return true;
      }
      if (!this.cachedExpireUserTokenTime) {
        this.cachedExpireUserTokenTime = expiresAt;
      }
    }
    return false;
  }

  private shouldRefreshLinkToken(linkExpiresAt: number, sharedLinkAccessId: string) {
    if (sharedLinkAccessId != null) {
      if (linkExpiresAt) {
        const tokenHasToBeRefreshedInMs = Date.now() + TokenRefresher.REFRESH_TOKEN_THRESHOLD_IN_MS - linkExpiresAt;
        if (tokenHasToBeRefreshedInMs > 0) {
          this.cachedExpireLinkTokenTime = null;
          return true;
        }
        if (!this.cachedExpireLinkTokenTime) {
          this.cachedExpireLinkTokenTime = linkExpiresAt;
        }
        return false;
      }
      return true;
    }
    return false;
  }

  private shouldRefreshUserTokenCached() {
    return this.shouldRefreshUserToken(this.cachedExpireUserTokenTime || this.getParsedCookieTime(getCookieValueFor(CookieKey.EXPIRES_AT)));
  }

  private shouldActuallyRefreshUserToken() {
    return this.shouldRefreshUserToken(this.getParsedCookieTime(getCookieValueFor(CookieKey.EXPIRES_AT)));
  }

  private shouldRefreshLinkTokenCached(sharedLinkAccessId: string) {
    return this.shouldRefreshLinkToken(this.cachedExpireLinkTokenTime || this.getParsedCookieTime(getCookieValueFor(CookieKey.LINK_EXPIRES_AT)), sharedLinkAccessId);
  }

  private shouldActuallyRefreshLinkToken(sharedLinkAccessId: string) {
    return this.shouldRefreshLinkToken(this.getParsedCookieTime(getCookieValueFor(CookieKey.LINK_EXPIRES_AT)), sharedLinkAccessId);
  }

  private setupAuthTokenRefresherAfterTimeout() {
    const expiresAtAuthToken = this.getParsedCookieTime(getCookieValueFor(CookieKey.EXPIRES_AT));
    if (expiresAtAuthToken != null) {
      let authTokenHasToBeRefreshedInMs = expiresAtAuthToken - Date.now() - TokenRefresher.SAVE_OFFSET_AFTER_REFRESH_TOKEN_THRESHOLD;
      authTokenHasToBeRefreshedInMs = authTokenHasToBeRefreshedInMs < 0 ? 10_000 : authTokenHasToBeRefreshedInMs;
      this.tryRefreshingAuthTokenAgainHandler = setTimeout(() => {
        this.tryRefreshingAuthTokenAgainHandler = null;
        this.refreshExpiredTokens();
      }, authTokenHasToBeRefreshedInMs);
    }
  }

  private setupLinkTokenRefresherAfterTimeout(sharedLinkAccessId: string) {
    const expiresAtLinkToken = this.getParsedCookieTime(getCookieValueFor(CookieKey.LINK_EXPIRES_AT));
    if (expiresAtLinkToken != null) {
      let linkTokenHasToBeRefreshedInMs = expiresAtLinkToken - Date.now() - TokenRefresher.SAVE_OFFSET_AFTER_REFRESH_TOKEN_THRESHOLD;
      linkTokenHasToBeRefreshedInMs = linkTokenHasToBeRefreshedInMs < 0 ? 10_000 : linkTokenHasToBeRefreshedInMs;
      this.tryRefreshingLinkTokenAgainHandler = setTimeout(() => {
        this.tryRefreshingLinkTokenAgainHandler = null;
        this.refreshExpiredTokens(sharedLinkAccessId);
      }, linkTokenHasToBeRefreshedInMs);
    }
  }

  private async isRefreshTokenInProgress(): Promise<boolean> {
    const locks = await navigator.locks.query();
    return locks.held.some(l => l.name === TokenRefresher.REFRESH_TOKEN_GLOBAL_LOCK_ID);
  }

  private async refreshToken(lockId: string, url: string, payload = {}) {
    await navigator.locks.request(lockId, { ifAvailable: true }, async (lock) => {
      if (lock == null) {
        return new Promise((resolve) => {
          const waitOnNewRefreshToken = setInterval(async () => {
            if (await this.isRefreshTokenInProgress() === false) {
              clearInterval(waitOnNewRefreshToken);
              return resolve(true);
            }
          }, 100);
        });
      } else {
        // TODO: Add retry logic on error
        try {
          const config: AxiosRequestConfig = {
            withCredentials: true,
            headers: {
              [TokenRefresher.XSRF_TOKEN_HEADER]: getCookieValueFor(CookieKey.XSRF),
              'user-origin-id': this.userOriginId,
            },
          };
          await axios.post(`${WebConfig.API_URL}${url}`, payload, config);
        } catch (err) {
          console.error('Error while refreshing token', err);
        }
      }
    });
  }
}

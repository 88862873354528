import { v4 as uuid } from 'uuid';
import { EventEmitter } from '~/models/uploader/EventEmitter';
import { PipelineItem } from '~/models/PipelineItem';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { ThumbnailerEvent } from '~/models/thumbnailer/ThumbnailerEvent';
import { WebWorkerEvent } from '~/models/thumbnailer/WebWorkerEvent';
import { QualityType } from '~/models/thumbnailer/QualityType';

interface WebWorker { id: string; instance: Worker; isBusy: boolean; }

export interface QualityConfiguration {
  resizeQuality: QualityType;
  imageQuality: number;
  width: number;
  maxFileWidth: number | null;
  // In KB
  maxFileSize: number;
}

export class Thumbnailer extends EventEmitter {
  public static DEFAULT_QUALITY_CONFIG = {
    resizeQuality: QualityType.MEDIUM,
    imageQuality: 0.8,
    width: 1024,
    maxFileWidth: null,
    maxFileSize: 250_000,
  };

  private total;
  private count;
  private resizeQueue: PipelineItem[] = [];
  private maxBatchFileSize = 10_000_000;
  private maxAmountOfWorkers = navigator.hardwareConcurrency;
  // we could also try to organize workers into multiple categories based on structure of input files / workloads
  // then we could however end up messing with the order of input files
  // (small items at the end of the input spectrum would come in way before larger items, despite the user expecting the larger item to come before the smaller one),
  // which is important to at least loosely keep for UX reasons
  private workerMap: Map<string, WebWorker> = new Map();
  private distributeExtractionIntervalHandler = null;
  private terminateWorkersAfterTimeoutHandler = null;
  private qualityConfiguration: QualityConfiguration;
  private workerSupport = false;

  constructor() {
    super();
    this.count = 0;
    this.total = 0;
    this.qualityConfiguration = Thumbnailer.DEFAULT_QUALITY_CONFIG;
  }

  public get numberOfActiveWorkers(): number {
    return this.workerMap.size;
  }

  public setWorkerSupport(workerSupport: boolean) {
    this.workerSupport = workerSupport;
  };

  public setQualityConfiguration(qualityConfiguration: Partial<QualityConfiguration>) {
    this.qualityConfiguration = { ...this.qualityConfiguration, ...qualityConfiguration };
  }

  public addItems(items: PipelineItem[]): void {
    clearTimeout(this.terminateWorkersAfterTimeoutHandler);
    this.resizeQueue.push(...items);
    this.total += items.length;
    this.startExtracting();
  }

  private calculateMedianImageSize() {
    const fileSizes = this.resizeQueue.map(i => i.file != null ? i.file.size : i.raw.file.size);
    fileSizes.sort((a, b) => a > b ? -1 : 1);
    return fileSizes[Math.floor(fileSizes.length / 2)];
  }

  private sendItemsToWorker(worker) {
    if (!worker.isBusy && this.resizeQueue.length > 0) {
      worker.isBusy = true;
      worker.instance.onmessage = event => this.handleMessage(event);
      worker.instance.postMessage({ items: this.buildOptimalWorkerBatch(), id: worker.id, config: this.qualityConfiguration });
    } else {
      worker.isBusy = false;
    }
  }

  private buildOptimalWorkerBatch() {
    const maxBatchSize = (this.resizeQueue.length || 1) / (this.numberOfActiveWorkers || 1);
    let batchFileSize = 0;
    const batch = [];
    // larger batches make sense for smaller files (since we need to do meaningful work in workers to be efficient and not stress the main thread too much)
    while (this.resizeQueue.length > 0 && batch.length < maxBatchSize && batchFileSize < this.maxBatchFileSize) {
      const item = this.resizeQueue.shift();
      batch.push(item);
      batchFileSize += item.file != null ? item.file.size : item.raw.file.size;
    }
    return batch;
  }

  private distributeExtractionLoad() {
    this.adjustWorkerCountToLoad();
    Array.from(this.workerMap.values()).filter(worker => !worker.isBusy).forEach((worker) => this.sendItemsToWorker(worker));
  }

  private sendItemsToWorkerWith(id: string) {
    const worker = this.workerMap.get(id);
    this.sendItemsToWorker(worker);
  }

  private calculateOptimalWorkerAmount() {
    const medianImageSize = this.calculateMedianImageSize();
    return medianImageSize > 1_000_000 ? this.maxAmountOfWorkers : Math.round(this.maxAmountOfWorkers / 2);
  }

  private adjustWorkerCountToLoad() {
    if (this.numberOfActiveWorkers !== this.maxAmountOfWorkers) {
      const optimalWorkerAmount = this.calculateOptimalWorkerAmount();
      const numberOfWorkersToLaunch = optimalWorkerAmount - this.workerMap.size;
      if (numberOfWorkersToLaunch > 0) {
        for (let i = 0; i < numberOfWorkersToLaunch; i++) {
          const workerId = uuid();
          this.workerMap.set(workerId, { id: workerId, instance: new Worker('./thumbnailWorker.js', { type: 'module' }), isBusy: false });
        }
      }
    }
  }

  private startExtracting() {
    if (this.workerSupport) {
      if (this.distributeExtractionIntervalHandler == null) {
        this.distributeExtractionIntervalHandler = setInterval(() => {
          this.distributeExtractionLoad();
        }, 500);
        this.distributeExtractionLoad();
      }
    } else {
      this.extractDimensionsWithoutWorker();
    }
  }

  private initiateWorkerTermination() {
    clearTimeout(this.terminateWorkersAfterTimeoutHandler);
    this.terminateWorkersAfterTimeoutHandler = setTimeout(() => this.terminateWorkers(), 5000);
  }

  private terminateWorkers() {
    clearInterval(this.distributeExtractionIntervalHandler);
    this.distributeExtractionIntervalHandler = null;
    this.workerMap.forEach(w => w.instance.terminate());
    this.workerMap.clear();
    this.count = 0;
    this.resizeQueue = [];
    this.total = 0;
  }

  private handleMessage = event => {
    if (event.data.type === WebWorkerEvent.WORK_COMPLETED) {
      this.sendItemsToWorkerWith(event.data.id);
    } else if (event.data.type === WebWorkerEvent.ITEM_PROCESSED) {
      const processedItem: PipelineItem = event.data.item;
      processedItem.eventsProcessed.push(event.data.error ? { type: PipelineEventType.THUMBNAILS_CREATED, error: event.data.error } : { type: PipelineEventType.THUMBNAILS_CREATED });
      this.emit(ThumbnailerEvent.ITEMS_RESIZED, [processedItem]);
      if (this.count === this.total - 1) {
        // Work is done for all items in queue, trigger termination of workers
        this.initiateWorkerTermination();
      } else {
        this.count += 1;
      }
    }
  };

  private extractDimensionsWithoutWorker() {
    // TODO: use pica here
    throw new Error('not implemented yet');
  }
}

import Vue from 'vue';
import VueGtag from 'vue-gtag';
import { Analytics } from '~/models/analytics/Analytics';
import { AnalyticsOptions } from '~/models/analytics/analyticsOptions';

export default (context: any, inject: any) => {
  Vue.use(VueGtag, {
    ...AnalyticsOptions,
  }, context.app.router);
  // @ts-ignore
  inject('analytics', new Analytics(Vue.$gtag));
};

import moment from 'moment';
import Vue from 'vue';

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY/MM/DD hh:mm');
  }
});

Vue.filter('formatDateDotWithoutTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

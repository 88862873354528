import { Position } from '~/models/views/SnippetMover';
import { ViewOffsets } from '~/models/views/ViewOffsets';

export class DropPositionDetails {
  public relativePosition: Position;

  constructor(public position: Position, public viewOffsets: ViewOffsets) {
    const { scrollLeft, scrollTop, windowTop, windowLeft } = viewOffsets;
    this.relativePosition = {
      left: position.left - windowLeft + scrollLeft,
      top: position.top - windowTop + scrollTop,
    };
  }
}

import moment from 'moment';
// @ts-ignore
import * as Vibrant from 'node-vibrant';
import _cloneDeep from 'lodash.clonedeep';
import _uniqBy from 'lodash.uniqby';
import {
  accumulateAssetSizeInBytes,
  Asset,
  AssetWithFolderTag,
  getAssetVersion,
  getLargestAssetBelowWidth,
  itemHasAssetVersions,
  ORIGINAL_ASSET_VERSION,
  transformToUnit
} from '~/models/Asset';
import Folder from '~/models/Folder';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { UnitSize } from '~/models/UnitSize';
import { RootState } from '~/store/state';
import { ViewSortingOption } from '~/store/cloud/state';
import { FolderState } from '~/store/folder/state';
import { sortItemsBySortingOption } from '~/models/item/sortItemsBySortingOption';
import Item from '~/models/item/Item';
import { FolderTag } from '~/models/tags/FolderTag';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { TagParser } from '~/models/tags/TagParser';
import { ObjectId } from '~/models/ObjectId';
import { ItemListBuilder } from '~/models/item/ItemListBuilder';
import { ItemList } from '~/models/item/ItemList';
import { byIncludesVersionsOrSubstitutes } from '~/models/asset/filters/byIncludesVersionsOrSubstitutes';
import { buildVersionSubstituteMap } from '~/models/asset/buildVersionSubstituteMap';
import { byOnlyLargest } from '~/models/asset/filters/byOnlyLargest';

export interface FolderTagHierarchy {
  tag: FolderTag,
  children: FolderTagHierarchy[],
}

export interface FolderTagHierarchyWithAssets extends FolderTagHierarchy {
  assets: AssetWithFolderTag[],
  children: FolderTagHierarchyWithAssets[]
}

function addChildren(allTags: FolderTag[], tag: FolderTagHierarchy, depth: number, itemsForTag: Map<string, number>): number {
  if (depth > 2) {
    return 0;
  }
  const children = allTags.filter(t => t.parentId === tag.tag.id);
  let ownCountWithChildrenCount: number = 0;
  for (const child of children) {
    const tagHierarchy = { tag: child, children: [] };
    const newDepth = depth + 1;
    const count = addChildren(allTags, tagHierarchy, newDepth, itemsForTag);
    if (count + itemsForTag.get(child.id) > 0) {
      tag.children.push(tagHierarchy);
    }
    ownCountWithChildrenCount = ownCountWithChildrenCount + count + itemsForTag.get(child.id);
  }
  return ownCountWithChildrenCount;
}

export default {
  itemById: (state: FolderState) => (itemId: string): Item => {
    return state.items.get(itemId);
  },
  tagsByMainView: (_state: FolderState, getters: any): FolderTagHierarchy[] => {
    return getters.tagsByView(ViewIdentifier.MAIN_VIEW);
  },
  tagsByView: (_state: FolderState, getters: any, _rootState: RootState, rootGetters: any) => (viewId: ViewIdentifier): FolderTagHierarchy[] => {
    const viewObjectId: ObjectId = rootGetters['cloud/viewObjectId'](viewId);
    return viewObjectId?.isFolderId ? getters.tagsByFolderId(viewObjectId.toUuid()) : [];
  },
  tagsByFolderId: (state: FolderState) => (folderId: string): FolderTagHierarchy[] => {
    if (folderId != null && state.folders[folderId] != null && state.folderItems[folderId] != null) {
      const tags = _cloneDeep(state.folders[folderId].tags);
      if (tags != null && tags.length > 0) {
        const itemCountForTag = new Map<string, number>(tags?.map(t => [t.id, state.folderItems[folderId].filter(f => t.id === f.item.folderTagId)?.length]));
        return (tags?.filter(t => t.parentId == null)?.map(t => ({ tag: t, children: [] } as FolderTagHierarchy)) ?? [])
          .reduce((prev, current) => {
            const childCount = addChildren(tags, current, 1, itemCountForTag);
            if (childCount + itemCountForTag.get(current.tag.id) > 0) {
              prev.push(current);
            }
            return prev;
          }, [] as FolderTagHierarchy[]);
      }
    }
    return [];
  },
  relevantTagStructureByTagIds: (state: FolderState) => (tagIds: Set<string>): FolderTagHierarchy[] => {
    const allTagsById = new Map<string, FolderTag>();
    const allTags = Object.values(state.folders).map(f => f.tags).flat();
    allTags.forEach(t => allTagsById.set(t.id, t));
    const tags = allTags.filter(t => tagIds.has(t.id));
    const relevantTags = _uniqBy(tags.map(t => [t, ...TagParser.getParentTagsForTag(t, allTagsById)]).flat(), 'id');
    return TagParser.buildFolderTagHierarchy(relevantTags);
  },
  scrapbookId: (state: FolderState): string => {
    return Object.values(state.folders).find(f => f.name === 'scrapbook')?.id || null;
  },
  isFolderWithDataLimitation: (state: FolderState) => (folderId: string): boolean => {
    return state.folders[folderId]?.id === folderId;
  },
  scrapbook: (state: FolderState, getters: any): Folder => {
    return _cloneDeep(state.folders[getters.scrapbookId] || null);
  },
  isFolderOwner: (state: FolderState, _getters: any, _rootState: RootState, rootGetters: any) => (folderId: string) => {
    const folder = state.folders[folderId];
    return folder?.owner?.id === rootGetters['user/currentUser']?.id;
  },
  exists: (state: FolderState) => (folderId: string): boolean => {
    return !!state.folders[folderId];
  },
  foldersWithItems: (state: FolderState): Folder[] => {
    return _cloneDeep(Object.values(state.folders)).map((f) => ({
      ...f,
      items: _cloneDeep(state.folderItems[f.id] || []),
    }));
  },
  foldersWithItemsByIdMap: (_state: FolderState, getters: any): { [folderId: string]: Folder } => {
    const obj = {};
    getters.foldersWithItems.forEach((f) => {
      obj[f.id] = f;
    });
    return obj;
  },
  folders: (state: FolderState): { [folderId: string]: Folder } => {
    return _cloneDeep(state.folders);
  },
  hasSelectedItems: (_state: FolderState, getters: any, _rootState: RootState, rootGetters: any) => (folderId: string) => {
    return rootGetters['selection/isOneOfItemsSelected'](getters.folderItemsById[folderId]);
  },
  folderWithItemsById: (_state: FolderState, getters: any) => (id: string): Folder => {
    return getters.foldersWithItemsByIdMap[id];
  },
  folderById: (_state: FolderState, getters: any) => (id: string): Folder => {
    return getters.folders[id];
  },
  folderItemsById: (state: FolderState) => (id: string): ItemWithPosition[] => {
    return _cloneDeep(state.folderItems[id] || []);
  },
  offlineFolderItemsById: (_state: FolderState, getters: any) => (id: string): ItemWithPosition[] => {
    return getters.folderItemsById(id).filter(item => item.item?.assets.some(a => a.isOffline));
  },
  onlineFolderItemsById: (_state: FolderState, getters: any) => (id: string): ItemWithPosition[] => {
    return getters.folderItemsById(id).filter(item => item.item?.assets.some(a => !a.isOffline));
  },
  folderItemsInCurrentView: (_state: FolderState, getters: any, _rootState: RootState, rootGetters: any): ItemWithPosition[] => {
    const folderId = rootGetters['cloud/cloudObjectForView'](ViewIdentifier.MAIN_VIEW)?.objectId?.toUuid();
    return getters.folderItemsById(folderId);
  },
  offlineItemsByVersion: (_state: FolderState, getters: any) => (id: string, versions: number[]): ItemList => {
    return new ItemListBuilder()
      .withItems(getters.offlineFolderItemsById(id))
      .withAssetFilter(byIncludesVersionsOrSubstitutes(buildVersionSubstituteMap(versions)))
      .build();
  },
  offlineItemsSizeByVersion: (_state: FolderState, getters: any) => (id: string, versions: number[]): UnitSize => {
    return getters.offlineItemsByVersion(id, versions)
      .assetList
      .asRoundedUnitSize;
  },
  folderItemsByIdSorted: (_state: FolderState, getters: any) => (id: string, sortingOption: ViewSortingOption): ItemWithPosition[] => {
    const items: ItemWithPosition[] = _cloneDeep(getters.folderItemsById(id));
    return sortItemsBySortingOption(items, sortingOption);
  },
  folderFileNames: (_state: FolderState, getters: any) => (folderId: string): string[] => {
    return getters.folderItemsById(folderId)
      .filter(item => !item.item.isPlaceholder)
      .map((item: ItemWithPosition) => {
        const originalAssetName = item.item.name;
        return originalAssetName || '';
      });
  },
  filteredFolderAssetsByVersion: (_state: FolderState, getters: any) => (folderId: string, version: number): Asset[] => {
    return getters.folderItemsById(folderId)
      .filter(item => !!getAssetVersion(item.item, version))
      .map(item => getAssetVersion(item.item, version));
  },
  filteredAssetsByWidth: (_state: FolderState, getters: any) => (folderId: string, width: number): Asset[] => {
    return getters.folderItemsById(folderId)
      .map(item => getLargestAssetBelowWidth(item.item, width))
      .filter(asset => asset !== null);
  },
  commonFolderColors: (_state: FolderState, getters: any) => (folderId: string): any[][] => {
    // @ts-ignore
    return getters.folderWithItemsById(folderId)
      ? getters.folderWithItemsById(folderId).items.reduce((accumulatedColors: any[], currentItem: ItemWithPosition) => {
        if (!currentItem.item.colors || !currentItem.item.colors.length) {
          return accumulatedColors;
        }
        const newColors = [];
        let isSame = false;
        const currentItemColorsByPopulation = JSON.parse(JSON.stringify(currentItem.item.colors))
          .sort((a: any, b: any) => a.population > b.population ? -1 : 1);
        const mostPopulatedColor = currentItemColorsByPopulation[0];
        for (const accumulatedColor of accumulatedColors) {
          if (Vibrant.Util.rgbDiff(mostPopulatedColor.rgb, accumulatedColor) < 10) {
            isSame = true;
            break;
          }
        }
        if (!isSame) {
          newColors.push(mostPopulatedColor.rgb);
        }
        return [...accumulatedColors, ...newColors];
      }, [])
      : [];
  },
  folderItemCount: (_state: FolderState, getters: any) => (folderId: string): number => {
    const folderById = getters.folderWithItemsById(folderId);
    return folderById?.items?.length ?? 0;
  },
  folderItemCountByVersion: (_state: FolderState, getters: any) => (folderId: string, version?: number[]): number => {
    const items = getters.folderWithItemsById(folderId)?.items?.filter(item => version ? itemHasAssetVersions(item, version) : item);
    return items?.length ?? 0;
  },
  folderSizeInBytes: (_state: FolderState, getters: any) => (folderId: string, filterByAssetVersions?: number[], includeOfflineItems = false): number => {
    const folderById = getters.folderWithItemsById(folderId);
    return folderById?.items?.length > 0
      ? accumulateAssetSizeInBytes(folderById.items, filterByAssetVersions, includeOfflineItems)
      : (folderById?.sizeInCloud ?? 0);
  },
  folderSizeBiggestAssets: (_state: FolderState, getters: any) => (folderId: string): UnitSize => {
    const folderById = getters.folderWithItemsById(folderId);
    return new ItemListBuilder()
      .withItems(folderById?.items)
      .withAssetFilter(byOnlyLargest)
      .build()
      .assetList
      .asRoundedUnitSize;
  },
  folderSizeInCloud: (_state: FolderState, getters: any) => (folderId: string, version = ORIGINAL_ASSET_VERSION, includeOfflineItems = false): UnitSize => {
    const sizeInBytes = getters.folderSizeInBytes(folderId, [version], includeOfflineItems);
    return transformToUnit(sizeInBytes);
  },
  totalSizeInCloud: (state: FolderState): UnitSize => {
    const sizeInBytes = Object.values(state.folders).reduce((accumulatedSize: number, folder: Folder) => {
      const folderSize = folder.items?.length
        ? folder.items.reduce((acc: number, item: ItemWithPosition) => {
          return acc + (item.item ? item.item.assets.reduce((acc: number, asset: Asset) => acc + asset.size, 0) : 0);
        }, 0)
        : parseInt(folder.sizeInCloud);
      return accumulatedSize + folderSize;
    }, 0);
    if (sizeInBytes > 100000000) {
      return {
        size: sizeInBytes / 1000000000,
        unit: 'GB',
      };
    }
    if (sizeInBytes > 0) {
      return {
        size: sizeInBytes / 1000000,
        unit: 'MB',
      };
    }
    return {
      size: 0,
      unit: 'MB',
    };
  },
  sharedFolders: (state: FolderState, _getters, _rootState: RootState, rootGetters: any): Folder[] => {
    return Object.values(state.folders)
      .map(f => f)
      .filter(f => f.isShared && f.owner.id === rootGetters['user/currentUser'].id)
      .sort((a, b) => moment(a.modified).isBefore(b.modified) ? 1 : -1);
  },
  linkDataExists: (state: FolderState, _getters, _rootState: RootState, rootGetters: any) => (folderId): boolean => {
    const folder = state.folders[folderId];
    return folder?.isShared && rootGetters['link/linkDataExists'](ObjectId.fromFolderId(folderId).toString());
  },
};

import { FolderItem } from '~/models/item/FolderItem';
import { BatchProgressInfo } from '~/store/file/actions';
import { QualityConfiguration, Thumbnailer } from '~/models/thumbnailer/Thumbnailer';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { StorageKey } from '~/models/storage/StorageKey';
import { PipelineItem } from '~/models/PipelineItem';
import { ObjectId } from '~/models/ObjectId';

export enum UploadProcessStatus {
  INDEXING = 'INDEXING',
  UPLOADING = 'UPLOADING',
}

export enum ProgressType {
  DOWNLOADING = 'DOWNLOADING',
  ZIPPING = 'ZIPPING'
}

export interface ExternalUploadProgress {
  uploadId: string;
  folderId?: string;
  selectionId?: string;
  itemProgress: { itemId: string, progress: number }[];
  progressInPercent: number;
  progressThumbs: number;
  currentItems: number;
  totalItems: number;
}

export interface Progress {
  type: ProgressType;
  batches: BatchProgressInfo[];
  percentTransferred: number;
  uploadedItems: FolderItem[];
  filesTransferred: number;
  bytesTransferred: number;
  maxFiles: number;
  maxBytes: number;
  finished: boolean;
  aborted: boolean;
  errors: any[];
}

export interface PipelineOptions {
  steps: PipelineCommandInstruction[];
}

export interface UploadProcess {
  status: UploadProcessStatus;
  objectId: ObjectId;
  hasRawFiles: boolean;
  items: PipelineItem[];
  pipelineOptions?: PipelineCommandType[];
  replaceExistingFiles?: boolean;
}
export interface MatchingProcess {
  hasRawFiles: boolean;
  matchedItems: PipelineItem[];
  pipelineOptions: PipelineCommandType[];
  filesForMatching?: File[];
  objectId: ObjectId;
}

export interface FileState {
  uploadProcess: UploadProcess;
  matchingProcess: MatchingProcess;
  currentlyPreparingUpload: boolean;
  externalUploadProgress: ExternalUploadProgress | null;
  uploadProgress: Progress | null;
  abortNextUpload: boolean;
  downloadProgress: Progress | null;
  abortNextDownload: boolean;
  thumbnailQualityConfig: QualityConfiguration;
  pipelineOptions: PipelineOptions;
}

export default (): FileState => ({
  uploadProcess: null as UploadProcess,
  matchingProcess: null as MatchingProcess,
  currentlyPreparingUpload: false,
  externalUploadProgress: null,
  uploadProgress: null,
  abortNextUpload: false,
  downloadProgress: null,
  abortNextDownload: false,
  thumbnailQualityConfig: localStorage.getItem(StorageKey.THUMBNAIL_CONFIG)
    ? JSON.parse(localStorage.getItem(StorageKey.THUMBNAIL_CONFIG))
    : Thumbnailer.DEFAULT_QUALITY_CONFIG,
  pipelineOptions: localStorage.getItem(StorageKey.PIPELINE_OPTIONS)
    ? JSON.parse(localStorage.getItem(StorageKey.PIPELINE_OPTIONS))
    : {
        steps: [
          { type: PipelineCommandType.UPLOAD_THUMBNAILS, waitsForCompletion: true, isActive: false },
          { type: PipelineCommandType.UPLOAD_ORIGINALS, waitsForCompletion: true, isActive: false },
          { type: PipelineCommandType.UPLOAD_RAWS, waitsForCompletion: true, isActive: false },
        ] as PipelineCommandInstruction[],
      },
});

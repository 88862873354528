const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['cloud-menu'] = require('../middleware/cloud-menu.ts')
middleware['cloud-menu'] = middleware['cloud-menu'].default || middleware['cloud-menu']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware

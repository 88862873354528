import { v4 as uuid } from 'uuid';
import { ActionContext, ActionTree, Dispatch } from 'vuex';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { buffer, delay, filter, map, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { last, reduce } from 'rxjs/operators';
import {
  Asset,
  AssetWithFolderTag,
  CUSTOM_SUB_VERSION,
  ORIGINAL_ASSET_VERSION,
  RAW_ASSET_VERSION
} from '~/models/Asset';
import { getFileExtension, getFileNameForAsset, getUniqueFileName } from '~/models/File';
import { FolderItem } from '~/models/item/FolderItem';
import { BatchFile } from '~/models/uploader/BatchFile';
import { containsErrorFreeEventType, PipelineEventProcessedStatus, PipelineItem } from '~/models/PipelineItem';
import { RootState } from '~/store/state';
import { FileState, PipelineOptions, ProgressType, UploadProcessStatus } from '~/store/file/state';
import { MutantContentView } from '~/models/views/MutantContentView';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { UploadProgressEventData } from '~/models/socket/events/UploadProgressEventData';
import { Notification, NotificationPosition, NotificationType } from '~/models/Notification';
import { ViewType } from '~/models/views/ViewType';
import { FileValidator, ValidationParams } from '~/models/FileValidator';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { InfoMessages, MessageType, WarningMessages } from '~/models/MessageTypes';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { ItemType } from '~/models/item/ItemType';
import Item from '~/models/item/Item';
import { QualityConfiguration } from '~/models/thumbnailer/Thumbnailer';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { TaggedFile } from '~/models/tags/TaggedFile';
import { FolderTagHierarchy } from '~/store/folder/getters';
import { ZipBatch } from '~/models/file/ZipBatch';
import { ZipBuilder } from '~/models/file/ZipBuilder';
import { ContextMenuType } from '~/store/context/state';
import { ObjectId } from '~/models/ObjectId';
import { FileMatcher } from '~/models/file/FileMatcher';
import {
  FileProcessingPipeline,
  pipelineCommandToProgressEventMap,
  pipelineCommandToRelatedEventMap
} from '~/models/pipeline/FileProcessingPipeline';
import { WebConfig } from '~/Config';
import { InternalEventType } from '~/models/InternalEventType';
import { UploadOption } from '~/models/UploadOption';
import { FilterOption } from '~/store/cloud/state';
import { filterByConditions } from '~/models/utility/filterByConditions';

export interface BatchProgressInfo {
  id: string;
  files: BatchFile[];
  progress: number;
}

export interface UploadData {
  windowId: string;
  event: AddExternalContentEvent;
  pipelineCommands?: PipelineCommandType[];
  uploadOptions?: UploadOption[];
}

export interface MatchUploadData {
  windowId: string;
  event: AddExternalContentEvent;
}

interface DownloadAssetsPayload {
  assets: AssetWithFolderTag[],
  zipFolderName: string
  keepFolderStructure?: boolean;
}

interface PipelineItemErrorInsights {
  rawExtension: string;
  rawSize: number;
  originalExtension: string;
  originalSize: number;
  thumbnailExtension: string;
  thumbnailSize: number;
  eventsProcessed: PipelineEventProcessedStatus[];
}

// do not use directly, interface only exists for readable type declaration
interface BasePayload {
  itemsWithPosition: ItemWithPosition[],
  pipelineOptions: PipelineCommandType[],
  pipelineId?: string,
  folderId: string,
  objectId: ObjectId,
  files: TaggedFile[],
  idList?: string[],
  isUploadAllowed?: boolean,
  pipelineItems: PipelineItem[];
  pipelineCommands: PipelineCommandType[];
  commands: PipelineCommandInstruction[],
  retry?: boolean,
  status: UploadProcessStatus,
  replaceExistingFiles: boolean,
  uploadOptions: UploadOption[],
}

export type MatchItems = Pick<BasePayload, 'pipelineOptions' | 'pipelineId' | 'replaceExistingFiles'>;
export type UploadItems = Pick<BasePayload, 'itemsWithPosition' | 'pipelineOptions' | 'pipelineId'>;
export type UploadMatchedItems = Pick<BasePayload, 'folderId' | 'pipelineId'>;
export type UploadProcessParams = Pick<BasePayload, 'replaceExistingFiles' | 'pipelineItems' | 'objectId' | 'pipelineOptions'>;
export type UploadFilesTo = Pick<BasePayload, 'folderId' | 'files' | 'idList' | 'pipelineId' | 'isUploadAllowed' | 'pipelineCommands' | 'uploadOptions'>;
export type UploadPipelineItems = Pick<BasePayload, 'folderId' | 'pipelineId' | 'pipelineItems' | 'commands' | 'isUploadAllowed' | 'retry'>;
export type InitializeOrContinueUploadProcess = Pick<BasePayload, 'pipelineItems' | 'commands' | 'objectId'>;
export type AddItemsToExistingPipeline = Pick<BasePayload, 'folderId' | 'pipelineId' | 'pipelineItems' | 'commands' | 'isUploadAllowed' | 'retry'>;
export type AddItemsToNewPipeline = Pick<BasePayload, 'folderId' | 'pipelineId' | 'pipelineItems' | 'commands' | 'isUploadAllowed'>;

type FileContext = ActionContext<FileState, RootState>;

export const viewProgressSubscriptionMap: Map<string, Subscription[]> = new Map();
const pipelineErrorSubscriptionMap: Map<string, Subject<boolean>> = new Map();

const actions: ActionTree<FileState, RootState> = {
  togglePipelineStep({ state, commit }: FileContext, command: PipelineCommandType) {
    commit('setPipelineSteps', state.pipelineOptions.steps.map(step => step.type === command ? { ...step, isActive: !step.isActive } : step));
  },
  setExternalUploadProgress({ commit }: FileContext, progress: UploadProgressEventData) {
    commit('setExternalUploadProgress', progress);
    if (progress.progressInPercent >= 100 && progress?.progressThumbs >= 100) {
      setTimeout(() => commit('setExternalUploadProgress', null), 1500);
    }
  },
  async downloadSingleFileAssets(_context: FileContext, assets: Asset[]) {
    for (const asset of assets) {
      let blobData;
      if (asset.file) {
        blobData = asset.file;
      } else if (asset.base64) {
        const response = await fetch(asset.base64);
        blobData = await response.blob();
      } else {
        const { data } = await this.$api.get(`/assets/${asset.id}/download?hash=${asset.hash}`, { responseType: 'blob' });
        blobData = data;
      }
      this.$fileSaver.saveAs(blobData, asset.name);
    }
  },
  abortDownload({ commit }: FileContext) {
    commit('abortDownload');
  },
  abortUpload({ commit }: FileContext) {
    commit('abortUpload');
  },
  dismissUpload({ state, dispatch, rootState, rootGetters }: FileContext) {
    const mainViewObjectId = rootState.cloud.registeredWindows[ViewIdentifier.MAIN_VIEW].viewIds[0];
    if (mainViewObjectId != null) {
      // TODO: Info - In some cases we need to dismiss assets instead of whole items, this case is currently not being handled here -> will be relevant for matching files
      const itemsToDismiss = rootGetters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW)
        .filter(item => state.uploadProcess?.items?.some(i => i.id === item.item?.id));
      dispatch('endUploadProcess');
      dispatch('cancelPipeline', mainViewObjectId.toUuid());
      if (mainViewObjectId.isSelectionId) {
        dispatch('selection/removeItemsFromSelection', { items: itemsToDismiss, selectionId: mainViewObjectId.toUuid() }, { root: true });
      } else {
        // We throttle the removal so that incoming items will also be cleaned up
        throttle(() => dispatch('folder/removeItems', { items: itemsToDismiss, folderId: mainViewObjectId.toUuid() }, { root: true }), 3000, { leading: false, trailing: true })();
      }
    }
  },
  dismissMatchAndUpload({ dispatch, rootState }: FileContext) {
    const mainViewObjectId = rootState.cloud.registeredWindows[ViewIdentifier.MAIN_VIEW].viewIds[0];
    if (mainViewObjectId != null) {
      dispatch('cancelPipeline', mainViewObjectId.toUuid());
      dispatch('endMatchingProcess');
    }
  },
  dismissDownload({ commit }: FileContext) {
    commit('dismissDownload');
  },
  async downloadAssets({ commit, state, rootGetters }: FileContext, payload: DownloadAssetsPayload) {
    const { keepFolderStructure, zipFolderName, assets } = payload;
    commit('updateDownloadProgress', {
      type: ProgressType.DOWNLOADING,
      percentTransferred: 0,
      filesTransferred: 0,
      bytesTransferred: 0,
      maxFiles: assets.length,
      maxBytes: Array.from(assets).reduce((acc: number, curr: Asset) => acc + curr.size, 0),
      finished: false,
      errors: [],
    });
    let filesTransferred = 0;
    const allFileNames = [];
    const folderTags: FolderTagHierarchy[] = keepFolderStructure
      ? rootGetters['folder/relevantTagStructureByTagIds'](new Set(assets.map(asset => asset.folderTagId)))
      : [];
    const zipBatch: ZipBatch = this.$zipBuilder.buildSingleBatch(zipFolderName, folderTags, assets);
    const assetBatches = buildAssetBatches(assets, 6);
    try {
      for (const batch of assetBatches) {
        if (!state.abortNextDownload) {
          const promises = [];
          for (const asset of batch) {
            if (asset.file != null) {
              promises.push({ data: asset.file });
            } else if (asset.id != null) {
              promises.push(this.$api.get(`/assets/${asset.id}/download?hash=${asset.hash}`, { responseType: 'blob' }));
            }
          }
          const results = await Promise.all(promises);
          results.forEach((result, idx) => {
            const asset = batch[idx];
            const fileName = getUniqueFileName(getFileNameForAsset(asset), allFileNames);
            const zipFolderId = keepFolderStructure && asset.folderTagId ? asset.folderTagId : ZipBuilder.ROOT_TAG_ID;
            zipBatch.folders.get(zipFolderId).file(fileName, result.data);
            allFileNames.push(fileName);
          });
          filesTransferred += batch.length;
          commit('updateDownloadProgress', {
            type: ProgressType.DOWNLOADING,
            percentTransferred: Math.round(filesTransferred / state.downloadProgress.maxFiles * 100),
            filesTransferred,
            bytesTransferred: 0,
            finished: filesTransferred === state.downloadProgress.maxFiles,
          });
        } else {
          commit('abortDownloadSuccess');
          break;
        }
      }
      if (!state.abortNextDownload) {
        const content = await zipBatch.zipRoot.generateAsync({ type: 'blob' }, (progress) => commit('updateDownloadProgress', {
          type: ProgressType.ZIPPING,
          percentTransferred: progress.percent,
        }));
        this.$fileSaver.saveAs(content, `mutant-${payload.zipFolderName}.zip`);
      }
    } catch (error) {
      this.$log.error('Error while downloading assets', error);
    } finally {
      zipBatch.zipRoot.remove(payload.zipFolderName);
    }
  },
  async initializeMatchingProcess({
    dispatch,
    rootGetters,
    state,
    commit,
  }: FileContext, { payload }: ActionPayload<MatchUploadData>) {
    const { event, windowId } = payload;
    const uploadDestinationView: MutantContentView = rootGetters['cloud/view'](windowId);
    const folderId = uploadDestinationView.folderId;
    const folderStructure = await this.$fileParser.parseFileFolderStructure(uploadDestinationView.folderId, event.event);
    const files: TaggedFile[] = await dispatch('validateFiles', { folderId, files: folderStructure.files });
    if (files.length) {
      if (state.matchingProcess == null) {
        const hasRawFiles = files.some(f => FileValidator.isRawFile(f.file));
        commit('setMatchingProcess', { hasRawFiles, filesForMatching: files.map(f => f.file), objectId: ObjectId.fromFolderId(folderId) });
      } else {
        console.error('Matching process already running');
      }
    }
  },
  async addFilesToMatchingProcess({
    dispatch,
    rootGetters,
    commit,
  }: FileContext, { payload }: ActionPayload<MatchUploadData>) {
    const { event, windowId } = payload;
    const uploadDestinationView: MutantContentView = rootGetters['cloud/view'](windowId);
    const folderId = uploadDestinationView.folderId;
    const folderStructure = await this.$fileParser.parseFileFolderStructure(uploadDestinationView.folderId, event.event);
    const files: TaggedFile[] = await dispatch('validateFiles', { folderId, files: folderStructure.files });
    if (files.length) {
      const hasRawFiles = files.some(f => FileValidator.isRawFile(f.file));
      commit('addFilesForMatchingToUploadProcess', { hasRawFiles, filesForMatching: files.map(f => f.file) });
    }
  },
  matchFilesToItems({ rootGetters, state, commit, dispatch }: FileContext, { payload }: ActionPayload<MatchItems>) {
    const objectId: ObjectId = rootGetters['cloud/viewObjectId'](ViewIdentifier.MAIN_VIEW);
    const { pipelineId, pipelineOptions, replaceExistingFiles } = payload;
    // TODO: move this into own action
    let existingItems = rootGetters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW);
    commit('folder/clearMatchedWithFromItems', { folderId: objectId.toUuid(), itemIds: existingItems.map(i => i.id) }, { root: true });
    existingItems = rootGetters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW);
    const { filesForMatching } = state.matchingProcess;

    if (rootGetters['cloud/hasObjectSelectedItems'](objectId)) {
      existingItems = rootGetters['selection/filterSelectedItems'](existingItems);
    }

    const pipelineItems = FileMatcher.match({
      pipelineId,
      existingItems,
      filesForMatching,
      pipelineOptions,
      replaceExistingFiles,
    });
    commit('setMatchedItemsAndOptions', { pipelineItems, pipelineOptions });
    if (pipelineItems.length > 0) {
      dispatch('folder/matchItemAssets', pipelineItems, { root: true });
    }
  },
  uploadMatchedItems({
    dispatch,
    state,
  }: FileContext, { payload }: ActionPayload<UploadMatchedItems>) {
    const pipelineItems = state.matchingProcess.matchedItems;
    if (pipelineItems.length > 0) {
      const { folderId, pipelineId } = payload;
      const command = state.matchingProcess.pipelineOptions.includes(PipelineCommandType.UPLOAD_THUMBNAILS) ? PipelineCommandType.CREATE_THUMBNAILS : PipelineCommandType.EXTRACT_METADATA;
      const pipelineOptions = [command, ...state.matchingProcess.pipelineOptions];
      const commands = FileProcessingPipeline.buildPipelineCommandInstruction(FileProcessingPipeline.sortPipelineCommands(pipelineOptions));
      dispatch<ActionPayload<UploadPipelineItems>>({
        type: 'uploadPipelineItems',
        payload: {
          folderId,
          pipelineId,
          pipelineItems,
          commands,
        },
      });
    }
  },
  async sendItems(context: FileContext, { items, destination }: { items: ItemWithPosition[], destination: string }) {
    const allItemsAreSynced = items.length && items.every(item => item.item.isSynced);
    if (!allItemsAreSynced) {
      await context.dispatch('setNotificationMessage', {
        payload: {
          message: 'The selected items need to be synced first.',
          type: NotificationType.ERROR,
          duration: 5000,
          position: NotificationPosition.TOP_RIGHT,
        },
      }, { root: true });
    } else if (context.rootGetters['user/currentUser']?.id) {
      const itemIds = items.map(item => item.item.id);
      await this.$api.post(`/items/send?target-user-id=${destination}`, {
        items: itemIds,
      });
    }
  },
  async upload({
    commit,
    dispatch,
    state,
    rootGetters,
  }: FileContext, data: ActionPayload<UploadData>) {
    if (rootGetters.pipelineFinished(state.uploadProcess?.objectId?.toUuid())) {
      await dispatch('endUploadProcess');
    }
    const { event, windowId, pipelineCommands, uploadOptions = [] } = data.payload;
    if (!state.currentlyPreparingUpload) {
      if (state.pipelineOptions?.steps?.length > 0 && !rootGetters['context/isOpen'](ContextMenuType.UPLOAD_CONTROL)) {
        pipelineCommands.push(...state.pipelineOptions.steps.filter((step) => step.isActive).map((s) => s.type));
      }
      const uploadDestinationView: MutantContentView = rootGetters['cloud/view'](windowId);
      const isUploadAllowed = !rootGetters['user/isGuest'] && uploadDestinationView.owners.includes(rootGetters['user/currentUser'].id);
      if (uploadDestinationView.isSingleSelectionView) {
        return await createScrapbookAndUpload(rootGetters, dispatch, uploadDestinationView, event, isUploadAllowed, pipelineCommands);
      }
      const folderId = uploadDestinationView.isEmpty ? uuid() : uploadDestinationView.folderId;
      const folderStructure = await this.$fileParser.parseFileFolderStructure(folderId, event.event);
      let filesToUpload = folderStructure.files;
      if (uploadOptions.includes(UploadOption.IGNORE_EXISTING_FILES)) {
        const existingItems = rootGetters['cloud/itemsForCloudObject'](rootGetters['cloud/currentCloudObject']);
        filesToUpload = FileMatcher.ignoreExistingFiles(existingItems, folderStructure.files);
        if (filesToUpload.length === 0) {
          await dispatch<ActionPayload<Notification>>(
            {
              type: 'setNotificationMessage',
              payload: { message: WarningMessages.EMPTY_FILE_LIST, type: NotificationType.INFO, duration: 5000 },
            },
            { root: true });
          await dispatch('context/closeMenu', ContextMenuType.UPLOAD_CONTROL, { root: true });
          return;
        }
      }
      commit('folder/setFolderTagsForFolder', { folderId, folderTags: folderStructure.uniqueFolderTags }, { root: true });
      commit('prepareUpload');
      const validatedFiles = await dispatch('validateFiles', { folderId, files: filesToUpload });
      if (validatedFiles.length) {
        event.event.stopPropagation();
        if (uploadDestinationView.isEmpty) {
          await dispatch('folder/saveItemsAsNewFolder', {
            folderId,
            windowId,
          }, { root: true });
          if (rootGetters['user/isUser']) {
            await dispatch('folder/synchronizeFolders', null, { root: true });
          }
        }
        dispatch('uploadFilesTo', {
          files: validatedFiles,
          folderId,
          isUploadAllowed,
          pipelineCommands,
          uploadOptions,
        });
        setTimeout(() => commit('uploadPrepared'), 1000);
      } else {
        setTimeout(() => commit('uploadPrepared'), 1000);
      }
    }
  },
  validateFiles({ dispatch, rootGetters }: FileContext, { folderId, files }: { folderId: string, files: TaggedFile[]; }): TaggedFile[] {
    const dataLimitation = rootGetters['folder/isFolderWithDataLimitation'](folderId) && rootGetters['user/isUserWithDataLimitation'];
    const params: ValidationParams = {
      files,
      options: {
        dataLimitation,
        sizeTaken: rootGetters['folder/folderSizeInBytes'](folderId, [ORIGINAL_ASSET_VERSION]),
        existingItems: rootGetters['folder/folderItemCount'](folderId),
      },
    };
    const { validatedFiles, messages } = FileValidator.validate(params);
    if (messages?.size > 0) {
      dispatchNotification(dispatch, messages);
    }
    return validatedFiles;
  },
  setThumbnailQualityConfig({ commit }: FileContext, qualityConfiguration: Partial<QualityConfiguration>) {
    commit('thumbnailQualityConfig', qualityConfiguration);
    this.$thumbnailer.setQualityConfiguration(qualityConfiguration);
  },
  pausePipeline(_context: FileContext, pipelineId: string) {
    this.$pipelineManager.pausePipeline(pipelineId);
  },
  continuePipeline(_context: FileContext, pipelineId: string) {
    this.$pipelineManager.continuePipeline(pipelineId);
  },
  cancelPipeline({ commit }: FileContext, pipelineId: string) {
    this.$pipelineManager.cancelPipeline(pipelineId);
    commit('setViewProgress', { pipelineId, viewProgress: null }, { root: true });
  },
  startUploadProcess({ commit }: FileContext, { payload }: ActionPayload<UploadProcessParams>) {
    const { pipelineItems, replaceExistingFiles, objectId, pipelineOptions } = payload;
    const hasRawFiles = pipelineItems.some(i => i.raw != null);
    commit('setUploadProcess', {
      status: UploadProcessStatus.INDEXING,
      objectId,
      hasRawFiles,
      replaceExistingFiles,
      items: pipelineItems,
      pipelineOptions,
    });
  },
  addItemsToUploadProcess({ state, commit }: FileContext, items: PipelineItem[]) {
    const hasRawFiles = items.some(i => i.raw != null);
    const filteredExistingItems = state.uploadProcess.items.filter(item => !items.some(i => i.id === item.id));
    commit('setUploadProcess', { ...state.uploadProcess, hasRawFiles: state.uploadProcess.hasRawFiles || hasRawFiles, items: [...filteredExistingItems, ...items] });
  },
  updateUploadProcess({ state, commit }: FileContext, { status, pipelineOptions }: { status: UploadProcessStatus, pipelineOptions: PipelineOptions }) {
    commit('setUploadProcess', { ...state.uploadProcess, status, pipelineOptions });
  },
  async reindexPipelineItems({ state, commit, dispatch }: FileContext, pipelineId: string) {
    const existingPipeline = this.$pipelineManager.getPipeline(pipelineId);
    if (existingPipeline.isActive) {
      dispatchNotification(dispatch, new Set<MessageType>([InfoMessages.NO_REINDEX_WHILE_PIPELINE_IS_RUNNING]));
      return;
    }
    const commands = FileProcessingPipeline.buildPipelineCommandInstruction([PipelineCommandType.CREATE_THUMBNAILS]);
    const replaceThumbs = (pipelineItems: PipelineItem[]) => commit('folder/replaceAssets', { folderId: pipelineId, items: pipelineItems }, { root: true });
    const pipeline = this.$pipelineManager.setupPipeline(pipelineId, commands);
    const pipelineItems = state.uploadProcess.items;
    commit('folder/removeThumbnails', { items: pipelineItems, folderId: pipelineId }, { root: true });
    await dispatch('setupViewProgressListeners', pipeline);
    await dispatch('setupPipelineErrorHandling', pipeline);
    pipeline
      .subscription$
      .pipe(
        filter(event => event.type === PipelineEventType.THUMBNAILS_CREATED),
        map(event => event.data),
        buffer(timer(200, 400)),
        filter(b => b.length > 0),
        map((items: PipelineItem[][]) => items
          .flat()
          .filter(item => item.eventsProcessed.some(containsErrorFreeEventType(PipelineEventType.THUMBNAILS_CREATED)))),
        filter(items => items.length > 0)
      )
      .subscribe((items: PipelineItem[]) => {
        replaceThumbs(items);
        this.$internalEvents.next({ type: InternalEventType.ITEM_DIMENSIONS_EXTRACTED, data: items.map(i => i.id) });
      });
    pipeline.processItems(pipelineItems);
  },
  cancelUploadAndMatchingProcess({ state, dispatch }: FileContext, folderId: string) {
    if (state.uploadProcess?.objectId?.toUuid() === folderId && state.uploadProcess?.items?.length > 0) {
      dispatch('endUploadProcess');
      dispatch('cancelPipeline', state.uploadProcess?.objectId?.toUuid());
    }
    if (state.matchingProcess && state.matchingProcess?.objectId?.toUuid() === folderId) {
      dispatch('context/closeMenu', ContextMenuType.MATCH_AND_UPLOAD_CONTROL, { root: true });
      dispatch('endMatchingProcess');
      dispatch('cancelPipeline', state.matchingProcess?.objectId?.toUuid());
    }
  },
  endUploadProcess({ commit, dispatch, rootGetters }: FileContext) {
    const reviewFilter = rootGetters['cloud/reviewFilter'];
    if (reviewFilter?.includes(FilterOption.UPLOAD_PENDING)) {
      dispatch('cloud/setReviewFilter', { reviewFilter: reviewFilter.filter(f => f !== FilterOption.UPLOAD_PENDING), windowId: ViewIdentifier.MAIN_VIEW }, { root: true });
    }
    commit('setUploadProcess', null);
  },
  endMatchingProcess({ state, commit }: FileContext) {
    commit('folder/clearMatchedWithFromItems', { folderId: state.matchingProcess.objectId.toUuid(), itemIds: state.matchingProcess.matchedItems.map(i => i.id) }, { root: true });
    commit('setMatchingProcess', null);
  },
  uploadItems({ dispatch, rootState }: FileContext, { payload }: ActionPayload<UploadItems>) {
    const { itemsWithPosition, pipelineOptions = [], pipelineId } = payload;
    if (pipelineOptions.length && itemsWithPosition.length) {
      const folderId = itemsWithPosition[0].item.folderId;
      const offlineItems = rootState.folder.offlineItems;
      const commands = FileProcessingPipeline.buildPipelineCommandInstruction(FileProcessingPipeline.sortPipelineCommands(pipelineOptions));
      const pipelineItems = buildPipelineItemsFromItems(pipelineId, itemsWithPosition, offlineItems, commands.map(c => c.type));
      dispatch<ActionPayload<UploadPipelineItems>>({
        type: 'uploadPipelineItems',
        payload: {
          folderId,
          pipelineId,
          pipelineItems,
          commands,
        },
      });
    }
  },
  async uploadFilesTo({ dispatch, getters, rootGetters }: FileContext, { pipelineId, folderId, files, idList, isUploadAllowed = false, pipelineCommands = [], uploadOptions = [] }: UploadFilesTo) {
    // TODO: add way to provide explicit order positions in arguments (important for adding items in a specific position)
    // TODO: respect order of items that are already in the pipeline (sorting while the pipeline is running should not break things)
    const startOrder = rootGetters['folder/folderItemsById'](folderId)?.length || 0;
    const usedNames: string[] = rootGetters['folder/folderFileNames'](folderId);
    pipelineId = pipelineId ?? folderId;
    // TODO: Make sure items are not added to the view in random order on completion, e.g. add placeholders or add offline items only in correct order
    const replaceExistingItem = uploadOptions.includes(UploadOption.REPLACE_EXISTING_FILES);
    let existingFiles = new Map<string, string>();
    if (replaceExistingItem) {
      const existingItems = rootGetters['cloud/itemsForCloudObject'](rootGetters['cloud/currentCloudObject']);
      existingFiles = FileMatcher.getDuplicateFiles(existingItems, files);
    }
    const commands: PipelineCommandInstruction[] = pipelineCommands.length
      ? FileProcessingPipeline.buildPipelineCommandInstruction(FileProcessingPipeline.sortPipelineCommands(pipelineCommands))
      : getters.selectedPipelineCommands(isUploadAllowed);
    const pipelineItems = buildPipelineItems(pipelineId, files, idList, usedNames, folderId, startOrder, false, replaceExistingItem, existingFiles, pipelineCommands);
    const shouldOpenRemoteControl = commands.length === 1
      && commands.some(c => c.type === PipelineCommandType.CREATE_THUMBNAILS)
      && !rootGetters['context/isOpen'](ContextMenuType.UPLOAD_CONTROL);
    if (shouldOpenRemoteControl) {
      if (rootGetters['context/isOpen'](ContextMenuType.MATCH_AND_UPLOAD_CONTROL)) {
        await dispatch('endMatchingProcess');
        await dispatch('context/closeMenu', ContextMenuType.MATCH_AND_UPLOAD_CONTROL, { root: true });
      }
      await dispatch('context/openMenu', { type: ContextMenuType.UPLOAD_CONTROL }, { root: true });
    }
    await dispatch<ActionPayload<UploadPipelineItems>>({
      type: 'uploadPipelineItems',
      payload: {
        folderId,
        pipelineId,
        pipelineItems,
        commands,
      },
    });
  },
  async uploadPipelineItems({ dispatch }: FileContext, { payload }: ActionPayload<UploadPipelineItems>) {
    const { pipelineId, pipelineItems, commands, folderId, retry = false } = payload;
    const objectId = ObjectId.fromFolderId(folderId);
    await dispatch<ActionPayload<InitializeOrContinueUploadProcess>>({
      type: 'initializeUploadProcess',
      payload: {
        pipelineItems, commands, objectId,
      },
    });
    if (!this.$pipelineManager.isPipelineRunning(pipelineId)) {
      await dispatch<ActionPayload<AddItemsToNewPipeline>>({
        type: 'addItemsToNewPipeline',
        payload: {
          folderId, pipelineId, pipelineItems, commands,
        },
      });
    } else {
      await dispatch<ActionPayload<AddItemsToExistingPipeline>>({
        type: 'addItemsToExistingPipeline',
        payload: { folderId, pipelineId, pipelineItems, commands, retry },
      });
    }
  },
  async initializeUploadProcess({ state, dispatch }: FileContext, { payload }: ActionPayload<InitializeOrContinueUploadProcess>) {
    const { pipelineItems, commands, objectId } = payload;
    const pipelineOptions = commands.map(c => c.type);
    if (state.uploadProcess == null) {
      await dispatch<ActionPayload<UploadProcessParams>>({
        type: 'startUploadProcess',
        payload: {
          pipelineItems, pipelineOptions, objectId, replaceExistingFiles: false,
        },
      });
    } else if (state.uploadProcess.status !== UploadProcessStatus.UPLOADING && commands.some(c => c.type === PipelineCommandType.CREATE_THUMBNAILS)) {
      await dispatch('addItemsToUploadProcess', pipelineItems);
    }
    if (state.uploadProcess.status !== UploadProcessStatus.UPLOADING && commands.some(command => FileProcessingPipeline.UPLOAD_COMMANDS.includes(command.type))) {
      await dispatch('updateUploadProcess', { status: UploadProcessStatus.UPLOADING, pipelineOptions });
    }
  },
  async addItemsToNewPipeline({
    commit,
    dispatch,
    state,
  }: FileContext, {
    payload,
  }: ActionPayload<AddItemsToNewPipeline>) {
    const {
      folderId,
      pipelineId,
      commands,
      pipelineItems,
    } = payload;
    const addOfflineItems = (pipelineItems: PipelineItem[]) => commit('folder/addOfflineItems', { folderId, items: pipelineItems }, { root: true });
    const replaceItems = (pipelineItems: PipelineItem[]) => commit('folder/replaceItems', { folderId, items: pipelineItems }, { root: true });
    const synchronizeSelections = () => dispatch('selection/synchronizeSelections', null, { root: true });
    const synchronizeSelectionsThrottled = throttleAndDebounce(synchronizeSelections, 1000, 2000);
    const pipeline = this.$pipelineManager.setupPipeline(pipelineId, commands);
    await dispatch('setupViewProgressListeners', pipeline);
    await dispatch('setupPipelineErrorHandling', pipeline);
    if (pipeline.hasCreateThumbnailsEvent && state.matchingProcess == null) {
      pipeline
        .subscription$
        .pipe(
          filter(event => event.type === PipelineEventType.THUMBNAILS_CREATED),
          map(event => event.data),
          buffer(timer(200, 400)),
          filter(b => b.length > 0),
          map((items: PipelineItem[][]) => items
            .flat()
            .filter(item => item.eventsProcessed.some(containsErrorFreeEventType(PipelineEventType.THUMBNAILS_CREATED)))),
          filter(items => items.length > 0)
        )
        .subscribe((items: PipelineItem[]) => {
          const [itemsToAdd, itemsToReplace] = filterByConditions(items, (i) => !i.replaceItem);
          addOfflineItems(itemsToAdd);
          replaceItems(itemsToReplace);
          this.$internalEvents.next({ type: InternalEventType.ITEM_DIMENSIONS_EXTRACTED, data: items.map(i => i.id) });
        });
    }
    if (pipeline.hasUploadEvent) {
      // TODO: we should check if the original upload target is actually a selection view, so we can omit this step here if it is not one
      // After we successfully uploaded a pipeline item we can also try to sync it in selections that use it
      const firstUploadEvent = pipeline.firstUploadEvent;
      pipeline
        .subscription$
        .pipe(filter(event => event.type === firstUploadEvent))
        .subscribe(() => {
          synchronizeSelectionsThrottled();
        });
      // We need to make sure the target folder is synced first so we can upload items to it
      await dispatch('folder/synchronizeFolders', null, { root: true });
    }
    pipeline
      .subscription$
      .pipe(last())
      // we need to fix now stale item positions that might have occurred within the duration of the pipeline (e.g. due to items that could not be processed by the pipeline or were moved by the user)
      .subscribe(() => dispatch('cloud/fixStaleItemPositions', ViewIdentifier.MAIN_VIEW, { root: true }));
    pipeline.processItems(pipelineItems);
  },
  async addItemsToExistingPipeline({
    dispatch,
  }: FileContext, {
    payload,
  }: ActionPayload<AddItemsToExistingPipeline>) {
    const {
      folderId,
      pipelineId,
      commands,
      pipelineItems,
      retry = false,
    } = payload;
    const pipeline = this.$pipelineManager.getPipeline(pipelineId);
    const commandsToProcess = commands.map(c => c.type);
    if (pipeline.isCompatibleWithGivenCommands(commandsToProcess)) {
      pipeline.processItems(pipelineItems);
    } else if (pipeline.isUpgradableWithGivenCommands(commandsToProcess)) {
      // We need to synchronize folders before we update the pipeline, since items that are already being processed would instantly be uploaded (and potentially fail) as soon as we update it.
      if (!pipeline.hasUploadEvent && commandsToProcess.some(command => FileProcessingPipeline.UPLOAD_COMMANDS.includes(command))) {
        await dispatch('folder/synchronizeFolders', null, { root: true });
      }
      pipeline.updateCommands(commands);
      await dispatch('setupViewProgressListeners', pipeline);
      await dispatch('setupPipelineErrorHandling', pipeline);
      pipeline.processItems(pipelineItems);
    } else if (retry) {
      alert('A different file processing pipeline is still being processed. Please wait a few seconds for it to complete.');
    } else {
      setTimeout(() => {
        dispatch<ActionPayload<UploadPipelineItems>>({
          type: 'uploadPipelineItems',
          payload: {
            folderId,
            pipelineId,
            pipelineItems,
            commands,
            retry: true,
          },
        });
      }, FileProcessingPipeline.GRACE_PERIOD_FOR_BUFFERING_IN_MS);
    }
  },
  setupViewProgressListeners({ state, commit, getters }: FileContext, pipeline: FileProcessingPipeline) {
    if (viewProgressSubscriptionMap.has(pipeline.id)) {
      viewProgressSubscriptionMap.get(pipeline.id).forEach(subscription => subscription.unsubscribe());
    }
    const subscriptions = [];
    const emitProgress = (progress: { pipelineId: string; step: number; percentage: number; }) => commit('updateViewProgress', progress, { root: true });
    const throttledProgressEmitters = pipeline.commands.map(_command => throttle(emitProgress, 200, { leading: true, trailing: true }));
    commit('setViewProgress', { pipelineId: pipeline.id, viewProgress: { totalSteps: pipeline.commands.length, progressSteps: pipeline.commands.map((command, idx) => ({ label: command.infoLabel, step: idx, percentage: 0 })) } }, { root: true });
    pipeline.commands.forEach((command, idx) => {
      if (idx === pipeline.commands.length - 1) {
        subscriptions.push(pipeline
          .subscription$
          .pipe(
            filter(event => event.type === PipelineEventType.PIPELINE_PROGRESS)
          ).subscribe((event) => {
            const percentage = event.data;
            throttledProgressEmitters[idx]({
              pipelineId: event.pipelineId,
              step: idx,
              percentage,
            });
            if (event.data === 100) {
              const allUploadedVersions = FileProcessingPipeline.commandsToVersions(pipeline.commands.map(c => c.type));
              const allSize = getters.uploadProcessItems(allUploadedVersions).assetList?.asByteSize;
              commit('user/addToUserUploadVolume', allSize, { root: true });
              setTimeout(() => {
                if (state.uploadProcess == null) {
                  commit('setViewProgress', { pipelineId: event.pipelineId, viewProgress: null }, { root: true });
                }
              }, 500);
            }
          }));
      } else {
        subscriptions.push(pipeline
          .subscription$
          .pipe(filter(event => event.type === pipelineCommandToProgressEventMap.get(command.type)))
          .subscribe((event) => {
            throttledProgressEmitters[idx]({ pipelineId: event.pipelineId, step: idx, percentage: event.data });
          }));
      }
    });
    viewProgressSubscriptionMap.set(pipeline.id, subscriptions);
  },
  setupPipelineErrorHandling({ dispatch }: FileContext, pipeline: FileProcessingPipeline) {
    if (pipelineErrorSubscriptionMap.has(pipeline.id)) {
      pipelineErrorSubscriptionMap.get(pipeline.id).next(true);
      pipelineErrorSubscriptionMap.delete(pipeline.id);
    }
    const stopSignal$: Subject<boolean> = new Subject();
    pipeline
      .subscription$
      .pipe(
        filter(event => event.type === pipelineCommandToRelatedEventMap.get(pipeline.commands[pipeline.commands.length - 1].type)),
        map(event => event.data),
        reduce((pipelineItemsWithErrors: PipelineItem[], items: PipelineItem[]) =>
          pipelineItemsWithErrors.concat(items.filter(item => item.eventsProcessed?.some(c => c.error != null))), []
        ),
        takeUntil(stopSignal$),
        delay(1000)
      )
      .subscribe((pipelineItemsWithErrors: PipelineItem[]) =>
        dispatch('capturePipelineErrors', pipelineItemsWithErrors)
      );
    pipelineErrorSubscriptionMap.set(pipeline.id, stopSignal$);
  },
  capturePipelineErrors({ dispatch }: FileContext, pipelineItemsWithErrors: PipelineItem[]) {
    if (pipelineItemsWithErrors.length > 0) {
      console.warn(`${pipelineItemsWithErrors.length} pipeline errors captured. Items with errors`, pipelineItemsWithErrors);
      dispatch('setNotificationMessage', {
        payload: {
          message: `Unfortunately the upload process encountered errors with ${pipelineItemsWithErrors.length} file(s). We are actively working on improving the upload experience.`,
          type: NotificationType.ERROR,
          duration: 10000,
        },
      }, { root: true });
      this.$sentry.captureEvent({
        event_id: uuid(),
        timestamp: Date.now(),
        message: `Pipeline encountered processing errors with ${pipelineItemsWithErrors.length} files.`,
        extra: {
          events: formatPipelineItemErrorInsights(pipelineItemsWithErrors),
        },
        environment: WebConfig.ENVIRONMENT,
        platform: 'javascript',
      });
    }
  },
};

export default actions;

export function dispatchNotification(dispatch: Dispatch, messages: Set<MessageType>, type = NotificationType.ERROR) {
  messages.forEach(message => {
    dispatch<ActionPayload<Notification>>({
      type: 'setNotificationMessage',
      payload: {
        message,
        type,
        duration: 5000,
      },
    }, { root: true });
  });
}

export function buildPipelineItems(pipelineId: string, files: TaggedFile[], idList: string[], usedNames: string[], folderId: string, startOrder: number, replaceExistingFiles: boolean, replaceItem = false, existingFiles = new Map<string, string>(), commands: PipelineCommandType[] = []): PipelineItem[] {
  return [...files].map((originalFile, idx) => {
    let file: File;
    if (!replaceItem) {
      const uniqueFileName = getUniqueFileName(originalFile.file.name, usedNames);
      usedNames.push(uniqueFileName);
      file = uniqueFileName === originalFile.file.name
        ? originalFile.file
        : new File([originalFile.file.slice(0, originalFile.file.size, originalFile.file.type)], uniqueFileName, {
          type: originalFile.file.type,
        });
    } else {
      file = originalFile.file;
    }
    const pipelineItem: PipelineItem = {
      folderId,
      pipelineId,
      folderTagId: originalFile.folderTagId,
      file,
      id: idList ? idList[idx] : uuid(),
      order: startOrder + idx,
      replaceThumbs: replaceExistingFiles || (usedNames.includes(file.name) && replaceItem),
      eventsProcessed: [],
      eventsToProcess: commands,
      replaceItem: usedNames.includes(file.name) && replaceItem,
    };
    if (FileValidator.isRawFile(file)) {
      pipelineItem.raw = { file };
      pipelineItem.file = null;
    }
    if (existingFiles.has(pipelineItem.file?.name)) {
      pipelineItem.id = existingFiles.get(pipelineItem.file?.name);
    }
    return pipelineItem;
  });
}

export function getAssetsAndEventsProcessed(item: ItemWithPosition) {
  const originalAsset = item.item.assets.find(a => a.version === ORIGINAL_ASSET_VERSION);
  const thumbnailAsset = item.item.assets.find(a => a.version === CUSTOM_SUB_VERSION);
  const rawAsset = item.item.assets.find(a => a.version === RAW_ASSET_VERSION);
  const eventsProcessed: PipelineEventProcessedStatus[] = [];
  if (thumbnailAsset || originalAsset) {
    eventsProcessed.push({ type: PipelineEventType.THUMBNAILS_CREATED });
    if (thumbnailAsset && !thumbnailAsset.isOffline) {
      eventsProcessed.push({ type: PipelineEventType.THUMBNAILS_UPLOADED });
    }
  }
  if (originalAsset && !originalAsset.isOffline) {
    eventsProcessed.push({ type: PipelineEventType.ORIGINALS_UPLOADED });
  }
  if (rawAsset && !rawAsset.isOffline) {
    eventsProcessed.push({ type: PipelineEventType.RAWS_UPLOADED });
  }
  return { originalAsset, thumbnailAsset, rawAsset, eventsProcessed };
}

function buildPipelineItemFromItem(pipelineId: string, item: ItemWithPosition, pipelineCommands: PipelineCommandType[], offlineItems: Map<string, boolean>) {
  const { originalAsset, thumbnailAsset, rawAsset, eventsProcessed } = getAssetsAndEventsProcessed(item);
  const pipelineItem: PipelineItem = {
    folderId: item.item.folderId,
    pipelineId,
    file: originalAsset?.file,
    width: originalAsset?.width,
    height: originalAsset?.height,
    id: item.item.id,
    order: item.position.order,
    folderTagId: item.item.folderTagId,
    thumbnail: thumbnailAsset ? { file: thumbnailAsset.file, base64: thumbnailAsset.base64, width: thumbnailAsset.width, height: thumbnailAsset.height } : null,
    raw: rawAsset ? { file: rawAsset.file, width: rawAsset.width, height: rawAsset.height } : null,
    eventsProcessed,
    eventsToProcess: pipelineCommands,
  };
  if (!offlineItems.get(item.item.id)) {
    pipelineItem.replaceItem = true;
    pipelineItem.replaceThumbs = true;
  }
  return pipelineItem;
}

export function buildPipelineItemsFromItems(pipelineId: string, items: ItemWithPosition[], offlineItems: Map<string, boolean>, pipelineCommands: PipelineCommandType[]) {
  return items.map((item) => buildPipelineItemFromItem(pipelineId, item, pipelineCommands, offlineItems));
}

export function convertPipelineItemToFolderItem(pipelineItem: PipelineItem): FolderItem {
  const assets: Asset[] = [];
  if (pipelineItem.raw != null) {
    assets.unshift({
      itemId: pipelineItem.id,
      name: pipelineItem.raw.file.name,
      isOffline: true,
      isEstimated: false,
      file: pipelineItem.raw.file,
      base64: null,
      version: RAW_ASSET_VERSION,
      size: pipelineItem.raw.file.size,
      mimeType: pipelineItem.raw.file.type,
      width: pipelineItem.raw.width,
      height: pipelineItem.raw.height,
    });
  }
  if (pipelineItem.file != null) {
    assets.unshift({
      itemId: pipelineItem.id,
      name: pipelineItem.file.name,
      isOffline: true,
      isEstimated: false,
      file: pipelineItem.file,
      base64: pipelineItem.base64 || null,
      version: ORIGINAL_ASSET_VERSION,
      size: pipelineItem.file.size,
      mimeType: pipelineItem.file.type,
      width: pipelineItem.width,
      height: pipelineItem.height,
    });
  }
  if (pipelineItem.thumbnail != null) {
    assets.unshift({
      itemId: pipelineItem.id,
      name: pipelineItem.thumbnail.file?.name || pipelineItem.file?.name || '',
      isOffline: true,
      isEstimated: false,
      file: pipelineItem.thumbnail.file,
      base64: pipelineItem.thumbnail.base64,
      version: CUSTOM_SUB_VERSION,
      size: pipelineItem.thumbnail.file.size,
      mimeType: pipelineItem.thumbnail.file.type,
      width: pipelineItem.thumbnail.width,
      height: pipelineItem.thumbnail.height,
    });
  }
  const item = {
    id: pipelineItem.id,
    name: pipelineItem.file?.name || pipelineItem.raw?.file?.name || '',
    folderId: pipelineItem.folderId,
    folderTagId: pipelineItem.folderTagId,
    type: ItemType.IMAGE,
    isSynced: false,
    assets,
  } as Item;
  return {
    id: pipelineItem.id,
    folderId: pipelineItem.folderId,
    position: {
      order: pipelineItem.order,
    },
    item,
    itemId: pipelineItem.id,
    modified: Date.now().toLocaleString(),
    created: Date.now().toLocaleString(),
  };
}
async function createScrapbookAndUpload(rootGetters, dispatch: Dispatch, uploadDestinationView: MutantContentView, event: AddExternalContentEvent, isUploadAllowed: boolean, pipelineCommands: PipelineCommandType[]) {
  // We add an offline scrapbook folder, which makes file handling easier
  // because for online upload the scrapbook is always present and handles files of newly created selections
  if (rootGetters['folder/scrapbookId'] == null && !rootGetters['user/isProUser']) {
    await dispatch(
      'folder/createFolderWithoutPush',
      { id: uuid(), viewType: ViewType.HORIZONTAL, name: 'scrapbook', isPublic: false },
      { root: true });
  }
  dispatch('selection/uploadFilesTo', {
    selectionId: uploadDestinationView.selectionId,
    event,
    isUploadAllowed,
    pipelineCommands,
  }, { root: true });
}
function throttleAndDebounce(fn, throttleDelay, debounceDelay) {
  const throttled = throttle(fn, throttleDelay);
  const debounced = debounce(throttled, debounceDelay);

  return function(...args) {
    throttled.apply(this, args);
    debounced.apply(this, args);
  };
}

function buildAssetBatches(assets: AssetWithFolderTag[], batchSize: number): AssetWithFolderTag[][] {
  return assets.reduce((batches, asset) => {
    if (batches[batches.length - 1].length < batchSize) {
      batches[batches.length - 1].push(asset);
    } else {
      batches.push([asset]);
    }
    return batches;
  }, [[]]);
}

function formatPipelineItemErrorInsights(pipelineItemsWithErrors: PipelineItem[]): PipelineItemErrorInsights[] {
  return pipelineItemsWithErrors.map(i => {
    return {
      rawExtension: getFileExtension(i.raw?.file?.name),
      rawSize: i.raw?.file.size,
      originalExtension: getFileExtension(i.file?.name),
      originalSize: i.file?.size,
      thumbnailExtension: getFileExtension(i.thumbnail?.file?.name),
      thumbnailSize: i.thumbnail?.file?.size,
      eventsProcessed: i.eventsProcessed,
    };
  });
}

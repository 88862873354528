export interface ContactSheetOptions {
  columnCount: number
  columnCountInterval: number,
  minColumnWidth: number;
  maxColumnWidth: number;
  margin: number;
  marginFactor: number;
  marginFactorMin: number;
  marginFactorMax: number;
  minColumnCount: number;
  maxColumnCount: number;
  marginInterval: number;
  textSize: number;
}

export const contactSheetOptionsDefault = (): ContactSheetOptions => {
  return {
    columnCount: localStorage.getItem('contactSheet.columnCount') ? parseInt(localStorage.getItem('contactSheet.columnCount'), 10) : 8,
    columnCountInterval: 1,
    minColumnCount: 1,
    maxColumnCount: 18,
    margin: localStorage.getItem('contactSheet.margin') ? parseInt(localStorage.getItem('contactSheet.margin'), 10) : 8,
    minColumnWidth: 200,
    maxColumnWidth: 500,
    marginFactor: localStorage.getItem('contactSheet.marginFactor') ? parseInt(localStorage.getItem('contactSheet.marginFactor'), 10) : 0,
    marginFactorMin: 0,
    marginFactorMax: 10,
    marginInterval: 1,
    textSize: 12,
  };
};

import { ActionContext } from 'vuex';
import { ContextMenuState, ContextMenuType } from '~/store/context/state';
import { RootState } from '~/store/state';

type MenuContext = ActionContext<ContextMenuState, RootState>;

const actions = {
  openMenu({ commit }: MenuContext, data: { type: ContextMenuType, data?: any, event?: any }) {
    commit('openMenu', data);
  },
  updateMenuData({ commit }: MenuContext, data: { type: ContextMenuType; isOpen?: boolean; data: any }) {
    commit('updateMenuData', data);
  },
  closeMenu({ commit }: MenuContext, type: ContextMenuType) {
    commit('closeMenu', type);
  },
  toggleMenu({ commit }: MenuContext, data: any) {
    commit('toggleMenu', data);
  },
  closeAllMenus({ dispatch }: MenuContext) {
    Object.keys(ContextMenuType).forEach(key => dispatch('closeMenu', key));
  },
};

export default actions;

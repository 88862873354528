import { Device } from '~/models/Device';
import { MessageRoute } from '~/models/MessageRoute';
import { Contact } from '~/models/user/Contact';
import { UserProfile } from '~/models/user/UserProfile';
import { UserLimits, UserState } from '~/store/user/state';
import { StorageKey } from '~/models/storage/StorageKey';
import { UserDetails } from '~/models/user/UserDetails';
import { JwtUserData } from '~/models/user/JwtUserData';
import { User } from '~/models/user/User';
import { GenericApiData } from '~/models/GenericApiData';

export default {
  signUp: (state: UserState) => {
    state.signUp = {
      isPending: true,
      error: null,
    };
  },
  signUpFailed: (state: UserState, error: any) => {
    state.signUp = {
      isPending: false,
      error,
    };
  },
  login: (state: UserState) => {
    state.login = {
      success: false,
      isPending: true,
      error: null,
    };
  },
  forgotPasswordPending: (state: UserState) => {
    state.forgotPassword = {
      isPending: true,
      success: false,
      error: null,
    };
  },
  forgotPasswordSuccess: (state: UserState) => {
    state.forgotPassword = {
      isPending: false,
      success: true,
      error: null,
    };
  },
  forgotPasswordFailed: (state: UserState, err: unknown) => {
    state.forgotPassword = {
      isPending: false,
      success: false,
      error: err,
    };
  },
  loginSuccess: (state: UserState, data: User) => {
    state.login = {
      success: true,
      isPending: false,
      error: null,
    };
    state.signUp = {
      isPending: false,
      error: null,
    };
    state.user = data;
  },
  loginFailed: (state: UserState, error: any) => {
    state.login = {
      success: false,
      isPending: false,
      error,
    };
  },
  automaticLogoutUser: (state: UserState) => {
    localStorage.removeItem(StorageKey.USER);
    if (state.user?.id != null) {
      // We set the current id for future logins, if a new user logs in we can detect it
      localStorage.setItem(StorageKey.USER, JSON.stringify({ id: state.user.id } as Partial<JwtUserData>));
    }
    state.user = null;
    state.login = {
      success: false,
      isPending: false,
      error: null,
    };
    state.userDetails = null;
    state.devices = [];
  },
  loadDevicesSuccess: (state: UserState, devices: Device[]) => {
    state.devices = devices;
  },
  loadUserDetailsSuccess: (state: UserState, data: UserDetails) => {
    state.userDetails = data;
  },
  addProfile: (state: UserState, profile: UserProfile) => {
    state.profiles.set(profile.id, profile);
  },
  updateContact: (state: UserState, contact: Contact) => {
    state.contacts = state.contacts.map(c => c.id === contact.id ? { ...c, ...contact } : c);
  },
  removeContact: (state: UserState, contact: Contact) => {
    state.contacts = state.contacts.filter(c => c.id !== contact.id);
  },
  addContact: (state: UserState, contact: Contact) => {
    state.contacts = [...state.contacts, contact];
  },
  setContacts: (state: UserState, contacts: GenericApiData<Contact[]>) => {
    state.contacts = [...state.contacts, ...contacts.data];
  },
  setMessageRoutes: (state: UserState, routes: MessageRoute[]) => {
    state.messageRoutes = routes;
  },
  setReceiveContentEnabled: (state: UserState, receiveContentEnabled: boolean) => {
    state.isReceiveContentEnabled = receiveContentEnabled;
  },
  removeMessageRoute: (state: UserState, messageRouteId) => {
    state.messageRoutes = state.messageRoutes.filter(m => m.id !== messageRouteId);
  },
  startProfileSearch: (state: UserState, searchTerm: string) => {
    state.profileSearch = {
      ...state.profileSearch,
      isLoading: true,
      searchTerm,
    };
  },
  setProfileSearchResult: (state: UserState, {
    searchTerm,
    profileResults,
  }: { searchTerm: string, profileResults: UserProfile[] }) => {
    state.profileSearch = {
      searchTerm,
      isLoading: false,
      error: null,
      profileResults,
    };
  },
  setUsernameSearchResult: (state: UserState, { searchTerm, isTaken }: { searchTerm: string, isTaken: boolean }) => {
    state.usernameSearch = {
      searchTerm,
      isTaken,
      isLoading: false,
    };
  },
  startUsernameSearch: (state: UserState, searchTerm: string) => {
    state.usernameSearch = {
      ...state.usernameSearch,
      isLoading: true,
      searchTerm,
    };
  },
  setUserRoleUpgradePending: (state: UserState, { subscriptionId, isPending }: { subscriptionId?: string; isPending: boolean; }) => {
    if (isPending) {
      localStorage.setItem(StorageKey.USER_ROLE_UPGRADE_PENDING, String(isPending));
      if (subscriptionId != null) {
        localStorage.setItem(StorageKey.USER_ROLE_UPGRADE_PENDING_SINCE, String(Date.now()));
        localStorage.setItem(StorageKey.USER_ROLE_UPGRADE_PENDING_FOR_SUBSCRIPTION_ID, subscriptionId);
      }
    } else {
      localStorage.removeItem(StorageKey.USER_ROLE_UPGRADE_PENDING);
      localStorage.removeItem(StorageKey.USER_ROLE_UPGRADE_PENDING_SINCE);
      localStorage.removeItem(StorageKey.USER_ROLE_UPGRADE_PENDING_FOR_SUBSCRIPTION_ID);
    }
    state.userRoleUpgradePending = isPending;
  },
  setUserLimits: (state: UserState, limits: UserLimits) => {
    state.userLimits = state.userLimits
      ? {
          ...limits,
          uploadVolumeUsed: state.userLimits.uploadVolumeUsed > limits.uploadVolumeUsed ? state.userLimits.uploadVolumeUsed : limits.uploadVolumeUsed,
        }
      : limits;
  },
  addToUserUploadVolume: (state: UserState, volume: number) => {
    if (state.userLimits != null) {
      const uploadVolumeUsed = state.userLimits.uploadVolumeUsed += volume;
      const isUploadLimitExceeded = uploadVolumeUsed > state.userLimits.uploadVolumeLimit;
      state.userLimits = {
        ...state.userLimits,
        uploadVolumeUsed,
        isUploadLimitExceeded,
      };
    }
  },
};

import { SelectionItem } from '~/models/selection/SelectionItem';
import { FolderItem } from '~/models/item/FolderItem';

export interface FixedItemOrdering<T> {
  orderedItems: T[],
  adjustedItems: T[],
}

// We are correcting inconsistent orders of Folders or Selections
// This is just a post bug fix, so ideally we would not need this correction.
// However, it makes sure that the ordering is always correct when loading a Folder or Selection
// ATTENTION: mutates input array, so that we don't need to create an expensive deep clone
export function fixOrdering<T extends FolderItem | SelectionItem>(items: T[]): FixedItemOrdering<T> {
  const sortedItems = sortForOrder(items);
  if (isCorrectOrder(sortedItems)) {
    return { orderedItems: sortedItems, adjustedItems: [] };
  } else {
    const { adjustedItems, orderedItems } = correctOrderForItems(sortedItems);
    return { orderedItems, adjustedItems };
  }
}

function correctOrderForItems<T extends FolderItem | SelectionItem>(items: T[]): { adjustedItems: T[], orderedItems: T[] } {
  const adjustedItems = [] as T[];
  const orderedItems = items
    .map((i, index) => {
      if (index === i.position?.order) {
        return i;
      } else {
        const newItem = { ...i, position: { order: index } } as T;
        adjustedItems.push(newItem);
        return newItem;
      }
    });
  return { adjustedItems, orderedItems };
}

function sortForOrder<T extends FolderItem | SelectionItem>(items: T[]) {
  return items.sort((a, b) => {
    if (a.position.order > b.position.order) {
      return 1;
    } else if (a.position.order === b.position.order) {
      return 0;
    } else {
      return -1;
    }
  });
}

// Check equality of array based on item id and position
function isCorrectOrder<T extends FolderItem | SelectionItem>(items: T[]) {
  return items
    .every((value, index) =>
      value?.position?.order === index
    );
}

import { BatchQueryParams, BatchUploader, UploadQueueItem } from '~/models/uploader/BatchUploader';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import { filterByConditions } from '~/models/utility/filterByConditions';

export class OriginalsBatchUploader extends BatchUploader {
  emitUploaded(batch: UploadQueueItem[], error?: Error) {
    this.emit('originals-uploaded', batch.map(i => {
      return {
        item: {
          ...i.item,
          eventsProcessed: i.item.eventsProcessed?.length > 0 ? [...i.item.eventsProcessed, { type: PipelineEventType.ORIGINALS_UPLOADED, error }] : [{ type: PipelineEventType.ORIGINALS_UPLOADED, error }],
        },
      };
    }));
  }

  addQueueItems(queueItems: UploadQueueItem[]): void {
    const [itemsToSkip, itemsToUpload] = filterByConditions(queueItems, (i) => i.item.eventsProcessed?.some(e => e.type === PipelineEventType.ORIGINALS_UPLOADED) || !i.item.file);
    this.uploadQueue.push(...itemsToUpload);
    if (itemsToSkip?.length > 0) {
      this.emitUploaded(itemsToSkip);
    }
    this.setupWorkers();
  }

  buildBatch(): UploadQueueItem[] {
    const batch = [];
    let batchSize = 0;
    while (this.uploadQueue.length && batch.length < 10 && batchSize < 2_000_000) {
      const batchItem = this.uploadQueue.shift();
      if (batchItem != null) {
        batchSize += batchItem.item.file.size;
        batch.push(batchItem);
      }
    }
    return batch;
  }

  buildQueryParamsForBatch(batch: UploadQueueItem[]): BatchQueryParams {
    return this.buildQueryParamsForBatchCommon(batch, (_item) => ORIGINAL_ASSET_VERSION, (item) => item.file, (item) => item.width + 'x' + item.height);
  }
}

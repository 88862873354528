import { ActionContext, ActionTree } from 'vuex';
import { v4 as uuid } from 'uuid';
import { MessagesAddedEventData } from '~/models/socket/events/MessagesAddedEventData';
import Item from '~/models/item/Item';
import { ResizeCompletedEventData } from '~/models/ResizeCompletedEventData';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { Contact } from '~/models/user/Contact';
import { UserProfile } from '~/models/user/UserProfile';
import { RootState } from '~/store/state';
import { UserState, ValidationType } from '~/store/user/state';
import { ContextMenuType } from '~/store/context/state';
import { StorageKey } from '~/models/storage/StorageKey';
import { createError } from '~/store/actions';
import { PasswordChangeValidationFeedback } from '~/models/ui/PasswordChangeValidationFeedback';
import { parseJwt } from '~/store/mutations';
import { removeCookieValueFor } from '~/models/Cookie';
import { CookieKey } from '~/models/CookieKey';
import { User } from '~/models/user/User';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { EmailValidationFeedback } from '~/models/ui/EmailValidationFeedback';
import { LANDING_PAGE_ROUTE } from '~/store/homepage/state';
import { InfoMessages, WarningMessages } from '~/models/MessageTypes';
import { NotificationType } from '~/models/Notification';
import { WebConfig } from '~/Config';

interface LoginData {
  username: string;
  password: string;
}

export interface SignUpData {
  username: string;
  email: string;
  password: string;
  signupCode?: string;
}

export interface ValidationData {
  validationType: ValidationType,
  isError: boolean,
  message: string,
  validationName: string
}

export enum TabMessageType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

export interface TabMessage {
  id: string;
  originId: string;
  type: TabMessageType;
}
type UserContext = ActionContext<UserState, RootState>;
// !!! Do not change this for now !!!
export const BROWSER_DEVICE_NAME = 'Web Browser';

const actions: ActionTree<UserState, RootState> = {
  setSentryUserContext({ getters }: UserContext) {
    const user = getters.currentUser;
    this.$sentry.setUser({ id: user.id, username: user.username });
  },
  async manualLogout({ rootState }: { rootState: RootState }) {
    try {
      await this.$api.post('auth/logout');
    } catch (e) {
      // Can not do anything right now
    } finally {
      removeCookieValueFor(CookieKey.EXPIRES_AT);
      const betaLandingPageVisited = rootState.betaLandingPageVisited;
      localStorage.clear();
      if (betaLandingPageVisited) {
        localStorage.setItem(StorageKey.BETA_LANDING_VISITED, betaLandingPageVisited.toString());
      }
      localStorage.setItem(StorageKey.TAB_MESSAGE, JSON.stringify({ id: uuid(), originId: rootState.originId, type: TabMessageType.LOGOUT }));
      this.$router.push({ name: LANDING_PAGE_ROUTE },
        () => {
          this.$router.go(0);
        }, () => {
          // We need to handle this cases, abort is called if router is routing to current active route
          // e.g. from /cloud-id to /cloud-id
          this.$router.go(0);
        });
    }
  },
  async automaticLogout({ commit, dispatch, rootState }: UserContext) {
    try {
      await this.$api.post('auth/logout');
    } catch (e) {
      // Can not do anything right now
    } finally {
      commit('automaticLogoutUser');
      dispatch('cleanSocket', null, { root: true });
      dispatch('visitHomepage', null, { root: true });
      this.$sentry.configureScope(scope => scope.setUser(null));
      dispatch('clearState', {}, { root: true });
      localStorage.setItem(StorageKey.TAB_MESSAGE, JSON.stringify({ id: uuid(), originId: rootState.originId, type: TabMessageType.LOGOUT }));
    }
  },
  async resendMail(_context: UserContext) {
    await this.$api.post('/auth/resend-verification-mail');
  },
  async signUp({
    dispatch,
    commit,
  }: UserContext, signUpData: SignUpData) {
    try {
      commit('signUp');
      await this.$api.post('/auth/signup', signUpData);
      await dispatch('login', { username: signUpData.username, password: signUpData.password });
      this.$analytics.trackSignup();
    } catch (error) {
      commit('signUpFailed', createError(error));
    }
  },
  async forgotPassword({ commit }: UserContext, obj: { email?: string; username?: string;}) {
    try {
      commit('forgotPasswordPending');
      await this.$api.post('/auth/resetPassword', obj);
      commit('forgotPasswordSuccess');
    } catch (err) {
      commit('forgotPasswordFailed', err);
    }
  },
  async login({ dispatch, commit, rootState, getters }: UserContext, loginData: LoginData) {
    let loginSuccess = true;
    try {
      commit('login');
      const res = await this.$api.post('/auth/login', { ...loginData, client_id: 'web' }, { withCredentials: true });
      const jwtData = parseJwt(res.data.id_token);
      // We clear the localStorage if another user was logged in
      const storedUser: User = localStorage.getItem(StorageKey.USER) != null ? JSON.parse(localStorage.getItem(StorageKey.USER)) : null;
      if (storedUser?.id == null || storedUser.id !== jwtData.sub) {
        localStorage.clear();
      }
      await dispatch('loadUserDetails');
      dispatch('setSentryUserContext');
      this.$analytics.setUserId(getters.currentUser.id);
      this.$analytics.trackLogin();
      await dispatch('loadUserRelatedData');
      dispatch('initializeSocketConnection', null, { root: true });
      localStorage.setItem(StorageKey.TAB_MESSAGE, JSON.stringify({ id: uuid(), originId: rootState.originId, type: TabMessageType.LOGIN }));
      loginSuccess = true;
    } catch (error) {
      loginSuccess = false;
      commit('loginFailed', createError(error));
    } finally {
      if (loginSuccess && this.$router.currentRoute.query.consent) {
        const { client_id: clientId, redirect_uri: redirectUri, code_challenge: codeChallenge } = this.$router.currentRoute.query;
        setTimeout(() => {
          window.location.href = `${WebConfig.API_URL}/auth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}`;
        }, 250);
      }
    }
  },
  async refreshToken({ commit, dispatch }: UserContext) {
    try {
      await this.$tokenRefresher.refreshAuthToken();
      await dispatch('loadUserDetails');
    } catch (error) {
      commit('loginFailed', createError(error));
    }
  },
  async upgradeAccount({ commit, dispatch }: any, subscriptionId: string) {
    // we don't handle the failure case here, since we are already waiting for the paypal webhook in our backend
    await this.$api.post('/users/me/upgrade', { subscriptionId });
    try {
      await dispatch('refreshToken');
    } finally {
      // refreshing the token for the upgraded user role should not stop us from ending the upgrade process here
      commit('setUserRoleUpgradePending', { isPending: false });
    }
  },
  async loadProfile({ commit }: UserContext, userId: string) {
    const { data } = await this.$api.get(`/users/${userId}/profile`);
    commit('addProfile', data);
  },
  async loadContacts({ commit }: UserContext) {
    const { data } = await this.$api.get('/contacts');
    commit('setContacts', data);
  },
  async deleteContact({ commit }: UserContext, contact: Contact) {
    await this.$api.delete(`/contacts/${contact.id}`);
    commit('removeContact', contact);
  },
  async patchContact({ commit }: UserContext, patchContact: { id: string, isEnabled: boolean }) {
    await this.$api.patch(`/contacts/${patchContact.id}`, { isEnabled: patchContact.isEnabled });
    commit('updateContact', patchContact);
  },
  synchronizeDevices({ state, dispatch }: UserContext) {
    // the base browser device is created on signup so we don't need to actively sync here since it should already be present
    if (!state.devices.length) {
      dispatch('loadUserRelatedData');
    }
  },
  async loadUserRelatedData({ commit, dispatch }: UserContext) {
    const { data: devices } = await this.$api.get('/devices');
    commit('loadDevicesSuccess', devices);
    dispatch('loadUserCloudObjects', null);
  },
  loadUserCloudObjects({ dispatch }: UserContext) {
    dispatch('folder/loadFolders', null, { root: true });
    dispatch('selection/loadSelections', null, { root: true });
  },
  async loadUserDetails({ commit, dispatch }: UserContext) {
    const { data } = await this.$api.get('/users/me');
    commit('loadUserDetailsSuccess', data);
    commit('profile/loadProfileSuccess', { id: data.id, username: data.username, hasProfilePicture: data.hasProfilePicture } as UserProfile, { root: true });
    const userData: User = { id: data.id, username: data.username, roles: data.roles };
    localStorage.setItem(StorageKey.USER, JSON.stringify(userData));
    commit('loginSuccess', userData);
    await dispatch('loadUserLimits');
  },
  async updateField({ commit }: UserContext, { key, value }: { key: string, value: any }) {
    const patchData = {};
    patchData[key] = value;
    const { data } = await this.$api.patch('/users/me', patchData);
    commit('loadUserDetailsSuccess', data);
  },
  itemAddedForConversation({ commit }: UserContext, item: Item) {
    commit('itemAddedForConversation', item);
  },
  itemResizedForConversation({ commit }: UserContext, data: ResizeCompletedEventData) {
    commit('itemResizedForConversation', data);
  },
  messagesAddedToConversation({
    getters,
    commit,
  }: UserContext, data: MessagesAddedEventData) {
    data.messages.forEach(message => {
      const senderId = typeof message.from === 'string' ? message.from : message.from.id;
      message.from = getters.contact(message.from);
      message.to = getters.contact(message.to);
      message.unread = senderId !== getters.currentUser.id;
      commit('addNewMessage', message);
    });
  },
  async sendObjectToConversation({ rootGetters, rootState, dispatch }: UserContext, {
    userId,
    event,
  }: { userId: string, event: DragEvent | Event }) {
    let mutantId;
    let url;
    // dataTransfer info needs to be fetched from event before doing async stuff, else this info is lost
    if ((<DragEvent> event).dataTransfer) {
      mutantId = (<DragEvent> event).dataTransfer.getData(TransferOption.MUTANT_ID);
      url = (<DragEvent> event).dataTransfer.getData(TransferType.URL);
    }
    // @ts-ignore
    const files: File[] = event.dataTransfer ? event.dataTransfer.files : event.target && event.target.files;
    if (files && files.length) {
      const folderId = rootGetters['folder/scrapbookId'];
      dispatch('file/uploadFilesTo', { folderId, files }, { root: true });
      let uploadInProgress = true;
      while (uploadInProgress) {
        await new Promise((resolve) => setTimeout(() => resolve, 500));
        if (rootState.file.uploadProgress.finished) {
          uploadInProgress = false;
        }
      }
      const uploadedItemIds = rootState.file.uploadProgress.uploadedItems?.map(i => i.id) || [];
      if (uploadedItemIds.length) {
        await this.$api.post('/items/move', {
          items: uploadedItemIds,
          userId,
        });
      }
    } else {
      const items: string[] = rootState.dragInfo?.items.map(i => i.id) || [];
      if (items.length) {
        await this.$api.post('/items/move', {
          items,
          userId,
        });
      }
    }
  },
  async sendItems({ state, commit, getters }: UserContext, { destination, items }: { destination: Contact, items: Item[] }) {
    const userConnection: Contact = getters.contact(state.user.id);
    const { data } = await this.$api.post('/items/send', {
      recipient: destination.id,
      items: items.map(i => i.id),
    });
    data.from = userConnection;
    data.to = destination;
    commit('addNewMessage', data);
    if (this.$router.currentRoute.name === 'messages-id') {
      setTimeout(() => commit('clearItemsToSend', destination.id), 1000);
    }
  },
  async removePreparedItems({ getters, commit }: UserContext, connection: Contact) {
    const promises = [];
    const preparedItems = getters.contact(connection.id)?.itemsToSend || [];
    preparedItems.forEach(item => {
      promises.push(this.$api.delete(`/items/${item.id}`));
    });
    await Promise.all(promises);
    commit('removePreparedItems', { items: preparedItems, connection });
  },
  async addMessageRoute({ commit }: UserContext, {
    selectionId,
    folderId,
    position,
  }: { selectionId: string, folderId: string, position: { x: number, y: number } }) {
    const data: any = {};
    if (selectionId) {
      data.selectionId = selectionId;
      if (position) {
        data.position = position;
      }
    }
    if (folderId) {
      data.folderId = folderId;
    }
    const { data: newMessageRoute } = await this.$api.post('/messages/routes', data);
    commit('setMessageRoutes', [newMessageRoute]);
  },
  async removeMessageRoute({ commit }: UserContext, messageRouteId) {
    await this.$api.delete(`/messages/routes/${messageRouteId}`);
    commit('removeMessageRoute', messageRouteId);
  },
  async fetchMessageRoutes({ commit, getters }: UserContext) {
    if (getters.isUser) {
      const { data } = await this.$api.get('/messages/routes');
      commit('setMessageRoutes', data);
    }
  },
  async setIsReceiveContentEnabled({ state, commit, getters }: UserContext) {
    if (getters.isUser) {
      await this.$api.put('/dropzone/options', { isReceiveContentEnabled: !state.isReceiveContentEnabled });
      commit('setReceiveContentEnabled', !state.isReceiveContentEnabled);
    }
  },
  async loadDropzoneInfo({ commit, getters }: UserContext) {
    if (getters.isUser) {
      const { data } = await this.$api.get('/dropzone/options');
      commit('setReceiveContentEnabled', data.data?.isReceiveContentEnabled ?? false);
    }
  },
  async searchProfiles({ commit }: UserContext, searchTerm: string) {
    commit('startProfileSearch', searchTerm);
    const { data: profileResults } = await this.$api.get(`/users/search?q=${encodeURIComponent(searchTerm)}`);
    commit('setProfileSearchResult', {
      searchTerm,
      profileResults,
    });
  },
  async searchUsername({ commit }: UserContext, searchTerm: string) {
    commit('startUsernameSearch', searchTerm);
    const { data: usernameResults } = await this.$api.get(`/users/search?q=${encodeURIComponent(searchTerm)}`);
    const isTaken = usernameResults.data?.username != null;
    commit('setUsernameSearchResult', {
      searchTerm,
      isTaken,
    });
  },
  async addContact({ commit, dispatch }: UserContext, username: string): Promise<Contact> {
    try {
      const { data } = await this.$api.post('/contacts', { username, isEnabled: true });
      commit('addContact', data.data);
      return data.data;
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch('setNotificationMessage', {
          payload: {
            message: InfoMessages.CONTACT_ALREADY_EXISTS,
            type: NotificationType.INFO,
            duration: 5000,
          },
        }, { root: true });
      } else if (error?.response?.status === 404) {
        dispatch('setNotificationMessage', {
          payload: {
            message: InfoMessages.USER_NOT_FOUND,
            type: NotificationType.INFO,
            duration: 5000,
          },
        }, { root: true });
      } else {
        dispatch('setNotificationMessage', {
          payload: {
            message: WarningMessages.SERVER_ERROR,
            type: NotificationType.ERROR,
            duration: 5000,
          },
        }, { root: true });
      }
    }
  },
  async validateMail({ dispatch, getters }: UserContext, validationCode: string) {
    let validationError = false;
    try {
      await this.$api.post('/auth/validateEmail', { token: validationCode });
    } catch {
      validationError = true;
    } finally {
      await this.$router.replace({ name: LANDING_PAGE_ROUTE });
      if (validationError) {
        await dispatch<ActionPayload<ValidationData>>({
          type: 'openValidationContext',
          payload: {
            isError: validationError,
            validationType: ValidationType.EMAIL_VERIFICATION,
            message: validationError ? EmailValidationFeedback.error : EmailValidationFeedback.success,
            validationName: 'EMAIL VALIDATION',
          },
        });
      } else if (getters.isGuest) {
        await dispatch('setNotificationMessage', {
          payload: {
            message: InfoMessages.VERIFY_EMAIL_SUCCESS_WITH_LOGIN_PROMPT,
            type: NotificationType.SUCCESS,
            duration: 8000,
          },
        }, { root: true });
        await dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: true } }, { root: true });
      } else {
        await dispatch('setNotificationMessage', {
          payload: {
            message: InfoMessages.VERIFY_EMAIL_SUCCESS,
            type: NotificationType.SUCCESS,
            duration: 5000,
          },
        }, { root: true });
        await this.$tokenRefresher.refreshAuthToken();
        await dispatch('loadUserDetails');
        await dispatch('context/openMenu', { type: ContextMenuType.ACCOUNT_OVERVIEW }, { root: true });
      }
    }
  },
  openValidationContext({ dispatch }, data: ValidationData) {
    dispatch('context/openMenu', { type: ContextMenuType.VALIDATION, data }, { root: true });
  },
  async changePassword({ dispatch }: UserContext, data: { oldPassword: string, newPassword: string, token: string}) {
    let validationError = false;
    try {
      await this.$api.post('/auth/changePassword', { oldPassword: data.oldPassword, newPassword: data.newPassword, token: data.token });
    } catch {
      validationError = true;
    } finally {
      await dispatch<ActionPayload<ValidationData>>({
        type: 'openValidationContext',
        payload: {
          isError: validationError,
          validationType: ValidationType.PASSWORD_CHANGE,
          message: validationError ? PasswordChangeValidationFeedback.error : PasswordChangeValidationFeedback.success,
          validationName: 'PASSWORD CHANGE',
        },
      });
    }
  },
  async loadUserLimits({ commit }: UserContext) {
    const { data } = await this.$api.get('/users/me/limits');
    commit('setUserLimits', data);
  },
};

export default actions;

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5d9e0dad = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _1a8adcf5 = () => interopDefault(import('../pages/cloud/index.vue' /* webpackChunkName: "pages/cloud/index" */))
const _1793ac6e = () => interopDefault(import('../pages/dropzone.vue' /* webpackChunkName: "pages/dropzone" */))
const _1959480f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _66127560 = () => interopDefault(import('../pages/cloud/_id/index.vue' /* webpackChunkName: "pages/cloud/_id/index" */))
const _3845e566 = () => interopDefault(import('../pages/shared/_id/index.vue' /* webpackChunkName: "pages/shared/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _5d9e0dad,
    name: "account"
  }, {
    path: "/cloud",
    component: _1a8adcf5,
    name: "cloud"
  }, {
    path: "/dropzone",
    component: _1793ac6e,
    name: "dropzone"
  }, {
    path: "/",
    component: _1959480f,
    name: "index"
  }, {
    path: "/cloud/:id",
    component: _66127560,
    name: "cloud-id"
  }, {
    path: "/shared/:id",
    component: _3845e566,
    name: "shared-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import { FileProcessingPipeline } from '~/models/pipeline/FileProcessingPipeline';
import { FileProcessingPipelineBuilder } from '~/models/pipeline/FileProcessingPipelineBuilder';
import { PipelineItem } from '~/models/PipelineItem';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';

export class FileProcessingPipelineManager {
  private defaultPipeline: FileProcessingPipeline;
  private pipelineMap = new Map<string, FileProcessingPipeline>();

  public static defaultPipelineId = 'DEFAULT';

  constructor() {
    this.defaultPipeline = new FileProcessingPipelineBuilder()
      .withId(FileProcessingPipelineManager.defaultPipelineId)
      .withCommands([])
      .build();
  }

  public setupPipeline(pipelineId: string, commands: PipelineCommandInstruction[]): FileProcessingPipeline {
    const pipeline = new FileProcessingPipelineBuilder()
      .withId(pipelineId)
      .withCommands(commands)
      .build();
    if (this.hasPipeline(pipelineId)) {
      this.updatePipeline(pipeline);
    } else {
      this.addPipeline(pipeline);
    }
    this.runPipeline(pipelineId);
    return pipeline;
  }

  public hasPipeline(pipelineId: string): boolean {
    return this.pipelineMap.has(pipelineId);
  }

  public addPipeline(pipeline: FileProcessingPipeline): void {
    this.pipelineMap.set(pipeline.id, pipeline);
  }

  public updatePipeline(pipeline: FileProcessingPipeline): void {
    this.pipelineMap.set(pipeline.id, pipeline);
  }

  public removePipeline(pipelineId: string): void {
    this.pipelineMap.delete(pipelineId);
  }

  public runPipeline(pipelineId: string): void {
    const pipeline = this.getPipeline(pipelineId);
    if (!pipeline.isActive) {
      pipeline.run();
    }
  }

  public processItemsForPipeline(pipelineId, pipelineItems: PipelineItem[]): void {
    this.getPipeline(pipelineId)?.processItems(pipelineItems);
  }

  public getPipeline(pipelineId: string): FileProcessingPipeline {
    return this.pipelineMap.get(pipelineId) || this.defaultPipeline;
  }

  public pausePipeline(pipelineId: string): void {
    this.getPipeline(pipelineId)?.pause();
  }

  public cancelPipeline(pipelineId: string): void {
    this.getPipeline(pipelineId)?.cancel();
  }

  public continuePipeline(pipelineId: string): void {
    this.getPipeline(pipelineId)?.resume();
  }

  public stopPipeline(pipelineId: string): void {
    this.getPipeline(pipelineId)?.stop();
  }

  public isPipelineRunning(pipelineId: string): boolean {
    return this.getPipeline(pipelineId)?.isActive;
  }
}

import { bufferWhen, filter, Observable, takeUntil } from 'rxjs';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { pipelineCommands$ } from '~/models/pipelineCommands$';
import { pipelineEvents$ } from '~/models/pipelineEvents$';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { PipelineEvent } from '~/models/pipeline/PipelineEvent';
import { containsErroredEvent, containsEventType, PipelineItem } from '~/models/PipelineItem';
import { FileProcessingPipelineStatus } from '~/models/pipeline/FileProcessingPipelineStatus';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';

function arraysMatch(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export enum PipelineInfoLabel {
  EXTRACT_METADATA = 'extract metadata',
  CREATE_THUMBNAILS = 'indexing',
  UPLOAD_THUMBNAILS = 'upload thumbnails',
  UPLOAD_ORIGINALS = 'upload originals',
  UPLOAD_RAWS = 'upload raws',
}

const pipelineCommandOrder = [PipelineCommandType.EXTRACT_METADATA, PipelineCommandType.CREATE_THUMBNAILS, PipelineCommandType.UPLOAD_THUMBNAILS, PipelineCommandType.UPLOAD_ORIGINALS, PipelineCommandType.UPLOAD_RAWS];
const pipelineEventOrder = [PipelineEventType.METADATA_EXTRACTED, PipelineEventType.THUMBNAILS_CREATED, PipelineEventType.ORIGINALS_UPLOADED, PipelineEventType.RAWS_UPLOADED];

export const pipelineCommandToRelatedEventMap: Map<PipelineCommandType, PipelineEventType>
  = new Map([
    [PipelineCommandType.EXTRACT_METADATA, PipelineEventType.METADATA_EXTRACTED],
    [PipelineCommandType.CREATE_THUMBNAILS, PipelineEventType.THUMBNAILS_CREATED],
    [PipelineCommandType.UPLOAD_THUMBNAILS, PipelineEventType.THUMBNAILS_UPLOADED],
    [PipelineCommandType.UPLOAD_ORIGINALS, PipelineEventType.ORIGINALS_UPLOADED],
    [PipelineCommandType.UPLOAD_RAWS, PipelineEventType.RAWS_UPLOADED],
  ]);

export const pipelineEventToRelatedCommandMap: Map<PipelineEventType, PipelineCommandType> = new Map([
  [PipelineEventType.METADATA_EXTRACTED, PipelineCommandType.EXTRACT_METADATA],
  [PipelineEventType.THUMBNAILS_CREATED, PipelineCommandType.CREATE_THUMBNAILS],
  [PipelineEventType.THUMBNAILS_UPLOADED, PipelineCommandType.UPLOAD_THUMBNAILS],
  [PipelineEventType.ORIGINALS_UPLOADED, PipelineCommandType.UPLOAD_ORIGINALS],
  [PipelineEventType.RAWS_UPLOADED, PipelineCommandType.UPLOAD_RAWS],
]);

const pipelineCommandToInfoLabelMap: Map<PipelineCommandType, PipelineInfoLabel>
  = new Map([
    [PipelineCommandType.EXTRACT_METADATA, PipelineInfoLabel.EXTRACT_METADATA],
    [PipelineCommandType.CREATE_THUMBNAILS, PipelineInfoLabel.CREATE_THUMBNAILS],
    [PipelineCommandType.UPLOAD_THUMBNAILS, PipelineInfoLabel.UPLOAD_THUMBNAILS],
    [PipelineCommandType.UPLOAD_ORIGINALS, PipelineInfoLabel.UPLOAD_ORIGINALS],
    [PipelineCommandType.UPLOAD_RAWS, PipelineInfoLabel.UPLOAD_RAWS],
  ]);

const pipelineCommandToCompletionEventMap: Map<PipelineCommandType, PipelineEventType> = new Map([
  [PipelineCommandType.EXTRACT_METADATA, PipelineEventType.METADATA_EXTRACTED_DONE],
  [PipelineCommandType.CREATE_THUMBNAILS, PipelineEventType.THUMBNAILS_CREATED_DONE],
  [PipelineCommandType.UPLOAD_THUMBNAILS, PipelineEventType.THUMBNAILS_UPLOADED_DONE],
  [PipelineCommandType.UPLOAD_ORIGINALS, PipelineEventType.ORIGINALS_UPLOADED_DONE],
  [PipelineCommandType.UPLOAD_RAWS, PipelineEventType.RAWS_UPLOADED_DONE],
]);

export const pipelineCommandToProgressEventMap: Map<PipelineCommandType, PipelineEventType> = new Map([
  [PipelineCommandType.EXTRACT_METADATA, PipelineEventType.METADATA_EXTRACTED_PROGRESS],
  [PipelineCommandType.CREATE_THUMBNAILS, PipelineEventType.THUMBNAILS_CREATED_PROGRESS],
  [PipelineCommandType.UPLOAD_THUMBNAILS, PipelineEventType.THUMBNAILS_UPLOADED_PROGRESS],
  [PipelineCommandType.UPLOAD_ORIGINALS, PipelineEventType.ORIGINALS_UPLOADED_PROGRESS],
  [PipelineCommandType.UPLOAD_RAWS, PipelineEventType.RAWS_UPLOADED_PROGRESS],
]);

export class FileProcessingPipeline {
  private pipelineItemsTotal = 0;
  private pipelineItemsBeingProcessed: Set<string> = new Set();
  private pipelineItemsProcessed: PipelineItem[] = [];
  private status = FileProcessingPipelineStatus.STOPPED;
  private nextPipelineCommands: Map<PipelineCommandType, PipelineCommandInstruction> = new Map<PipelineCommandType, PipelineCommandInstruction>();
  private pipelineItemsProcessedForCommands: Map<PipelineCommandType, number> = new Map();
  private stopPipelineTimeoutHandler = null;
  private stalePipelineItemIds: Set<string> = new Set();
  private eventsWaitingForManualSignOff: PipelineEvent<PipelineItem[]>[] = [];
  private commandBeingProcessed: PipelineCommandInstruction = null;
  private subscriptions = [];
  private itemsWaitingToBeProcessedByUpdatedPipeline: PipelineItem[] = [];

  public static PROGRESS_COMPLETED = 100;
  public static GRACE_PERIOD_FOR_BUFFERING_IN_MS = 1_000;
  public static UPLOAD_COMMANDS = [PipelineCommandType.UPLOAD_THUMBNAILS, PipelineCommandType.UPLOAD_ORIGINALS, PipelineCommandType.UPLOAD_RAWS];

  public static commandsToVersions(commands: PipelineCommandType[]): number[] {
    return commands
      .filter(command => this.UPLOAD_COMMANDS.includes(command))
      .map(command => {
        if (command === PipelineCommandType.UPLOAD_THUMBNAILS) {
          return CUSTOM_SUB_VERSION;
        }
        if (command === PipelineCommandType.UPLOAD_RAWS) {
          return RAW_ASSET_VERSION;
        }
        return ORIGINAL_ASSET_VERSION;
      });
  }

  public static sortPipelineCommands(commands: PipelineCommandType[]): PipelineCommandType[] {
    return commands
      ?.slice()
      .sort((a, b) => pipelineCommandOrder.indexOf(a) - pipelineCommandOrder.indexOf(b));
  }

  public static buildPipelineCommandInstruction(commands: PipelineCommandType[]): PipelineCommandInstruction[] {
    return commands.map((command, idx) => ({ type: command, infoLabel: pipelineCommandToInfoLabelMap.get(command), waitsForCompletion: idx !== 0, waitsForManualSignOff: false }));
  }

  public static sortPipelineItemsByAlreadyProcessedEvents(pipelineItems: PipelineItem[]) {
    return pipelineItems?.slice().sort((a, b) => {
      if (a.eventsProcessed?.length > 0 || b.eventsProcessed?.length > 0) {
        const lastEventProcessedA = a.eventsProcessed?.length ? a.eventsProcessed[a.eventsProcessed.length - 1] : null;
        const lastEventProcessedB = b.eventsProcessed?.length ? b.eventsProcessed[b.eventsProcessed.length - 1] : null;
        if (lastEventProcessedA?.type !== lastEventProcessedB?.type) {
          if (lastEventProcessedA == null) {
            return -1;
          }
          if (lastEventProcessedB == null) {
            return 1;
          }
          return pipelineEventOrder.indexOf(lastEventProcessedB?.type) - pipelineEventOrder.indexOf(lastEventProcessedA?.type);
        }
      }
      return a.order - b.order;
    });
  }

  private static sortPipelineCommandInstructions(commands: PipelineCommandInstruction[]) {
    return commands?.slice().sort((a, b) => pipelineCommandOrder.indexOf(a.type) - pipelineCommandOrder.indexOf(b.type));
  }

  constructor(public id: string, public commands: PipelineCommandInstruction[]) {
    this.commands = this.setPipelineInfoLabels(this.commands);
    this.validatePipelineCommands(this.commands);
    this.buildNextPipelineCommands(this.commands);
  }

  public updateCommands(newCommands: PipelineCommandInstruction[]) {
    clearTimeout(this.stopPipelineTimeoutHandler);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
    const updatedCommands = FileProcessingPipeline
      .sortPipelineCommandInstructions([...this.commands.filter(command => !newCommands.some(newCommand => newCommand.type === command.type)), ...newCommands])
      .map((command, idx) => {
        command.waitsForCompletion = idx !== 0;
        return command;
      });
    const previouslyMissingCommands = newCommands.filter(newCommand => !this.commands.some(oldCommand => oldCommand.type === newCommand.type) && pipelineCommandOrder.indexOf(newCommand.type) < pipelineCommandOrder.indexOf(this.commands[0]?.type));
    if (previouslyMissingCommands.length) {
      throw new Error(`You can only add steps to a running pipeline for now, the missing commands are ${previouslyMissingCommands.map(c => c.type).join(',')}`);
    }
    this.commands = this.setPipelineInfoLabels(updatedCommands);
    this.nextPipelineCommands = new Map();
    this.buildNextPipelineCommands(updatedCommands);
    const itemsAlreadyProcessedBeforeUpdate = this.pipelineItemsProcessed.slice();
    this.pipelineItemsProcessed = [];
    this.pipelineItemsTotal -= itemsAlreadyProcessedBeforeUpdate.length;
    this.itemsWaitingToBeProcessedByUpdatedPipeline = itemsAlreadyProcessedBeforeUpdate;
    this.run();
  }

  private setPipelineInfoLabels(commands: PipelineCommandInstruction[]): PipelineCommandInstruction[] {
    return commands.map(command => {
      command.infoLabel = pipelineCommandToInfoLabelMap.get(command.type);
      return command;
    });
  }

  private buildNextPipelineCommands(commands) {
    commands.forEach((command, idx) => {
      const nextCommand = commands[idx + 1] || { type: PipelineCommandType.COMPLETE_PROCESSING_FILES, waitsForCompletion: false };
      this.nextPipelineCommands.set(command.type, nextCommand);
      if (!this.pipelineItemsProcessedForCommands.has(command.type)) {
        this.pipelineItemsProcessedForCommands.set(command.type, 0);
      }
    });
  }

  private get commandTypes(): PipelineCommandType[] {
    return this.commands.map(command => command.type);
  }

  private validatePipelineCommands(commands: PipelineCommandInstruction[]) {
    if (commands[0]?.waitsForCompletion) {
      throw new Error(`The first command ${commands[0].type} cannot wait for completion of a previous (non existing) command.`);
    }
    commands.forEach((command, idx) => {
      const nextCommand = commands[idx + 1];
      if (nextCommand != null && pipelineCommandOrder.indexOf(command.type) > pipelineCommandOrder.indexOf(nextCommand.type)) {
        throw new Error(`${nextCommand.type} must be configured before ${command.type}`);
      }
    });
  }

  removeStalePipelineItems(itemIds: string[]) {
    itemIds.forEach(itemId => this.stalePipelineItemIds.add(itemId));
  }

  public get subscription$(): Observable<PipelineEvent<any>> {
    const isEventRelatedToPipeline = e => e.pipelineId === this.id;
    const isDoneEvent = e => e.type === PipelineEventType.PIPELINE_DONE;
    const isPipelineDone$ = pipelineEvents$.pipe(filter(isEventRelatedToPipeline), filter(isDoneEvent));
    return pipelineEvents$.pipe(takeUntil(isPipelineDone$), filter(isEventRelatedToPipeline));
  }

  public get firstUploadEvent(): PipelineEventType {
    const firstUploadCommand = this.commands.map(c => c.type).filter(commandType => FileProcessingPipeline.UPLOAD_COMMANDS.includes(commandType))[0];
    if (firstUploadCommand) {
      return pipelineCommandToRelatedEventMap.get(firstUploadCommand);
    }
    return null;
  }

  public get hasCreateThumbnailsEvent(): boolean {
    return this.commands.some(command => command.type === PipelineCommandType.CREATE_THUMBNAILS);
  }

  public get hasUploadEvent(): boolean {
    return this.firstUploadEvent != null;
  }

  public get activeStep(): PipelineCommandInstruction {
    return this.commandBeingProcessed;
  }

  public get nextStep(): PipelineCommandInstruction {
    return this.nextPipelineCommands.get(this.activeStep.type);
  }

  public get isActive(): boolean {
    return this.status === FileProcessingPipelineStatus.ACTIVE;
  }

  public get isProcessingItems(): boolean {
    return this.pipelineItemsBeingProcessed.size > 0;
  }

  public isUpgradableWithGivenCommands(commandsToProcess: PipelineCommandType[]) {
    const commandsBeingProcessed = this.commandTypes;
    return (commandsBeingProcessed.length === 1 && commandsBeingProcessed.includes(PipelineCommandType.CREATE_THUMBNAILS))
      || arraysMatch([PipelineCommandType.CREATE_THUMBNAILS, ...commandsBeingProcessed], commandsToProcess);
  }

  public isCompatibleWithGivenCommands(commandsToProcess: PipelineCommandType[]) {
    const commandsBeingProcessed = this.commandTypes;
    return arraysMatch(commandsBeingProcessed, commandsToProcess)
      // upload raws is only processed for files that actually are raw files, so this step can be processed for both original and raw files
      || arraysMatch(commandsBeingProcessed, [...commandsToProcess, PipelineCommandType.UPLOAD_RAWS]);
  }

  public pause(): void {
    this.status = FileProcessingPipelineStatus.PAUSED;
    pipelineCommands$.next({ type: PipelineCommandType.PAUSE_PIPELINE, pipelineId: this.id });
  }

  public resume(): void {
    this.status = FileProcessingPipelineStatus.ACTIVE;
    pipelineCommands$.next({ type: PipelineCommandType.CONTINUE_PIPELINE, pipelineId: this.id });
  }

  public cancel(): void {
    pipelineCommands$.next({ type: PipelineCommandType.CANCEL_PIPELINE, pipelineId: this.id });
    this.reset();
  }

  public reset(): void {
    this.status = FileProcessingPipelineStatus.STOPPED;
    this.eventsWaitingForManualSignOff = [];
    pipelineEvents$.next({ type: PipelineEventType.PIPELINE_DONE, pipelineId: this.id });
    this.pipelineItemsTotal = 0;
    this.pipelineItemsProcessed = [];
    this.commands.forEach(command => this.pipelineItemsProcessedForCommands.set(command.type, 0));
  }

  public stop(): void {
    clearTimeout(this.stopPipelineTimeoutHandler);
    this.stopPipelineTimeoutHandler = setTimeout(() => {
      this.reset();
    }, FileProcessingPipeline.GRACE_PERIOD_FOR_BUFFERING_IN_MS);
  }

  public processItems(items: PipelineItem[]): void {
    clearTimeout(this.stopPipelineTimeoutHandler);
    if (this.itemsWaitingToBeProcessedByUpdatedPipeline.length > 0 || this.pipelineItemsBeingProcessed.size > 0) {
      items = FileProcessingPipeline.sortPipelineItemsByAlreadyProcessedEvents(
        this.itemsWaitingToBeProcessedByUpdatedPipeline
          .concat(items.filter(item => !this.itemsWaitingToBeProcessedByUpdatedPipeline.some(i => i.id === item.id)
            && !this.pipelineItemsBeingProcessed.has(item.id)))
      );
    }
    this.pipelineItemsTotal += items.length;
    this.adjustProcessedCountsForSkippedCommandsNotAlreadyProcessedByPipeline(items);
    this.itemsWaitingToBeProcessedByUpdatedPipeline = [];
    setTimeout(() => pipelineCommands$.next({ type: PipelineCommandType.PROCESS_FILES, pipelineId: this.id, items }), 0);
  }

  // For pipeline items that were already in progress before but got stuck in a pipeline update (were reentered into the updated processing pipeline)
  // we need to figure out which command needs to be processed in the updated pipeline
  private findEntryCommandForItem(pipelineItem): PipelineCommandType {
    let entryCommand = this.commands[0]?.type || PipelineCommandType.COMPLETE_PROCESSING_FILES;
    if (pipelineItem.eventsProcessed?.length > 0) {
      for (const command of this.commands) {
        if (pipelineItem.eventsProcessed.some(containsEventType(pipelineCommandToRelatedEventMap.get(command.type)))) {
          entryCommand = this.nextPipelineCommands.get(command.type).type;
        }
      }
    }
    return entryCommand;
  }

  private adjustProcessedCountsForSkippedCommandsNotAlreadyProcessedByPipeline(items: PipelineItem[]) {
    items.forEach(item => {
      for (const command of this.commands) {
        if (item.eventsProcessed?.length && item.eventsProcessed.some(containsEventType(pipelineCommandToRelatedEventMap.get(command.type))) && !this.itemsWaitingToBeProcessedByUpdatedPipeline.some(i => i.id === item.id)) {
          this.pipelineItemsProcessedForCommands.set(command.type, this.pipelineItemsProcessedForCommands.get(command.type) + 1);
        } else {
          break;
        }
      }
    });
    this.pipelineItemsProcessedForCommands.forEach((value, key) => {
      const progress = Math.floor(value / this.pipelineItemsTotal * 100) || 0;
      pipelineEvents$.next({ type: pipelineCommandToProgressEventMap.get(key), pipelineId: this.id, data: progress });
    });
  }

  private forceCompleteErroredItems(items: PipelineItem[]) {
    if (items.length) {
      this.pipelineItemsTotal -= items.length;
      items.forEach(item => {
        item.eventsProcessed.forEach(event => {
          if (event.error == null) {
            const pipelineEventType = pipelineEventToRelatedCommandMap.get(event.type);
            this.pipelineItemsProcessedForCommands.set(pipelineEventType, this.pipelineItemsProcessedForCommands.get(pipelineEventType) - 1);
          }
        });
      });
      pipelineCommands$.next({
        type: PipelineCommandType.COMPLETE_PROCESSING_FILES,
        pipelineId: this.id,
        items,
      });
    }
  }

  public run(): void {
    this.status = FileProcessingPipelineStatus.ACTIVE;
    const isEventRelatedToPipeline = e => e.pipelineId === this.id;
    const isDoneEvent = e => e.type === PipelineEventType.PIPELINE_DONE;
    const done$ = pipelineEvents$.pipe(filter(isEventRelatedToPipeline), filter(isDoneEvent));

    this.subscriptions.push(pipelineCommands$
      .pipe(
        takeUntil(done$),
        filter(isEventRelatedToPipeline),
        filter(e => e.type === PipelineCommandType.PROCESS_FILES)
      )
      .subscribe(event => {
        event.items.forEach(item => {
          const entryCommand = this.findEntryCommandForItem(item);
          this.pipelineItemsBeingProcessed.add(item.id);
          pipelineCommands$.next({ ...event, items: [item], type: entryCommand });
        });
      }));

    // Setup event listener and command dispatching for all configured pipeline commands
    for (const command of this.commands) {
      const { waitsForCompletion: nextCommandWaitsForCompletion } = this.nextPipelineCommands.get(command.type);
      if (nextCommandWaitsForCompletion) {
        const commandDone$ = pipelineEvents$
          .pipe(
            takeUntil(done$),
            filter(isEventRelatedToPipeline),
            filter(e => e.type === pipelineCommandToCompletionEventMap.get(command.type))
          );
        this.subscriptions.push(pipelineEvents$
          .pipe(
            takeUntil(done$),
            filter(isEventRelatedToPipeline),
            filter(e => e.type === pipelineCommandToRelatedEventMap.get(command.type)),
            bufferWhen(() => commandDone$),
            filter(b => b.length > 0)
          )
          .subscribe((events: PipelineEvent<PipelineItem[]>[]) => {
            this.commandBeingProcessed = this.nextPipelineCommands.get(command.type);
            const filteredEvents: PipelineEvent<PipelineItem[]>[] = events.map(event => {
              const itemCountBefore = event.data.length;
              event.data = event.data.filter(item => !this.stalePipelineItemIds.has(item.id));
              this.pipelineItemsTotal -= itemCountBefore - event.data.length;
              return event;
            });
            const nextPipelineCommand = this.nextPipelineCommands.get(command.type)?.type;
            filteredEvents.forEach(event => {
              let items = event.data;
              if (items.some(i => !i.eventsToProcess.includes(nextPipelineCommand))) {
                items = event.data.map(i => ({ ...i, eventsToProcess: this.commands.map(c => c.type) }));
              }
              setTimeout(() => pipelineCommands$.next({
                type: nextPipelineCommand,
                pipelineId: event.pipelineId,
                items,
              }), 0);
            });
          }));
      }
      this.subscriptions.push(pipelineEvents$
        .pipe(
          takeUntil(done$),
          filter(isEventRelatedToPipeline),
          filter(e => e.type === pipelineCommandToRelatedEventMap.get(command.type))
        )
        .subscribe(event => {
          const activeItems = event.data.filter(item => !this.stalePipelineItemIds.has(item.id));
          this.pipelineItemsTotal -= (event.data.length - activeItems.length);
          const [erroredItems, filteredEventItems] = filterByConditions(activeItems, (item) => item.eventsProcessed.some(containsErroredEvent));
          this.forceCompleteErroredItems(erroredItems);
          const itemsProcessedForCommand = this.pipelineItemsProcessedForCommands.get(command.type) + filteredEventItems.length;
          this.pipelineItemsProcessedForCommands.set(command.type, itemsProcessedForCommand);
          const progress = Math.floor(itemsProcessedForCommand / this.pipelineItemsTotal * 100) || 1;
          if (!nextCommandWaitsForCompletion) {
            const nextPipelineCommand = this.nextPipelineCommands.get(command.type)?.type;
            let items = filteredEventItems;
            if (items.some(i => !i.eventsToProcess.includes(nextPipelineCommand))) {
              items = event.data.map(i => ({ ...i, eventsToProcess: this.commands.map(c => c.type) }));
            }
            pipelineCommands$.next({
              type: nextPipelineCommand,
              pipelineId: event.pipelineId,
              items,
            });
          }
          setTimeout(() => pipelineEvents$.next({ type: pipelineCommandToProgressEventMap.get(command.type), pipelineId: event.pipelineId, data: progress }), 0);
          if (itemsProcessedForCommand === this.pipelineItemsTotal) {
            setTimeout(() => pipelineEvents$.next({ type: pipelineCommandToCompletionEventMap.get(command.type), pipelineId: event.pipelineId, data: true }), 0);
          }
        }));
    }

    this.subscriptions.push(pipelineCommands$
      .pipe(
        takeUntil(done$),
        filter(isEventRelatedToPipeline),
        filter(e => e.type === PipelineCommandType.COMPLETE_PROCESSING_FILES)
      )
      .subscribe(event => {
        this.pipelineItemsProcessed.push(...event.items.filter(item => item.eventsProcessed.every(event => event.error == null)));
        event.items.forEach(item => this.pipelineItemsBeingProcessed.delete(item.id));
        const progress = Math.floor((this.pipelineItemsProcessed?.length || 1) / (this.pipelineItemsTotal || 1) * 100) || 1;
        pipelineEvents$.next({ type: PipelineEventType.PIPELINE_PROGRESS, pipelineId: event.pipelineId, data: progress });
        if (progress === FileProcessingPipeline.PROGRESS_COMPLETED) {
          this.commandBeingProcessed = null;
          this.stop();
        }
      }));
  }
}

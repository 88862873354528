import JSZip from 'jszip';
import {
  FolderTagHierarchy,
  FolderTagHierarchyWithAssets
} from '~/store/folder/getters';
import { AssetWithFolderTag } from '~/models/Asset';
import { FolderTag } from '~/models/tags/FolderTag';
import { ZipBatch } from '~/models/file/ZipBatch';
import { JSZipFactory } from '~/plugins/zipBuilder';

export class ZipBuilder {
  public static ROOT_TAG_ID = 'root';

  constructor(private jsZip: JSZipFactory) {
  }

  public buildSingleBatch(folderName: string, folderTags: FolderTagHierarchy[], assets: AssetWithFolderTag[]): ZipBatch {
    const hierarchy: FolderTagHierarchy = {
      tag: {
        id: ZipBuilder.ROOT_TAG_ID,
        name: folderName,
        parentId: null,
      } as FolderTag,
      children: folderTags,
    };
    const folderTagToAssetMap = this.buildFolderTagToAssetMap(assets);
    const assetFolderHierarchy = this.mergeAssetsIntoFolderHierarchy(hierarchy, folderTagToAssetMap);
    return this.buildBatch(assetFolderHierarchy);
  }

  private buildFolderTagToAssetMap(assets: AssetWithFolderTag[]): Map<string, AssetWithFolderTag[]> {
    const result: Map<string, AssetWithFolderTag[]> = new Map();
    assets.forEach(asset => result.set(asset.folderTagId || ZipBuilder.ROOT_TAG_ID, [...(result.get(asset.folderTagId || ZipBuilder.ROOT_TAG_ID) || []), asset]));
    return result;
  }

  private mergeAssetsIntoFolderHierarchy(folderTagHierarchy: FolderTagHierarchy, folderTagToAssetMap: Map<string, AssetWithFolderTag[]>): FolderTagHierarchyWithAssets {
    return {
      ...folderTagHierarchy,
      assets: folderTagToAssetMap.get(folderTagHierarchy.tag.id) || [],
      children: folderTagHierarchy.children.map(hierarchy => this.mergeAssetsIntoFolderHierarchy(hierarchy, folderTagToAssetMap)),
    };
  }

  private buildBatch(assetFolderHierarchy: FolderTagHierarchyWithAssets): ZipBatch {
    const jsZip = this.jsZip.createInstance();
    const { folders, assets } = this.traverseFolderHierarchy(assetFolderHierarchy, jsZip);
    return {
      assets,
      folders,
      zipRoot: jsZip,
    };
  }

  private traverseFolderHierarchy(assetFolderHierarchy: FolderTagHierarchyWithAssets, jsZip: JSZip): { folders: Map<string, JSZip>, assets: AssetWithFolderTag[] } {
    const folders = new Map<string, JSZip>();
    const assets: AssetWithFolderTag[] = assetFolderHierarchy.assets;
    const folder = jsZip.folder(assetFolderHierarchy.tag.name);
    folders.set(assetFolderHierarchy.tag.id, folder);
    const results = assetFolderHierarchy.children
      .map(child => this.traverseFolderHierarchy(child, folder));
    return results
      .reduce((acc, curr) => {
        curr.folders.forEach((value, key) => acc.folders.set(key, value));
        acc.assets = [...acc.assets, ...curr.assets];
        return acc;
      }, { folders, assets });
  }
}

export enum ContextMenuType {
  TERMS_PRIVACY_CONTACT = 'TERMS_PRIVACY_CONTACT',
  DESKTOP_DOWNLOAD_INSTRUCTIONS = 'DESKTOP_DOWNLOAD_INSTRUCTIONS',
  UPLOAD_CONTROL = 'UPLOAD_CONTROL',
  MATCH_AND_UPLOAD_CONTROL = 'MATCH_AND_UPLOAD_CONTROL',
  SHARED_LINK_RECEIVER = 'SHARED_LINK_RECEIVER',
  ITEM = 'ITEM',
  FOLDER = 'FOLDER',
  SELECTION = 'SELECTION',
  DOWNLOAD_ALL = 'DOWNLOAD_ALL',
  DOWNLOAD_READER = 'DOWNLOAD_READER',
  CONTACTS = 'CONTACTS',
  SHARED_LINK = 'SHARED_LINK',
  TOUR = 'TOUR',
  VIEW = 'VIEW',
  LOGIN = 'LOGIN',
  ACCOUNT_OVERVIEW = 'ACCOUNT_OVERVIEW',
  ACCOUNT_UPGRADE = 'ACCOUNT_UPGRADE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  VALIDATION = 'VALIDATION',
  REQUEST_ORIGINALS = 'REQUEST_ORIGINALS',
  SEND_FEEDBACK = 'SEND_FEEDBACK',
}

export interface ContextMenu<T> {
  isOpen: boolean;
  type: ContextMenuType
  event?: any;
  data?: T;
}

export interface ContextMenuState {
  menus: ContextMenu<any>[];
  backgroundCount: number;
  currentZindex: number;
}

export default (): ContextMenuState => ({
  menus: [],
  backgroundCount: 11,
  currentZindex: 100000,
});

import Cookies from 'js-cookie';
import { WebConfig } from '~/Config';

// TODO: Move all this to shared code utilities
const STAGE_URL = WebConfig.STAGE_URL;

export const getCookieValueFor = (name: string) => {
  const value = Cookies.get(name);
  return value != null ? value : null;
};

export const setCookieValueFor = (name: string, value: string, expires?: Date) => {
  const cookieOptions: any = { path: '/', expires: 365 };
  if (WebConfig.isProductionEnvironment()) {
    cookieOptions.domain = `.${STAGE_URL}`;
  }
  if (expires) {
    cookieOptions.expires = expires;
  }
  Cookies.set(name, value, cookieOptions);
};

export const removeCookieValueFor = (name: string) => {
  const cookieOptions: any = { path: '/' };
  if (WebConfig.isProductionEnvironment()) {
    cookieOptions.domain = `.${STAGE_URL}`;
  }
  Cookies.remove(name);
};

export const parseCookie = (cookie: string): { [key: string]: string } =>
  cookie != null && cookie !== ''
    ? cookie
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc: { [key: string]: string }, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {})
    : {};

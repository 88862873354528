import { DetailViewContentType } from '~/store/detailView/actions';
import { INITIAL_STATE, DetailViewState, NextDetailViewState } from '~/store/detailView/state';
import { Asset } from '~/models/Asset';

export default {
  initAndOpenDetailView: (state: DetailViewState, data: DetailViewState) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, data);
  },
  setNextDetailViewState: (state: DetailViewState, data: NextDetailViewState) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, data);
  },
  closeAndResetDetailView: (state: DetailViewState) => {
    state = Object.assign(state, INITIAL_STATE);
    state.isOpen = false;
  },
  setContent: (state: DetailViewState, { content, type }: {content: string, type: DetailViewContentType}) => {
    if (type === DetailViewContentType.PREVIOUS) {
      state.previousContent = content;
    } else if (type === DetailViewContentType.CURRENT) {
      state.content = content;
    } else if (type === DetailViewContentType.NEXT) {
      state.nextContent = content;
    }
  },
  toggleAssetDetails: (state: DetailViewState) => {
    state.showAssetDetails = !state.showAssetDetails;
  },
  openAnimationFinished: (state: DetailViewState) => {
    state.isOpenAnimationFinished = true;
  },
  setCurrentAsset: (state: DetailViewState, asset: Asset) => {
    state.currentAsset = asset;
  },
};

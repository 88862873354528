import _cloneDeep from 'lodash.clonedeep';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import {
  RemoveItemsEvent,
  SortItemsEvent,
  SortItemsEventExternal,
  SortItemsEventInternal
} from '~/models/item/SortItemsEvent';
import { filterByConditions } from '~/models/utility/filterByConditions';

export interface SortResult {
  originalItems: ItemWithPosition[];
  sortedItems: ItemWithPosition[];
  inserted: ItemWithPosition[];
  updated: Partial<ItemWithPosition>[];
  removed: string[];
}

export function sortItemsByRemovalWithChangeSet(items: ItemWithPosition[], removalEvent: RemoveItemsEvent): SortResult {
  const itemList = _cloneDeep(items);
  if (removalEvent.items.length) {
    const mapOfRemovedItems = new Map();
    for (const removedItem of removalEvent.items) {
      mapOfRemovedItems.set(removedItem.id, true);
    }
    let firstIndexOfRemovedItem = null;
    const sortedItems = itemList
      .filter((i, idx) => {
        const isRemoved = mapOfRemovedItems.has(i.id);
        if (firstIndexOfRemovedItem == null && isRemoved) {
          firstIndexOfRemovedItem = idx;
        }
        return !isRemoved;
      })
      .map((i, idx) => ({
        id: i.id,
        position: { order: idx },
      })) as ItemWithPosition[];
    const updatedItems = [...sortedItems.slice(firstIndexOfRemovedItem, sortedItems.length)];
    return {
      originalItems: itemList,
      sortedItems,
      inserted: [],
      updated: updatedItems,
      removed: Array.from(mapOfRemovedItems.keys()),
    };
  }
  return {
    originalItems: itemList,
    sortedItems: itemList,
    inserted: [],
    updated: [],
    removed: [],
  };
}

export function sortItemsByFillWithChangeSet(items: ItemWithPosition[], sortEvent: SortItemsEvent): SortResult {
  const itemList = _cloneDeep(items);
  const impactRange = sortEvent.impactRange;
  const sliceEndRange = impactRange.end >= itemList.length ? itemList.length : impactRange.end + 1;
  const sortedItems = sortEvent instanceof SortItemsEventExternal
    || itemList.length === impactRange.start
    || impactRange.start !== impactRange.end
    ? sortImpactedItems(itemList, sortEvent)
    : [];
  const [updated, inserted] = sortEvent instanceof SortItemsEventExternal
    ? filterByConditions(sortedItems, (item) => !sortEvent.items.some((i) => item.id === i.id) || sortEvent.isItemInternalToView(item))
    : [sortedItems, []];
  return {
    originalItems: itemList,
    sortedItems: sortedItems.length
      ? [
          ...itemList.slice(0, impactRange.start),
          ...sortedItems,
          ...itemList.slice(sliceEndRange, itemList.length),
        ]
      : itemList,
    updated,
    inserted,
    removed: [],
  };
}

export function sortItemsByFill(items: ItemWithPosition[], sortEvent: SortItemsEvent): ItemWithPosition[] {
  const impactRange = sortEvent.impactRange;
  if (sortEvent instanceof SortItemsEventInternal && impactRange.start === impactRange.end) {
    return items;
  }
  const sliceEndRange = impactRange.end === items.length ? items.length : impactRange.end + 1;
  const impactedItemsSorted = sortImpactedItems(items, sortEvent);
  return [
    ...items.slice(0, impactRange.start),
    ...impactedItemsSorted,
    ...items.slice(sliceEndRange, items.length)];
}

function sortImpactedItems(items: ItemWithPosition[], sortEvent: SortItemsEvent) {
  const originalSortItems = _cloneDeep(sortEvent.items);
  const sortItems = _cloneDeep(sortEvent.items);
  const sortTargetPosition = sortEvent.position;
  const sortedItems = [];
  const impactRange = sortEvent.impactRange;
  const sliceEndRange = impactRange.end >= items.length ? items.length : impactRange.end + 1;
  let nextSortItem = sortItems.shift();
  let itemsShifted = 0;
  let positionFilled = false;
  for (let i = impactRange.start; i < sliceEndRange; i++) {
    const item = items[i];
    if (i === sortTargetPosition) {
      if (itemsShifted > 0 && item.id !== nextSortItem?.id) {
        sortedItems.push(itemWithOrder(item, impactRange.start + sortedItems.length));
      }
      for (const sortItem of originalSortItems) {
        sortedItems.push(itemWithOrder(sortItem, impactRange.start + sortedItems.length));
      }
      positionFilled = true;
      if (!itemsShifted && item.id !== nextSortItem?.id) {
        sortedItems.push(itemWithOrder(item, impactRange.start + sortedItems.length));
      }
      continue;
    }
    if (item) {
      if (nextSortItem && item.id === nextSortItem?.id) {
        nextSortItem = sortItems.shift();
        itemsShifted++;
        continue;
      }
      sortedItems.push(itemWithOrder(item, impactRange.start + sortedItems.length));
    }
  }
  if (!positionFilled) {
    for (const sortItem of originalSortItems) {
      sortedItems.push(itemWithOrder(sortItem, impactRange.start + sortedItems.length));
    }
  }
  return sortedItems;
}

function itemWithOrder(item: ItemWithPosition, order: number) {
  return {
    ...item,
    position: {
      ...item.position,
      order,
    },
  };
}

import { CloudObject } from '~/models/cloud/CloudObject';
import Selection from '~/models/selection/Selection';
import Folder from '~/models/Folder';
import { Filter } from '~/models/filters/Filter';

export enum SortType {
  ALPHABETICALLY_ASC = 'ALPHABETICALLY_ASC',
  ALPHABETICALLY_DESC = 'ALPHABETICALLY_DESC',
  MODIFIED_ASC = 'MODIFIED_ASC',
  MODIFIED_DESC = 'MODIFIED_DESC',
}

export enum FilterType {
  CUSTOM = 'Custom',
  FOLDER = 'Folder',
  SELECTION = 'Selection',
  RECENT = 'Recent',
  CONTACT_SHEET = 'Contact Sheet',
}

export type ObjectTypeFilter<T extends CloudObject<Folder | Selection>> = Filter<T>;

export interface ObjectFilter {
  filter: ObjectTypeFilter<CloudObject<Folder | Selection>>;
  type: FilterType;
  orFiltered: boolean;
  priority: number;
}

export interface CloudObjectFilter {
  sortType?: SortType;
  filterType?: FilterType[];
}

export const mergeFilters = (filters: FilterType[], filterType: FilterType): FilterType[] => {
  return filters.find(f => f === filterType) != null ? filters.filter(f => f !== filterType) : [...filters, filterType];
};

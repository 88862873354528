import { buffer, filter, Observable, Subject, timer } from 'rxjs';

export class SocketEventAggregator {
  public eventObservable: Observable<any>;

  initialize(): void {
    this.eventObservable = new Subject().pipe(
      buffer(timer(0, 750)),
      filter((bufferedValues) => bufferedValues.length > 0)
    );
  }
}

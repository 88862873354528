import { MagnifyOptions } from '~/models/MagnifyOptions';
import { Shortcut } from '~/models/Shortcut';
import { ViewSortingOption } from '~/store/cloud/state';

export const DEFAULT_MAGNIFY_HEIGHT = 400;

export enum MagnifyViewContentType {
  SHORTCUT = 'SHORTCUT',
  FOLDER = 'FOLDER',
  CURRENT_SELECTION = 'CURRENT_SELECTION'
}

export interface MagnifyViewContent {
  type: MagnifyViewContentType;
  shortcut?: Shortcut
}

export interface MagnifyState {
  isOpen: boolean;
  height: number;
  options: MagnifyOptions;
  viewContent: MagnifyViewContent;
  sortedBy: ViewSortingOption;
  bottomOffset: number;
  HEADER_HEIGHT: number;
  FOOTER_HEIGHT: number;
}

export default (): MagnifyState => ({
  isOpen: false,
  height: DEFAULT_MAGNIFY_HEIGHT,
  options: {
    margin: 1,
    marginFactor: localStorage.getItem('magnify.options.marginFactor') ? parseInt(localStorage.getItem('magnify.options.marginFactor'), 10) : 1,
    marginFactorMin: 0,
    marginFactorMax: 10,
    marginInterval: 1,
  },
  viewContent: {
    type: MagnifyViewContentType.FOLDER,
  },
  sortedBy: ViewSortingOption.CUSTOM_ORDER,
  bottomOffset: 128,
  HEADER_HEIGHT: 38,
  FOOTER_HEIGHT: 22,
});

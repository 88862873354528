// eslint-disable-next-line import/named
import _cloneDeep from 'lodash.clonedeep';
import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { Subject } from 'rxjs';
import { Asset, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import Item from '~/models/item/Item';
import { ItemType } from '~/models/item/ItemType';
import { WebConfig } from '~/Config';

export class ImageLoader {
  cachedUrlData: Map<string, string> = new Map();
  cachedAssets: Map<string, Asset> = new Map();
  assetPreloadInProgress: Map<string, boolean> = new Map();
  cancelTokenMap: Map<string, CancelTokenSource> = new Map();
  activePreloadCount = 0;
  preloadSubject$: Subject<{ itemId: string; assetId: string; assetSrc: string }> = new Subject();
  preloadQueues: Map<string, any[]> = new Map();
  preloadingInProgress = false;
  MAX_CONCURRENT_PRELOAD = 30;

  constructor(private $api: AxiosInstance) {
  }

  public getOptimalAssetByWidth(item: Item, width: number): Asset {
    if (item.type !== ItemType.IMAGE) {
      return null;
    }
    // Check if we are only working with offline images
    if (item.assets.length <= 2) {
      const displayAsset = this.getOfflineDisplayAsset(item.assets);
      const mimeType = displayAsset.mimeType;
      if (mimeType === 'image/jpeg' || mimeType === 'image/gif' || mimeType === 'image/webp' || mimeType === 'image/png') {
        if (!this.cachedAssets.has(item.id) && displayAsset.version < ORIGINAL_ASSET_VERSION) {
          this.cachedAssets.set(item.id, displayAsset);
        }
        return displayAsset;
      } else {
        return null;
      }
    }
    const relevantAssetsSortedByWidth = _cloneDeep(item.assets)
      .filter(a => !a.isMatched && !a.matchedWith && a.mimeType === 'image/jpeg' && a.size <= 2_000_000)
      .sort((a: Asset, b: Asset) => {
        if (a.width < b.width) {
          return -1;
        } else if (a.width === b.width) {
          return a.version < b.version ? -1 : 1;
        } else {
          return 1;
        }
      }).filter(a => ![ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION].includes(a.version));
    const asset = relevantAssetsSortedByWidth.find(a => a.width >= width) || relevantAssetsSortedByWidth.pop() || null;
    if (this.cachedAssets.has(item.id) && (!asset || (this.cachedAssets.get(item.id).version > asset.version && this.cachedAssets.get(item.id).version !== ORIGINAL_ASSET_VERSION))) {
      const cachedAsset = this.cachedAssets.get(item.id);
      if (relevantAssetsSortedByWidth.some(a => a.id === cachedAsset.id)) {
        return cachedAsset;
      } else {
        // Remove stale assets from cache. Not optimal! - better would be to correctly clear the cache as soon as assets become stale.
        // However this seems not to work consistently when matching items in the matching process, as stale assets remain in the view without explicitly making the check here
        this.cachedAssets.delete(item.id);
      }
    }
    if (asset != null) {
      this.cachedAssets.set(item.id, asset);
    }
    return asset;
  }

  clearCachedAsset(asset: Asset) {
    this.cachedAssets.delete(asset.id);
    this.cachedAssets.delete(asset.itemId);
  }

  public async loadVersionByWidthInBase64(item: Item, width: number): Promise<string> {
    if (item.type !== ItemType.IMAGE) {
      return '';
    }
    if (item.isPlaceholder) {
      return '';
    }
    if (item.type === 1) {
      const asset = this.getOptimalAssetByWidth(item, width);
      return asset ? await this.loadAssetUrl(item, asset) : '';
    } else {
      return '';
    }
  }

  public cancelPendingRequests(objectId: string) {
    this.cancelQueue(objectId);
    const token = this.cancelTokenMap.get(objectId);
    if (token) {
      token.cancel();
      this.cancelTokenMap.delete(objectId);
    }
  }

  public cancelQueue(objectId: string) {
    this.preloadQueues.delete(objectId);
  }

  public async preloadAssetUrl(item: Item, asset: Asset, objectId?: string, order?: number): Promise<void> {
    if (this.isAssetCached(asset)) {
      const assetSrc = await this.getCachedAssetUrl(asset);
      this.preloadSubject$.next({ itemId: item.id, assetId: asset.id, assetSrc });
    } else {
      if (this.preloadQueues.has(objectId)) {
        const queue = this.preloadQueues.get(objectId);
        if (!queue.some(queueItem => queueItem.item.id === item.id && queueItem.asset.id === asset.id)) {
          queue.push({ item, asset, objectId, order });
        }
      } else {
        this.preloadQueues.set(objectId, [{ item, asset, objectId, order }]);
      }
      this.preloadItems(objectId);
    }
  }

  public preloadItems(objectId) {
    if (!this.preloadingInProgress) {
      this.preloadingInProgress = true;
      this.preloadQueuedItemsConcurrently(objectId);
    }
  }

  private async preloadQueuedItemsConcurrently(objectId) {
    if (this.preloadQueues.get(objectId)?.length > 0 && this.activePreloadCount < this.MAX_CONCURRENT_PRELOAD) {
      const toBeProcessed = this.preloadQueues.get(objectId)?.splice(0, this.MAX_CONCURRENT_PRELOAD - this.activePreloadCount) || [];
      this.activePreloadCount += toBeProcessed.length;
      const loadAssetUrlPromises = toBeProcessed.map(data => {
        return this.loadAssetUrl(data.item, data.asset, data.objectId)
          .then(url => {
            this.preloadSubject$.next({ itemId: data.item.id, assetId: data.asset.id, assetSrc: url });
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => this.activePreloadCount--);
      });
      await Promise.race(loadAssetUrlPromises).then(() => this.preloadQueuedItemsConcurrently(objectId));
    } else {
      this.preloadingInProgress = false;
    }
  }

  public isAssetCached(asset: Asset): boolean {
    if (asset.base64 != null) {
      return true;
    }
    if (asset.id && this.cachedUrlData.has(asset.id)) {
      return true;
    }
    return !asset.id && asset.file != null;
  }

  public async getCachedAssetUrl(asset: Asset): Promise<string> {
    if (asset.base64 != null) {
      return asset.base64;
    }
    if (asset.id && this.cachedUrlData.has(asset.id)) {
      return this.cachedUrlData.get(asset.id);
    }
    if (!asset.id && asset.file != null) {
      return await this.loadFileAsBase64(asset.file);
    }
    return null;
  }

  public async loadAssetUrl(item: Item, asset: Asset, objectId?: string): Promise<string> {
    if (item && item.type !== ItemType.IMAGE) {
      return '';
    }
    const cachedAssetUrl = await this.getCachedAssetUrl(asset);
    if (cachedAssetUrl) {
      return cachedAssetUrl;
    }
    if (objectId && !this.cancelTokenMap.has(objectId)) {
      this.cancelTokenMap.set(objectId, axios.CancelToken.source());
    }
    this.assetPreloadInProgress[asset.id] = true;
    try {
      await this.preloadAsset(asset, objectId);
      const assetUrl = this.getImageUrl(asset);
      if (asset.id != null) {
        this.cachedUrlData.set(asset.id, assetUrl);
      }
      return assetUrl;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      // TODO: do we want to retry the request here - we could collect all failed requests and retry after a completed batch
      console.error(`error loading asset ${asset.version} for item ${item?.id}`, err);
      return '';
    } finally {
      this.assetPreloadInProgress.set(asset.id, false);
    }
  }

  public async loadUrlAsBase64(url: string): Promise<string> {
    const { data: blob } = await this.$api.get(url, { withCredentials: true, baseURL: '', responseType: 'blob' });
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => {
        return resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  private loadFileAsBase64(file: File): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => {
        return resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  // TODO: use profile image hash for caching
  public async loadProfileImageInBase64(userId: string, useCache: boolean = false) {
    if (useCache && this.cachedUrlData.has(userId)) {
      return this.cachedUrlData.get(userId);
    }
    const src = this.getProfileImageUrl();
    try {
      const { headers, data } = await this.$api.get(src, {
        responseType: 'arraybuffer',
      });
      const mimeType = headers['content-type'].toLowerCase();
      const base64ImageData = Buffer.from(data, 'binary').toString('base64');
      const result = 'data:' + mimeType + ';base64,' + base64ImageData;
      this.cachedUrlData.set(userId, result);
      return result;
    } catch (err) {
      // TODO: do we want to retry the request here - we could collect all failed requests and retry after a completed batch
      console.error(`error loading profile image for user ${userId}`);
      return '';
    }
  }

  private async preloadAsset(asset: Asset, objectId?: string): Promise<void> {
    await this.$api.get(`/assets/${asset.id}/download?hash=${asset.hash}`, { withCredentials: true, cancelToken: this.cancelTokenMap.get(objectId)?.token });
  }

  public loadImageElement(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const imageElement = document.createElement('img');
      imageElement.src = src;
      imageElement.crossOrigin = 'use-credentials';
      imageElement.onerror = (error) => {
        reject(error);
      };
      imageElement.onload = () => {
        resolve(imageElement);
      };
    });
  }

  private getImageUrl(asset: Asset) {
    return `${WebConfig.API_URL}/assets/${asset.id}/download?hash=${asset.hash}`;
  }

  public getProfileImageSelfUrl() {
    return `${WebConfig.API_URL}${this.getProfileImageUrl()}`;
  }

  public getProfileImageUrl() {
    return '/users/me/profile/picture';
  }

  private getOfflineDisplayAsset(assets: Asset[]) {
    // Get lowest res version
    return [...assets].sort((a, b) => a.version < b.version ? -1 : 1)[0];
  }

  private wait(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), milliseconds));
  }
}

import { Background } from '~/models/views/Background';
import { ObjectId } from '~/models/ObjectId';
import { LayoutType } from '~/models/LayoutType';
import { RecentObject } from '~/models/RecentObject';
import { Orientation, RootState } from '~/store/state';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import { PipelineInfoLabel } from '~/models/pipeline/FileProcessingPipeline';

export enum PIPELINE_STATUS {
  ACTIVE = 'in progress ...',
  COMPLETE = 'complete',
  INACTIVE = '',
}

export interface PipelineProgress {
  label: PipelineInfoLabel;
  step: number;
  percentage: number;
  status?: PIPELINE_STATUS;
}

function includesUploadLabel(s: { label: PipelineInfoLabel; step: number; percentage: number }) {
  return [PipelineInfoLabel.UPLOAD_RAWS, PipelineInfoLabel.UPLOAD_ORIGINALS, PipelineInfoLabel.UPLOAD_THUMBNAILS].includes(s.label);
}

const rootGetters = {
  hasPipelineProgress: (state: RootState) => (id: string): boolean => {
    return state.viewProgress[id] != null;
  },
  pipelineProgress: (state: RootState) => (id: string): PipelineProgress[] => {
    return state.viewProgress[id]?.progressSteps?.map((step, stepNum) => {
      const enrichedStep: PipelineProgress = { ...step };
      if (step.percentage === 100) {
        enrichedStep.status = PIPELINE_STATUS.COMPLETE;
      } else if (enrichedStep.percentage > 0) {
        enrichedStep.status = PIPELINE_STATUS.ACTIVE;
      } else if (state.viewProgress[id]?.progressSteps[stepNum + 1]?.percentage === 100) {
        enrichedStep.status = PIPELINE_STATUS.COMPLETE;
        enrichedStep.percentage = 100;
      } else {
        enrichedStep.status = PIPELINE_STATUS.INACTIVE;
      }
      return enrichedStep;
    });
  },
  pipelineUploadActive: (state: RootState) => (id?: string): boolean => {
    id = id || (state.file.uploadProcess?.objectId?.toUuid() ?? '');
    return state.viewProgress[id]?.progressSteps != null && state.viewProgress[id]?.progressSteps?.some(s => includesUploadLabel(s) && s.percentage < 100);
  },
  pipelineFinished: (state: RootState, getters: any) => (id?: string): boolean => {
    id = id || (state.file.uploadProcess?.objectId?.toUuid() ?? '');
    return state.viewProgress[id]?.progressSteps == null || (!getters.pipelineUploadActive(id) && state.viewProgress[id]?.progressSteps?.some(s => includesUploadLabel(s) && s.percentage === 100));
  },
  activeCloudMenuTab: (state: RootState) : CloudMenuTab => {
    return state.activeCloudMenuTab;
  },
  isOnline: (state: RootState): boolean => {
    return state.isConnectedToWebsocket;
  },
  orientation: (state: RootState): Orientation => {
    return state.orientation;
  },
  // must be function so that it is not cached
  // either the url is already on cloud id and has shared link id or the url is on landing page for shared link
  // which includes shared
  isSharedLink: (state: RootState) => {
    return state.isSharedRoute;
  },
  canUseShareApi: (_state: RootState) => () => {
    return (window.navigator as any).canShare != null && window.navigator.share != null;
  },
  isHD: (_state: RootState): boolean => {
    return window.devicePixelRatio >= 1;
  },
  isRetina: (_state: RootState): boolean => {
    return window.devicePixelRatio >= 2;
  },
  isMobile: (state: RootState): boolean => {
    return state.isMobile;
  },
  isMobileWidth: (state: RootState): boolean => {
    return state.isMobileWidth;
  },
  isLandscape: (state: RootState): boolean => {
    return state.orientation === Orientation.LANDSCAPE;
  },
  isFloatingMenuOpen: (state: RootState) => (id: string): boolean => {
    return state.floatingMenus.some(m => m.id === id && !m.minimized);
  },
  defaultBackground: (state: RootState): Background => {
    return state.backgrounds.find(background => background.isDefault);
  },
  defaultMoodboardBackground: (state: RootState): Background => {
    return state.backgrounds.find(background => background.isMoodboardDefault);
  },
  isActiveObject: (state: RootState) => (objectId: ObjectId): boolean => {
    for (const window of Object.values(state.cloud.registeredWindows)) {
      if (window.viewIds.some(o => o.toString() === objectId.toString())) {
        return true;
      }
    }
    return false;
  },
  tooltip: (_state: RootState, getters: any): any => (data: any) => {
    return {
      ...data,
      ...getters.tooltipBase,
    };
  },
  tooltipBase: (state: RootState): any => {
    return state.showTooltips
      ? {
          trigger: 'hover',
        }
      : {
          trigger: 'manual',
        };
  },
  currentLayout: (state: RootState): LayoutType => {
    return state.currentLayoutType;
  },
  isReviewMode: (state: RootState): boolean => {
    return state.currentLayoutType === LayoutType.REVIEW_MODE;
  },
  wasLatestEventReceivedForSelectionId: (state: RootState) => (selectionId: string) => {
    return state.eventHistory.length && state.eventHistory[0].data.id === selectionId;
  },
  screenBackground(state: RootState): Background {
    return state.backgrounds.find(background => background.id === state.screenBackground);
  },
  recentObjectActivity(state: RootState): RecentObject[] {
    const recentObjectIdMap = new Map<string, ObjectId>();
    for (const objectId of state.recentObjectIds) {
      recentObjectIdMap.set(objectId.toUuid(), objectId);
    }
    const recentObjectActivityMap = new Map<string, RecentObject>();
    for (const folder of Object.values(state.folder.folders)) {
      if (recentObjectActivityMap.size === state.recentObjectIds.length) {
        break;
      }
      if (recentObjectIdMap.has(folder.id)) {
        const objectId = recentObjectIdMap.get(folder.id);
        recentObjectActivityMap.set(objectId.toString(), {
          objectId,
          name: folder.name,
          itemCount: folder.itemCount ? folder.itemCount : folder.items?.length || 0,
          lastModified: folder.modified,
        });
      }
    }
    for (const selection of Object.values(state.selection.selections)) {
      if (recentObjectActivityMap.size === state.recentObjectIds.length) {
        break;
      }
      if (recentObjectIdMap.has(selection.id)) {
        const objectId = recentObjectIdMap.get(selection.id);
        recentObjectActivityMap.set(objectId.toString(), {
          objectId,
          name: selection.name,
          itemCount: selection.itemCount ? selection.itemCount : selection.items?.length || 0,
          lastModified: selection.modified,
        });
      }
    }
    return state.recentObjectIds.map(objectId => recentObjectActivityMap.get(objectId.toString())).filter(val => val !== undefined);
  },
};

export default rootGetters;

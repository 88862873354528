import { v4 as uuid } from 'uuid';
import { EventEmitter } from '~/models/uploader/EventEmitter';
import { PipelineItem } from '~/models/PipelineItem';
import { WebWorkerEvent } from '~/models/thumbnailer/WebWorkerEvent';
import { ThumbnailerEvent } from '~/models/thumbnailer/ThumbnailerEvent';
import { QualityType } from '~/models/thumbnailer/QualityType';

interface WebWorker { id: string; instance: Worker; isBusy: boolean; }

const SCALE_DOWN_SIZE = 25_000_000;

export interface QualityConfiguration {
  resizeQuality: QualityType;
  imageQuality: number;
  width: number;
  maxFileWidth: number | null;
  // In KB
  maxFileSize: number;
}

export class ProfilePictureThumbnailer extends EventEmitter {
  public static DEFAULT_QUALITY_CONFIG = {
    resizeQuality: QualityType.MEDIUM,
    imageQuality: 0.8,
    width: 520,
    maxFileWidth: null,
    maxFileSize: 100,
  };

  private file: File = null;
  private worker: WebWorker;
  private qualityConfiguration: QualityConfiguration;

  constructor(file: File, private readonly workerSupport: boolean) {
    super();
    this.qualityConfiguration = ProfilePictureThumbnailer.DEFAULT_QUALITY_CONFIG;
    this.file = file;
    this.workerSupport = workerSupport;
    if (this.file?.size > SCALE_DOWN_SIZE) {
      // We need to scale the image down if it is really big
      this.qualityConfiguration = { ...ProfilePictureThumbnailer.DEFAULT_QUALITY_CONFIG, width: 320, imageQuality: 0.7 };
    }
    this.worker = { instance: new Worker('./thumbnailWorker.js', { type: 'module' }), isBusy: true, id: uuid() };
    this.startExtracting();
  }

  private sendItemsToWorker() {
    this.worker.instance.onmessage = event => this.handleMessage(event);
    this.worker.instance.postMessage({ items: [{ file: this.file, id: uuid() }], id: this.worker.id, config: this.qualityConfiguration });
  }

  private startExtracting() {
    if (this.workerSupport) {
      this.sendItemsToWorker();
    }
  }

  private terminateWorker() {
    this.worker.instance.terminate();
  }

  private handleMessage = event => {
    if (event.data.type === WebWorkerEvent.ITEM_PROCESSED) {
      const processedItemThumbnail: PipelineItem = event.data.item?.thumbnail ?? event.data.item;
      this.emit(ThumbnailerEvent.ITEMS_RESIZED, processedItemThumbnail);
      this.terminateWorker();
    }
  };
}

import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';

export interface UploadMenuState {
  isExpanded: boolean;
  pipelineOptions: PipelineCommandType[];
  replaceExisingFiles: boolean;
  ignoreExisingFiles: boolean;
}

export const initalUploadMenuState = {
  isExpanded: false,
  pipelineOptions: [PipelineCommandType.UPLOAD_THUMBNAILS],
  replaceExisingFiles: false,
  ignoreExisingFiles: false,
};

export default (): UploadMenuState => (initalUploadMenuState);

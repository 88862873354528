export class Point {
  constructor(public x, public y) {
  }

  public scale(origin: Point, scale: number): Point {
    const relX = this.x - origin.x;
    const relY = this.y - origin.y;
    const scaledX = relX * scale;
    const scaledY = relY * scale;
    return new Point(scaledX + origin.x, scaledY + origin.y);
  }

  public rotate(origin, angle): Point {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const x = (cos * (this.x - origin.x)) + (sin * (this.y - origin.y)) + origin.x;
    const y = (cos * (this.y - origin.y)) - (sin * (this.x - origin.x)) + origin.y;
    return new Point(x, y);
  }

  public translate(x: number, y: number) {
    return new Point(this.x + x, this.y + y);
  }

  public translateX(value: number): Point {
    return new Point(this.x + value, this.y);
  }

  public translateY(value: number): Point {
    return new Point(this.x, this.y + value);
  }

  public distanceTo(point: Point): number {
    return Math.sqrt((point.x - this.x) ** 2 + (point.y - this.y) ** 2);
  }

  public angleTo(point: Point): number {
    const originX = point.x - this.x;
    const originY = point.y - this.y;
    const bearingRadians = Math.atan2(originY, originX);
    let bearingDegrees = bearingRadians * 180 / Math.PI;
    while (bearingDegrees < 0) {
      bearingDegrees += 360;
    }
    return bearingDegrees;
  }
}

export class Rect {
  topLeft: Point;
  topRight: Point;
  bottomLeft: Point;
  bottomRight: Point;
  width: number;
  height: number;

  constructor({ topLeft, topRight, bottomLeft, bottomRight, width, height }: { topLeft: Point, topRight: Point, bottomLeft: Point, bottomRight: Point, width: number, height: number }) {
    this.topLeft = topLeft;
    this.topRight = topRight;
    this.bottomLeft = bottomLeft;
    this.bottomRight = bottomRight;
    this.width = width;
    this.height = height;
  }

  public rotate(origin: Point, angle): Rect {
    return new Rect({
      topLeft: this.topLeft.rotate(origin, angle),
      topRight: this.topRight.rotate(origin, angle),
      bottomLeft: this.bottomLeft.rotate(origin, angle),
      bottomRight: this.bottomRight.rotate(origin, angle),
      width: this.width,
      height: this.height,
    });
  }

  public toBoundingRect(): BoundingRect {
    const minX = Math.min(this.topLeft.x, this.topRight.x, this.bottomLeft.x, this.bottomRight.x);
    const maxX = Math.max(this.topLeft.x, this.topRight.x, this.bottomLeft.x, this.bottomRight.x);
    const minY = Math.min(this.topLeft.y, this.topRight.y, this.bottomLeft.y, this.bottomRight.y);
    const maxY = Math.max(this.topLeft.y, this.topRight.y, this.bottomLeft.y, this.bottomRight.y);
    return new BoundingRect({
      top: minY,
      bottom: maxY,
      left: minX,
      right: maxX,
      width: maxX - minX,
      height: maxY - minY,
    });
  }
}

export class BoundingRect {
  public top: number;
  public left: number;
  public bottom: number;
  public right: number;
  public width: number;
  public height: number;

  constructor({ top, left, bottom, right, width, height }: {
    top: number,
    left: number,
    bottom: number,
    right: number,
    width: number,
    height: number,
  }) {
    this.top = top;
    this.left = left;
    this.bottom = bottom;
    this.right = right;
    this.width = width;
    this.height = height;
  }
}

export class ComplexMath {
  constructor() {
  }
}

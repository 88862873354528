import { Component, Vue } from 'nuxt-property-decorator';
import { HOME_PAGE_RELATED_ROUTES, LANDING_PAGE_ROUTE } from '~/store/homepage/state';

@Component
export default class RouteAwareness extends Vue {
  public get isLandingPage(): boolean {
    return this.route.name === LANDING_PAGE_ROUTE && Object.keys(this.route.query).length === 0;
  }

  public get isHomepageView(): boolean {
    return HOME_PAGE_RELATED_ROUTES.includes(this.route.name);
  }

  public get route() {
    return this.$route || this.$router.currentRoute;
  }
}

import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Snippet from '~/models/Snippet';
import SnippetBuilder from '~/models/views/SnippetBuilder';
import { ViewItem } from '~/models/views/ViewItem';
import { getAspectRatio } from '~/models/Asset';
import { HorizontalView } from '~/models/views/horizontal/HorizontalView';
import { SelectedItemInfo } from '~/models/SelectedItemInfo';

export class HorizontalViewBuilder {
  private static YOUTUBE_WIDTH = 560;
  private static YOUTUBE_HEIGHT = 315;

  private distortionMarginDefaultHeight = 120;
  private distortionMarginFactor = 10;

  private globalSelectedItems: Map<string, SelectedItemInfo> = new Map<
    string,
    SelectedItemInfo
  >();

  private items: ItemWithPosition[] = [];
  private itemHeight: number = 120;
  private itemOverlap: number = 0;
  private itemSpacing: number = 0;
  private distortionMap: any = null;
  private spacingBetweenSelectedItems: number = 0;
  private spacing: number = 0;
  private scrollSpacing: number = 0;
  private viewWidth: number = 0;
  private displayCenteredItems: boolean = false;

  constructor() {}

  public setItems(items: ItemWithPosition[]): HorizontalViewBuilder {
    this.items = items;
    return this;
  }

  public setSelectedItems(
    globalSelectedItems: Map<string, SelectedItemInfo>
  ): HorizontalViewBuilder {
    this.globalSelectedItems = globalSelectedItems;
    return this;
  }

  public setViewWidth(value: number) {
    this.viewWidth = value;
    return this;
  }

  public setDisplayCenteredItems(value: boolean) {
    this.displayCenteredItems = value;
    return this;
  }

  public setScrollSpacing(value: number) {
    this.scrollSpacing = value;
    return this;
  }

  public setSpacingBetweenSelectedItems(spacing: number): HorizontalViewBuilder {
    this.spacingBetweenSelectedItems = spacing;
    return this;
  }

  public setDistortionMap(value: any): HorizontalViewBuilder {
    this.distortionMap = value;
    return this;
  }

  public setItemSpacing(value: number): HorizontalViewBuilder {
    this.itemSpacing = value;
    return this;
  }

  public setSpacing(spacing: number): HorizontalViewBuilder {
    this.spacing = spacing;
    return this;
  }

  public setItemOverlap(pixelAmount: number | string): HorizontalViewBuilder {
    this.itemOverlap = parseInt(<string>pixelAmount, 10);
    return this;
  }

  public setItemHeight(itemHeight: number | string): HorizontalViewBuilder {
    this.itemHeight = parseInt(<string>itemHeight, 10);
    return this;
  }

  public build(): HorizontalView {
    const barView: Snippet[] = [];
    let currentViewWidth = 0;
    const distortionMargin = this.distortionMap
      ? (this.itemHeight / this.distortionMarginDefaultHeight)
        * this.distortionMarginFactor
      : 0;
    currentViewWidth += distortionMargin + this.spacing;
    const itemHeight = this.itemHeight - 2 * distortionMargin;
    let itemBeforeHasSelectionMargin = false;
    let viewOffset = 0;
    if (this.items[0] && this.displayCenteredItems) {
      viewOffset = HorizontalViewBuilder.calculateViewOffset(this.items[0], this.viewWidth, this.itemHeight);
    }
    for (const item of this.items) {
      let selectedItemSpacing = 0;
      if (this.spacingBetweenSelectedItems) {
        if (this.globalSelectedItems.has(item.id)) {
          selectedItemSpacing = this.spacingBetweenSelectedItems;
          if (!itemBeforeHasSelectionMargin) {
            currentViewWidth += selectedItemSpacing;
          }
          itemBeforeHasSelectionMargin = true;
        } else {
          itemBeforeHasSelectionMargin = false;
        }
      }
      currentViewWidth += this.itemSpacing;
      const adjustedItem = HorizontalViewBuilder.adjustItemSize(item, itemHeight);
      const overlap
        = currentViewWidth > distortionMargin && this.itemOverlap
          ? this.itemOverlap
          : 0;
      adjustedItem.setViewPositionTop(distortionMargin);
      adjustedItem.setViewPositionLeft(currentViewWidth - overlap + viewOffset);
      if (this.distortionMap) {
        adjustedItem.transform = `translateY(${
          this.distortionMap[item.id].yAxis
        }px) rotate(${this.distortionMap[item.id].rotation}deg)`;
      }
      currentViewWidth
        += adjustedItem.viewPosition.width
        - this.itemOverlap
        + selectedItemSpacing;
      barView.push(new SnippetBuilder().fromItem(adjustedItem).build());
    }
    currentViewWidth += distortionMargin + this.spacing + this.itemSpacing + this.scrollSpacing + viewOffset;
    return new HorizontalView(
      barView,
      currentViewWidth,
      this.itemHeight,
      this.itemSpacing,
      this.itemOverlap
    );
  }

  public static calculateViewOffset(item: ItemWithPosition, viewWidth: number, itemHeight: number): number {
    const adjustedItem = HorizontalViewBuilder.adjustItemSize(item, itemHeight);
    return viewWidth / 2 - adjustedItem?.viewPosition?.width / 2;
  }

  public static adjustItemSize(
    item: ItemWithPosition,
    height: number
  ): ViewItem {
    const viewItem: ViewItem = new ViewItem(item);
    if (viewItem.itemData.type === 2) {
      viewItem.setViewPositionWidth((height * HorizontalViewBuilder.YOUTUBE_WIDTH) / HorizontalViewBuilder.YOUTUBE_HEIGHT);
    } else {
      viewItem.setViewPositionWidth(height * getAspectRatio(item.item));
    }
    viewItem.setViewPositionHeight(height);
    viewItem.setViewPositionTop(0);
    return viewItem;
  }
}

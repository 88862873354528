import { ActionContext, ActionTree } from 'vuex';
import { LayoutType } from '~/models/LayoutType';
import { ViewType } from '~/models/views/ViewType';
import { RootState } from '~/store/state';
import { LinkState, OpenSharedLinkMenuPayload } from '~/store/link/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ObjectId } from '~/models/ObjectId';
import { SuccessMessages } from '~/models/MessageTypes';
import { Notification, NotificationType } from '~/models/Notification';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { WebConfig } from '~/Config';
import { ViewTypeNum, viewTypeNumToViewType } from '~/models/views/ViewTypeNum';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import { ContextMenuType } from '~/store/context/state';
import { User } from '~/models/user/User';

export enum LayoutTypeNum {
  MINIMAL = 1,
  ESSENTIALS = 2,
  FILMSTRIP = 3,
  FULLSCREEN = 4,
}

type LinkContext = ActionContext<LinkState, RootState>;

const actions: ActionTree<LinkState, RootState> = {
  async initializeSharedLink({
    state,
    commit,
    dispatch,
    rootGetters,
  }: LinkContext, { accessId, objectId }: { objectId: string, accessId: string }) {
    this.$log.info('initialize shared link');
    const layoutType = rootGetters.isMobile ? LayoutType.ESSENTIALS : LayoutType.REVIEW_MODE;
    dispatch('setActiveLayoutType', layoutType, { root: true });
    if (rootGetters.isMobile) {
      dispatch('initializeMobileSizes', {}, { root: true });
    } else {
      dispatch('context/openMenu', { type: ContextMenuType.SHARED_LINK_RECEIVER }, { root: true });
    }
    if (state.links[objectId]?.accessId !== accessId) {
      await dispatch('initializeSharedLinkData', accessId);
    }
    setTimeout(() => commit('setLayoutInitialized', true, { root: true }), 250);
    this.$log.info('shared link data initialized.');
  },

  async initializeSharedLinkData({ dispatch }: LinkContext, accessId: string) {
    await dispatch('loadLinkAuth', accessId);
    await dispatch('loadLinkData', accessId);
  },
  async createSharedView({ commit, dispatch }: LinkContext, viewType: ViewType) {
    await dispatch('selection/create', null, { root: true });
    commit('cloud/viewChanged', { windowId: ViewIdentifier.MAIN_VIEW, view: viewType }, { root: true });
  },
  openSharedLinkMenu({ commit }: LinkContext, data: OpenSharedLinkMenuPayload) {
    commit('openSharedLinkMenu', data);
  },
  closeSharedLinkMenu({ commit }: LinkContext) {
    commit('closeSharedLinkMenu');
  },
  async loadLinkData({ commit }: LinkContext, id) {
    const { data } = await this.$api.get(`/links/${id}`);
    // TODO: persist link data to storage for remembering in recent folders and folders that were shared in the past
    commit('setLinkData', data);
  },
  setLinkData({ commit }: LinkContext, data) {
    commit('setLinkData', data);
  },
  async loadLinkAuth(_context: LinkContext, id?: string) {
    const sharedLinkId = id ?? this.$router.currentRoute.query['shared-link-id'];
    if (sharedLinkId != null) {
      await this.$api.post('/links/auth', { accessId: sharedLinkId, client_id: 'web' });
    }
  },
  async requestOriginals({ rootGetters, state }: LinkContext, message?: string) {
    const objectId = rootGetters['cloud/currentCloudObjectId']?.toString();
    await this.$api.post('/links/request-originals', {
      accessId: state.links[objectId].accessId,
      message,
      filter: rootGetters['cloud/reviewFilter'],
    });
  },
  // todo: note: currently not in use, because shared links are always opened in review mode
  applyLinkOptions({
    state,
    commit,
    dispatch,
    rootGetters,
  }: LinkContext, { objectId }: {objectId: string}) {
    if (state.links[objectId]?.options) {
      // TODO: fix defaults, these are currently overwritten by null values in state options
      const {
        layoutType = LayoutTypeNum.ESSENTIALS,
        viewType = ViewTypeNum.HORIZONTAL,
        mosaicSpacing = 1,
        gridSpacing = 1,
        horizontalSpacing = 1,
        rowHeight = 250,
      } = state.links[objectId].options;
      let {
        columnCount = 5,
      } = state.links[objectId].options;

      // note: when a shared link is done by mobile and opened by desktop, we need to set a fallback view, because mosaic with one column doesn't look good on desktop
      const sharedByMobileOpenedOnDesktop = columnCount === 1 && viewType === ViewTypeNum.MOSAIC && !rootGetters.isMobile;
      let view;
      if (sharedByMobileOpenedOnDesktop) {
        view = ViewType.HORIZONTAL;
        columnCount = null;
      } else {
        view = viewTypeNumToViewType(viewType);
      }
      let layout: LayoutType;
      if (layoutType === LayoutTypeNum.ESSENTIALS) {
        layout = LayoutType.ESSENTIALS;
      } else if (layoutType === LayoutTypeNum.FILMSTRIP) {
        layout = LayoutType.FILMSTRIP;
      } else if (layoutType === LayoutTypeNum.FULLSCREEN) {
        layout = LayoutType.FULLSCREEN;
      }
      dispatch('chooseLayout', layout, { root: true });
      if (mosaicSpacing) {
        dispatch('cloud/setMosaicMarginFactor', { windowId: ViewIdentifier.MAIN_VIEW, marginFactor: mosaicSpacing }, { root: true });
      }
      if (gridSpacing) {
        dispatch('cloud/setGridMarginFactor', { windowId: ViewIdentifier.MAIN_VIEW, marginFactor: gridSpacing }, { root: true });
      }
      if (horizontalSpacing) {
        dispatch('cloud/setMagnifyMarginFactor', { windowId: ViewIdentifier.MAIN_VIEW, marginFactor: horizontalSpacing }, { root: true });
      }
      if (rowHeight) {
        dispatch('cloud/setGridRowHeight', { windowId: ViewIdentifier.MAIN_VIEW, rowHeight }, { root: true });
      }
      if (columnCount) {
        dispatch('cloud/setMosaicColumnCount', { windowId: ViewIdentifier.MAIN_VIEW, columnCount }, { root: true });
      }
      if (!rootGetters.isMobile) {
        setTimeout(() => commit('cloud/viewChanged', { windowId: ViewIdentifier.MAIN_VIEW, view }, { root: true }), 150);
      }
    }
    setTimeout(() => commit('setLayoutInitialized', true, { root: true }), 250);
  },
  async createSharedLinkForCloudObject({ dispatch, rootGetters, commit }: LinkContext, cloudObject: CloudObject<Folder | Selection>) {
    if (!cloudObject.object.isSynced && !rootGetters['user/isGuest']) {
      const user = rootGetters['user/currentUser'] as User;
      await dispatch('folder/synchronizeFolders', {}, { root: true });
      commit('folder/setFolderOwner', { folderId: cloudObject.id, owner: { id: user.id, username: user.username } }, { root: true });
    }
    if (cloudObject?.isSelection) {
      await dispatch('createSharedLinkForSelection', { selectionId: cloudObject.object.id });
    } else if (cloudObject?.isFolder) {
      await dispatch('createSharedLink', { folderId: cloudObject.object.id });
    }
  },
  async createSharedLinkForSelection({ commit, dispatch, getters }: LinkContext, {
    selectionId,
    accessCode,
  }: { selectionId: string, accessCode: string }) {
    const options = getters.sharedLinkOptionsSnapshot;
    const requestData: any = {
      selectionId, options,
    };
    if (accessCode) {
      requestData.accessCode = accessCode;
    }
    const { data } = await this.$api.post('/links', requestData);
    dispatch('selection/setSelectionShared', selectionId, { root: true });
    commit('setLinkData', data);
  },
  async createSharedLink({ commit, dispatch, getters }: LinkContext, {
    folderId,
    accessCode,
  }: { folderId: string, accessCode: string }) {
    const options = getters.sharedLinkOptionsSnapshot;
    const { data } = await this.$api.post('/links', { folderId, accessCode, options });
    dispatch('folder/setFolderShared', folderId, { root: true });
    commit('setLinkData', data);
  },
  removeCode({ dispatch }: LinkContext, accessId) {
    dispatch('updateSharedLink', { accessId, accessCode: null });
  },
  async updateSharedLink({ commit, dispatch, getters }: any, {
    accessId,
    accessCode,
    withOptions = false,
  }: { accessId: string, accessCode: string, withOptions?: boolean }) {
    const updatedSharedLinkData: any = {
      accessCode,
    };
    if (withOptions) {
      updatedSharedLinkData.options = getters.sharedLinkOptionsSnapshot;
    }
    const { data } = await this.$api.put(`/links/${accessId}`, updatedSharedLinkData);
    commit('setLinkData', data);
    if (data.type === 'FOLDER') {
      await dispatch('folder/loadSharedLinks', data.folderId, { root: true });
    } else {
      await dispatch('selection/loadSharedLinks', data.selectionId, { root: true });
    }
  },
  dismissChanges({ state, dispatch, rootGetters }: LinkContext) {
    const objectId = rootGetters['cloud/currentCloudObjectId']?.toString();
    dispatch('loadLinkData', state.links[objectId]?.accessId);
  },
  async delete({ commit }: { commit: any }, {
    accessId,
    objectId,
  }: { accessId: string, objectId: ObjectId }) {
    await this.$api.delete(`/links/${accessId}`);
    commit('deleteLinkSuccess', objectId.toString());
    if (objectId.isFolderId) {
      commit('folder/deleteSharedLinkSuccess', { folderId: objectId.toUuid() }, { root: true });
    } else if (objectId.isSelectionId) {
      commit('selection/deleteSharedLinkSuccess', { selectionId: objectId.toUuid(), accessId }, { root: true });
    }
  },
  visitSharedLink(_context: LinkContext, accessId) {
    const url = `${WebConfig.URL}/shared/${accessId}`;
    const win = window.open(url, '_blank');
    win.focus();
  },
  async shareWithMobile({ dispatch }: LinkContext, accessId: string) {
    const sharedLinkUrl = `${WebConfig.URL}/shared/${accessId}`;

    // note: Web Share API behaves weird on iOS, either text or url property gets shared.
    const sharedData = /iPhone|iPad/i.test(navigator.userAgent)
      ? {
          title: 'Mutant Shared Link',
          text: `Check out my pictures on Mutant Web: ${sharedLinkUrl}`,
          url: sharedLinkUrl,
        }
      : {
          title: 'Mutant Shared Link',
          text: 'Check out my pictures on Mutant Web',
          url: sharedLinkUrl,
        };
    try {
      await navigator.share(sharedData);
    } catch (error) {
      if (error.name !== 'AbortError') {
        this.$sentry.captureException(error);
        dispatch('copyToClipboard', accessId);
      }
    }
  },
  async copyToClipboard({ dispatch }: LinkContext, accessId: string) {
    const sharedLinkUrl = `${WebConfig.URL}/shared/${accessId}`;
    try {
      await navigator.clipboard.writeText(sharedLinkUrl);
      dispatch<ActionPayload<Notification>>({ type: 'setNotificationMessage', payload: { message: SuccessMessages.COPIED_TO_CLIPBOARD, type: NotificationType.SUCCESS } }, { root: true });
    } catch (error) {
      const el = document.createElement('textarea');
      el.value = `${WebConfig.URL}/shared/${accessId}`;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      const copySuccess = document.execCommand('copy');
      document.body.removeChild(el);
      if (!copySuccess) {
        this.$sentry.captureMessage('Copy to clipboard failed');
      }
    } finally {
      dispatch<ActionPayload<Notification>>({ type: 'setNotificationMessage', payload: { message: SuccessMessages.COPIED_TO_CLIPBOARD, type: NotificationType.SUCCESS } }, { root: true });
    }
  },
  copyToClipboardForFolderId(context: LinkContext, folderId: string) {
    const sharedLink = context.getters.linkData(ObjectId.fromFolderId(folderId));
    context.dispatch('copyToClipboard', sharedLink.accessId);
  },
  copyToClipboardForSelectionId(context: LinkContext, selectionId: string) {
    const sharedLink = context.getters.linkData(ObjectId.fromSelectionId(selectionId));
    context.dispatch('copyToClipboard', sharedLink.accessId);
  },
};

export default actions;

import moment from 'moment';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ViewSortingOption } from '~/store/cloud/state';
import { sortItemsByOrderAsc } from '~/models/item/sortItemsByOrderAsc';

export function sortItemsBySortingOption(
  items: ItemWithPosition[],
  option: ViewSortingOption
): ItemWithPosition[] {
  if (option === ViewSortingOption.LAST_MODIFIED_DESC) {
    return items
      .map((i) => i)
      .sort((a, b) => (moment(a.modified).isBefore(b.modified) ? 1 : -1));
  }
  if (option === ViewSortingOption.LAST_MODIFIED_ASC) {
    return items
      .map((i) => i)
      .sort((a, b) => (moment(b.modified).isBefore(a.modified) ? 1 : -1));
  }
  if (option === ViewSortingOption.CUSTOM_ORDER) {
    return [...items].sort(sortItemsByOrderAsc);
  }
  if (option === ViewSortingOption.ALPHABETICAL_ASC) {
    return items
      .map((i) => i)
      .sort((a, b) => a.item?.assets[0]?.name?.localeCompare(b.item?.assets[0]?.name));
  }
  if (option === ViewSortingOption.ALPHABETICAL_DESC) {
    return items
      .map((i) => i)
      .sort((a, b) => a.item?.assets[0]?.name?.localeCompare(b.item?.assets[0]?.name))
      .reverse();
  }
  return items;
}

import { FolderTag } from '~/models/tags/FolderTag';
import { FolderTagHierarchy } from '~/store/folder/getters';

export class TagParser {
  public static getParentTagsForTag(tag: FolderTag, tagMap: Map<string, FolderTag>): FolderTag[] {
    const parentTags: FolderTag[] = [];
    let parentId = tag.parentId;
    while (parentId) {
      const parentTag = tagMap.get(parentId);
      parentTags.push(parentTag);
      parentId = parentTag.parentId;
    }
    return parentTags;
  }

  public static buildFolderTagHierarchy(tags: FolderTag[]): FolderTagHierarchy[] {
    const tagMap = new Map<string, FolderTagHierarchy>();
    for (const tag of tags) {
      const tagWithChildren: FolderTagHierarchy = { tag, children: [] };
      tagMap.set(tag.id, tagWithChildren);
    }
    const rootTags: FolderTagHierarchy[] = [];
    for (const hierarchy of tagMap.values()) {
      if (!hierarchy.tag.parentId) {
        rootTags.push(hierarchy);
      } else {
        const parent = tagMap.get(hierarchy.tag.parentId);
        if (parent) {
          parent.children.push(hierarchy);
        }
      }
    }
    return rootTags;
  }
}

import { Socket } from 'socket.io-client';
import Vue from 'vue';
import { DragInfo } from '~/models/views/DragInfo';
import selectionState from '~/store/selection/state';
import folderState from '~/store/folder/state';
import userState from '~/store/user/state';
import magnifyState from '~/store/magnify/state';
import fileState from '~/store/file/state';
import cloudState from '~/store/cloud/state';
import homepageState from '~/store/homepage/state';
import detailViewState from '~/store/detailView/state';
import contextState from '~/store/context/state';
import linkState from '~/store/link/state';
import { DragElementOffsetValues } from '~/models/views/DragElementOffsetValues';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { Background } from '~/models/views/Background';
import { BackgroundResizeCompletedEventData } from '~/models/socket/events/BackgroundResizeCompletedEventData';
import { ObjectId } from '~/models/ObjectId';
import { MutantContentView } from '~/models/views/MutantContentView';
import rootState, { initializeOrientation, RootState, ViewProgress } from '~/store/state';
import { FooterTab } from '~/models/FooterTab';
import { DroppedOnViewEvent } from '~/models/DroppedOnViewEvent';
import { DropInfo } from '~/models/views/DropInfo';
import { CloudMenuRoute } from '~/models/CloudMenuRoute';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import { StorageKey } from '~/models/storage/StorageKey';
import { Notification } from '~/models/Notification';
import { MutationPayload } from '~/models/VuexAdditionalTypes';
import { LayoutType } from '~/models/LayoutType';
import { MenuEntry } from '~/models/MenuEntry';
import { DesktopReleaseData } from '~/store/actions';

const mutations = {
  setShowFolderTitle(state: RootState, value: boolean) {
    state.showFolderTitle = value;
  },
  toggleLightMode(state: RootState) {
    state.isLightMode = !state.isLightMode;
  },
  setDesktopReleaseData(state: RootState, data: DesktopReleaseData) {
    state.desktopReleaseData = { ...data };
  },
  setNavigationLaneOpen(state: RootState, value: boolean) {
    state.isNavigationLaneOpen = value;
    localStorage.setItem(StorageKey.SHORTCUTS_IS_OPEN, String(value));
  },
  setActiveElementId(state: RootState, data: MutationPayload<string>) {
    if (state.activeElementId !== data.payload) {
      state.activeElementId = data.payload;
    }
  },
  toggleShowOriginals(state: RootState) {
    state.showOriginals = !state.showOriginals;
    localStorage.setItem(StorageKey.SHOW_ORIGINALS, state.showOriginals.toString());
  },
  clearState(state: RootState) {
    for (const [key, value] of Object.entries(rootState())) {
      Vue.set(state, key, value);
    }
    state.selection = selectionState();
    state.folder = folderState();
    state.user = userState();
    state.magnify = magnifyState();
    state.file = fileState();
    state.cloud = cloudState();
    state.homepage = homepageState();
    state.detailView = detailViewState();
    state.context = contextState();
    state.link = linkState();
  },
  setBetaLandingPageVisited(state: RootState) {
    state.betaLandingPageVisited = true;
    localStorage.setItem(StorageKey.BETA_LANDING_VISITED, 'true');
  },
  setActiveHomepageMenuEntry(state: RootState, menuEntry: MenuEntry) {
    state.activeHomepageMenuEntry = menuEntry;
  },
  setWorkerSupport(state: RootState, workerSupport: boolean) {
    state.workerSupport = workerSupport;
  },
  setNotificationMessage(state: RootState, data: MutationPayload<Notification>) {
    Vue.set(state.notifications, data.payload.id, { message: data.payload.message, type: data.payload.type, position: data.payload.position });
  },
  disableNotificationMessage(state: RootState, id: string) {
    Vue.delete(state.notifications, id);
  },
  setMobileSpecificValues(state: RootState) {
    const maxMobileWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--mobile-max-width'));
    const maxMobileHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--mobile-max-height'));
    state.isMobile = window.matchMedia(`only screen and (max-width: ${maxMobileWidth}px), (max-height: ${maxMobileHeight}px)`)?.matches;
    state.isMobileWidth = window.matchMedia(`only screen and (max-width: ${maxMobileWidth}px)`)?.matches;
    state.orientation = initializeOrientation();
  },
  setInteractiveFooterHeight(state: RootState, height: number) {
    state.interactiveFooterHeight = height;
    localStorage.setItem(StorageKey.INTERACTIVE_FOOTER_HEIGHT, height.toString(10));
  },
  setLastKnownModified(state: RootState, lastKnownModifiedDate: number) {
    if (lastKnownModifiedDate > state.lastKnownModified) {
      state.lastKnownModified = lastKnownModifiedDate;
    }
  },
  setMenusOpacity(state: RootState, opacity: number) {
    state.transparency.menus = opacity;
  },
  addToCustomObjectIds(state: RootState, objectId: ObjectId) {
    state.customObjectIds = state.customObjectIds.some(
      (o) => o.toString() === objectId.toString()
    )
      ? [
          objectId,
          ...state.customObjectIds.filter(
            (o) => o.toString() !== objectId.toString()
          ),
        ]
      : [objectId, ...state.customObjectIds];
  },
  initializeCustomObjectIds: (state: RootState, objectIds: ObjectId[]) => {
    state.customObjectIds = objectIds;
  },
  removeFromCustomObjectIds(state: RootState, objectId: ObjectId) {
    state.customObjectIds = state.customObjectIds.filter(
      (o) => o.toString() !== objectId.toString()
    );
  },
  clearRecentObjectIds(state: RootState) {
    state.recentObjectIds = [];
  },
  setViewProgress: (state: RootState, { pipelineId, viewProgress }: { pipelineId: string; viewProgress: ViewProgress; }) => {
    Vue.set(state.viewProgress, pipelineId, viewProgress);
  },
  updateViewProgress: (state: RootState, { pipelineId, step, percentage }: { pipelineId: string, step: number, percentage: number}) => {
    if (state.viewProgress[pipelineId] != null) {
      Vue.set(state.viewProgress, pipelineId, {
        ...state.viewProgress[pipelineId],
        progressSteps: state.viewProgress[pipelineId].progressSteps.map(progressStep => {
          if (progressStep.step === step) {
            return {
              ...progressStep,
              percentage,
            };
          }
          return progressStep;
        }),
      });
    }
  },
  setIsOffline: (state: RootState, value: boolean) => {
    state.isOffline = value;
  },
  storeInitialized: (state: RootState) => {
    state.isInitialized = true;
  },
  setDropInfo: (state: RootState, data: DropInfo) => {
    state.dropInfo = data;
  },
  setDragInfo: (state: RootState, data: DragInfo) => {
    state.dragInfo = data;
  },
  setDropViewData: (state: RootState, data: DroppedOnViewEvent) => {
    state.droppedOnView = data;
  },
  setDragSize: (
    state: RootState,
    dragSize: { width: number; height: number }
  ) => {
    state.currentDragSize = dragSize;
  },
  setDragOffsets: (state: RootState, dragOffsets: DragElementOffsetValues) => {
    state.currentDragOffsets = dragOffsets;
  },
  setSocketConnection: (state: RootState, isConnectedToWebsocket: boolean) => {
    state.isConnectedToWebsocket = isConnectedToWebsocket;
  },
  useScreenBackground: (state: RootState, backgroundId: string) => {
    state.screenBackground = backgroundId;
  },
  setMenuExpanded(state: RootState, value: boolean) {
    state.isMenuExpanded = value;
  },
  setMenuRoute(state: RootState, newRoute: CloudMenuRoute) {
    state.menuRoute = newRoute;
  },
  toggleSidePane(state: RootState) {
    state.cloudSidePaneOpen = !state.cloudSidePaneOpen;
    if (state.cloudSidePaneOpen) {
      document.documentElement.style.setProperty('--side-pane-width', (state.cloudSidePaneWidth !== 0 ? state.cloudSidePaneWidth : 300) + 'px');
    } else {
      document.documentElement.style.setProperty('--side-pane-width', '0');
    }
  },
  openSidePane(state: RootState) {
    state.cloudSidePaneOpen = true;
  },
  openMenu(state: RootState) {
    if (state.menuWidth === 0) {
      state.menuWidth = 350;
    }
    document.documentElement.style.setProperty('--cloud-menu-width', (state.menuWidth) + 'px');
    state.menuOpen = true;
  },
  setActiveCloudMenuTab(state: RootState, tab: CloudMenuTab) {
    state.activeCloudMenuTab = tab;
  },
  persistCloudMenuRight(state: RootState) {
    localStorage.setItem(StorageKey.RIGHT_MENU, JSON.stringify({
      isOpen: state.cloudMenuRightOpen,
      width: state.cloudMenuRightWidth,
      activeTab: state.activeCloudMenuTab,
      activeOptionTab: state.activeCloudMenuOptionTab,
    }));
  },
  persistSidePane(state: RootState) {
    localStorage.setItem(StorageKey.SIDE_PANE, JSON.stringify({
      isOpen: state.cloudSidePaneOpen,
      width: state.cloudSidePaneWidth,
    }));
  },
  persistCloudMenu(state: RootState) {
    localStorage.setItem(StorageKey.CLOUD_MENU, JSON.stringify({
      isOpen: state.menuOpen,
      width: state.menuWidth,
    }));
  },
  toggleShowCaptions(state: RootState) {
    state.showCaptions = !state.showCaptions;
    localStorage.setItem('showCaptions', state.showCaptions.toString());
  },
  toggleShowResolutions(state: RootState) {
    state.showResolutions = !state.showResolutions;
    localStorage.setItem('showResolutions', state.showResolutions.toString());
  },
  toggleCloudMenuRight(state: RootState) {
    state.cloudMenuRightOpen = !state.cloudMenuRightOpen;
    if (state.cloudMenuRightOpen) {
      if (state.cloudMenuRightWidth === 0) {
        state.cloudMenuRightWidth = 350;
      }
      document.documentElement.style.setProperty('--cloud-menu-right-width', state.cloudMenuRightWidth + 'px');
    } else {
      document.documentElement.style.setProperty('--cloud-menu-right-width', '0');
    }
  },
  setMinimalView(state: RootState, value: boolean) {
    state.isMinimalView = value;
    document.documentElement.style.setProperty('--footer-menu-height', '0');
  },
  setLayout(state: RootState, layout: LayoutType) {
    localStorage.setItem(StorageKey.CURRENT_LAYOUT_TYPE, layout);
    state.currentLayoutType = layout;
  },
  closeMenu(state: RootState) {
    document.documentElement.style.setProperty('--cloud-menu-width', '0');
    state.menuOpen = false;
  },
  updateCloudMenuRightWidth(state: RootState, width: number) {
    state.cloudMenuRightWidth = width;
  },
  updateSidePaneWidth(state: RootState, width: number) {
    state.cloudSidePaneWidth = width;
  },
  toggleAttachMenu(state: RootState) {
    state.isMenuFloating = !state.isMenuFloating;
  },
  updateCloudMenuWidth(state: RootState, width: number) {
    state.menuWidth = width;
  },
  setLayoutDragging(state: RootState, isCurrentlyDragged: boolean) {
    state.isLayoutDragged = isCurrentlyDragged;
  },
  setAdjustWindowsToDrag(state: RootState, value: boolean) {
    state.adjustWindowsToDrag = value;
  },
  setWindowSize(state: RootState) {
    state.windowHeight = window.innerHeight;
    state.windowWidth = window.innerWidth;
  },
  registerFloatingMenu(state: RootState, floatingMenu: any) {
    if (!state.floatingMenus.some((m) => m.id === floatingMenu.id)) {
      state.floatingMenus = [
        ...state.floatingMenus,
        {
          minimized: false,
          ...floatingMenu,
        },
      ];
    }
  },
  closeFloatingMenu(state: RootState, floatingMenuId: string) {
    state.floatingMenus = state.floatingMenus.filter(
      (m) => m.id !== floatingMenuId
    );
  },
  minimizeFloatingMenu(state: RootState, floatingMenuId: string) {
    state.floatingMenus = state.floatingMenus.map((m) => {
      if (m.id === floatingMenuId) {
        m.minimized = true;
      }
      return m;
    });
  },
  openFloatingMenu(state: RootState, floatingMenuId: string) {
    state.floatingMenus = state.floatingMenus.map((m) => {
      if (m.id === floatingMenuId) {
        m.minimized = false;
      }
      return m;
    });
  },
  setFloatingMenuPosition(state: RootState, data: any) {
    state.floatingMenus = state.floatingMenus.map((m) => {
      if (m.id === data.id) {
        m.left = data.left;
        m.top = data.top;
      }
      return m;
    });
  },
  setMainViewHeaderHeight(state: RootState, height: number) {
    state.mainViewHeaderHeight = height;
  },
  addToEventHistory(state: RootState, event: SocketEvent<any>) {
    state.eventHistory = [event, ...state.eventHistory.slice(0, 10)];
  },
  setShowCover(state: RootState, value: boolean) {
    state.showCover = value;
  },
  setLayoutInitialized(state: RootState, value: boolean) {
    state.layoutInitialized = value;
  },
  addBackground(state: RootState, background: Background) {
    state.backgrounds = [...state.backgrounds, background];
  },
  updateBackground(state: RootState, updatedBackground: Background) {
    state.backgrounds = state.backgrounds.map((background) => {
      if (background.id === updatedBackground.id) {
        return {
          ...background,
          ...updatedBackground,
        };
      }
      if (updatedBackground.isDefault) {
        background.isDefault = false;
      }
      if (updatedBackground.isMoodboardDefault) {
        background.isMoodboardDefault = false;
      }
      return background;
    });
  },
  removeBackground(state: RootState, id) {
    state.backgrounds = state.backgrounds.filter(
      (background) => background.id !== id
    );
  },
  addBackgroundAssets(
    state: RootState,
    { backgroundId, assets }: BackgroundResizeCompletedEventData
  ) {
    state.backgrounds = state.backgrounds.map((background) =>
      background.id === backgroundId
        ? {
            ...background,
            assets: [...background.assets, ...assets],
          }
        : background
    );
  },
  setBackgrounds(state: RootState, backgrounds: Background[]) {
    state.backgrounds = backgrounds;
  },
  addToRecentObjectIds(state: RootState, objectId: ObjectId) {
    const recentObjectIds = state.recentObjectIds.some(
      (o) => o.toString() === objectId.toString()
    )
      ? [
          objectId,
          ...state.recentObjectIds.filter(
            (o) => o.toString() !== objectId.toString()
          ),
        ]
      : [objectId, ...state.recentObjectIds];
    if (recentObjectIds.length > 50) {
      recentObjectIds.pop();
    }
    state.recentObjectIds = recentObjectIds;
  },
  removeFromRecentObjectIds(state: RootState, objectId: ObjectId) {
    state.recentObjectIds = state.recentObjectIds.filter(
      (o) => o.toString() !== objectId.toString()
    );
  },
  initializeRecentObjectIds(state: RootState, objectIds: ObjectId[]) {
    state.recentObjectIds = objectIds;
  },
  setActiveFooterTab(state: RootState, tab: FooterTab) {
    state.activeFooterTab = tab;
    localStorage.setItem(StorageKey.SHORTCUTS_ACTIVE_LANE, tab);
  },
  sharedLinkRoute(state: RootState, isShared: boolean) {
    state.isSharedRoute = isShared;
  },
  addContentView(state: RootState, contentView: MutantContentView) {
    state.registeredContentViews.set(contentView.id, contentView);
  },
  removeContentView(state: RootState, contentViewId: string) {
    state.registeredContentViews.delete(contentViewId);
  },
  setSocket(state: RootState, socket: Socket) {
    state.socket = socket;
  },
};

export const parseJwt = (token: string) => {
  return JSON.parse(atob(token.split('.')[1]));
};

export default mutations;

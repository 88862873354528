import _cloneDeep from 'lodash.clonedeep';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Snippet from '~/models/Snippet';
import { ViewItem } from '~/models/views/ViewItem';
import SnippetBuilder from '~/models/views/SnippetBuilder';
import Item, {
} from '~/models/item/Item';
import { Mosaic } from '~/models/views/mosaic/Mosaic';
import { getAspectRatio } from '~/models/Asset';

export class MosaicBuilder {
  private static DEFAULT_ASSET_HEIGHT: number = 400;

  private marginBetweenHorizontalItems: number = 0;
  private marginBetweenVerticalItems: number = 0;
  private items: ItemWithPosition[] = [];
  private columnCount: number | null = null;
  private columnWidth: number = 200;
  private viewWidth: number = 500;
  private useFullSnippetHeight: boolean = false;

  constructor() {}

  public setItems(items: ItemWithPosition[]): MosaicBuilder {
    this.items = items;
    return this;
  }

  public setViewWidth(viewWidth: number): MosaicBuilder {
    this.viewWidth = viewWidth;
    return this;
  }

  public setColumnWidth(columnWidth: number): MosaicBuilder {
    this.columnWidth = columnWidth;
    return this;
  }

  public setColumnCount(columnCount: number): MosaicBuilder {
    this.columnCount = columnCount;
    return this;
  }

  public setMarginBetweenItems(margin: number): MosaicBuilder {
    this.marginBetweenHorizontalItems = margin;
    if (margin > 0) {
      this.marginBetweenVerticalItems = margin * 0.7;
    }
    return this;
  }

  public withFullSnippetHeight(value: boolean): MosaicBuilder {
    this.useFullSnippetHeight = value;
    return this;
  }

  public setBaseMosaic(mosaic: Mosaic): MosaicBuilder {
    this.setViewWidth(mosaic.width);
    this.setMarginBetweenItems(mosaic.marginBetweenHorizontalItems);
    this.setColumnCount(mosaic.columnCount);
    return this;
  }

  public build(): Mosaic {
    return this.buildPartialMosaic([]);
  }

  public buildPartialMosaic(startHeights: number[] = []): Mosaic {
    const columnCount = this.columnCount ? this.columnCount : Math.ceil(this.viewWidth / this.columnWidth);
    const scaleWidth = (this.viewWidth - (columnCount + 1) * this.marginBetweenHorizontalItems) / columnCount;
    const itemAdjustedColumnCount = this.items.length < columnCount ? this.items.length : columnCount;
    const heightPerColumn: number[] = _cloneDeep(startHeights);
    const itemHeightPerColumn: number[] = _cloneDeep(startHeights); // without margins for stable mosaic calculation
    const mosaic: Snippet[] = [];
    if (itemHeightPerColumn.length !== columnCount || itemHeightPerColumn.includes(0)) {
      // Prefill first row of columns
      this.times(itemAdjustedColumnCount)((i: number) => {
        const item: ViewItem = new ViewItem(this.items[i]);
        let height = this.getScaledItemHeight(item.itemData, scaleWidth);
        item.setViewPosition({
          width: scaleWidth,
          height,
          left: i * scaleWidth + this.marginBetweenHorizontalItems * i + this.marginBetweenHorizontalItems,
          top: 0,
        });
        const snippet = new SnippetBuilder().fromItem(item).build();
        height = this.useFullSnippetHeight ? snippet.height : height;
        itemHeightPerColumn[i] = height != null ? height : MosaicBuilder.DEFAULT_ASSET_HEIGHT;
        heightPerColumn[i] = height != null ? height + this.marginBetweenVerticalItems : MosaicBuilder.DEFAULT_ASSET_HEIGHT;
        mosaic.push(snippet);
      });
    }
    // Calculate item sizes
    let fittingColumn = 0;
    for (let i = columnCount; i < this.items.length; i++) {
      const item: ViewItem = new ViewItem(this.items[i]);
      let height = this.getScaledItemHeight(item.itemData, scaleWidth);
      item.setViewPosition({
        left:
          fittingColumn * scaleWidth
          + this.marginBetweenHorizontalItems * fittingColumn
          + this.marginBetweenHorizontalItems,
        top: heightPerColumn[fittingColumn],
        height,
        width: scaleWidth,
      });
      const snippet = new SnippetBuilder().fromItem(item).build();
      height = this.useFullSnippetHeight ? snippet.height : height;
      mosaic.push(snippet);
      heightPerColumn[fittingColumn]
        = heightPerColumn[fittingColumn]
        + height
        + this.marginBetweenVerticalItems;
      itemHeightPerColumn[fittingColumn]
        = itemHeightPerColumn[fittingColumn] + height;
      fittingColumn = fittingColumn === columnCount - 1 ? 0 : fittingColumn + 1;
    }
    let highestColumnHeight = 0;
    heightPerColumn.forEach((h) => {
      if (h > highestColumnHeight) {
        highestColumnHeight = h;
      }
    });
    return new Mosaic(
      mosaic,
      this.viewWidth,
      highestColumnHeight,
      this.columnCount,
      this.marginBetweenHorizontalItems,
      this.marginBetweenVerticalItems,
      scaleWidth
    );
  }

  private getScaledItemHeight(item: Item, scaleWidth: number): number {
    return scaleWidth / getAspectRatio(item);
  }

  private times(n: number) {
    return (f: any) => {
      const iter = (i: number) => {
        if (i === n) {
          return;
        }
        f(i);
        iter(i + 1);
      };
      return iter(0);
    };
  }
}

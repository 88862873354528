import { Asset } from '~/models/Asset';

export interface VibrantColor {
  type: string;
  rgb: any;
  population: number;
}

export default interface Item {
  id: string;
  type: number;
  data: any;
  name: string;
  folderId: string;
  folderTagId: string;
  ownerId: string;
  created: string;
  modified: string;
  assets: Asset[];
  colors?: VibrantColor[];
  exif?: Map<String, any>;

  // additional properties added by us (not from the api)
  isSynced?: boolean;
  placeholderId?: string;
  isPlaceholder?: boolean;
}

export const YOUTUBE_DEFAULT_WIDTH = 560;
export const YOUTUBE_DEFAULT_HEIGHT = 315;

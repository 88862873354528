import { v4 as uuid } from 'uuid';
import { Asset } from '~/models/Asset';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ObjectId } from '~/models/ObjectId';
import { ViewOptions } from '~/store/cloud/state';
import { ViewType } from '~/models/views/ViewType';

export enum MutantContentViewType {
  GLOBAL_SELECTION = 'GLOBAL_SELECTION',
  SELECTION = 'SELECTION',
  FOLDER = 'FOLDER',
  USER = 'USER',
  MISC = 'MIXED'
}

export class MutantContentItems {
  items: ItemWithPosition[];
}

export class MutantContentView {
  id: string;
  viewOptions: ViewOptions;
  contentType!: MutantContentViewType;
  backgrounds: Asset[] = [];
  name: string;
  owners: string[];
  objectIds!: ObjectId[];
  subtitle!: string;
  isInNavigationMode: boolean = false;
  viewHash: string;

  constructor(
    id: string,
    name: string,
    owners: string[],
    contentType: MutantContentViewType,
    objectIds: ObjectId[],
    subtitle: string
  ) {
    this.id = id;
    this.name = name;
    this.contentType = contentType;
    this.owners = owners;
    this.objectIds = objectIds;
    this.subtitle = subtitle;
    this.viewHash = uuid();
  }

  public get viewSpacing(): number {
    if (this.viewOptions?.activeViewType === ViewType.MOSAIC) {
      return this.viewOptions.mosaic.margin;
    }
    if (this.viewOptions?.activeViewType === ViewType.GRID) {
      return this.viewOptions.grid.margin;
    }
    if (this.viewOptions?.activeViewType === ViewType.HORIZONTAL) {
      return this.viewOptions.magnify.margin;
    }
    if (this.viewOptions?.activeViewType === ViewType.CONTACT_SHEET) {
      return this.viewOptions.contactSheet.margin;
    }
    return 0;
  }

  public matches(view: MutantContentView) {
    return !this.objectIds.some(viewId => view.objectIds.some(viewIdComparable => viewId.toUuid() !== viewIdComparable.toUuid()));
  }

  public setBackgrounds(backgrounds: Asset[]) {
    this.backgrounds = backgrounds;
  }

  public setViewOptions(viewOptions: ViewOptions) {
    this.viewOptions = viewOptions;
  }

  public setNavigationMode(isInNavigationMode: boolean) {
    this.isInNavigationMode = isInNavigationMode;
  }

  public get isEmpty(): boolean {
    return this.objectIds.length === 0;
  }

  public get isGlobalSelectionView(): boolean {
    return this.contentType === MutantContentViewType.GLOBAL_SELECTION;
  }

  public get isFolderView(): boolean {
    return this.contentType === MutantContentViewType.FOLDER;
  }

  public get isSelectionView(): boolean {
    return this.contentType === MutantContentViewType.SELECTION;
  }

  public get isSingleSelectionView(): boolean {
    return this.isSelectionView && this.objectIds.length === 1;
  }

  public get isSingleFolderView(): boolean {
    return this.isFolderView && this.objectIds.length === 1;
  }

  public get isMultiFolderView(): boolean {
    return this.contentType === MutantContentViewType.FOLDER && this.objectIds.length > 1;
  }

  public get folderId(): string | null {
    return this.contentType === MutantContentViewType.FOLDER && this.isSingleFolderView ? this.objectIds[0].toUuid() : null;
  }

  public get selectionId(): string | null {
    return this.contentType === MutantContentViewType.SELECTION && this.isSingleSelectionView ? this.objectIds[0].toUuid() : null;
  }

  hasId(id: string) {
    return this.objectIds.some(objectId => objectId.toUuid() === id);
  }
}

import { Plugin } from '@nuxt/types';
import { filter } from 'rxjs';
import { Context, Inject } from '@nuxt/types/app';
import { pipelineCommands$ } from '~/models/pipelineCommands$';
import { pipelineEvents$ } from '~/models/pipelineEvents$';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { Thumbnailer } from '~/models/thumbnailer/Thumbnailer';
import { ThumbnailerEvent } from '~/models/thumbnailer/ThumbnailerEvent';

const thumbnailer: Plugin = (_context: Context, inject: Inject) => {
  const thumbnailer = new Thumbnailer();

  pipelineCommands$
    .pipe(filter(e => e.type === PipelineCommandType.CREATE_THUMBNAILS))
    .subscribe(event => thumbnailer.addItems(event.items));

  thumbnailer.on(ThumbnailerEvent.ITEMS_RESIZED, (items) => items.forEach(item => pipelineEvents$.next({
    type: PipelineEventType.THUMBNAILS_CREATED,
    pipelineId: item.pipelineId,
    data: [item],
  })));

  inject('thumbnailer', thumbnailer);
};

export default thumbnailer;

export function createBatchesForCollection(collection: any[], batchSize: number): any[][] {
  if (collection.length === 0) {
    return [];
  }
  if (collection.length <= batchSize) {
    return [collection];
  }
  const batches = [];
  let currentBatchStart = 0;
  while (currentBatchStart < collection.length) {
    batches.push(collection.slice(currentBatchStart, currentBatchStart + batchSize));
    currentBatchStart += batchSize;
  }
  return batches;
}

export enum CloudMenuTab {
  REVIEW = 'review',
  RECENT = 'recent',
  SELECTIONS = 'selections',
  CONTACTS = 'contacts',
  ITEM_LIST = 'item_list',
  SHARED_LINK = 'shared_link',
  VIEW = 'view',
  PIPELINE = 'pipeline',
  DROPZONE = 'dropzone',
}

import { UserProfile } from '~/models/user/UserProfile';

export interface ProfileState {
  data: UserProfile | null;
  saved: boolean;
}

export default (): ProfileState => ({
  data: null,
  saved: true,
});

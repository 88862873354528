import { LayoutType } from '~/models/LayoutType';
import { LayoutTypeNum } from '~/store/link/actions';
import { LinkData, LinkState, SharedLinkOptions } from '~/store/link/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ViewTypeNum, viewTypeToViewTypeNum } from '~/models/views/ViewTypeNum';
import { RootState } from '~/store/state';

export default {
  hasOverlay: (state: LinkState): boolean => {
    return state.showShareOverlay;
  },
  isLinkOwner: (state: LinkState, _getters: any, _rootState: RootState, rootGetters: any) => {
    const objectId = rootGetters['cloud/currentCloudObjectId']?.toString();
    return state?.links[objectId]?.owner?.id === rootGetters['user/currentUser'].id;
  },
  linkOwnerName: (state: LinkState, _getters: any, _rootState: RootState, rootGetters: any) => {
    const objectId = rootGetters['cloud/currentCloudObjectId']?.toString();
    return state?.links[objectId]?.owner?.username;
  },
  objectIdByAccessId: (state: LinkState, _getters: any, _rootState: RootState, _rootGetters: any) => (accessId: string): string => {
    return Object.keys(state.links).find((objectId) => state.links[objectId]?.accessId === accessId ? objectId : undefined);
  },
  linkData: (state: LinkState, _getters: any, _rootState: RootState) => (objectId: string): LinkData => {
    return state.links[objectId];
  },
  linkDataExists: (state: LinkState, _getters: any, _rootState: RootState) => (objectId: string): boolean => {
    return !!state.links[objectId];
  },
  linkDataForCurrentCloudObject: (state: LinkState, _getters: any, _rootState: RootState, rootGetters: any): LinkData => {
    const objectId = rootGetters['cloud/currentCloudObjectId']?.toString();
    return state.links[objectId];
  },
  linkDataExistsForCurrentCloudObject: (_state: LinkState, getters: any): boolean => {
    return !!getters.linkDataForCurrentCloudObject;
  },
  sharedLinkOptionsSnapshot: (_state: LinkState, _getters: any, _rootState: RootState, rootGetters: any): SharedLinkOptions => {
    let viewType: ViewTypeNum = 1;
    const viewOptions = rootGetters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewOptions;
    viewType = viewTypeToViewTypeNum(viewOptions?.activeViewType);
    let layoutType: LayoutTypeNum = 1;
    switch (rootGetters.currentLayout) {
      case LayoutType.ESSENTIALS:
        layoutType = LayoutTypeNum.ESSENTIALS;
        break;
      case LayoutType.FILMSTRIP:
        layoutType = LayoutTypeNum.FILMSTRIP;
        break;
      case LayoutType.FULLSCREEN:
        layoutType = LayoutTypeNum.FULLSCREEN;
        break;
    }
    return {
      layoutType,
      viewType,
      mosaicSpacing: viewOptions.mosaic?.marginFactor,
      gridSpacing: viewOptions.grid?.marginFactor,
      horizontalSpacing: viewOptions.magnify?.marginFactor,
      rowHeight: viewOptions.grid?.rowHeight,
      columnCount: viewOptions.mosaic?.columnCount,
    };
  },
  layoutHasChanged: (state: LinkState, getters: any, _rootState: RootState, rootGetters: any): boolean => {
    const objectId = rootGetters['cloud/currentCloudObjectId']?.toString();
    const options = state.links[objectId]?.options;
    const optionsSnapshot = getters.sharedLinkOptionsSnapshot;
    return options != null
      ? (options.layoutType !== optionsSnapshot.layoutType
        || options.viewType !== optionsSnapshot.viewType
        || options.mosaicSpacing !== optionsSnapshot.mosaicSpacing
        || options.gridSpacing !== optionsSnapshot.gridSpacing
        || options.horizontalSpacing !== optionsSnapshot.horizontalSpacing
        || options.rowHeight !== optionsSnapshot.rowHeight
        || options.columnCount !== optionsSnapshot.columnCount)
      : true;
  },
};

import { ActionContext } from 'vuex';
import JSZip from 'jszip';
import { CUSTOM_SUB_VERSION, getLargestThumbnailAsset, ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import * as File from '~/models/File';
import { BackgroundFit } from '~/models/views/BackgroundFit';
import { ObjectId, parseObjectIdsFromString } from '~/models/ObjectId';
import Selection from '~/models/selection/Selection';
import { isValidViewType, ViewType } from '~/models/views/ViewType';
import {
  CloudState,
  convertGridUserMarginFactorToInternal,
  convertMagnifyUserMarginFactorToInternal,
  convertMosaicUserMarginFactorToInternal,
  FilterOption,
  HighlightInfo,
  MutantWindow,
  ReviewFilter,
  TagState,
  ViewSortingOption
} from '~/store/cloud/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { MosaicBuilder } from '~/models/views/mosaic/MosaicBuilder';
import { MoodboardBuilder } from '~/models/views/moodboard/MoodboardBuilder';
import { GridBuilder } from '~/models/views/grid/GridBuilder';
import { HorizontalViewBuilder } from '~/models/views/horizontal/HorizontalViewBuilder';
import { NavigationInstructionDirection } from '~/models/views/NavigationInstruction';
import { SocketActions } from '~/models/socket/SocketActions';
import { CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import { StorageKey } from '~/models/storage/StorageKey';
import { ChangeSet } from '~/models/ChangeSet';
import Item from '~/models/item/Item';
import { RootState } from '~/store/state';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { MutantWindowPayload } from '~/models/MutantWindowPayload';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { Notification, NotificationType } from '~/models/Notification';
import { LayoutType } from '~/models/LayoutType';
import { isSharedLinkUrl } from '~/store/helper';
import { FolderTagHierarchy } from '~/store/folder/getters';

type CloudContext = ActionContext<CloudState, RootState>;

enum SHARED_LINK_SOURCE {
  DESKTOP = 'desktop',
}

interface NavigationInstructions {
  direction: NavigationInstructionDirection;
  view: ViewIdentifier;
}

export interface UploadFiles {
  replaceFiles: boolean;
  uploadRawFiles: boolean;
  files: File[];
}

const actions = {
  async initializeCloudContent({
    rootState,
    dispatch,
    commit,
    rootGetters,
  }: CloudContext) {
    const ids: ObjectId[] = parseObjectIdsFromString(this.$router.currentRoute?.params?.id);
    const view: string = this.$router.currentRoute.query?.view as string;
    const sharedLinkAccessId = this.$router.currentRoute.query['shared-link-id'];
    if (sharedLinkAccessId) {
      await dispatch('link/initializeSharedLink', { objectId: ids[0]?.toString(), accessId: sharedLinkAccessId }, { root: true });
    }
    const reviewFilter = this.$router.currentRoute.query['review-filter'];
    if (reviewFilter) {
      const reviewFilterArray = reviewFilter.split(',').map((item) => parseInt(item, 10));
      commit('setReviewFilter', { windowId: ViewIdentifier.MAIN_VIEW, reviewFilter: reviewFilterArray });
      commit('setReviewFilterState', { windowId: ViewIdentifier.MAIN_VIEW, active: true });
    }
    if (Object.keys(rootState.link.links).length > 0 || !rootGetters['user/isGuest']) {
      await dispatch('initializeSocketConnection', {}, { root: true });
    }
    if (rootGetters['link/isLinkOwner'] && this.$router.currentRoute.query.source === SHARED_LINK_SOURCE.DESKTOP && ids[0]?.isSelectionId) {
      setTimeout(() => {
        this.$log.info('preload shared link preview items');
        dispatch('selection/loadItemPreview', ids[0].toUuid(), { root: true });
      }, 500);
    }
    const promises = [];
    const addToPaneObject: any = { windowId: ViewIdentifier.MAIN_VIEW, objectIds: ids };
    if (view && typeof view === 'string' && isValidViewType(view.toUpperCase())) {
      addToPaneObject.viewType = view.toUpperCase();
    }
    if (rootState.currentLayoutType === LayoutType.FILMSTRIP) {
      promises.push(dispatch('setActiveLayoutType', LayoutType.FILMSTRIP, { root: true }));
    }
    promises.push(
      dispatch('cloud/initializeViewMargin', ViewIdentifier.MAIN_VIEW, { root: true }),
      dispatch('cloud/addToPane', addToPaneObject, { root: true }),
      dispatch('cloud/initializeViewMargin', ViewIdentifier.SIDE_PANE, { root: true }),
      dispatch('magnify/initializeViewMargin', null, { root: true }),
      dispatch('selection/loadSelections', {}, { root: true })
    );
    await Promise.all(promises);
  },
  async loadRecentCloudObjects({ commit }: CloudContext): Promise<void> {
    const { data }: { data: { objectIds: [string] } } = await this.$api.get('/cloud-objects/recent/');
    const objectIds = data.objectIds.map(cloudObjectId => new ObjectId(cloudObjectId));
    commit('initializeRecentObjectIds', objectIds, { root: true });
  },
  async loadCustomCloudObjects({ commit }: CloudContext): Promise<void> {
    const { data }: { data: { objectIds: [string] } } = await this.$api.get('/cloud-objects/custom/');
    const objectIds = data.objectIds.map(cloudObjectId => new ObjectId(cloudObjectId));
    commit('initializeCustomObjectIds', objectIds, { root: true });
  },
  loadStaleObjects({ dispatch }: CloudContext, objectIds: ObjectId[]) {
    for (const objectId of objectIds) {
      if (objectId.isSelectionId) {
        dispatch('selection/refreshStaleSelection', objectId.toUuid(), { root: true });
      } else if (objectId.isFolderId) {
        dispatch('folder/refreshStaleFolder', objectId.toUuid(), { root: true });
      }
    }
  },
  async updateItemName({ rootGetters, commit, dispatch }: CloudContext, {
    itemId,
    name,
  }: { itemId: string, name: string }) {
    const item = rootGetters['folder/itemById'](itemId);
    if (item != null && item.name !== name) {
      await dispatch('updateItemNameInternally', { itemId, name });
      commit('folder/buildRawItemChanges', {
        changeSet: { updates: [{ name }] } as ChangeSet<Item>,
        itemId,
      }, { root: true });
      await dispatch('folder/synchronizeRawItemChanges', {}, { root: true });
    }
  },
  rateItemsByItemId({ dispatch, getters }: CloudContext, { itemId, rating }: { itemId: string, rating: number }) {
    const selectionId = getters.view(ViewIdentifier.MAIN_VIEW).selectionId;
    const folderId = getters.view(ViewIdentifier.MAIN_VIEW).folderId;
    if (selectionId != null) {
      dispatch('selection/rateItemsByItemId', { selectionId, itemId, rating }, { root: true });
    } else {
      dispatch('folder/rateItemsByItemId', { folderId, itemId, rating }, { root: true });
    }
  },
  async updateItemNameInternally({ rootGetters, commit, dispatch }: CloudContext, {
    itemId,
    name,
  }: { itemId: string, name: string }) {
    const item = rootGetters['folder/itemById'](itemId);
    if (item != null && item.name !== name) {
      commit('folder/itemNameChange', { itemId, name }, { root: true });
      await dispatch('folder/applyItemDataChangesToFolderItems', [itemId], { root: true });
      await dispatch('selection/applyItemDataChangesToSelectionItems', [itemId], { root: true });
    }
  },
  updateName({ dispatch }: CloudContext, { cloudObject, name }: { cloudObject: CloudObject<Folder | Selection>, name: string }) {
    if (cloudObject?.object?.name !== name) {
      let newName = name;
      if (name.length < 3) {
        newName = name.padEnd(3, '_');
      }
      if (cloudObject?.isFolder) {
        dispatch('folder/updateName', { name: newName, folderId: cloudObject.objectId?.toUuid() }, { root: true });
      } else if (cloudObject?.isSelection) {
        dispatch('selection/updateSelectionName', { name: newName, selectionId: cloudObject.objectId.toUuid() }, { root: true });
      }
    }
  },
  setReviewFilter({ commit }: CloudContext, { windowId, reviewFilter }: { windowId: string, reviewFilter: ReviewFilter }) {
    const reviewFilterParams = reviewFilter.filter(f => ![FilterOption.SELECTION, FilterOption.UPLOAD_PENDING].includes(f)).join(',');
    this.$router.push({
      ...this.$router.currentRoute,
      query: {
        ...this.$router.currentRoute.query,
        'review-filter': reviewFilterParams,
      },
    });
    commit('setReviewFilter', { windowId, reviewFilter });
  },
  resetReviewFilter({ commit }: CloudContext, { windowId }: { windowId: string}) {
    this.$router.push({
      ...this.$router.currentRoute,
      query: { ...this.$router.currentRoute.query, 'review-filter': undefined },
    });
    commit('setReviewFilter', { windowId, reviewFilter: [] });
  },
  setCenteredItemInView({ commit, rootGetters }: CloudContext, position: number) {
    const views = rootGetters.isReviewMode ? [ViewIdentifier.MAIN_VIEW, ViewIdentifier.NAVIGATION_VIEW] : [ViewIdentifier.MAIN_VIEW];
    views.forEach((view) => {
      commit('setCenteredItemInView', { position, view });
    });
  },
  setBackgroundFit({ commit }: CloudContext, data: { windowId: string; backgroundFit: BackgroundFit }) {
    commit('setBackgroundFit', data);
  },
  toggleImmersionMode({ state, commit }: CloudContext) {
    const showInImmersionMode = !state.showInImmersionMode;
    localStorage.setItem('main.immersiveMode', showInImmersionMode.toString());
    commit('setImmersionMode', showInImmersionMode);
  },
  setIlluminationGrade({ commit }: CloudContext, grade) {
    localStorage.setItem('main.illuminationGrade', grade.toString());
    commit('setIlluminationGrade', grade);
  },
  setViewSortingOption({ commit }: CloudContext, data: { windowId: string; option: ViewSortingOption }) {
    localStorage.setItem('folder.sortOption', data.option);
    commit('setViewSortingOption', data);
  },
  setViewIds({ commit }: CloudContext, data: { windowId: string; objectIds: ObjectId[]}) {
    commit('setViewIds', data);
  },
  setBackgroundOpacity({ commit }: CloudContext, data: { windowId: string; opacity: number }) {
    if (data.windowId === ViewIdentifier.MAIN_VIEW) {
      localStorage.setItem(StorageKey.MAIN_VIEW_BACKGROUND_OPACITY, data.opacity.toString());
    } else if (data.windowId === ViewIdentifier.SIDE_PANE) {
      localStorage.setItem(StorageKey.SIDE_PANE_BACKGROUND_OPACITY, data.opacity.toString());
    }
    commit('setBackgroundOpacity', data);
  },
  setElementsOpacity({ commit }: CloudContext, data: { windowId: string; opacity: number }) {
    if (data.windowId === ViewIdentifier.MAIN_VIEW) {
      localStorage.setItem(StorageKey.MAIN_VIEW_ELEMENTS_OPACITY, data.opacity.toString());
    } else if (data.windowId === ViewIdentifier.SIDE_PANE) {
      localStorage.setItem(StorageKey.SIDE_PANE_ELEMENTS_OPACITY, data.opacity.toString());
    }
    commit('setElementsOpacity', data);
  },
  initializeViewMargin({ dispatch, state }: CloudContext, windowId: string) {
    const window = state.registeredWindows[windowId];
    dispatch('setMosaicMarginFactor', { windowId, marginFactor: window.viewOptions.mosaic.marginFactor });
    dispatch('setGridMarginFactor', { windowId, marginFactor: window.viewOptions.grid.marginFactor });
    dispatch('setMagnifyMarginFactor', { windowId, marginFactor: window.viewOptions.magnify.marginFactor });
  },
  setGridRowHeight: ({ state, commit }: CloudContext, data: { windowId: string; rowHeight: number }) => {
    localStorage.setItem('grid.rowHeight', data.rowHeight.toString());
    const window = state.registeredWindows[data.windowId];
    if (window.viewOptions.grid.margin > 1) {
      const rowHeightGrowthFactor = data.rowHeight / window.viewOptions.grid.rowHeight;
      const newColumnMargin = parseFloat((rowHeightGrowthFactor * window.viewOptions.grid.margin).toFixed(2));
      commit('setGridMargin', { windowId: data.windowId, margin: newColumnMargin });
    }
    commit('setGridRowHeight', data);
  },
  setGridMarginFactor: ({
    commit,
    state,
  }: CloudContext, data: { windowId: string; marginFactor: number }) => {
    const registeredWindow = state.registeredWindows[data.windowId];
    const gridViewOptions = registeredWindow.viewOptions.grid;
    if (data.marginFactor >= gridViewOptions.marginFactorMin && data.marginFactor <= gridViewOptions.marginFactorMax) {
      const { rowHeight } = gridViewOptions;
      // Map margin Values that make sense for the user to values we can render a nice view with
      const internalMarginFactor = convertGridUserMarginFactorToInternal(data.marginFactor);
      // Calculate new margin based on the size of the view, the row height and the new margin factor
      let estimatedColumns = window.innerWidth / rowHeight;
      estimatedColumns = estimatedColumns > 1 ? estimatedColumns : 1;
      const newMargin = (internalMarginFactor * window.innerWidth) / (estimatedColumns * internalMarginFactor + estimatedColumns + internalMarginFactor);
      localStorage.setItem('grid.marginFactor', data.marginFactor.toString());
      commit('setGridMarginFactor', data);
      commit('setGridMargin', { windowId: data.windowId, margin: newMargin });
    }
  },
  setMagnifyMarginFactor: ({ commit, state }: CloudContext, data: { windowId: string; marginFactor: number }) => {
    const registeredWindow = state.registeredWindows[data.windowId];
    const magnifyViewOptions = registeredWindow.viewOptions.magnify;
    if (data.marginFactor >= magnifyViewOptions.marginFactorMin && data.marginFactor <= magnifyViewOptions.marginFactorMax) {
      // Map margin Values that make sense for the user to values we can render a nice view with
      const internalMarginFactor = convertMagnifyUserMarginFactorToInternal(data.marginFactor);
      // Calculate new margin based on the size of the view, the row height and the new margin factor
      let estimatedColumns = window.innerWidth / window.innerHeight;
      estimatedColumns = estimatedColumns > 1 ? estimatedColumns : 1;
      const newMargin = (internalMarginFactor * window.innerWidth) / (estimatedColumns * internalMarginFactor + estimatedColumns + internalMarginFactor);
      localStorage.setItem('magnify.marginFactor', data.marginFactor.toString());
      commit('setMagnifyMarginFactor', data);
      commit('setMagnifyMargin', { windowId: data.windowId, margin: newMargin });
    }
  },
  setContactSheetMarginFactor: ({ commit, state }: CloudContext, data: { windowId: string; marginFactor: number }) => {
    const registeredWindow = state.registeredWindows[data.windowId];
    const contactSheetViewOptions = registeredWindow.viewOptions.contactSheet;
    if (data.marginFactor >= contactSheetViewOptions.marginFactorMin && data.marginFactor <= contactSheetViewOptions.marginFactorMax) {
      const newMargin = data.marginFactor * data.marginFactor;
      localStorage.setItem('contactSheet.marginFactor', data.marginFactor.toString());
      commit('setContactSheetMarginFactor', data);
      commit('setContactSheetMargin', { windowId: data.windowId, margin: newMargin });
    }
  },
  setMosaicMarginFactor: ({
    commit,
    state,
  }: CloudContext, data: { windowId: string; marginFactor: number }) => {
    const registeredWindow = state.registeredWindows[data.windowId];
    const mosaicViewOptions = registeredWindow.viewOptions.mosaic;
    if (data.marginFactor >= mosaicViewOptions.marginFactorMin && data.marginFactor <= mosaicViewOptions.marginFactorMax) {
      const { columnCount } = mosaicViewOptions;
      // Map margin Values that make sense for the user to values we can render a nice view with
      const internalMarginFactor = convertMosaicUserMarginFactorToInternal(data.marginFactor);
      // Calculate new margin based on the size of the view, the column count and the new margin factor
      const newMargin = (internalMarginFactor * window.innerWidth) / (columnCount * internalMarginFactor + columnCount + internalMarginFactor + 1);
      localStorage.setItem('mosaic.marginFactor', data.marginFactor.toString());
      commit('setMosaicMarginFactor', data);
      commit('setMosaicMargin', { windowId: data.windowId, margin: newMargin });
    }
  },
  setMosaicColumnCount: ({
    commit,
    state,
  }: CloudContext, data: { windowId: string; columnCount: number }) => {
    localStorage.setItem('mosaic.columnCount', data.columnCount.toString());
    const window = state.registeredWindows[data.windowId];
    if (window.viewOptions.mosaic.margin > 1) {
      const { columnCount, margin } = window.viewOptions.mosaic;
      const newMargin = (margin * (columnCount + 1)) / (data.columnCount + 1);
      const newMosaicMargin = parseFloat(newMargin.toFixed(2));
      commit('setMosaicMargin', { windowId: data.windowId, margin: newMosaicMargin });
    }
    commit('setMosaicColumnCount', data);
  },
  setContactSheetColumnCount: ({
    commit,
  }: CloudContext, data: { windowId: string; columnCount: number }) => {
    commit('setContactSheetColumnCount', data);
  },
  setMosaicColumnWidth: ({ commit }: CloudContext, data: { windowId: string; columnWidth: number }) => {
    const ceilWidth = Math.ceil(data.columnWidth);
    localStorage.setItem('mosaic.columnWidth', ceilWidth.toString());
    commit('setMosaicColumnWidth', { ...data, columnWidth: ceilWidth });
  },
  zoomIn({ state, commit, dispatch }: CloudContext, data: ActionPayload<MutantWindowPayload>) {
    const windowId = data.payload.windowId;
    const window = state.registeredWindows[windowId];
    const viewOptions = window.viewOptions;
    if (viewOptions.activeViewType === ViewType.MOSAIC) {
      if (viewOptions.mosaic.columnCount > viewOptions.mosaic.minColumnCount) {
        dispatch('setMosaicColumnWidth', { windowId, columnWidth: (viewOptions.mosaic.columnWidth * viewOptions.mosaic.columnCount) / (viewOptions.mosaic.columnCount - 1) });
        commit('setMosaicColumnCount', { windowId, columnCount: viewOptions.mosaic.columnCount - 1 });
      }
    } else if (viewOptions.activeViewType === ViewType.GRID && ((viewOptions.grid.rowHeight + viewOptions.grid.rowHeightInterval) <= viewOptions.grid.maxRowHeight)) {
      commit('setGridRowHeight', { windowId, rowHeight: viewOptions.grid.rowHeight + viewOptions.grid.rowHeightInterval });
    } else if (viewOptions.activeViewType === ViewType.CONTACT_SHEET) {
      if (viewOptions.contactSheet.columnCount > viewOptions.contactSheet.minColumnCount) {
        commit('setContactSheetColumnCount', { windowId, columnCount: viewOptions.contactSheet.columnCount - 1 });
      }
    }
  },
  zoomOut({ state, commit, dispatch }: CloudContext, data: ActionPayload<MutantWindowPayload>) {
    const windowId = data.payload.windowId;
    const window = state.registeredWindows[windowId];
    const viewOptions = window.viewOptions;
    if (viewOptions.activeViewType === ViewType.MOSAIC) {
      if (viewOptions.mosaic.columnCount < viewOptions.mosaic.maxColumnCount) {
        dispatch('setMosaicColumnWidth', { windowId, columnWidth: (viewOptions.mosaic.columnWidth * viewOptions.mosaic.columnCount) / (viewOptions.mosaic.columnCount + 1) });
        commit('setMosaicColumnCount', { windowId, columnCount: viewOptions.mosaic.columnCount + 1 });
      }
    } else if (viewOptions.activeViewType === ViewType.GRID && ((viewOptions.grid.rowHeight - viewOptions.grid.rowHeightInterval) >= viewOptions.grid.minRowHeight)) {
      commit('setGridRowHeight', { windowId, rowHeight: viewOptions.grid.rowHeight - viewOptions.grid.rowHeightInterval });
    } else if (viewOptions.activeViewType === ViewType.CONTACT_SHEET) {
      if (viewOptions.contactSheet.columnCount < viewOptions.contactSheet.maxColumnCount) {
        commit('setContactSheetColumnCount', { windowId, columnCount: viewOptions.contactSheet.columnCount + 1 });
      }
    }
  },
  setColorFilter(context: CloudContext, data: { windowId: string; color: number[] }) {
    context.commit('setColorFilter', data);
  },
  changeView({ getters, commit, dispatch, rootGetters }: CloudContext, data: { windowId: string; view: ViewType }) {
    const currentViewType = getters.view(data.windowId).viewOptions.activeViewType;
    if (currentViewType !== data.view) {
      const items = getters.viewItemsMap[data.windowId].items;
      if (items.length > 0) {
        dispatch('highlightItem', { originView: data.windowId, item: items[0], targetView: data.windowId, scroll: true });
        commit('setCenteredItemInView', { position: 0, view: data.windowId });
      }
    }
    commit('viewChanged', data);
    if (data.view === ViewType.MOODBOARD) {
      const selectionId = rootGetters['cloud/view'](data.windowId).selectionId;
      const moodboardSelectionItems: SelectionItem[] = rootGetters['selection/selectionItemsById'](selectionId);
      // TODO: check for selection owner
      if (rootGetters['selection/paneView'].isEmpty && moodboardSelectionItems && moodboardSelectionItems?.length && !moodboardSelectionItems?.some(i => i.position?.x !== null)) {
        const objectId = selectionId ? ObjectId.fromSelectionId(selectionId) : ObjectId.forGlobalSelection();
        dispatch('selection/addToPane', { windowId: ViewIdentifier.SIDE_PANE, objectIds: [objectId] }, { root: true });
        dispatch('changeView', { windowId: ViewIdentifier.SIDE_PANE, view: ViewType.MOSAIC });
        dispatch('selection/open', null, { root: true });
      }
    }
    if (data.windowId === ViewIdentifier.MAIN_VIEW) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('view', data.view.toString().toLowerCase());
      history.pushState(
        {},
        null,
        `${window.location.pathname}?${queryParams.toString()}`
      );
    }
  },
  highlightItem({ commit }: CloudContext, highlightInfo: HighlightInfo) {
    commit('setHighlightInfo', highlightInfo);
  },
  broadcastHighlightInfo(_context: CloudContext, highlightInfo: HighlightInfo) {
    const publishInfo = {
      itemId: highlightInfo.item?.item?.id,
      viewId: highlightInfo.originView,
      originViewId: highlightInfo.originView,
      targetViewId: highlightInfo.targetView,
    };
    this.$socket.emit(SocketActions.HIGHLIGHT_ITEM, publishInfo);
  },
  removeItemHighlight(context: any) {
    context.commit('setHighlightInfo', null);
  },
  downloadActiveContent({ dispatch, getters, rootGetters }: CloudContext, windowId: string) {
    const zipFolderName = rootGetters['cloud/view'](windowId).name;
    const highResAssets = getters.viewAssetsByVersion(windowId, [ORIGINAL_ASSET_VERSION, CUSTOM_SUB_VERSION]);
    dispatch('file/downloadAssets', {
      assets: highResAssets,
      zipFolderName,
    }, { root: true });
  },
  async preloadObjectIds({ dispatch, rootGetters }: CloudContext, objectIds: ObjectId[]) {
    const promises = [];
    for (const objectId of objectIds) {
      const uuid = objectId.toUuid();
      if (objectId.isSelectionId) {
        if (!rootGetters['selection/selectionItemsById'](uuid)?.length) {
          promises.push(dispatch('selection/loadSelection', uuid, { root: true }));
        }
      } else if (objectId.isFolderId) {
        if (!rootGetters['folder/folderItemsById'](uuid)?.length) {
          promises.push(dispatch('folder/loadFolder', uuid, { root: true }));
        }
      }
    }
    await Promise.all(promises);
  },
  applyDefaultView({ commit, rootGetters }: CloudContext, data: { windowId: string, objectIds: ObjectId[] }) {
    const objectId = data.objectIds[0];
    const uuid = objectId.toUuid();
    const defaultView = objectId.isSelectionId
      ? rootGetters['selection/selectionsById'](uuid)?.viewType
      : rootGetters['folder/folderById'](uuid)?.viewType || ViewType.MOSAIC;
    if (defaultView) {
      commit('viewChanged', { windowId: data.windowId, view: defaultView });
    }
  },
  async extractOriginals({ dispatch, getters }: CloudContext, { files, extractRawFiles }: { extractRawFiles: boolean, files: File[] }) {
    const objectId = getters.view(ViewIdentifier.MAIN_VIEW).objectIds[0];
    const objectName = getters.cloudObject(objectId)?.object?.name ?? 'collection';
    const zipFolderName = `${objectName}-extraction`;
    const JSZip: JSZip = this.$jsZip.createInstance();
    const zipFolder = JSZip.folder(zipFolderName);
    const itemsMap = new Map<string, Item>();
    const items: ItemWithPosition[] = getters.currentViewItems(ViewIdentifier.MAIN_VIEW);
    const extract = (isFileMatching: File.isFileMatching) => (file: File) => {
      if (isFileMatching(itemsMap, file)) {
        const { name } = File.getFileMetaData(file);
        zipFolder.file(file.name, file.arrayBuffer());
        itemsMap.delete(name);
      }
    };
    items.forEach(item => itemsMap.set(File.removeFileExtension(item.item.name), item.item));
    if (extractRawFiles) {
      files.forEach(extract(File.isRawFileMatching));
      if (itemsMap.size > 0) {
        files.forEach(extract(File.isJpegFileMatching));
      }
    } else {
      files.forEach(extract(File.isJpegFileMatching));
    }

    if (Object.keys(zipFolder.files).length > 1) {
      const content = await JSZip.generateAsync({ type: 'blob' });
      this.$fileSaver.saveAs(content, `mutant-${zipFolderName}.zip`);
    }
    if (itemsMap.size > 0) {
      const unmatchedItems = [...itemsMap.keys()].join(', ');
      dispatch<ActionPayload<Notification>>({
        type: 'setNotificationMessage',
        payload: { message: `Could not extract images: ${unmatchedItems}`, type: NotificationType.ERROR },
      },
      { root: true });
    }
    JSZip.remove(zipFolderName);
  },
  async downloadSnapshotOfView(context: any, windowId: string) {
    const activeView = context.getters.view(windowId);
    const activeViewItems = context.getters.currentViewItems(windowId);
    // TODO: get real view height without scroll area
    const viewElement = document.getElementById(windowId);
    const viewWidth = viewElement.clientWidth;
    let viewHeight = viewElement.clientHeight;
    // TODO: generalize view generation based on view options
    let calculatedView;
    const viewType = activeView.viewOptions.activeViewType;
    const backgroundAsset = viewType === ViewType.MOODBOARD && activeView.backgrounds?.length ? getLargestThumbnailAsset(activeView.backgrounds) : null;
    if (viewType === ViewType.MOSAIC) {
      calculatedView = new MosaicBuilder()
        .setColumnCount(activeView.viewOptions.mosaic.columnCount)
        .setItems(activeViewItems)
        .setMarginBetweenItems(activeView.viewOptions.mosaic.margin)
        .setViewWidth(viewWidth)
        .build();
    } else if (viewType === ViewType.GRID) {
      calculatedView = new GridBuilder()
        .setRowHeight(activeView.viewOptions.grid.rowHeight)
        .setItems(activeViewItems)
        .setMarginBetweenItems(activeView.viewOptions.grid.margin)
        .setViewWidth(viewWidth)
        .build();
    } else if (viewType === ViewType.HORIZONTAL) {
      calculatedView = new HorizontalViewBuilder()
        .setItemHeight(viewHeight)
        .setItems(activeViewItems)
        .setItemSpacing(activeView.viewOptions.magnify.margin)
        .build();
    } else if (viewType === ViewType.MOODBOARD) {
      viewHeight = backgroundAsset ? viewWidth / backgroundAsset.width * backgroundAsset.height : viewHeight;
      let builder = new MoodboardBuilder()
        .setViewWidth(viewWidth)
        .setViewHeight(viewHeight)
        .setBackgroundFit(BackgroundFit.CONTAIN)
        .setItems(activeViewItems);
      if (backgroundAsset) {
        builder = builder.setBackgroundDimensions({
          width: backgroundAsset.width,
          height: backgroundAsset.height,
        })
          .setBackgroundFit(activeView.backgroundFit);
      }
      calculatedView = builder.build();
    }
    const snapshot = await this.$snapshotter.generateSnapshot(calculatedView, { width: viewWidth, height: viewHeight }, backgroundAsset);
    this.$fileSaver.saveAs(snapshot, `${activeView.name}-${viewType.toLowerCase()}.png`);
  },
  async addToPane({ state, commit, dispatch, getters, rootGetters, rootState }: CloudContext, paneData: { windowId: string; objectIds: ObjectId[], setDefaultView: boolean, viewType: ViewType, preload: boolean }) {
    const preload = paneData.preload ?? true;
    const data = { windowId: paneData.windowId, objectIds: paneData.objectIds, setDefaultView: paneData.setDefaultView, viewType: paneData.viewType };
    const windowId = data.windowId || state.activeWindowId;
    const objectIds = data.objectIds.filter(o => o != null);
    dispatch('addMultipleToRecentObjectIds', objectIds, { root: true });
    const updatedWindow: MutantWindow = getters.window(windowId);
    const isNewCloudObject = updatedWindow.viewIds[0] != null && updatedWindow.viewIds[0].toUuid() !== objectIds[0].toUuid();
    const paneContentIsReplaced = data.setDefaultView || (data.objectIds.some(id => id.isFolderId) && updatedWindow.viewOptions.activeViewType === ViewType.MOODBOARD);
    // Set default view if pane content is replaced
    if (rootGetters.isMobile) {
      if (rootGetters.isLandscape) {
        updatedWindow.viewOptions.activeViewType = ViewType.HORIZONTAL;
      } else {
        updatedWindow.viewOptions.activeViewType = ViewType.MOSAIC;
      }
    } else if (paneContentIsReplaced) {
      updatedWindow.viewOptions.activeViewType = getters.defaultViewByObjectId(data.objectIds[0]);
    } else if (data.viewType != null) {
      updatedWindow.viewOptions.activeViewType = data.viewType;
    }
    commit('setWindow', { ...updatedWindow, viewIds: data.objectIds });
    // Mirror content for magnify and overlay panes
    if (windowId === ViewIdentifier.MAIN_VIEW) {
      commit('setViewIds', { ...data, windowId: ViewIdentifier.NAVIGATION_VIEW });
      commit('setViewIds', { ...data, windowId: ViewIdentifier.OVERLAY_VIEW });
    }
    // Redundant isSharedLinkUrl call because we have to set the shared link state after the navigation (otherwise the
    // state will be wrong
    if ((rootGetters['user/isUser'] || isSharedLinkUrl()) && preload) {
      // We use then and not await because we don't want to block the other initializations
      dispatch('preloadObjectIds', objectIds).then(() => {
        const items = getters.viewItemsMap[data.windowId]?.items;
        if (items?.length > 0) {
          dispatch('highlightItem', { originView: data.windowId, item: items[0], targetView: data.windowId, scroll: true });
          commit('setCenteredItemInView', { position: 0, view: data.windowId });
        }
      });
    }
    // We only switch or update cloud route for main view
    // TODO: split addToPane action into two separate actions for data and routing
    if (windowId === ViewIdentifier.MAIN_VIEW) {
      const objectIdStrings = objectIds.map(o => o.toString()).join(',');
      const viewType = updatedWindow.viewOptions.activeViewType.toLowerCase();
      if (this.$router.currentRoute.name === 'cloud-id') {
        const url = `/cloud/${objectIdStrings}`;
        const queryParams = new URLSearchParams(window.location.search);
        if (isNewCloudObject) {
          queryParams.delete('shared-link-id');
          queryParams.delete('review-filter');
        }
        queryParams.set('view', viewType);
        history.pushState({}, null, `${url}?${queryParams.toString()}`);
        if (rootGetters.isMobile) {
          dispatch('initializeMobileSizes', {}, { root: true });
        }
      } else {
        await this.$router.push({ name: 'cloud-id', params: { id: objectIdStrings }, query: { view: viewType } });
      }
    }
    commit('sharedLinkRoute', isSharedLinkUrl(), { root: true });
    if (rootGetters.isSharedLink) {
      commit('setMinimalView', true, { root: true });
    } else {
      commit('setMinimalView', false, { root: true });
    }
    if (getters.folderTagFilterHasEntries) {
      commit('clearFolderTags', { windowId: ViewIdentifier.MAIN_VIEW, tagState: TagState.CLEAR_TAGS });
    }
  },
  resetAllViewFilters({ commit }: CloudContext) {
    commit('setReviewFilterState', { windowId: ViewIdentifier.MAIN_VIEW, active: false });
    commit('clearFolderTags', { windowId: ViewIdentifier.MAIN_VIEW, tagState: TagState.CLEAR_TAGS });
    commit('setReviewFilter', { windowId: ViewIdentifier.MAIN_VIEW, reviewFilter: [] });
  },
  navigateView({ state, commit, getters }: CloudContext, instructions: NavigationInstructions) {
    const currentPosition = state.centeredItemInView[instructions.view] ?? 0;
    const items = getters.currentViewItems(instructions.view);
    let moveFactor = 1;
    const viewOptions = getters.view(instructions.view).viewOptions;
    if (viewOptions.activeViewType === ViewType.MOSAIC) {
      moveFactor = viewOptions.mosaic.columnCount;
    } else if (viewOptions.activeViewType === ViewType.CONTACT_SHEET) {
      moveFactor = viewOptions.contactSheet.columnCount;
    }
    let nextPosition = currentPosition;
    if (instructions.direction === NavigationInstructionDirection.LEFT && (currentPosition - moveFactor >= 0)) {
      nextPosition = nextPosition - moveFactor;
    }
    if (instructions.direction === NavigationInstructionDirection.RIGHT && (currentPosition + moveFactor <= items.length - 1)) {
      nextPosition = nextPosition + moveFactor;
    }
    let highlightItem = items[nextPosition];
    if (highlightItem == null && items?.length > 0 && nextPosition > items.length - 1) {
      highlightItem = items[items.length - 1];
      nextPosition = items.length - 1;
    }
    if (highlightItem != null) {
      commit('setCenteredItemInView', { position: nextPosition, view: instructions.view });
      commit('setHighlightInfo', {
        originView: null,
        targetView: instructions.view,
        item: highlightItem,
        scroll: true,
      });
    }
  },
  navigateToStart({ commit, getters }: CloudContext, view: ViewIdentifier) {
    const items = getters.currentViewItems(view);
    const startPosition = 0;
    const highlightItem = items[startPosition];
    if (highlightItem) {
      commit('setCenteredItemInView', { position: startPosition, view });
      commit('setHighlightInfo', {
        originView: null,
        targetView: view,
        item: highlightItem,
        scroll: true,
      });
    }
  },
  navigateToEnd({ commit, getters }: CloudContext, view: ViewIdentifier) {
    const items = getters.currentViewItems(view);
    const endPosition = items.length - 1;
    const highlightItem = items[endPosition];
    if (highlightItem) {
      commit('setCenteredItemInView', { position: endPosition, view });
      commit('setHighlightInfo', {
        originView: null,
        targetView: view,
        item: highlightItem,
        scroll: true,
      });
    }
  },
  async copySharedLink({ dispatch, rootGetters }: CloudContext, objectId: ObjectId) {
    const linkData = rootGetters['link/linkData'](objectId.toString());
    if (!linkData?.accessId) {
      if (objectId.isFolderId) {
        await dispatch('folder/loadSharedLinks', objectId.toUuid(), { root: true });
      } else {
        await dispatch('selection/loadSharedLinks', objectId.toUuid(), { root: true });
      }
    }
    await dispatch('link/copyToClipboard', linkData?.accessId, { root: true });
  },
  setFirstItemCentered({ rootGetters, getters, commit }: CloudContext) {
    if (rootGetters.isReviewMode) {
      setTimeout(() => {
        const filteredItems = getters.viewItemsMap[ViewIdentifier.MAIN_VIEW].items;
        if (filteredItems.length > 0) {
          commit('setHighlightInfo', {
            originView: null,
            targetView: ViewIdentifier.MAIN_VIEW,
            item: filteredItems[0],
            scroll: true,
          });
        }
        commit('setCenteredItemInView', { position: 0, view: ViewIdentifier.MAIN_VIEW });
      });
    }
  },
  toggleTag({ commit, getters }: CloudContext, { windowId, tag, isAdditionalMode }: { windowId: ViewIdentifier; tag: FolderTagHierarchy; isAdditionalMode: boolean; }) {
    const isTagSelected = getters.folderTagFilter.tags.some(t => t.id === tag.tag.id);
    if (isAdditionalMode) {
      if (isTagSelected) {
        commit('removeTagFromSelectedTags', { windowId, tag });
      } else {
        commit('addTagsToSelectedTags', { windowId, tag });
      }
    } else if (isTagSelected) {
      commit('deSelectAllTags', windowId);
    } else {
      commit('selectTag', { windowId, tag });
    }
    const stillSomeTagsSelected = getters.folderTagFilter.tags?.length > 0;
    if (stillSomeTagsSelected) {
      commit('setReviewFilterState', { windowId, active: true });
    }
  },
  async fixStaleItemPositions({ getters, dispatch }: CloudContext, windowId: ViewIdentifier) {
    const objectId = getters.view(windowId).objectIds[0];
    if (objectId != null) {
      if (objectId.isFolderId) {
        await dispatch('folder/fixStaleItemPositions', objectId.toUuid(), { root: true });
        await dispatch('folder/synchronizeFolders', null, { root: true });
      } else if (objectId.isSelectionId) {
        await dispatch('selection/fixStaleItemPositions', objectId.toUuid(), { root: true });
      }
      // we need to synchronize potentially impacted selections in both cases
      await dispatch('selection/synchronizeSelections', null, { root: true });
    }
  },
};

export default actions;

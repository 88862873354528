import _uniqBy from 'lodash.uniqby';
import _uniq from 'lodash.uniq';
import _merge from 'lodash.merge';
import _cloneDeep from 'lodash.clonedeep';
import _keyBy from 'lodash.keyby';
import _values from 'lodash.values';

export interface ChangeSet<T> {
  inserts?: T[];
  updates?: Partial<T>[];
  removals?: string[];
}

export const MAX_SYNC_BATCH_SIZE = 250;

// Keep track of performance (may be an issue with large ChangeSets)
export function mergeChangeSet(oldChangeSet: ChangeSet<any> = {}, newChangeSet: ChangeSet<any> = {}) {
  const allRemovals = _uniq((oldChangeSet.removals || []).concat(newChangeSet.removals || []));
  const removals = oldChangeSet.inserts
    ? _cloneDeep(allRemovals.filter(removalId => !oldChangeSet.inserts.some(insert => insert.id === removalId)))
    : allRemovals;
  let inserts = _uniqBy([...(oldChangeSet.inserts || []), ...(newChangeSet.inserts || [])], 'id')
    .filter(resource => !allRemovals.includes(resource.id));
  inserts = newChangeSet.updates
    ? inserts.map(insert => {
      const update = newChangeSet.updates.find(u => u.id === insert.id);
      return update ? _merge(insert, update) : insert;
    })
    : inserts;
  const updates = _values(_merge(_keyBy((oldChangeSet.updates || []), 'id'), _keyBy((newChangeSet.updates || []), 'id')))
    .filter(update => !allRemovals.includes(update.id))
    .filter(update => !(oldChangeSet.inserts && oldChangeSet.inserts.some(insert => insert.id === update.id)));
  const result: ChangeSet<any> = {};
  if (removals.length) {
    result.removals = removals;
  }
  if (inserts.length) {
    result.inserts = inserts;
  }
  if (updates.length) {
    result.updates = updates;
  }
  return Object.keys(result).length ? result : null;
}

import { AggregatedEvents } from '~/models/socket/aggregateEvents';
import { SelectionUpdatedEventData } from '~/models/socket/events/SelectionUdpatedEventData';
import { mergeChangeSet } from '~/models/ChangeSet';
import { SocketEvent } from '~/models/socket/events/SocketEvent';

export function aggregateSelectionUpdatedEvent(agg: AggregatedEvents, curr: SocketEvent<SelectionUpdatedEventData>): AggregatedEvents {
  if (agg.selectionUpdatedEvents.some(event => event.data.id === curr.data.id)) {
    agg.selectionUpdatedEvents = agg.selectionUpdatedEvents.map(event => {
      if (event.data.id === curr.data.id) {
        event.data = {
          ...event.data,
          ...curr.data,
          items: event.data.items && curr.data.items ? mergeChangeSet(event.data.items, curr.data.items) : event.data.items,
        };
      }
      return event;
    });
  } else {
    agg.selectionUpdatedEvents.push(curr);
  }
  return agg;
}

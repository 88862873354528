import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { FolderItem } from '~/models/item/FolderItem';

export function groupItemsByFolder(items: ItemWithPosition[]): ItemWithPosition[][] {
  return items.reduce((aggregate: ItemWithPosition[][], itemWithPosition: ItemWithPosition) => {
    const folderIdOfCurrentItem = itemWithPosition.item?.folderId || (itemWithPosition as FolderItem).folderId;
    if (!aggregate.length || !aggregate.some(groupedItems => groupedItems.some(gi => (gi.item?.folderId || (gi as FolderItem).folderId) === folderIdOfCurrentItem))) {
      aggregate.push([itemWithPosition]);
    } else {
      aggregate = aggregate.map(groupedItems => {
        if (groupedItems[0].item?.folderId || (groupedItems[0] as FolderItem).folderId === folderIdOfCurrentItem) {
          groupedItems.push(itemWithPosition);
        }
        return groupedItems;
      });
    }
    return aggregate;
  }, []);
}

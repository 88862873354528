import { fabric } from 'fabric';
import { ImageLoader } from '~/models/ImageLoader';
import { Asset } from '~/models/Asset';
import { MutantView } from '~/models/views/MutantView';
import { ViewType } from '~/models/views/ViewType';
import Snippet from '~/models/Snippet';

export class Snapshotter {
  constructor(private imageLoader: ImageLoader) {}

  // TODO: We should make a snapshot of the actual VISIBLE area, since this breaks for larger scroll views (e.g. horizontal view with more than ~10 items)
  public async generateSnapshot(
    view: MutantView,
    size: { width: number; height: number },
    backgroundAsset: Asset
  ): Promise<Blob> {
    const canvasElement = document.createElement('canvas');
    canvasElement.width = size.width;
    canvasElement.height = size.height;
    const canvas = new fabric.StaticCanvas(canvasElement);
    if (view.type === ViewType.MOODBOARD && backgroundAsset) {
      const backgroundBase64 = await this.imageLoader.loadAssetUrl(
        null,
        backgroundAsset
      );
      const backgroundImage = await this.createImage({
        width: size.width,
        height: size.height,
        left: 0,
        top: 0,
        src: backgroundBase64,
        opacity: 1,
        rotation: 0,
      });
      canvas.add(backgroundImage);
    }
    const snippetsSortedByDepthIndex = this.sortSnippetsByZindexAsc(view.snippets);
    for (const snippet of snippetsSortedByDepthIndex) {
      const imageData = await this.imageLoader.loadVersionByWidthInBase64(
        snippet.item.itemData,
        snippet.item.viewPosition.width
      );
      const fabricImage = await this.createImage({
        width: snippet.item.viewPosition.width,
        height: snippet.item.viewPosition.height,
        top: snippet.item.viewPosition.top,
        left: snippet.item.viewPosition.left,
        rotation:
          view.type === ViewType.MOODBOARD
            ? snippet.item.item.position.rotation || 0
            : 0,
        opacity: 1,
        src: imageData,
      });
      canvas.add(fabricImage);
    }
    canvas.renderAll();
    return this.dataURItoBlob(canvas.toDataURL({}));
  }

  private async createImage({
    width,
    height,
    src,
    top,
    left,
    rotation,
    opacity,
  }: {
    width: number;
    height: number;
    src: string;
    top: number;
    left: number;
    rotation: number;
    opacity: number;
  }): Promise<any> {
    const imageElement = await this.imageLoader.loadImageElement(src);
    const img = new fabric.Image(imageElement, {
      left: left + width / 2,
      top: top + height / 2,
      originX: 'center',
      originY: 'center',
      opacity,
      // TODO: maybe refactor view model to contain information about base shadow size / shadow calculation
      //  context: we want the same item shadows on the canvas as we do have in our live views
      shadow: new fabric.Shadow({
        offsetX: 10,
        offsetY: 10,
        blur: 10,
        color: '#000',
      }),
    });
    img.scaleToWidth(width);
    if (rotation != null) {
      img.rotate(rotation);
    }
    return img;
  }

  private sortSnippetsByZindexAsc(snippets: Snippet[]): Snippet[] {
    return [...snippets].sort((a, b) =>
      a.item.zindex < b.item.zindex ? -1 : 1
    );
  }

  private dataURItoBlob(dataURI) {
    // https://stackoverflow.com/questions/12168909/blob-from-dataurl
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
}

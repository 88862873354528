export enum MenuEntry {
  MUTANT_ALPHA = 'MUTANT ALPHA',
  MUTANT_BETA = 'MUTANT BETA',
  MUTANT_X = 'MUTANT X',
  CONTACT = 'CONTACT',
  PRICING = 'PRICING',
  HIRING = 'HIRING'
}

export const navigationEntryToRoute
  = [
    { menuEntry: MenuEntry.MUTANT_ALPHA, route: 'alpha' },
    { menuEntry: MenuEntry.MUTANT_BETA, route: 'beta' },
    { menuEntry: MenuEntry.CONTACT, route: 'contact' },
    { menuEntry: MenuEntry.HIRING, route: 'hiring' },
    { menuEntry: MenuEntry.MUTANT_X, route: 'mutant-x' },
  ];

export function mapBetaMenuToRoute(menuEntry: MenuEntry): string {
  return navigationEntryToRoute.find(n => n.menuEntry === menuEntry)?.route;
}

export function mapRouteToBetaMenu(route: string): MenuEntry {
  return navigationEntryToRoute.find(n => n.route === route)?.menuEntry;
}

import { v4 as uuid } from 'uuid';
import { TaggedFile } from '~/models/tags/TaggedFile';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { FolderTag } from '~/models/tags/FolderTag';
import { FolderStructure } from '~/models/file/FolderStructure';

interface WebkitFile extends File {
  webkitRelativePath: string;
}

export class FileParser {
  private static FILENAME_IGNORE_LIST = ['.DS_Store'];
  private static MAX_FOLDER_STRUCTURE_PARSING_DEPTH = 3;

  public static isDragEvent(event: DragEvent | InputEvent): event is DragEvent {
    return 'dataTransfer' in event;
  }

  constructor(private $log: any) {}

  public async parseFileFolderStructure(folderId: string, event: DragEvent | InputEvent): Promise<FolderStructure> {
    return this.filterDuplicateFiles(
      FileParser.isDragEvent(event)
        ? await this.parseFileStructureFromDragEvent(folderId, event)
        : this.parseFileStructureFromInputEvent(folderId, event)
    );
  }

  private async parseFileStructureFromDragEvent(folderId: string, event: DragEvent): Promise<FolderStructure> {
    const items = event.dataTransfer.items;
    if (items == null && event.dataTransfer.files != null) {
      return { files: Array.from(event.dataTransfer.files).map(file => ({ file, folderTagId: null })), uniqueFolderTags: [] };
    }
    if (typeof items[0].webkitGetAsEntry !== 'function') {
      // Note: webkitGetAsEntry is supported by all major browsers
      this.$log.warn('File tree traversal with "webkitGetAsEntry" is unsupported by the corresponding browser, fallback to normal file parsing');
      return { files: [], uniqueFolderTags: [] };
    }
    const folderTreeTraversals: Promise<FolderStructure>[] = [];
    for (const item of Array.from(items)) {
      folderTreeTraversals.push(this.traverseFileTreeForEntry(folderId, item.webkitGetAsEntry()));
    }
    const results = await Promise.all(folderTreeTraversals);
    const uniqueFolderTags = results.map(r => r.uniqueFolderTags).flat();
    const files = results.map(r => r.files).flat();
    return { files, uniqueFolderTags };
  }

  private parseFileStructureFromInputEvent(folderId: string, event: InputEvent): FolderStructure {
    const taggedFiles: TaggedFile[] = [];
    const uniqueDirectoryMap: Map<string, FolderTag> = new Map();
    const flatFiles = Array.from((event.target as HTMLInputElement)?.files || []);
    for (const element of flatFiles) {
      const file = element as WebkitFile;
      const filePath = file.webkitRelativePath;
      const directories = filePath.split('/').slice(0, -1);
      if (!FileParser.FILENAME_IGNORE_LIST.includes(file.name) && directories.length <= FileParser.MAX_FOLDER_STRUCTURE_PARSING_DEPTH) {
        const directoryPath = directories.join('/');
        if (uniqueDirectoryMap.has(directoryPath)) {
          taggedFiles.push({ file, folderTagId: uniqueDirectoryMap.get(directoryPath).id });
        } else {
          let folderTag;
          if (directoryPath.length > 0) {
            const folderName = directories.pop();
            const parentId = uniqueDirectoryMap.get(directories.join('/'))?.id;
            folderTag = { id: uuid(), name: folderName, folderId, parentId, isSynced: false };
            uniqueDirectoryMap.set(directoryPath, folderTag);
          }
          taggedFiles.push({ file, folderTagId: folderTag?.id });
        }
      }
    }
    return { files: taggedFiles, uniqueFolderTags: Array.from(uniqueDirectoryMap.values()) };
  }

  // When the user selects expanded folders + files (instead of collapsed folders + files), files within the sub folders are both present on the root level and within those sub folders.
  // e.g. in a structure like this:
  // fileA.jpg
  // fileB.jpg
  // folderA >
  //   fileC.jpg
  //   fileD.jpg
  //
  // when the user cmd + a selects all files, the files fileC and fileD are occurring twice, so we are filtering those duplicates out.
  // We check if a file on the root level has a duplicate (same name + exact size) within a given folder
  private filterDuplicateFiles(folderStructure: FolderStructure): FolderStructure {
    const { files, uniqueFolderTags } = folderStructure;
    const [rootFiles, filesWithFolderTags] = filterByConditions(files, f => f.folderTagId === null);
    if (rootFiles.length === 0 || filesWithFolderTags.length === 0) {
      return { files, uniqueFolderTags };
    }
    const filteredFiles = rootFiles
      .filter(file => !filesWithFolderTags.some(f => file.file.name === f.file.name && file.file.size === f.file.size))
      .concat(filesWithFolderTags);
    return { files: filteredFiles, uniqueFolderTags };
  }

  private async traverseFileTreeForEntry(folderId: string, entry: FileSystemEntry, tags: FolderTag[] = [], maxDepth = FileParser.MAX_FOLDER_STRUCTURE_PARSING_DEPTH, depth = 0): Promise<FolderStructure> {
    let files: TaggedFile[] = [];
    let uniqueFolderTags: FolderTag[] = [];
    if (entry == null) {
      return {
        uniqueFolderTags,
        files,
      };
    }
    if (entry.isFile) {
      const file: File = await new Promise(resolve => (entry as FileSystemFileEntry).file(resolve));
      if (!FileParser.FILENAME_IGNORE_LIST.includes(file.name)) {
        files.push({ file, folderTagId: tags.length ? tags[tags.length - 1].id : null });
      }
    } else if (entry.isDirectory) {
      if (depth < maxDepth) {
        const dirReader = (entry as FileSystemDirectoryEntry).createReader();
        const entries = [];
        let entryQueue = await new Promise(resolve => dirReader.readEntries(resolve)) as FileSystemEntry[];
        while (entryQueue?.length > 0) {
          entries.push(...entryQueue);
          entryQueue = await new Promise(resolve => dirReader.readEntries(resolve)) as FileSystemEntry[];
        }
        const folderTag = {
          id: uuid(),
          parentId: tags.length ? tags[tags.length - 1].id : null,
          folderId,
          name: entry.name,
          isSynced: false,
        };
        uniqueFolderTags.push(folderTag);
        for (const entry of entries) {
          const { uniqueFolderTags: folderTags, files: entryFiles } = await this.traverseFileTreeForEntry(folderId, entry, [...tags, folderTag], maxDepth, depth + 1);
          files = files.concat(entryFiles);
          uniqueFolderTags = uniqueFolderTags.concat(folderTags);
        }
      }
    }
    return {
      uniqueFolderTags,
      files,
    };
  }
}

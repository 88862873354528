export interface GridOptions {
  rowHeight: number;
  minRowHeight: number;
  maxRowHeight: number;
  rowHeightInterval: number;
  margin: number;
  marginFactor: number;
  marginFactorMin: number;
  marginFactorMax: number;
  marginInterval: number;
}

export const gridOptionsDefault = (): GridOptions => {
  return {
    // TODO: check collision with value from localstorage
    minRowHeight: 50,
    maxRowHeight: 500,
    rowHeightInterval: 25,
    // @ts-ignore
    rowHeight: localStorage.getItem('grid.rowHeight') ? parseInt(localStorage.getItem('grid.rowHeight'), 10) : 200,
    // @ts-ignore
    margin: 0,
    marginFactor: localStorage.getItem('grid.marginFactor') ? parseInt(localStorage.getItem('grid.marginFactor'), 10) : 0,
    marginFactorMin: 0,
    marginFactorMax: 10,
    marginInterval: 1,
  };
};

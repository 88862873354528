import moment from 'moment';
import _cloneDeep from 'lodash.clonedeep';
import {
  accumulateAssetSize,
  accumulateAssetSizeInBytes,
  accumulateBiggestAssetsSize,
  Asset,
  getAssetVersion,
  getLargestAsset,
  getLargestAssetBelowWidth, itemHasAssetVersions,
  ORIGINAL_ASSET_VERSION,
  transformToUnit
} from '~/models/Asset';
import { MutantContentView } from '~/models/views/MutantContentView';
import Selection from '~/models/selection/Selection';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { UnitSize } from '~/models/UnitSize';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { RootState } from '~/store/state';
import { ViewSortingOption } from '~/store/cloud/state';
import { SelectionState } from '~/store/selection/state';
import { GlobalSelection } from '~/models/selection/GlobalSelection';
import { SelectionCluster } from '~/models/selection/SelectionCluster';
import { clusterSelectionsByDate } from '~/models/selection/clusterSelectionsByDate';
import { FolderState } from '~/store/folder/state';
import Position from '~/models/Position';
import { sortItemsBySortingOption } from '~/models/item/sortItemsBySortingOption';
import { ObjectId } from '~/models/ObjectId';

export default {
  hasItemsSelected: (state: SelectionState) => {
    return state.globalSelectedItemsOrdered.length;
  },
  exists: (state: SelectionState) => (selectionId: string) => {
    return !!state.selections[selectionId];
  },
  positionMapForSelectionItems: (_state, getters: any) => (selectionId: string, itemIds: string, position: Partial<Position>): Map<string, Partial<Position>> => {
    const positionMap = new Map<string, Partial<Position>>();
    if (position) {
      let zindex = getters.highestZindexForSelection(selectionId);
      for (const itemId of itemIds) {
        zindex++;
        let randomRotation = randomInteger(-5, 5);
        if (randomRotation < 0) {
          randomRotation += 360;
        }
        positionMap.set(itemId, {
          ...position,
          rotation: position.rotation ? position.rotation : randomRotation,
          zindex,
        });
      }
    }
    return positionMap;
  },
  isSelectionOwner: (_state: SelectionState, getters: any, _rootState: RootState, rootGetters: any) => (selectionId: string) => {
    const selection = getters.selections[selectionId];
    const currentUser = rootGetters['user/currentUser'];
    return !!(selection && currentUser && selection.owner?.id === currentUser.id);
  },
  paneView: (_state: SelectionState, _getters: any, _rootState: RootState, rootGetters: any): MutantContentView => {
    const windowId = ViewIdentifier.SIDE_PANE;
    return rootGetters['cloud/view'](windowId);
  },
  hasSelectedItems: (_state: FolderState, getters: any) => (selectionId: string) => {
    return getters.isOneOfItemsSelected(getters.selectionItemsById(selectionId));
  },
  selectionsWithItems: (state: SelectionState): Selection[] => {
    return _cloneDeep(Object.values(state.selections)).map(s => ({
      ...s,
      items: _cloneDeep(state.selectionItems[s.id] || []),
    }));
  },
  selections: (state: SelectionState): { [selectionId: string]: Selection } => {
    return _cloneDeep(state.selections);
  },
  selectionsById: (_state: SelectionState, getters: any) => (id: string): { [selectionId: string]: Selection } => {
    return getters.selections[id];
  },
  selectionsByIdMap: (_state: SelectionState, getters: any): { [selectionId: string]: Selection } => {
    const obj = {};
    getters.selectionsWithItems.forEach((s) => {
      obj[s.id] = s;
    });
    return obj;
  },
  selectionWithItemsById: (_state: SelectionState, getters: any) => (id: string): Selection => {
    return getters.selectionsByIdMap[id];
  },
  itemWithHighestZindex: (_state: SelectionState, getters: any) => (id: string): ItemWithPosition => {
    const selectionItemsSortedByZindex = getters.selectionItemsById(id)
      .filter(i => i.position && i.position.zindex !== null)
      .sort((a, b) => a.position.zindex > b.position.zindex ? -1 : 1);
    return selectionItemsSortedByZindex.length && (selectionItemsSortedByZindex[0] || null);
  },
  highestZindexForSelection: (_state: SelectionState, getters: any) => (id: string): number => {
    const itemWithHighestZindex = getters.itemWithHighestZindex(id);
    return itemWithHighestZindex ? itemWithHighestZindex.position?.zindex || 0 : 0;
  },
  selectionItemsById: (state: SelectionState) => (id: string): ItemWithPosition[] => {
    return _cloneDeep(state.selectionItems[id] || []);
  },
  selectionItemsByIdSorted: (_state: SelectionState, getters: any) => (id: string, sortingOption: ViewSortingOption): ItemWithPosition[] => {
    const items = getters.selectionItemsById(id);
    return sortItemsBySortingOption(items, sortingOption);
  },
  selectionContainsItem: (_state: SelectionState, getters: any) => (selectionId: string, id: string) => {
    return getters.selectionItemsById(selectionId).some(item => item.id === id);
  },
  selectionFolderReference: (_state: SelectionState, getters: any, rootGetters: any) => (selectionId: string): string => {
    const selectionItems = getters.selectionItemsById(selectionId);
    if (selectionItems.length) {
      const folderIdRepresentationMap = selectionItems.map(i => i.item.folderId).reduce((aggregate, folderId) => {
        aggregate[folderId] = aggregate[folderId] ? aggregate[folderId]++ : 1;
        return aggregate;
      }, {});
      const entries = Object.entries(folderIdRepresentationMap);
      return entries.length ? entries.sort((a: [string, number], b: [string, number]) => a[1] > b[1] ? 1 : -1)[0][0] : null;
    }
    return rootGetters['folder/scrapbookId'];
  },
  isItemSelected: (state: SelectionState) => (item: ItemWithPosition): boolean => {
    return !!state.globalSelectedItems[item.id];
  },
  isOneOfItemsSelected: (state: SelectionState) => (items: ItemWithPosition[]): boolean => {
    return items?.some(i => !!state.globalSelectedItems[i.id]);
  },
  filterSelectedItems: (state: SelectionState) => (items: ItemWithPosition[]): ItemWithPosition[] => {
    return items.map(i => i).filter(i => !!state.globalSelectedItems[i.id]);
  },
  currentSelectionClusters: (state: SelectionState): SelectionCluster[] => {
    return clusterSelectionsByDate(Object.values(state.selections));
  },
  globalSelectionHasItems: (_state: SelectionState, getters: any) => {
    return getters.globalSelectionItems.length > 0;
  },
  globalSelection: (_state: SelectionState, _getters: any, _rootState: RootState, rootGetters): GlobalSelection => {
    return {
      name: 'Global Selection',
      owner: rootGetters['user/currentUser'],
    };
  },
  globalSelectionItemsByFolderId: (_state: SelectionState, getters) => (folderId: string): ItemWithPosition[] => {
    return getters.globalSelectionItems.filter(i => i.item.folderId === folderId);
  },
  globalSelectionItemsInMainView: (_state: SelectionState, getters): ItemWithPosition[] => {
    return getters.globalSelectionItemsInView(ViewIdentifier.MAIN_VIEW);
  },
  globalSelectionItemsInView: (_state: SelectionState, getters, _rootState: RootState, rootGetters) => (viewId: ViewIdentifier): ItemWithPosition[] => {
    const mainViewItems = rootGetters['cloud/viewItemsMap'][viewId].items;
    return getters.globalSelectionItems.filter(item => mainViewItems.some(i => item.id === i.id));
  },
  globalSelectionItemsInCurrentSelection: (state: SelectionState, getters) => (selectionId: string) => {
    return getters.selectionItemsById(selectionId).filter(item => !!state.globalSelectedItems[item.id]);
  },
  globalSelectionItemsInFolder: (_state: SelectionState, getters) => (folderId: string) => {
    return getters.globalSelectionItems.filter(item => item.folderId === folderId);
  },
  globalSelectionItems: (state: SelectionState): ItemWithPosition[] => {
    return _cloneDeep(state.globalSelectedItemsOrdered);
  },
  globalSelectionName: (_state: SelectionState, getters): string => {
    return getters.globalSelection ? getters.globalSelection.name : 'Selection';
  },
  globalSelectionOwner: (_state: SelectionState, getters): string => {
    return getters.globalSelection ? getters.globalSelection.owner : null;
  },
  selectionOwnerById: (_state: SelectionState, getters: any) => (selectionId: string): string => {
    const selection = getters.selections[selectionId];
    return selection ? selection.owner : null;
  },
  selectionsByLastModified: (state: SelectionState) => {
    return _cloneDeep(Object.values(state.selections)).sort((a: Selection, b: Selection) =>
      moment(a.modified).isBefore(moment(b.modified)) ? 1 : -1);
  },
  globalSelectionAssetsByVersion: (_state: SelectionState, getters) => (version: number): Asset[] => {
    return getters.globalSelectionItems
      .map(item => getAssetVersion(item.item, version))
      .filter(asset => asset != null);
  },
  globalSelectionAssetsByHighestVersion: (_state: SelectionState, getters): Asset[] => {
    return getters.globalSelectionItems
      .map(item => getLargestAsset(item.item?.assets))
      .filter(asset => asset != null);
  },
  filteredAssetsByWidth: (_state: SelectionState, getters) => (width: number): Asset[] => {
    return getters.globalSelectionItems
      .map(item => getLargestAssetBelowWidth(item, width))
      .filter(asset => asset !== null && asset !== undefined);
  },
  downloadSizeGlobalSelection: (_state: SelectionState, getters): UnitSize => {
    return accumulateAssetSize(getters.globalSelectionItems, [ORIGINAL_ASSET_VERSION]);
  },
  selectionItemCount: (_state: FolderState, getters: any) => (selectionId: string): number => {
    const items = getters.selectionItemsById(selectionId);
    return items?.length ?? 0;
  },
  selectionItemCountByVersion: (_state: SelectionState, getters: any) => (selectionId: string, version?: number[]): number => {
    const items = getters.selectionItemsById(selectionId)?.items?.filter(item => version ? itemHasAssetVersions(item, version) : item);
    return items?.length ?? 0;
  },
  selectionSizeInBytes: (_state: FolderState, getters: any) => (selectionId: string): number => {
    const items = getters.selectionItemsById(selectionId);
    return items?.length > 0 ? accumulateAssetSizeInBytes(items, [ORIGINAL_ASSET_VERSION]) : getters.selections[selectionId]?.sizeInCloud ?? 0;
  },
  sizeInCloudByIdBiggestAssets: (_state: SelectionState, getters: any) => (selectionId: string): UnitSize => {
    const items = getters.selectionItemsById(selectionId);
    return transformToUnit(items?.length > 0 ? accumulateBiggestAssetsSize(items) : (getters.selectionsById(selectionId)?.sizeInCloud ?? 0));
  },
  sizeInCloudById: (_state: SelectionState, getters: any) => (selectionId: string, version = ORIGINAL_ASSET_VERSION, includeOfflineItems = false): UnitSize => {
    const items = getters.selectionItemsById(selectionId);
    return items?.length > 0 ? accumulateAssetSize(items, [version], includeOfflineItems) : transformToUnit((getters.selectionsById(selectionId)?.sizeInCloud ?? 0));
  },
  sizeInCloudGlobalSelection: (_state: SelectionState, getters: any): UnitSize => {
    return accumulateAssetSize(getters.globalSelectionItems);
  },
  linkDataExists: (state: SelectionState, _getters, _rootState: RootState, rootGetters: any) => (selectionId): boolean => {
    const selection: Selection = state.selections[selectionId];
    return selection?.isShared && rootGetters['link/linkDataExists'](ObjectId.fromSelectionId(selectionId).toString());
  },
  sharedSelections: (_state: SelectionState, getters, _rootState: RootState, rootGetters: any): Selection[] => {
    return getters.selectionsWithItems
      .filter(s => s.isShared && s.owner.id === rootGetters['user/currentUser'].id)
      .sort((a, b) => moment(a.modified).isBefore(b.modified) ? 1 : -1);
  },
  sharedSelectionsWithMe: (_state: SelectionState, getters, _rootState: RootState, rootGetters: any): Selection[] => {
    return getters.selectionsWithItems
      .filter(s => s.isShared && s.owner.id !== rootGetters['user/currentUser'].id)
      .sort((a, b) => moment(a.modified).isBefore(b.modified) ? 1 : -1);
  },
};

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

import { Middleware } from '@nuxt/types';

const cloudMenu: Middleware = ({ store, route, from }: any) => {
  const isRouteToLandingPage = route.path === '/';
  if (from.query['detail-view']) {
    store.dispatch('detailView/close', { root: true });
  }
  if (isRouteToLandingPage) {
    if (store.state.menuOpen) {
      store.dispatch('closeMenu');
    }
  } else if (route.query.menu != null) {
    store.dispatch('openMenu', route.query.menu.toString().toLowerCase());
  }
};

export default cloudMenu;

import { User } from '~/models/user/User';
import { Device } from '~/models/Device';
import { orderedRoles, UserRole } from '~/models/user/UserRole';
import { BROWSER_DEVICE_NAME } from '~/store/user/actions';
import { UserState } from '~/store/user/state';
import { Guest } from '~/models/user/Guest';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { getCookieValueFor } from '~/models/Cookie';
import { CookieKey } from '~/models/CookieKey';
import { UserDetails } from '~/models/user/UserDetails';

export default {
  currentUser: (state: UserState): User | Guest => {
    return state.user ? state.user : { username: 'guest', roles: [UserRole.GUEST] };
  },
  userDetails: (state: UserState): UserDetails => {
    return state.userDetails;
  },
  isGuest: (_state: UserState, getters: any): boolean => {
    return getters.currentUser.roles?.includes(UserRole.GUEST) ?? false;
  },
  isUser: (_state: UserState, getters: any): boolean => {
    return getters.currentUser.roles?.some((role: UserRole) => [UserRole.USER].includes(role)) ?? false;
  },
  isProUser: (_state: UserState, getters: any): boolean => {
    return getters.currentUser.roles?.some(
      role => [
        UserRole.ALPHA_USER,
        UserRole.ADMIN,
        UserRole.PRO_USER,
        UserRole.BETA_USER].includes(role)) ?? false;
  },
  isUserWithoutDataLimitation: (_state: UserState, getters: any): boolean => {
    return getters.currentUser.roles?.some(
      role => [
        UserRole.ADMIN,
        UserRole.PRO_USER].includes(role)) ?? false;
  },
  isUserWithDataLimitation: (_state: UserState, getters: any): boolean => {
    return !getters.isUserWithoutDataLimitation;
  },
  isAlphaUser: (_state: UserState, getters: any): boolean => {
    return getters.currentUser.roles?.includes(UserRole.ALPHA_USER) ?? false;
  },
  isNonPayingUser: (_state: UserState, getters: any): boolean => {
    return !getters.isUser;
  },
  supremeRoleName: (_state: UserState, getters: any): string => {
    return orderedRoles.find(item => getters.currentUser?.roles.includes(item?.role))?.name;
  },
  isAdmin: (_state: UserState, getters: any): boolean => {
    return getters.currentUser.roles.includes(UserRole.ADMIN);
  },
  isAccountUpgradeOptionGiven: (_state: UserState, getters: any): boolean => {
    return getters.isAdmin;
  },
  browserDevice: (state: UserState): Device | null => {
    return state.devices.find(d => d.name === BROWSER_DEVICE_NAME);
  },
  isOwnerOfCurrentFolder: (state: UserState, _getters: any, _rootState: any, rootGetters: any) => (windowId: ViewIdentifier): boolean => {
    const ownerId = rootGetters['cloud/currentFolder'](windowId)?.owner?.id;
    return (state.user && (state.user.id === ownerId
      || rootGetters['cloud/currentFolder']?.isPublicWritable))
      || (
        state.user == null && ownerId == null
      );
  },
  isTokenExpiring: (_state: UserState) => (): boolean => {
    const expiresAt = getCookieValueFor(CookieKey.EXPIRES_AT);
    const expiresAtParsed = new Date(parseInt(expiresAt, 10)).getTime();
    const now = Math.floor(new Date().getTime());
    return now + 30_000 > expiresAtParsed;
  },
  isUploadVolumeNearExceedingLimit: (state: UserState) => {
    return state.userLimits ? state.userLimits.uploadVolumeUsed + 500_000_000 > state.userLimits.uploadVolumeLimit : false;
  },
};

export const BROWSER_SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp'];

export enum SUPPORTED_IMAGE_TYPES {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    TIFF = 'image/tiff',
    BMP = 'image/bmp',
    WEBP = 'image/webp',
    ARW = 'image/x-sony-arw',
    CR2 = 'image/x-canon-cr2',
    CRW = 'image/x-canon-crw',
    DCR = 'image/x-kodak-dcr',
    DNG = 'image/x-adobe-dng',
    ERF = 'image/x-epson-erf',
    K25 = 'image/x-kodak-k25',
    KDC = 'image/x-kodak-kdc',
    MRW = 'image/x-minolta-mrw',
    NEF = 'image/x-nikon-nef',
    ORF = 'image/x-olympus-orf',
    PEF = 'image/x-pentax-pef',
    RAF = 'image/x-fujifilm-raf',
    RAW = 'image/x-panasonic-raw',
    SR2 = 'image/x-sony-sr2',
    SRF = 'image/x-sony-srf',
    X3F = 'image/x-sigma-x3f'
};

export const SUPPORTED_FILE_EXTENSIONS = ['cr2', 'dng', 'arw', 'x3f', 'crw', 'dcr', 'erf', 'k25', 'kdc', 'mrw', 'nef', 'orf', 'pef', 'raf', 'raw', 'sr2', 'srf'];

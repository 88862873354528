import { ActionContext, ActionTree } from 'vuex';
import { nanoid } from 'nanoid';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { HttpStatusCode } from 'axios';
import { Asset, ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import { ItemsRemovedEventData } from '~/models/socket/events/ItemsRemovedEvent';
import { MetadataCompletedEventData } from '~/models/socket/events/MetadataCompletedEventData';
import Folder, { getUniqueName } from '~/models/Folder';
import { FolderItem } from '~/models/item/FolderItem';
import Item from '~/models/item/Item';
import { ObjectId } from '~/models/ObjectId';
import { ResizeCompletedEventData } from '~/models/ResizeCompletedEventData';
import { RemoveItemsEvent, SortItemsEvent, SortItemsEventExternal } from '~/models/item/SortItemsEvent';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { RootState } from '~/store/state';
import { ViewSortingOption } from '~/store/cloud/state';
import { FolderState } from '~/store/folder/state';
import { ViewType } from '~/models/views/ViewType';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import {
  sortItemsByFillWithChangeSet,
  sortItemsByRemovalWithChangeSet,
  SortResult
} from '~/models/item/sortItemsByFill';
import { SortItemsEventType } from '~/models/item/SortItemsEventType';
import { groupItemsByFolder } from '~/models/item/groupItemsByFolder';
import { FolderUpdatedEventData } from '~/models/socket/events/FolderUpdatedEventData';
import { createBatchesForCollection } from '~/models/utility/createBatchesForCollection';
import { ItemsAddedEventData } from '~/models/socket/events/ItemsAddedEvent';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { ChangeSet, MAX_SYNC_BATCH_SIZE } from '~/models/ChangeSet';
import { fixOrdering } from '~/models/utility/fixOrdering';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { Owner } from '~/models/selection/Owner';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { UploadData } from '~/store/file/actions';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { PipelineItem } from '~/models/PipelineItem';
import { WarningMessages } from '~/models/MessageTypes';
import { NotificationType } from '~/models/Notification';

const mime = require('mime-types');

const DATA_URL_REGEX = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

interface CreateFolderData {
  id?: string;
  viewType?: ViewType;
  windowId?: ViewIdentifier;
  sync?: boolean;
  name: string;
  isPublic: boolean;
}

export enum ViewOrigin {
  MAIN_VIEW = 'MAIN_VIEW',
  MAGNIFY = 'MAGNIFY'
}

export interface SortFolderEvent {
  windowId: string;
  folderId: string,
  event: SortItemsEvent;
  originalSortOrder: ViewSortingOption;
}

export interface OfflineItem {
  id: string;
  order: number;
  file: File;
  base64?: string;
  width?: number;
  height?: number;
}

export interface Placeholder {
  id: string;
  order: number;
  file: File;
}
type FolderContext = ActionContext<FolderState, RootState>;

export type DeleteFolderData = { folderId: string, sync?: boolean };

const actions: ActionTree<FolderState, RootState> = {
  async rateItemsByItemId({ commit, dispatch, rootGetters, getters }: FolderContext, { folderId, itemId, rating }: { folderId: string, itemId: number, rating: number }) {
    const changeSet = {
      updates: [{
        id: itemId,
        rating,
      }],
    };
    commit('applyFolderChanges', { folderId, changeSet });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeFolders');
    } else if (getters.linkDataExists(folderId)) {
      await dispatch('synchronizeRatedItemsForSharedFolder', folderId);
    }
  },
  async rateItems({ commit, dispatch, rootGetters, getters, rootState }: FolderContext, { folderId, rating }: { folderId: string, rating: number }) {
    const globalSelectionItems = rootGetters['selection/globalSelectionItems'];
    const centeredItem = rootState.cloud.highlightInfo?.item;
    let changeSet: ChangeSet<Partial<SelectionItem>>;
    if (globalSelectionItems.length) {
      changeSet = {
        updates: globalSelectionItems.map(selectedItem => ({
          id: selectedItem.id,
          rating,
        })),
      };
    } else if (centeredItem) {
      changeSet = {
        updates: [{
          id: centeredItem.id,
          rating,
        }],
      };
    }
    if (changeSet) {
      commit('applyFolderChanges', { folderId, changeSet });
      if (rootGetters['user/isUser']) {
        await dispatch('synchronizeFolders');
      } else if (getters.linkDataExists(folderId)) {
        await dispatch('synchronizeRatedItemsForSharedFolder', folderId);
      }
    }
  },
  sortItems({
    rootGetters,
    getters,
    commit,
    dispatch,
  }: FolderContext, event: SortFolderEvent) {
    if (event.event instanceof SortItemsEventExternal) {
      dispatch('moveItems', { folderId: event.folderId, event: event.event });
    } else {
      const folderItems = getters.folderItemsByIdSorted(event.folderId, ViewSortingOption.CUSTOM_ORDER);
      const destinationSortResult: SortResult = sortItemsByFillWithChangeSet(
        folderItems,
        event.event
      );
      commit('applyFolderChanges', {
        changeSet: {
          updates: destinationSortResult.updated.map(i => ({
            id: i.item?.id || i.itemId || i.id,
            position: {
              order: i.position.order,
            },
          })),
        },
        folderId: event.folderId,
      });
      if (rootGetters['user/isUser']) {
        dispatch('synchronizeFolders');
      }
    }
    dispatch('addToRecentObjectIds', ObjectId.fromFolderId(event.folderId), { root: true });
  },
  async downloadFolder({
    getters,
    dispatch,
  }: FolderContext, folderId: string) {
    const folder = getters.folderWithItemsById(folderId);
    if (!folder.items) {
      await dispatch('loadFolder', folderId);
    }
    const highResAssets = getters.filteredFolderAssetsByVersion(folderId, ORIGINAL_ASSET_VERSION);
    dispatch('file/downloadAssets', {
      assets: highResAssets,
      zipFolderName: folder.name,
    }, { root: true });
  },
  async scrollToItem({ _ }: any, item: Item) {
    const itemElement = document.getElementById(`overlay-${item.id}`);
    if (itemElement) {
      const itemScrollOffset = offset(itemElement);
      itemElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      let waitingForScroll = itemScrollOffset / 2 > 500 ? itemScrollOffset / 2 : 500;
      waitingForScroll = waitingForScroll > 3000 ? 3000 : waitingForScroll;
      await delay(waitingForScroll);
    }
  },
  async updateName({ state, commit }: FolderContext, {
    folderId,
    name,
  }: { folderId: string, name: string }) {
    const newName = getUniqueName(name, Object.values(state.folders).map(f => f.name));
    const data = {
      name: newName,
    };
    await this.$api.patch(`/folders/${folderId}`, data);
    commit('updateNameSuccess', { folderId, name: newName });
  },
  async updateSubtitle({ commit }: { commit: any }, {
    folderId,
    subtitle,
  }: { folderId: string, subtitle: string }) {
    await this.$api.patch(`/folders/${folderId}`, { subtitle });
    commit('updateSubtitleSuccess', { folderId, subtitle });
  },
  async updateDescription({ commit }: FolderContext, {
    folderId,
    description,
  }: { folderId: string, description: string }) {
    await this.$api.patch(`/folders/${folderId}`, { description });
    commit('updateDescriptionSuccess', { folderId, description });
  },
  async updateViewType({ commit, dispatch }: FolderContext, {
    folderId,
    viewType,
  }: { folderId: string, viewType: ViewType }) {
    await this.$api.patch(`/folders/${folderId}`, { viewType });
    commit('updateViewType', { folderId, viewType });
    dispatch('cloud/changeView', { windowId: ViewIdentifier.MAIN_VIEW, view: viewType }, { root: true });
  },
  async addExternalItemTo({ getters }: FolderContext, {
    folderId,
    itemId,
  }: { folderId: string, itemId: string }) {
    if (folderId && !getters.folderItemsById(folderId).some(i => i.id === itemId)) {
      await this.$api.patch(`/items/${itemId}`, { folderId });
    }
  },
  moveItems({ commit, rootGetters, getters, dispatch }: FolderContext, {
    folderId,
    event,
  }: { event: SortItemsEventExternal, folderId: string }) {
    // If the given event items are items originating from a selection we adjust the ids here
    // to mimic FolderItems for better handling within the sorting operations when moving the items between folders
    event.items = event.items.map(i => {
      i.id = i.itemId || i.item.id;
      return i;
    });
    const folderItems = getters.folderItemsByIdSorted(folderId, ViewSortingOption.CUSTOM_ORDER);
    const destinationSortResult: SortResult = sortItemsByFillWithChangeSet(
      folderItems,
      event
    );
    commit('applyFolderChanges', {
      changeSet: {
        inserts: destinationSortResult.inserted.map(i => ({
          id: i.id,
          itemId: i.id,
          position: {
            order: i.position.order,
          },
          folderId,
          modified: i.modified,
          created: i.created,
        })),
        updates: destinationSortResult.updated.map(i => ({
          id: i.id,
          itemId: i.id,
          position: {
            order: i.position.order,
          },
        })),
      },
      folderId,
    });
    // We need to make adjustments to the origin folders of the moved items
    // (items got moved, so we need to adjust the position.order of the items that got moved out of these folders)
    const itemsGroupedByOriginalFolders: ItemWithPosition[][] = groupItemsByFolder(event.items);
    for (const itemGroup of itemsGroupedByOriginalFolders) {
      const originalFolderId = (itemGroup[0] as FolderItem).folderId || itemGroup[0].item.folderId;
      if (originalFolderId !== folderId) {
        const originalFolderItems = getters.folderItemsByIdSorted(originalFolderId, ViewSortingOption.CUSTOM_ORDER);
        const originSortResult: SortResult = sortItemsByRemovalWithChangeSet(
          originalFolderItems,
          new RemoveItemsEvent(itemGroup)
        );
        const changeSet = {
          // we need to add the folderId and position change to the original items here since this gets synced by the synchronizeFolder action
          updates: originSortResult.updated.concat(itemGroup.map(i => {
            const insertedItem = destinationSortResult.inserted.find(item => (item.id === i.id));
            return {
              id: i.id,
              position: {
                order: insertedItem.position.order,
              },
              folderId,
            } as FolderItem;
          })),
          removals: originSortResult.removed,
        };
        commit('applyFolderChanges', {
          changeSet,
          folderId: originalFolderId,
        });
      }
    }
    commit('applyItemChanges', {
      updates: [...event.items].map(i => ({ id: i.id, folderId })),
    });
    dispatch('selection/applyItemDataChangesToSelectionItems', [...event.items].map(i => i.itemId || i.item.id), { root: true });
    if (rootGetters['user/isUser']) {
      dispatch('synchronizeFolders');
    }
  },
  handleDropOnFolderEvent({
    dispatch,
    rootGetters,
    rootState,
  }: FolderContext, {
    folderId,
    event,
  }: { folderId: string, event: DragEvent }) {
    // @ts-ignore
    const files: File[] = event.dataTransfer ? event.dataTransfer.files : event.target && event.target.files;
    if (files && files.length) {
      dispatch('file/uploadFilesTo', { folderId, files }, { root: true });
    } else {
      const url = event.dataTransfer && event.dataTransfer.getData('URL');
      const transferType = event.dataTransfer?.getData(TransferOption.MUTANT_TRANSFER_TYPE) || rootState.dragInfo?.transferType;
      const dragItems = rootState.dragInfo?.items || [];
      if (transferType === TransferType.SELECTION) {
        const globalSelectedItems: ItemWithPosition[] = rootGetters['selection/globalSelectionItems'];
        dispatch('moveItems', {
          event: new SortItemsEventExternal(0, globalSelectedItems, SortItemsEventType.FILL, null),
          folderId,
        }, { root: true });
      } else if (dragItems.length) {
        dispatch('moveItems', {
          event: new SortItemsEventExternal(0, dragItems, SortItemsEventType.FILL, null),
          folderId,
        });
        setTimeout(() => dispatch('selection/dismissItemsFromGlobalSelection', dragItems, { root: true }), 500);
      } else if (url) {
        // TODO: implement url extraction for sending links in conversations
        dispatch('extractUrlTo', {
          url,
          folderId,
          originalEvent: event,
        });
      }
    }
  },
  async extractUrl({ dispatch }: FolderContext, {
    folderId,
    url,
    originalEvent,
  }: { folderId: string, url: string, originalEvent: DragEvent }): Promise<Item | null> {
    // TODO: this is hacky since we do not support as much different links yet,
    //  use proper url parsing or dedicated api to handle different cases elegantly in the future
    //  introduce external event/url extractor
    if (url) {
      if (isSoundcloudUrl(url)) {
        const soundCloudId = getSoundcloudId(url);
        if (soundCloudId) {
          const { data } = await this.$api.post('/items', {
            folderId,
            type: 8,
            data: soundCloudId,
          });
          return data;
        }
        return null;
      }
      if (isYoutubeUrl(url)) {
        const youtubeId = getYoutubeId(url);
        if (youtubeId) {
          const { data } = await this.$api.post('/items', {
            folderId,
            type: 2,
            data: youtubeId,
          });
          return data;
        }
        return null;
      }
      if (isFileUrl(url)) {
        const { data } = await this.$axios.get(url, { responseType: 'blob' });
        const fileType = mime.lookup(data.type) || 'image/jpeg';
        const filename = `${getFileNameFromUrl(url)}.${mime.extension(fileType)}`;
        const file = new File([data], filename, { type: data.type });
        dispatch('file/uploadFilesTo', { folderId, files: [file] }, { root: true });
        return null;
      } else {
        const urlParts = url.split('/');
        if (urlParts.includes('www.instagram.com')) {
          const code = urlParts.find(u => u.length >= 8 && u.length <= 12);
          if (code) {
            const { data } = await this.$axios.get(`https://www.instagram.com/p/${code}/?__a=1`);
            const resources = data?.graphql?.shortcode_media?.display_resources;
            let mediaUrl;
            if (resources && resources.length) {
              const sortedByWidth = resources.sort((a, b) => a.config_width > b.config_width ? -1 : 1);
              mediaUrl = sortedByWidth[0];
            } else {
              mediaUrl = data?.graphql?.shortcode_media?.display_url;
            }
            if (mediaUrl) {
              const { data } = await this.$axios.get(resources[0].src, { responseType: 'blob' });
              const file = new File([data], `instagram-download-${code}.jpg`, { type: 'image/jpeg' });
              dispatch('file/uploadFilesTo', { folderId, files: [file] }, { root: true });
              return null;
            }
          }
        } else if (originalEvent) {
          const html = originalEvent.dataTransfer.getData('text/html');
          const htmlDoc = new DOMParser().parseFromString(html, 'text/html');
          // TODO: parse background-images
          const imageUrls = Array.from(htmlDoc.getElementsByTagName('img')).map(img => img.src);
          const [dataUrls, externalUrls] = divideBy(imageUrls, isDataURL);
          for (const dataUrl of dataUrls) {
            const file = dataURLtoFile(dataUrl, 'external-download.jpg');
            dispatch('file/uploadFilesTo', { folderId, files: [file] }, { root: true });
          }
          const results = [];
          for (const externalUrl of externalUrls) {
            try {
              const { data } = await this.$api.post('/items/extract', { url: externalUrl, folderId });
              results.push(...data);
            } catch (err) {
              this.$log.info('Error fetching', externalUrl, 'ignoring error', err);
            }
          }
          return results.length ? results[0] : null;
        }
      }
    }
    this.$log.error(`Could not extract Url / Url is not supported. Url: ${url}`);
    return null;
  },
  async copyItemsTo(_context: FolderContext, { items, folderId }: { items: string[], folderId: string }): Promise<Item[]> {
    const { data } = await this.$api.post('/items/copy', {
      items,
      folderId,
    });
    return data;
  },
  async copyItemsToNewFolder({ dispatch }: FolderContext, { items }: { items: string[] }) {
    const folderId = await dispatch('createFolderWithoutPush', { sync: true });
    await this.$api.post('/items/copy', {
      items,
      folderId,
    });
  },
  saveItemsAsNewFolder({ state, commit, dispatch, rootGetters }: FolderContext, { folderId, items, windowId }: { folderId?: string; items: ItemWithPosition[]; windowId: string; }) {
    const uniqueFolderName = getUniqueName('Folder', Object.values(state.folders).map(f => f.name));
    const folder = {
      id: folderId || uuid(),
      name: uniqueFolderName,
      owner: rootGetters['user/currentUser'],
      deviceId: rootGetters['user/browserDevice'].id,
      viewType: ViewType.HORIZONTAL,
      items: [],
      isSynced: false,
    };
    commit('createFolder', folder);
    if (items?.length) {
      dispatch('moveItems', { folderId: folder.id, event: new SortItemsEventExternal(0, items, SortItemsEventType.FILL, { start: 0, end: 0 }) });
    }
    dispatch('cloud/addToPane', { objectIds: [ObjectId.fromFolderId(folder.id)], windowId }, { root: true });
  },
  async createFolderWithoutPush({
    state,
    commit,
    dispatch,
    rootGetters,
  }: FolderContext, folderData: CreateFolderData): Promise<string> {
    const uniqueFolderName = getUniqueName(folderData?.name ? folderData?.name : 'Folder', Object.values(state.folders).map(f => f.name));
    const folder: Partial<Folder> = {
      viewType: folderData?.viewType ?? ViewType.HORIZONTAL,
      id: folderData?.id || uuid(),
      name: uniqueFolderName,
      isSynced: false,
      isPublic: folderData?.isPublic ?? false,
      isPublicWritable: false,
      tags: [],
      owner: { id: rootGetters['user/currentUser'].id, username: rootGetters['user/currentUser'].username } as Owner,
    };
    if (!rootGetters['user/isGuest'] && !rootGetters['user/browserDevice']) {
      await dispatch('user/loadUserRelatedData', null, { root: true });
    }
    folder.deviceId = rootGetters['user/browserDevice']?.id;
    commit('createFolder', folder);
    if (rootGetters['user/isUser'] && folderData?.sync) {
      await dispatch('synchronizeFolders');
    }
    return folder.id;
  },
  async createFolder({
    dispatch,
  }: FolderContext, folderData: CreateFolderData) {
    const folderId = await dispatch('createFolderWithoutPush', folderData);
    const objectId = ObjectId.fromFolderId(folderId);
    if (folderData.windowId != null) {
      await dispatch('cloud/addToPane', { windowId: folderData.windowId, objectIds: [objectId], viewType: folderData.viewType ?? ViewType.HORIZONTAL, preload: false }, { root: true });
    }
    await dispatch('cloud/resetAllViewFilters', undefined, { root: true });
    return folderId;
  },
  async loadFolder({ state, commit, dispatch, rootGetters }: FolderContext, id: string) {
    const folderFromStore = state.folders[id];
    if (!folderFromStore || folderFromStore.isSynced) {
      commit('loadFolder');
      if (folderFromStore && folderFromStore.items && folderFromStore.items.length) {
        commit('loadFolderSuccess', folderFromStore);
      }
      const response = await this.$api.get(`/folders/${id}`);
      const folder = <Folder> response.data;
      const fixedItemOrdering = fixOrdering(folder.items);
      const { adjustedItems, orderedItems } = fixedItemOrdering;
      folder.items = orderedItems;
      commit('loadFolderSuccess', folder);

      // TODO: put initialization logic for folders into separate action
      if (folder.isShared) {
        await Promise.all([
          dispatch('loadSharedLinks', folder.id),
        ]);
      }
      if (adjustedItems?.length !== 0) {
        console.error('Sorting error encountered for folder: ', folder.id);
        const itemChangeSet: ChangeSet<SelectionItem> = {
          updates: adjustedItems.map(i => ({
            id: i.id,
            position: {
              order: i.position.order,
            },
          })),
        };
        commit('applyFolderChanges', { folderId: folder.id, changeSet: itemChangeSet });
        if (rootGetters['user/isUser']) {
          await dispatch('synchronizeFolders');
        }
      }
      commit('setLastKnownModified', moment(folder.modified).unix(), { root: true });
      dispatch('joinCloudObjects', { objectIds: [ObjectId.fromFolderId(id)] }, { root: true });
    }
  },
  async refreshStaleFolder({ commit, getters, state }: FolderContext, folderId: string) {
    const { data } = await this.$api.get(`/folders/${folderId}`);
    const folder = <Folder> data;
    const existingFolder = getters.folderWithItemsById(folderId);
    const lastModified = folder.modified;
    const recentlyChangedItems = existingFolder?.items.filter(i => moment(i.modified).isAfter(lastModified) || state.offlineItems.has(i.id));
    // We optimistically replace all folder data with the data from the api call,
    // with exception of the items since items are the only ones that may realistically change
    // between the time waiting on and receiving of the update detection response/event
    const folderMergedWithExistingState = existingFolder
      ? {
          ...existingFolder,
          ...folder,
          modified: recentlyChangedItems.length ? moment().toISOString() : folder.modified,
          items: [...recentlyChangedItems, ...folder.items.filter(item => !recentlyChangedItems.some(i => i.id === item.id))],
        }
      : folder;
    folder.itemCount = folderMergedWithExistingState.items.length;
    commit('loadFolderSuccess', folderMergedWithExistingState);
    commit('setLastKnownModified', moment(folderMergedWithExistingState.modified).unix(), { root: true });
  },
  async synchronizeRawItemChanges({ state, commit }: FolderContext) {
    const rawItemChanges = Object.entries(state.rawItemsChangeSets);
    for (const [itemId, itemChangeSet] of rawItemChanges) {
      const { prunedChangeSet, leftOverChangeSet } = pruneOfflineIdsFromChangeSet(itemChangeSet, state.offlineItems);
      if (prunedChangeSet?.updates?.length) {
        for (const updates of prunedChangeSet.updates) {
          await this.$api.patch(`/items/${itemId}`, {
            ...updates,
          });
        }
        commit('setRawItemChangeSet', { itemId, changeSet: leftOverChangeSet });
      }
    }
  },
  async synchronizeFolders({ state, commit, dispatch, rootGetters }: FolderContext) {
    if (!rootGetters['user/isGuest']) {
      const foldersToSync: Partial<Folder & {
        applyChangesToState: boolean
      }>[] = Object.values(state.folders).filter(f => !f.isSynced || f.itemChangeSet).map(f => {
        const uniqueFolderName = getUniqueName(f.name, Object.values(state.folders).filter(f => f.isSynced).map(f => f.name));
        const deviceId = f.deviceId || rootGetters['user/browserDevice']?.id;
        return {
          id: f.id,
          // We need to check for uniqueness of the folder name again here because the user might not have been logged in when the folder was created
          name: uniqueFolderName,
          // We need to provide a fallback for the deviceId here because the user might not have been logged in when the folder was created
          deviceId,
          isSynced: f.isSynced,
          applyChangesToState: f.name !== uniqueFolderName || f.deviceId !== deviceId,
        };
      });
      for (const folder of foldersToSync) {
        if (!folder.isSynced) {
          try {
            await this.$api.put(`/folders/${folder.id}`, {
              id: folder.id,
              name: folder.name,
              deviceId: folder.deviceId,
            });
          } catch (error) {
            if (error?.response?.status === HttpStatusCode.Conflict) {
              if (!state.conflictIds.includes(folder.id)) {
                commit('addToConflictIds', folder.id);
                await dispatch('loadFolders');
                folder.name = getUniqueName(folder.name, Object.values(state.folders).filter(f => f.isSynced).map(f => f.name));
                foldersToSync.push(folder);
                commit('applyFolderChanges', {
                  folderId: folder.id,
                  name: folder.name,
                  deviceId: folder.deviceId,
                });
                continue;
              } else {
                dispatch('setNotificationMessage', {
                  payload: {
                    message: WarningMessages.FOLDER_CONFLICT,
                    type: NotificationType.ERROR,
                    duration: 5000,
                  },
                }, { root: true });
              }
            }
            throw error;
          }
          commit('setLastKnownModified', Date.now(), { root: true });
          dispatch('joinCloudObjects', {
            objectIds: [ObjectId.fromFolderId(folder.id)],
            includeLastKnownModified: false,
          }, { root: true });
          if (folder.applyChangesToState) {
            commit('applyFolderChanges', {
              folderId: folder.id,
              name: folder.name,
              deviceId: folder.deviceId,
            });
          }
          commit('setFolderIsSynced', {
            folderId: folder.id,
            isSynced: true,
          });
        }
      }
      const folderIdsWithTagsToSync = Object.values(state.folders)
        ?.filter(f => f.tags?.some(t => !t.isSynced))
        ?.map(f => f.id) ?? [];
      await dispatch('postFolderTags', folderIdsWithTagsToSync);
    }
    await dispatch('synchronizeFolderItems');
  },
  async postFolderTags({ state, commit }: FolderContext, folderIds: string[]) {
    for (const folderId of folderIds) {
      const folderTags = state.folders[folderId].tags.filter(t => !t.isSynced);
      if (folderTags.length > 0) {
        const folderId = folderTags[0].folderId;
        await this.$api.post(`/folders/${folderId}/tags`, {
          tags: folderTags.map(t => ({ id: t.id, parentId: t.parentId, folderId: t.folderId, name: t.name })),
        });
        commit('setFolderTagsSynced', { folderId, tagIds: folderTags.map(t => t.id) });
      }
    }
  },
  async synchronizeRatedItemsForSharedFolder({ state }: FolderContext, folderId: string) {
    const itemChangeSet = state.folderItemChangeSets[folderId];
    if (itemChangeSet?.updates?.length) {
      const filteredChangeSet: any = {
        updates: itemChangeSet.updates.filter(u => u.rating != null).map(u => ({
          id: u.id,
          rating: u.rating,
        })),
      };
      if (filteredChangeSet.updates?.length) {
        await this.$api.post(`/folders/${folderId}/add-changes`, {
          itemChangeSet: filteredChangeSet,
        });
      }
    }
  },
  async synchronizeFolderItems({ state, commit, getters, rootGetters }: FolderContext) {
    const currentUser = rootGetters['user/currentUser'];
    const folderItemChangeSets = Object.entries(state.folderItemChangeSets)
      .map(([id, changeset]) => {
        const owner = getters.folderById(id)?.owner?.id === currentUser?.id;
        if (!owner || currentUser.id == null) {
          const allowedChangeSet = { removals: null, inserts: null, updates: changeset?.updates.map(u => { return u.id != null && u.rating != null ? { rating: u.rating, id: u.id } : null; }).filter(c => !!c) } as ChangeSet<FolderItem>;
          return [id, allowedChangeSet] as [string, ChangeSet<FolderItem>];
        }
        return [id, changeset] as [string, ChangeSet<FolderItem>];
      }).filter(([id, changeset]) => id != null && changeset != null);
    for (const [folderId, itemChangeSet] of folderItemChangeSets) {
      const { leftOverChangeSet, prunedChangeSet } = pruneOfflineIdsFromChangeSet(itemChangeSet, state.offlineItems);
      if (prunedChangeSet) {
        const unBatchedChangeSet: any = {};
        if (prunedChangeSet.updates?.length > MAX_SYNC_BATCH_SIZE) {
          for (const updates of createBatchesForCollection(prunedChangeSet.updates, MAX_SYNC_BATCH_SIZE)) {
            const updateChangeSet = {
              updates,
            };
            await this.$api.post(`/folders/${folderId}/add-changes`, {
              itemChangeSet: updateChangeSet,
            });
          }
        } else {
          unBatchedChangeSet.updates = prunedChangeSet.updates || [];
        }
        if (prunedChangeSet.removals?.length > MAX_SYNC_BATCH_SIZE) {
          for (const removals of createBatchesForCollection(prunedChangeSet.removals, MAX_SYNC_BATCH_SIZE)) {
            const removalChangeSet = {
              removals,
            };
            await this.$api.post(`/folders/${folderId}/add-changes`, {
              itemChangeSet: removalChangeSet,
            });
          }
        } else {
          unBatchedChangeSet.removals = prunedChangeSet.removals || [];
        }
        if (unBatchedChangeSet.updates?.length || unBatchedChangeSet.removals?.length) {
          if (unBatchedChangeSet.updates?.length === 0) {
            delete unBatchedChangeSet.updates;
          } else if (unBatchedChangeSet.removals?.length === 0) {
            delete unBatchedChangeSet.removals;
          }
          await this.$api.post(`/folders/${folderId}/add-changes`, {
            itemChangeSet: unBatchedChangeSet,
          });
        }
        commit('setFolderItemChangeSet', { folderId, changeSet: leftOverChangeSet });
      }
    }
  },
  async loadItem({ commit }: FolderContext, itemId: string) {
    const { data } = await this.$api.get(`/items/${itemId}`);
    commit('addItems', [data]);
  },
  async loadFolders({ commit }: FolderContext) {
    const { data } = await this.$api.get('/folders');
    commit('loadFoldersSuccess', data);
  },
  async removeFolder({ commit, dispatch, getters, rootGetters, rootState }: FolderContext, { folderId, sync = true }: DeleteFolderData) {
    if (!rootGetters.pipelineUploadActive() || rootState.file.uploadProcess.objectId.toUuid() !== folderId) {
      dispatch('file/cancelUploadAndMatchingProcess', folderId, { root: true });
      try {
        await dispatch('changeRouteForViews', folderId);
        const folderItemIds = getters.folderItemsById(folderId).map(i => i.id);
        commit('removeFolder', folderId);
        commit('selection/removeFolderItemsFromSelections', folderItemIds, { root: true });
        commit('applyItemChanges', {
          removals: folderItemIds,
        });
        if (sync) {
          await this.$api.delete(`/folders/${folderId}`);
          await dispatch('removeFromCustomObjectIds', ObjectId.fromFolderId(folderId), { root: true });
          await dispatch('removeFromRecentObjectIds', ObjectId.fromFolderId(folderId), { root: true });
        }
      } catch (err) {
        alert(`Error while deleting folder ${folderId}`);
      }
    } else {
      await dispatch('displayNotificationForDeletionNotPossible', null, { root: true });
    }
  },
  async changeRouteForViews({ dispatch, rootGetters }: FolderContext, folderId) {
    if (rootGetters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewIds.some((viewId: ObjectId) => viewId.toUuid() === folderId)) {
      const filteredViewIds = rootGetters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewIds.filter((viewId: ObjectId) => viewId.toUuid() !== folderId);
      await dispatch('cloud/setViewIds', { windowId: ViewIdentifier.MAIN_VIEW, objectIds: filteredViewIds }, { root: true });
      if (filteredViewIds.length) {
        await this.$router.push({
          ...this.$router.currentRoute,
          params: { id: filteredViewIds.map(viewId => viewId.toString()).join(',') },
        });
      } else {
        await this.$router.replace({ path: '/cloud' });
      }
    }
    if (rootGetters['cloud/window'](ViewIdentifier.SIDE_PANE).viewIds.some((viewId: ObjectId) => viewId.toUuid() === folderId)) {
      await dispatch('cloud/setViewIds', { windowId: ViewIdentifier.SIDE_PANE, objectIds: [] }, { root: true });
    }
  },
  async refineItemOfFolder({ rootGetters, getters, dispatch }: FolderContext, {
    folderId,
  }) {
    const folder = getters.folderWithItemsById(folderId);
    const globalSelectionItems = rootGetters['selection/globalSelectionItems'].map(i => i.id);
    const itemsToDelete = folder.items.filter(i => !globalSelectionItems.includes(i.id));
    await dispatch('removeItems', { folderId, items: itemsToDelete });
  },
  async removeItems({ commit, dispatch, rootGetters, getters, rootState }: FolderContext, {
    folderId,
    items,
  }: { folderId: string, items: ItemWithPosition[] }) {
    if (!rootGetters.pipelineUploadActive() || rootState.file.uploadProcess?.objectId?.toUuid() !== folderId) {
      commit('file/removeItemsFromPipeline', items.map(i => i.item.id), { root: true });
      const folderItems = getters.folderItemsByIdSorted(folderId, ViewSortingOption.CUSTOM_ORDER);
      const sortResult: SortResult = sortItemsByRemovalWithChangeSet(folderItems, new RemoveItemsEvent(items));
      commit('applyFolderChanges', {
        changeSet: {
          updates: sortResult.updated.map(i => {
            return {
              id: i.id || i.itemId,
              position: {
                order: i.position.order,
              },
              folderId,
            } as FolderItem;
          }),
        },
        folderId,
        persistChangeSet: false,
      });
      commit('applyFolderChanges', {
        changeSet: {
          removals: sortResult.removed,
        },
        folderId,
      });
      commit('applyItemChanges', {
        removals: sortResult.removed,
      });
      commit('selection/removeFolderItemsFromSelections', sortResult.removed, { root: true });
      this.$pipelineManager.getPipeline(folderId)?.removeStalePipelineItems(sortResult.removed);
      if (rootGetters['user/isUser']) {
        await dispatch('synchronizeFolders');
      }
    } else {
      await dispatch('displayNotificationForDeletionNotPossible', null, { root: true });
    }
  },
  async loadSharedLinks({ commit, dispatch, getters }: FolderContext, folderId) {
    if (getters.isFolderOwner(folderId)) {
      const { data } = await this.$api.get(`/folders/${folderId}/links`);
      if (data.length > 0) {
        commit('setFolderShared', folderId);
        dispatch('link/setLinkData', data[0], { root: true });
      }
    }
  },
  applyItemDataChangesToFolderItems({ state, commit }: FolderContext, itemIds: string) {
    const folderChangeMap = new Map();
    for (const itemId of itemIds) {
      const item = state.items.get(itemId);
      const itemChangeMap = folderChangeMap.get(item.folderId) || new Map();
      itemChangeMap.set(itemId, item);
      folderChangeMap.set(item.folderId, itemChangeMap);
    }
    for (const [folderId, itemChanges] of folderChangeMap.entries()) {
      commit('applyItemDataChangesToFolderItems', { folderId, itemChanges });
    }
  },
  async handleFolderUpdated({ commit, getters, rootState, dispatch }: FolderContext, data: FolderUpdatedEventData) {
    if (getters.exists(data.id)) {
      const changes: Partial<Folder> = {};
      if (data.name) {
        changes.name = data.name;
      }
      if (data.subtitle) {
        changes.subtitle = data.subtitle;
      }
      if (data.description) {
        changes.description = data.description;
      }
      if (data.items?.inserts?.length && !getters.isFolderOwner(data.id)) {
        for (const item of data.items.inserts) {
          const itemId = item.itemId || item.item?.id;
          if (!rootState.folder.items.has(itemId)) {
            await dispatch('loadItem', itemId);
          }
        }
      }
      const changeSet = data.items?.updates ? { updates: data.items.updates } : undefined;
      commit('applyFolderChanges', { folderId: data.id, ...changes, changeSet, isSynced: true, persistChangeSet: false });
      // We need to handle removals different from folder item updates for now,
      // since we need to account for involved selections and order positions in affected folder items as well
      if (data.items?.removals) {
        const itemsRemoveEvent = { folderId: data.id, items: data.items.removals };
        dispatch('itemsRemoved', itemsRemoveEvent);
        dispatch('selection/handleItemsRemoved', itemsRemoveEvent, { root: true });
      }
    }
  },
  itemsAdded({ commit, dispatch }: FolderContext, events: SocketEvent<ItemsAddedEventData>[]) {
    const items: Item[] = events.reduce((agg, curr) => agg.concat(curr.data.items.map(i => i.item)), []).filter(i => i != null);
    commit('addItems', items);
    for (const event of events) {
      const folderItems = event.data.items;
      commit('addFolderItems', { folderId: event.data.folderId, items: folderItems });
    }
    dispatch('selection/applyItemDataChangesToSelectionItems', items.map(i => i.id), { root: true });
  },
  itemsRemoved(context: FolderContext, itemsRemovedEventData: ItemsRemovedEventData) {
    context.commit('removeItemsSuccess', itemsRemovedEventData);
  },
  async itemsResized({ state, commit, dispatch }: FolderContext, events: SocketEvent<ResizeCompletedEventData>[]) {
    const itemsWithChanges = events.reduce((agg, resizeEvent) => {
      agg.push(resizeEvent.data.itemId);
      return agg;
    }, []);
    // load items in case RESIZE_COMPLETED is received before ITEM_ADDED (we don't know about the item yet)
    for (const itemId of itemsWithChanges) {
      if (!state.items.has(itemId)) {
        await dispatch('folder/loadItem', itemId, { root: true });
      }
    }
    commit('itemsResized', events.map(event => event.data));
    dispatch('applyItemDataChangesToFolderItems', itemsWithChanges);
    dispatch('selection/applyItemDataChangesToSelectionItems', itemsWithChanges, { root: true });
  },
  async handleAssetsAdded({ state, commit, dispatch }: FolderContext, data: SocketEvent<Asset>[]) {
    const itemIdsChanged = data.reduce((agg: string[], assetEvent) => {
      if (!agg.includes(assetEvent.data.itemId)) {
        agg.push(assetEvent.data.itemId);
      }
      return agg;
    }, []);
    for (const itemId of itemIdsChanged) {
      if (!state.items.has(itemId)) {
        await dispatch('folder/loadItem', itemId, { root: true });
      }
    }
    commit('assetsAdded', data.map(event => event.data));
    dispatch('applyItemDataChangesToFolderItems', itemIdsChanged);
    dispatch('selection/applyItemDataChangesToSelectionItems', itemIdsChanged, { root: true });
  },
  addItemMetadata(context: FolderContext, data: MetadataCompletedEventData) {
    context.commit('addItemMetadata', data);
    context.dispatch('applyItemDataChangesToFolderItems', [data.itemId]);
    context.dispatch('selection/applyItemDataChangesToSelectionItems', [data.itemId], { root: true });
  },
  folderAdded(context: FolderContext, data: Folder) {
    context.commit('createFolder', { ...data, isSynced: true });
  },
  setFolderShared(context: FolderContext, folderId: string) {
    context.commit('setFolderShared', folderId);
  },
  async uploadToNewFolder({ dispatch, commit, rootState }: FolderContext, externalContentEvent: AddExternalContentEvent) {
    const folderId = await dispatch('createFolder', { name: 'Demo Folder', viewType: ViewType.HORIZONTAL, sync: false });
    const objectId = ObjectId.fromFolderId(folderId);
    commit('cloud/setViewIds', { windowId: ViewIdentifier.MAIN_VIEW, objectIds: [objectId], viewType: ViewType.HORIZONTAL }, { root: true });
    commit('cloud/setViewIds', { windowId: ViewIdentifier.NAVIGATION_VIEW, objectIds: [objectId], viewType: ViewType.HORIZONTAL }, { root: true });
    await dispatch<ActionPayload<UploadData>>({ type: 'file/upload', payload: { windowId: ViewIdentifier.MAIN_VIEW, event: externalContentEvent, pipelineCommands: [PipelineCommandType.CREATE_THUMBNAILS] } }, { root: true });
    await this.$router.push({ name: 'cloud-id', params: { id: objectId.toString() }, query: { view: ViewType.HORIZONTAL } });
    if (!rootState.cloudMenuRightOpen) {
      dispatch('toggleCloudMenuRight', null, { root: true });
      dispatch('setActiveCloudMenuTab', CloudMenuTab.SHARED_LINK, { root: true });
    }
  },
  async fixStaleItemPositions({ state, commit, dispatch }: FolderContext, folderId: string) {
    const { adjustedItems } = fixOrdering(state.folderItems[folderId].slice());
    if (adjustedItems.length) {
      commit('applyFolderChanges', { folderId, changeSet: { updates: adjustedItems.map(i => ({ id: i.id, position: { order: i.position.order } })) } });
      await dispatch('selection/applyItemDataChangesToSelectionItems', adjustedItems.map(i => i.id), { root: true });
    }
  },
  matchItemAssets({ state, commit }: FolderContext, pipelineItems: PipelineItem[]) {
    commit('setMatchedWithForItemAssets', pipelineItems);
    const relevantAssets = pipelineItems
      .map(i => state.items.get(i.id))
      .filter(i => i != null)
      .reduce((assets, item) => assets.concat(item.assets.map(a => ({ ...a, itemId: item.id }))), []);
    relevantAssets.forEach(asset => this.$imageLoader.clearCachedAsset(asset));
  },
};

export default actions;

function delay(time: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), time);
  });
}

function isYoutubeUrl(url: string) {
  return url.includes('youtube.com/watch') || url.includes('youtu.be');
}

function isSoundcloudUrl(url: string) {
  return url.includes('soundcloud.com');
}

function isFileUrl(url: string) {
  const urlParts = url.split('.');
  return urlParts.length && urlParts.some(isSupportedFileType);
}

function isSupportedFileType(fileEnding: string) {
  return fileEnding === 'jpg' || fileEnding === 'gif' || fileEnding === 'png' || fileEnding === 'webp' || fileEnding === 'bmp';
}

function getFileNameFromUrl(url: string) {
  const urlPaths = url.split('/');
  const filenameUrlPath = urlPaths[urlPaths.length - 1];
  const extensionSplit = filenameUrlPath.split('.');
  return extensionSplit.length > 1 ? extensionSplit[extensionSplit.length - 1] : `downloaded-from-web-${nanoid(8)}`;
}

function getYoutubeId(url: string) {
  if (url.includes('youtube.com')) {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get('v');
  }
  if (url.includes('youtu.be')) {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname.split('/')[1];
  }
  return null;
}

function getSoundcloudId(url: string) {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname.slice(1, parsedUrl.pathname.length);
}

function isDataURL(s: string): boolean {
  return !!s.match(DATA_URL_REGEX);
}

// TODO: for reference: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File(
    [u8arr],
    filename, {
      type: mime,
    });
}

function divideBy(arr: any[], divideFn: Function) {
  return arr.reduce((agg, curr) => divideFn(curr)
    ? [[...agg[0], curr], agg[1]]
    : [agg[0], [...agg[1], curr]],
  [[], []]);
}

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
}

type ItemTypes = FolderItem | SelectionItem | Item;
function pruneOfflineIdsFromChangeSet(changeSet: ChangeSet<ItemTypes>, offlineIds: Map<string, boolean>): { prunedChangeSet: ChangeSet<ItemTypes>, leftOverChangeSet: ChangeSet<ItemTypes> } {
  if (changeSet == null) {
    return { prunedChangeSet: changeSet, leftOverChangeSet: changeSet };
  }
  const [filteredUpdates, unfilteredUpdates] = filterByConditions(changeSet.updates || [], update => !offlineIds.has(update.id));
  const [filteredRemovals, unfilteredRemovals] = filterByConditions(changeSet.removals || [], removalId => !offlineIds.has(removalId));
  const [filteredInserts, unfilteredInserts] = filterByConditions(changeSet.inserts || [], insert => !offlineIds.has(insert.id));
  return {
    prunedChangeSet: {
      updates: filteredUpdates,
      removals: filteredRemovals,
      inserts: filteredInserts,
    },
    leftOverChangeSet: {
      updates: unfilteredUpdates,
      removals: unfilteredRemovals,
      inserts: unfilteredInserts,
    },
  };
}

// just an initial implementation, which can be extended in future

export enum NodeEnv {
  PRODUCTION = 'production',
  TEST = 'test',
  DEVELOPMENT = 'development',
}

export interface WebEnvironment {
  ENVIRONMENT: string;
  ADMIN_DASHBOARD_WEB_URL: string;
  STAGE_URL: string;
  API_URL: string;
  GOOGLE_ANALYTICS_ID: string,
  PAYPAL_PLAN_ID: string;
  PAYPAL_CLIENT_ID: string;
  WS_URL: string;
  URL: string;
  isProductionEnvironment: () => boolean;
  isDevEnvironment: () => boolean;
}

export const WebConfig: WebEnvironment = {
  ENVIRONMENT: process.env.NODE_ENV,
  ADMIN_DASHBOARD_WEB_URL: process.env.ADMIN_DASHBOARD_WEB_URL,
  GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID,
  API_URL: process.env.API_URL,
  STAGE_URL: process.env.STAGE_URL,
  PAYPAL_PLAN_ID: process.env.PAYPAL_PLAN_ID,
  PAYPAL_CLIENT_ID: process.env.PAYPAL_CLIENT_ID,
  WS_URL: process.env.WS_URL,
  URL: process.env.URL,
  isProductionEnvironment: () => process.env.NODE_ENV === NodeEnv.PRODUCTION,
  isDevEnvironment: () => process.env.NODE_ENV === NodeEnv.DEVELOPMENT,
};

import { Plugin } from '~/node_modules/@nuxt/types';
import { filter } from 'rxjs';
import { pipelineCommands$ } from '~/models/pipelineCommands$';
import { pipelineEvents$ } from '~/models/pipelineEvents$';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { ThumbnailBatchUploader } from '~/models/uploader/ThumbnailBatchUploader';
import { OriginalsBatchUploader } from '~/models/uploader/OriginalsBatchUploader';
import { RawsBatchUploader } from '~/models/uploader/RawsBatchUploader';
import { containsErroredEventType, PipelineItem } from '~/models/PipelineItem';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { UploadQueueItem } from '~/models/uploader/BatchUploader';
import { NotificationType } from '~/models/Notification';

const batchUploaderPlugin: Plugin = (context: any) => {
  const thumbnailBatchUploader = new ThumbnailBatchUploader(context.app.$api);
  const originalsBatchUploader = new OriginalsBatchUploader(context.app.$api, 1);
  const rawsBatchUploader = new RawsBatchUploader(context.app.$api, 1);

  pipelineCommands$
    .pipe(filter(e => e.type === PipelineCommandType.UPLOAD_THUMBNAILS))
    .subscribe(event => {
      const [itemsWithError, items] = filterPipelineItemsWithError(PipelineEventType.THUMBNAILS_CREATED, event.items);
      thumbnailBatchUploader.upload(items);
      if (itemsWithError.length > 0) {
        pipelineEvents$.next({ type: PipelineEventType.THUMBNAILS_UPLOADED, pipelineId: itemsWithError[0].pipelineId, data: itemsWithError });
      }
    });

  pipelineCommands$
    .pipe(filter(e => e.type === PipelineCommandType.UPLOAD_ORIGINALS))
    .subscribe(event => {
      const [itemsWithoutOriginalFile, items] = filterPipelineItemsWithoutOriginalFile(event.items);
      originalsBatchUploader.upload(items);
      if (itemsWithoutOriginalFile.length > 0) {
        pipelineEvents$.next({ type: PipelineEventType.ORIGINALS_UPLOADED, pipelineId: itemsWithoutOriginalFile[0].pipelineId, data: itemsWithoutOriginalFile });
      }
    });

  pipelineCommands$
    .pipe(filter(e => e.type === PipelineCommandType.CANCEL_PIPELINE))
    .subscribe(_event => {
      thumbnailBatchUploader.cancelOutstandingWork();
      rawsBatchUploader.cancelOutstandingWork();
      originalsBatchUploader.cancelOutstandingWork();
      context.app.store.dispatch({
        type: 'setNotificationMessage',
        payload: { message: 'Upload was canceled and files will be removed', type: NotificationType.INFO },
      },
      { root: true });
    });

  pipelineCommands$
    .pipe(filter(e => e.type === PipelineCommandType.UPLOAD_RAWS))
    .subscribe(event => {
      const [itemsWithoutRawFile, items] = filterPipelineItemsWithoutRawFile(event.items);
      rawsBatchUploader.upload(items);
      if (itemsWithoutRawFile.length > 0) {
        pipelineEvents$.next({ type: PipelineEventType.RAWS_UPLOADED, pipelineId: itemsWithoutRawFile[0].pipelineId, data: itemsWithoutRawFile });
      }
    });

  thumbnailBatchUploader.on('thumbnails-uploaded', (batchItems: UploadQueueItem[]) => {
    const items = batchItems.map(i => i.item);
    pipelineEvents$.next({ type: PipelineEventType.THUMBNAILS_UPLOADED, pipelineId: items[0].pipelineId, data: items });
  });

  originalsBatchUploader.on('originals-uploaded', (batchItems: UploadQueueItem[]) => {
    const items = batchItems.map(i => i.item);
    pipelineEvents$.next({ type: PipelineEventType.ORIGINALS_UPLOADED, pipelineId: items[0].pipelineId, data: items });
  });

  rawsBatchUploader.on('raws-uploaded', (batchItems: UploadQueueItem[]) => {
    const items = batchItems.map(i => i.item);
    pipelineEvents$.next({ type: PipelineEventType.RAWS_UPLOADED, pipelineId: items[0].pipelineId, data: items });
  });
};

function filterPipelineItemsWithError(event: PipelineEventType, pipelineItems: PipelineItem[]) {
  return filterByConditions(pipelineItems, (item) => item.eventsProcessed.some(containsErroredEventType(event)));
}

function filterPipelineItemsWithoutOriginalFile(pipelineItems: PipelineItem[]) {
  return filterByConditions(pipelineItems, (item) => item.file == null);
}

function filterPipelineItemsWithoutRawFile(pipelineItems: PipelineItem[]) {
  return filterByConditions(pipelineItems, (item) => item.raw?.file == null);
}

export default batchUploaderPlugin;

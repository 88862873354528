import { CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION } from '~/models/Asset';

export function buildVersionSubstituteMap(versions: number[]): Map<number, number> {
  const map = new Map<number, number>();
  versions.forEach(v => {
    if (v === CUSTOM_SUB_VERSION && !versions.includes(ORIGINAL_ASSET_VERSION)) {
      map.set(CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION);
    } else {
      map.set(v, v);
    }
  });
  return map;
}

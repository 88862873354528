import { ResizeCompletedEventData } from '~/models/ResizeCompletedEventData';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { AggregatedEvents } from '~/models/socket/aggregateEvents';

export function aggregateResizeEvent(agg: AggregatedEvents, curr: SocketEvent<ResizeCompletedEventData>): AggregatedEvents {
  const currentData = curr.data;
  if (agg.itemsAddedEvents.some(event => event.data.items.some(item => item.id === currentData.itemId))) {
    agg.itemsAddedEvents = agg.itemsAddedEvents.map(event => {
      if (event.data.items.some(item => item.id === currentData.itemId)) {
        event.data.items = event.data.items.map(item => {
          if (item.id === currentData.itemId) {
            item.item.assets = [...item.item.assets.filter(asset => !currentData.assets.some(a => asset.id === a.id)), ...currentData.assets];
          }
          return item;
        });
      }
      return event;
    });
  } else {
    agg.resizeCompletedEvents.push(curr);
  }
  return agg;
}

import { PluginOptions } from 'vue-gtag';
import { WebConfig } from '~/Config';

export const AnalyticsOptions: PluginOptions = {
  pageTrackerEnabled: true,
  pageTrackerScreenviewEnabled: false,
  appName: 'web',
  bootstrap: false,
  enabled: WebConfig.isProductionEnvironment(),
  // @ts-ignore
  pageTrackerTemplate(to) {
    return {
      page_location: '',
      page_referrer: '',
      page_path: pruneUrl(to.path),
      page_title: to.name,
    };
  },
};

export function pruneUrl(url: string) {
  // See https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid/13653180
  const uuidRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/g;
  const tokenRegex = /([A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+)/g;
  const pruned = removeFrom(url, [uuidRegex, tokenRegex]);
  if (pruned.includes('shared')) {
    return pruned.slice(0, pruned.indexOf('shared') + ('shared'.length + 1));
  }
  return pruned;
}

function removeFrom(url: string, regex: RegExp[]) {
  return regex.reduce((pruned, regex) => {
    return pruned.replace(regex, '');
  }, url);
}

import { RootState } from '~/store/state';
import { convertMagnifyUserMarginFactorToInternal, ViewSortingOption } from '~/store/cloud/state';
import { MagnifyState, MagnifyViewContent } from '~/store/magnify/state';

const actions = {
  attach({ state, commit }: {state: MagnifyState, commit: any, rootState: RootState}) {
    commit('setBottomOffset', state.bottomOffset);
    commit('setAttached', true);
  },
  detach({ commit }: {commit: any}) {
    commit('setBottomOffset', 260);
    commit('setAttached', false);
  },
  openViewContent({ commit, dispatch }: { commit: any, dispatch: any }, viewContent: MagnifyViewContent) {
    commit('setViewContent', viewContent);
    dispatch('open');
  },
  toggle({ state, dispatch }: { state: MagnifyState, dispatch: any }) {
    if (state.isOpen) {
      dispatch('close');
    } else {
      dispatch('open');
    }
  },
  setSortingOption({ commit }: any, sortingOption: ViewSortingOption) {
    commit('setSortingOption', sortingOption);
  },
  open({ commit, state }: { commit: any, state: MagnifyState, rootGetters: any, dispatch: any }) {
    if (!state.isOpen) {
      commit('toggleView');
    }
  },
  close({ commit, state }: { commit: any, state: MagnifyState }) {
    if (state.isOpen) {
      commit('toggleView');
    }
  },
  initializeViewMargin({ state, dispatch }: {state: MagnifyState, dispatch: any}) {
    dispatch('setMarginFactor', state.options.marginFactor);
  },
  setMarginFactor({ commit, state }: { commit: any, state: MagnifyState, rootGetters: any }, marginFactor: number) {
    // Map margin Values that make sense for the user to values we can render a nice view with
    const internalMarginFactor = convertMagnifyUserMarginFactorToInternal(marginFactor);
    // Calculate new margin based on the size of the view, the magnify height and the new margin factor
    let estimatedColumns = window.innerWidth / state.height;
    estimatedColumns = estimatedColumns > 1 ? estimatedColumns : 1;
    const newMargin = (internalMarginFactor * window.innerWidth) / (estimatedColumns * internalMarginFactor + estimatedColumns + internalMarginFactor);
    localStorage.setItem('magnify.options.marginFactor', marginFactor.toString());
    commit('setMarginFactor', marginFactor);
    commit('setMargin', newMargin);
  },
};

export default actions;

export interface MagnifyOptions {
  margin: number;
  marginFactor: number;
  marginFactorMin: number;
  marginFactorMax: number;
  marginInterval: number;
}

export const magnifyOptionsDefault = (): MagnifyOptions => {
  return {
    margin: 1,
    marginFactor: localStorage.getItem('magnify.marginFactor') ? parseInt(localStorage.getItem('magnify.marginFactor'), 10) : 1,
    marginFactorMin: 0,
    marginFactorMax: 10,
    marginInterval: 1,
  };
};

import moment from 'moment';
import { LogEntry } from '~/plugins/logger/LogEntry';
import { LogLevelName } from '~/plugins/logger/LogLevelName';
import { LogLevel } from '~/plugins/logger/LogLevel';
import { LogLevelBadge } from '~/plugins/logger/LogLevelBadge';

export class CustomLogger {
  Console: any;
  public logs: LogEntry[] = []; // TODO: actually use logs and send those to Sentry / Elastic on error
  private collectLogs = false;
  private keepMaxLogs = 100;
  private minimumLogLevel = 0;
  private originalConsole = console;
  private logLevelColors: { [logLevelName: string]: { backgroundColor: string; color: string; } } = {
    [LogLevelName.DEBUG]: { backgroundColor: '#7725f5', color: '#fff' },
    [LogLevelName.TRACE]: { backgroundColor: '#666', color: '#fff' },
    [LogLevelName.INFO]: { backgroundColor: '#337cfc', color: '#fff' },
    [LogLevelName.WARN]: { backgroundColor: '#efa21f', color: '#000' },
    [LogLevelName.ERROR]: { backgroundColor: '#ef4e31', color: '#fff' },
    [LogLevelName.FATAL]: { backgroundColor: '#d92002', color: '#fff' },
  };

  private logMethods: { [logLevelName: string]: Function } = {
    [LogLevelName.DEBUG]: console.debug,
    [LogLevelName.TRACE]: console.trace,
    [LogLevelName.INFO]: console.info,
    [LogLevelName.WARN]: console.warn,
    [LogLevelName.ERROR]: console.error,
    [LogLevelName.FATAL]: console.error,
  }

  constructor(overrideDefaultConsole: boolean = false) {
    if (overrideDefaultConsole) {
      // eslint-disable-next-line no-global-assign
      console = this as unknown as Console;
    }
  }

  public setCollectLogs(collectLogs: boolean) {
    this.collectLogs = collectLogs;
  }

  public setKeepMaxLogs(keepMaxLogs: number) {
    this.keepMaxLogs = keepMaxLogs;
  }

  public setMinimumLogLevel(logLevel: number) {
    this.minimumLogLevel = logLevel;
  }

  public setLogLevelColors(logLevelColors: { [logLevelName: string]: { backgroundColor: string; color: string; } }) {
    this.logLevelColors = logLevelColors;
  }

  public debug(message: string, ...args: any[]) {
    this.write(LogLevelName.DEBUG, message, args);
  }

  public trace(message: string, ...args: any[]) {
    this.write(LogLevelName.TRACE, message, args);
  }

  public log(message: string, ...args: any[]) {
    this.info(message, ...args);
  }

  public info(message: string, ...args: any[]) {
    this.write(LogLevelName.INFO, message, args);
  }

  public warn(message: string, ...args: any[]) {
    this.write(LogLevelName.WARN, message, args);
  }

  public error(message: string, ...args: any[]) {
    this.write(LogLevelName.ERROR, message, args);
  }

  public fatal(message: string, ...args: any[]) {
    this.write(LogLevelName.FATAL, message, args);
  }

  private write(logLevelName: LogLevelName, message: string, args: any[]) {
    if (LogLevel[logLevelName] >= this.minimumLogLevel) {
      if (this.collectLogs) {
        this.appendLog(this.parseLogEntry(logLevelName, message, args));
      }
      const badge = this.createBadge(logLevelName);
      this.logMethods[logLevelName](`${badge.text}%c ${message}`, badge.style, '', ...args);
    }
  }

  private appendLog(logEntry: LogEntry) {
    this.logs.push(logEntry);
    if (this.logs.length > this.keepMaxLogs) {
      this.logs.shift();
    }
  }

  private parseLogEntry(logLevel: LogLevelName, message: string, args: any[] = []): LogEntry {
    const logEntry: LogEntry = {
      level: LogLevel[logLevel],
      message,
      args,
      time: moment.now(),
    };
    if (logLevel === LogLevelName.TRACE || LogLevel[logLevel] > LogLevel.WARN) {
      const traceError = new Error('trace');
      logEntry.trace = traceError.stack
        .split('\n')
        .slice(2)
        .map((line) => line.replace(/\s+at\s+/, ''))
        .join('\n');
    }
    return logEntry;
  }

  private createBadge(logLevelName: string): LogLevelBadge {
    return {
      text: `%c${logLevelName.toLowerCase()}`,
      style: `color:${this.logLevelColors[logLevelName].color}; background-color:${this.logLevelColors[logLevelName].backgroundColor}; padding: 2px 4px; border-radius: 2px`,
    };
  }

  assert(value: any, message?: string, ...optionalParams: any[]): void {
    this.originalConsole.assert(value, message, ...optionalParams);
  }

  clear(): void {
    this.originalConsole.clear();
  }

  count(label?: string): void {
    this.originalConsole.count(label);
  }

  countReset(label?: string): void {
    this.originalConsole.countReset(label);
  }

  dir(obj: any, options?: any): void {
    this.originalConsole.dir(obj, options);
  }

  dirxml(...data: any[]): void {
    this.originalConsole.dirxml(...data);
  }

  group(...label: any[]): void {
    this.originalConsole.group(...label);
  }

  groupCollapsed(...label: any[]): void {
    this.originalConsole.groupCollapsed(...label);
  }

  groupEnd(): void {
    this.originalConsole.groupEnd();
  }

  profile(label?: string): void {
    this.originalConsole.profile(label);
  }

  profileEnd(label?: string): void {
    this.originalConsole.profileEnd(label);
  }

  table(tabularData: any, properties?: ReadonlyArray<string>): void {
    this.originalConsole.table(tabularData, properties);
  }

  time(label?: string): void {
    this.originalConsole.time(label);
  }

  timeEnd(label?: string): void {
    this.originalConsole.timeEnd(label);
  }

  timeLog(label?: string, ...data: any[]): void {
    this.originalConsole.timeLog(label, ...data);
  }

  timeStamp(label?: string): void {
    this.originalConsole.timeStamp(label);
  }
}

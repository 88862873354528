import { Plugin } from '@nuxt/types';
import axios from 'axios';
import { getCookieValueFor } from '~/models/Cookie';
import { CookieKey } from '~/models/CookieKey';
import { WebConfig } from '~/Config';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { Notification, NotificationId, NotificationType } from '~/models/Notification';
import { WarningMessages } from '~/models/MessageTypes';
import { TokenRefresher } from '~/models/auth/TokenRefresher';
import { ExtendedContext } from '~/plugins/ExtendedContext';
import { ErrorKey } from '~/models/error/ErrorKey';

const axiosPlugin: Plugin = ({ store, $log, $tokenRefresher, error: nuxtError }: ExtendedContext, inject: any) => {
  const api = axios.create({
    baseURL: WebConfig.API_URL,
    withCredentials: true,
  });
  const SERVER_ERROR_CODES = [500, 502, 503, 504];
  const SUCCESS_CODES = [200, 201, 202, 203, 204];
  const handleUnauthorizedStatusCode = async (error) => {
    try {
      const sharedLinkAccessId = store.getters['link/linkDataForCurrentCloudObject']?.accessId;
      await $tokenRefresher.refreshExpiredTokens(sharedLinkAccessId);
      error.config.baseURL = WebConfig.API_URL;
      error.config.withCredentials = true;
      if (['post', 'put', 'patch', 'delete'].includes(error.config.method)) {
        const xsrfHeader = getCookieValueFor(CookieKey.XSRF);
        if (xsrfHeader != null) {
          error.config.headers[TokenRefresher.XSRF_TOKEN_HEADER] = xsrfHeader;
        }
      }
      return axios.request(error.config);
    } catch (err) {
      await store.dispatch('user/automaticLogout', null, { root: true });
    }
  };

  const addRequestHeaders = (config: any) => {
    config.headers['user-origin-id'] = store.state.originId;
    if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
      const xsrfHeader = getCookieValueFor(CookieKey.XSRF);
      if (xsrfHeader != null) {
        config.headers[TokenRefresher.XSRF_TOKEN_HEADER] = xsrfHeader;
      }
    }
  };
  const handleForbiddenStatusCodes = async (error) => {
    $log.error('Request forbidden', error);
    if (error?.response?.data?.errorKey === ErrorKey.UPLOAD_LIMIT_EXCEEDED) {
      await store.dispatch<ActionPayload<Notification>>(
        {
          type: 'setNotificationMessage',
          payload: { message: WarningMessages.UPLOAD_LIMIT_EXCEEDED, type: NotificationType.ERROR, duration: 5000 },
        },
        { root: true });
    } else {
      await store.dispatch<ActionPayload<Notification>>(
        {
          type: 'setNotificationMessage',
          payload: { message: WarningMessages.FORBIDDEN_ERROR, type: NotificationType.ERROR, duration: 5000 },
        },
        { root: true });
    }
  };

  const handleServerOnlineStatus = (res: any) => {
    const code = parseInt(res.status);
    if (SUCCESS_CODES.includes(code)
      && store.state.notifications[NotificationId.SERVER_UNREACHABLE]
      && !store.state.notifications[NotificationId.SERVER_REACHABLE]) {
      store.dispatch('displayServerOnlineNotification');
    }
    return res;
  };
  const handleServerErrorStatusCodes = async (error) => {
    $log.error('Server unreachable', error);
    const serverUnavailable = error.code === 502 || error.code === 'ERR_NETWORK';
    if (serverUnavailable) {
      if (!store.state.notifications[NotificationId.SERVER_UNREACHABLE] && store.getters['user/isUser']) {
        await store.dispatch<ActionPayload<Notification>>(
          {
            type: 'setNotificationMessage',
            payload: { id: NotificationId.SERVER_UNREACHABLE, message: WarningMessages.SERVER_UNREACHABLE, type: NotificationType.ERROR, duration: 'permanent' },
          },
          { root: true });
      }
    } else if (!store.state.notifications[NotificationId.SERVER_ERROR]) {
      await store.dispatch<ActionPayload<Notification>>(
        {
          type: 'setNotificationMessage',
          payload: { id: NotificationId.SERVER_ERROR, message: WarningMessages.SERVER_ERROR, type: NotificationType.ERROR, duration: 5000 },
        },
        { root: true });
    }
  };

  api.interceptors.request.use(
    async (config: any) => {
      const sharedLinkAccessId = store.getters['link/linkDataForCurrentCloudObject']?.accessId;
      await $tokenRefresher.refreshExpiredTokens(sharedLinkAccessId);
      addRequestHeaders(config);
      return config;
    }, (error: any) => Promise.reject(error));

  api.interceptors.response.use((config: any) => {
    handleServerOnlineStatus(config);
    return config;
  }, async (error: any) => {
    const code = parseInt(error.response && error.response.status);
    const errorResponseData = error?.response?.data;
    if (code === 401) {
      await handleUnauthorizedStatusCode(error);
    } else if (code === 403) {
      await handleForbiddenStatusCodes(error);
    } else if (SERVER_ERROR_CODES.includes(code) || error.code === 'ERR_NETWORK') {
      await handleServerErrorStatusCodes(error);
    } else if (errorResponseData?.errorKey === ErrorKey.SHARED_LINK_NOT_FOUND) {
      nuxtError(error);
    } else if (axios.isCancel(error)) {
      $log.info('Request canceled');
    } else {
      const errorMessage = code ? `${code}: ${errorResponseData?.message}` : error.message;
      $log.error(`Api Error - ${errorMessage}`);
    }
    return Promise.reject(error);
  });

  inject('api', api);
};

export default axiosPlugin;

import { Vue } from 'nuxt-property-decorator';
import { BackgroundFit } from '~/models/views/BackgroundFit';
import { ObjectId } from '~/models/ObjectId';
import { ViewType } from '~/models/views/ViewType';
import { CloudState, HighlightInfo, MutantWindow, ReviewFilter, TagState, ViewSortingOption } from '~/store/cloud/state';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { FolderTag } from '~/models/tags/FolderTag';
import { FolderTagHierarchy } from '~/store/folder/getters';

// TODO: refactor setting state.registeredWindows by using an Object instead of a map to make use of better state updates. Vue does not support maps
type ViewPositionPayload = { position: number, view: ViewIdentifier };

// https://stackoverflow.com/questions/58421221/using-a-map-inside-of-vuex-state
export default {
  cycleMobileColumnCount(state: CloudState) {
    const windowId = ViewIdentifier.MAIN_VIEW;
    const window = state.registeredWindows[windowId];
    const currentColumnCount = window.viewOptions.mosaic.columnCount;
    const columnCount = currentColumnCount > 2 ? 1 : currentColumnCount + 1;
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, columnCount } } });
  },
  setActiveWindowId(state: CloudState, windowId: ViewIdentifier) {
    state.activeWindowId = windowId;
  },
  toggleNavigationMode(state: CloudState, windowId: string) {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, isInNavigationMode: !window.isInNavigationMode });
  },
  setBackgroundFit(state: CloudState, { windowId, backgroundFit }: { windowId: string; backgroundFit: BackgroundFit }) {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, backgroundFit });
  },
  setViewSortingOption(state: CloudState, { windowId, option }: { windowId: string; option: ViewSortingOption }) {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, sortedBy: option });
  },
  setBackgroundOpacity(state: CloudState, { windowId, opacity }: {windowId: string; opacity: number}) {
    const window = state.registeredWindows[windowId];
    document.documentElement.style.setProperty(`--${windowId.toLowerCase()}-background-opacity`, opacity.toString());
    Vue.set(state.registeredWindows, windowId, { ...window, backgroundOpacity: opacity });
  },
  setElementsOpacity(state: CloudState, { windowId, opacity }: {windowId: string; opacity: number}) {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, elementsOpacity: opacity });
  },
  viewChanged: (state: CloudState, { windowId, view }: { windowId: string; view: ViewType }) => {
    const window = state.registeredWindows[windowId] as MutantWindow;
    if (window.viewOptions.activeViewType !== view) {
      Vue.set(state.centeredItemInView, windowId, 0);
      Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, activeViewType: view } });
    }
  },
  setGridMargin: (state: CloudState, { windowId, margin }: {windowId: string; margin: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, grid: { ...window.viewOptions.grid, margin } } });
  },
  setGridMarginFactor: (state: CloudState, { windowId, marginFactor }: { windowId: string, marginFactor: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, grid: { ...window.viewOptions.grid, marginFactor } } });
    localStorage.setItem('grid.marginFactor', marginFactor.toString(10));
  },
  setMagnifyMargin: (state: CloudState, { windowId, margin }: {windowId: string; margin: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, magnify: { ...window.viewOptions.magnify, margin } } });
  },
  setMagnifyMarginFactor: (state: CloudState, { windowId, marginFactor }: { windowId: string; marginFactor: number }) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, magnify: { ...window.viewOptions.magnify, marginFactor } } });
  },
  setContactSheetMargin: (state: CloudState, { windowId, margin }: {windowId: string; margin: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, contactSheet: { ...window.viewOptions.contactSheet, margin } } });
    localStorage.setItem('contactSheet.margin', margin.toString());
  },
  setContactSheetMarginFactor: (state: CloudState, { windowId, marginFactor }: { windowId: string; marginFactor: number }) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, contactSheet: { ...window.viewOptions.contactSheet, marginFactor } } });
  },
  setGridRowHeight: (state: CloudState, { windowId, rowHeight }: { windowId: string; rowHeight: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, grid: { ...window.viewOptions.grid, rowHeight } } });
    localStorage.setItem('grid.rowHeight', rowHeight.toString(10));
  },
  setMosaicMargin: (state: CloudState, { windowId, margin }: { windowId: string, margin: number }) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, margin } } });
  },
  setMosaicMarginFactor: (state: CloudState, { windowId, marginFactor }: { windowId: string, marginFactor: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, marginFactor } } });
    localStorage.setItem('mosaic.marginFactor', marginFactor.toString(10));
  },
  setMosaicColumnCount: (state: CloudState, { windowId, columnCount, persist = true }: { windowId: string; columnCount: number, persist: boolean }) => {
    const window = state.registeredWindows[windowId];
    if (columnCount >= window.viewOptions.mosaic.minColumnCount && columnCount <= window.viewOptions.mosaic.maxColumnCount) {
      const window = state.registeredWindows[windowId];
      Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, columnCount } } });
      if (persist) {
        localStorage.setItem('mosaic.columnCount', columnCount.toString(10));
      }
    }
  },
  setContactSheetColumnCount: (state: CloudState, { windowId, columnCount }: { windowId: string; columnCount: number }) => {
    const window = state.registeredWindows[windowId];
    if (columnCount >= window.viewOptions.contactSheet.minColumnCount && columnCount <= window.viewOptions.contactSheet.maxColumnCount) {
      Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, contactSheet: { ...window.viewOptions.contactSheet, columnCount } } });
      localStorage.setItem('contactSheet.columnCount', columnCount.toString(10));
    }
  },
  setMosaicColumnWidth: (state: CloudState, { windowId, columnWidth }: { windowId: string, columnWidth: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, columnWidth } } });
    localStorage.setItem('mosaic.columnWidth', columnWidth.toString(10));
  },
  setMosaicMaxColumnWidth: (state: CloudState, { windowId, maxColumnWidth }: { windowId: string, maxColumnWidth: number }) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, {
      ...window,
      viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, maxColumnWidth, columnWidth: window.viewOptions.mosaic.columnWidth > maxColumnWidth ? maxColumnWidth : window.viewOptions.mosaic.columnWidth } },
    });
  },
  setGridMaxRowHeight: (state: CloudState, { windowId, maxRowHeight }: { windowId: string, maxRowHeight: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, {
      ...window,
      viewOptions: { ...window.viewOptions, grid: { ...window.viewOptions.grid, maxRowHeight, rowHeight: window.viewOptions.grid.rowHeight > maxRowHeight ? maxRowHeight : window.viewOptions.grid.rowHeight } },
    });
  },
  setMosaicMaxColumnCount: (state: CloudState, { windowId, maxColumnCount }: { windowId: string; maxColumnCount: number}) => {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, {
      ...window,
      viewOptions: { ...window.viewOptions, mosaic: { ...window.viewOptions.mosaic, maxColumnCount, columnCount: window.viewOptions.mosaic.columnCount > maxColumnCount ? maxColumnCount : window.viewOptions.mosaic.columnCount } },
    });
  },
  setColorFilter: (state: CloudState, { windowId, color }: {windowId: string, color: number[] | null }) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    Vue.set(state.registeredWindows, windowId, { ...window, colorFilter: color });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, colorFilter: color });
  },
  setReviewFilterState: (state: CloudState, { windowId, active }: {windowId: string, active: boolean}) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    Vue.set(state.registeredWindows, windowId, { ...window, isReviewFilterActive: active });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, isReviewFilterActive: active });
  },
  selectTag: (state: CloudState, { windowId, tag }: { windowId: string, tag: FolderTagHierarchy }) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    const folderTagFilter = { state: TagState.FILTER, tags: flattenTagHierarchy(tag) };
    Vue.set(state.registeredWindows, windowId, { ...window, folderTagFilter });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, folderTagFilter });
  },
  deSelectAllTags: (state: CloudState, windowId: string) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    const folderTagFilter = { state: TagState.CLEAR_TAGS, tags: [] };
    Vue.set(state.registeredWindows, windowId, { ...window, folderTagFilter });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, folderTagFilter });
  },
  addTagsToSelectedTags: (state: CloudState, { windowId, tag }: { windowId: string, tag: FolderTagHierarchy }) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    const oldSelectedTags = window.folderTagFilter.tags ?? [];
    const allTagsToAdd = flattenTagHierarchy(tag).filter(t => !oldSelectedTags.some(old => old.id === t.id));
    const folderTagFilter = { state: TagState.FILTER, tags: [...oldSelectedTags, ...allTagsToAdd] };
    Vue.set(state.registeredWindows, windowId, { ...window, folderTagFilter });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, folderTagFilter });
  },
  removeTagFromSelectedTags: (state: CloudState, { windowId, tag }: { windowId: string, tag: FolderTagHierarchy }) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    const allTagsToRemove = flattenTagHierarchy(tag);
    const updatedTags = window.folderTagFilter?.tags?.filter(t => !allTagsToRemove.some(at => at.id === t.id));
    const tagState = updatedTags.length > 0 ? TagState.FILTER : TagState.CLEAR_TAGS;
    const folderTagFilter = { state: tagState, tags: [...updatedTags] };
    Vue.set(state.registeredWindows, windowId, { ...window, folderTagFilter });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, folderTagFilter });
  },
  clearFolderTags: (state: CloudState, { windowId, tagState }: { windowId: string, tagState: TagState }) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    const folderTagFilter = { state: tagState, tags: [] };
    Vue.set(state.registeredWindows, windowId, { ...window, folderTagFilter });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, folderTagFilter });
  },
  setReviewFilter: (state: CloudState, { windowId, reviewFilter }: {windowId: string, reviewFilter: ReviewFilter}) => {
    const window = state.registeredWindows[windowId];
    const navigationWindow = state.registeredWindows[ViewIdentifier.NAVIGATION_VIEW];
    const newReviewFilter = [...reviewFilter];
    Vue.set(state.registeredWindows, windowId, { ...window, reviewFilter: newReviewFilter });
    Vue.set(state.registeredWindows, ViewIdentifier.NAVIGATION_VIEW, { ...navigationWindow, reviewFilter: newReviewFilter });
  },
  setIlluminationGrade: (state: CloudState, value: number) => {
    state.illuminationGrade = value;
  },
  setImmersionMode: (state: CloudState, value: boolean) => {
    state.showInImmersionMode = value;
  },
  setViewIds(state: CloudState, { windowId, objectIds }: { windowId: string; objectIds: ObjectId[] }) {
    const window = state.registeredWindows[windowId];
    Vue.set(state.registeredWindows, windowId, { ...window, viewIds: objectIds });
  },
  setWindow(state: CloudState, window: MutantWindow) {
    Vue.set(state.registeredWindows, window.id, window);
  },
  setHighlightInfo(state: CloudState, info: HighlightInfo) {
    state.highlightInfo = info;
  },
  setCenteredItemInView(state: CloudState, instructions: ViewPositionPayload) {
    Vue.set(state.centeredItemInView, instructions.view, instructions.position);
  },
};

function flattenTagHierarchy(tag: FolderTagHierarchy): FolderTag[] {
  const allTags = [];
  allTags.push(tag.tag);
  if (tag.children.length > 0) {
    for (const child of tag.children) {
      allTags.push(...flattenTagHierarchy(child));
    }
  }
  return allTags;
}

import { AssetFilter } from '~/models/asset/AssetFilter';
import { Asset } from '~/models/Asset';

export const byIncludesVersionsOrSubstitutes = (versionSubstituteMap: Map<number, number>): AssetFilter<Asset> => assets => {
  const filteredAssets = [];
  Array.from(versionSubstituteMap.keys()).forEach(v => {
    const found = assets.find(a => a.version === v);
    if (found) {
      filteredAssets.push(found);
    } else {
      const substituteVersion = versionSubstituteMap.get(v);
      const substitute = assets.find(a => a.version === substituteVersion);
      if (substitute) {
        filteredAssets.push(substitute);
      }
    }
  });
  return filteredAssets;
};

import Vue from 'vue';
import moment from 'moment';
import _cloneDeep from 'lodash.clonedeep';
import { User } from '~/models/user/User';
import { Asset } from '~/models/Asset';
import { ChangeSet, mergeChangeSet } from '~/models/ChangeSet';
import { UserJoinedSelectionEventData } from '~/models/socket/events/UserJoinedSelectionEventData';
import { UserLeftSelectionEventData } from '~/models/socket/events/UserLeftSelectionEventData';
import Item from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Selection, { SelectionBackground } from '~/models/selection/Selection';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { SelectionState } from '~/store/selection/state';
import { Guest } from '~/models/user/Guest';
import { Owner } from '~/models/selection/Owner';
import { ViewType } from '~/models/views/ViewType';
import { SnapshotImageCreatedEventData } from '~/models/socket/events/SnapshotImageCreatedEventData';
import { SnapshotImage } from '~/models/SnapshotImage';
import { sortItemsByOrderAsc } from '~/models/item/sortItemsByOrderAsc';

export const COLOR_PALETTE = [
  '#527318',
  '#f64b3c',
  '#9de3d0',
  '#d63447',
  '#f57b51',
  '#f6eedf',
  '#d1cebd',
  '#f688bb',
  '#e8f9e9',
  '#ffe75e',
  '#feb72b',
  '#baf1a1',
  '#899857',
];
export const DEFAULT_USER_SELECTION_COLOR = '#fb9f00';

export default {
  setSelectionBackground(state: SelectionState, {
    selectionId,
    background,
  }: { selectionId: string, background: SelectionBackground }) {
    const selection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, { ...selection, background });
  },
  addActiveUserToSelection(state: SelectionState, {
    origin,
    event,
  }: { origin: string, event: UserJoinedSelectionEventData }) {
    const selection = state.selections[event.selectionId];
    Vue.set(state.selections, event.selectionId, {
      ...selection,
      activeUsers: selection.activeUsers
        ? [...selection.activeUsers, { origin, id: event.userId }]
        : [{
            origin,
            id: event.userId,
          }],
    });
  },
  removeActiveUserFromSelection(state: SelectionState, {
    origin,
    event,
  }: { origin: string, event: UserLeftSelectionEventData }) {
    const selection = state.selections[event.selectionId];
    Vue.set(state.selections, event.selectionId, {
      ...selection,
      activeUsers: selection.activeUsers.filter(u => !(u.id === event.userId && u.origin === origin)),
    });
  },
  toggleSelectedItems(state: SelectionState) {
    state.showSelectedItems = !state.showSelectedItems;
  },
  chooseSelection(state: SelectionState, selection: Selection) {
    if (selection.items) {
      const globalSelectedItems = { ...state.globalSelectedItems };
      selection.items.forEach((i) => {
        globalSelectedItems[i.id] = { selectedBy: [selection.owner] };
      });
      state.globalSelectedItems = globalSelectedItems;
      state.globalSelectedItemsOrdered = [...selection.items].map((i, idx) => {
        i.position.order = idx;
        return i;
      });
    }
    const oldSelection = state.selections[selection.id];
    Vue.set(state.selections, selection.id, {
      ...oldSelection,
      ...selection,
      selected: true,
    });
    Object.values(state.selections).forEach((s) => {
      Vue.set(state.selections, s.id, { ...s, selected: false });
    });
  },
  addPreviewItems(state: SelectionState, { items, selectionId }: { items: SelectionItem[], selectionId: string }) {
    Vue.set(state.selectionItems, selectionId, [
      ...(state.selectionItems[selectionId] || []).filter(item => !items.some(i => i.id === item.id)),
      ...items,
    ]);
    state.itemsInSelectionId.addMultiple(selectionId, items.map(i => i.itemId || i.item.id));
  },
  loadSelectionSuccess(state: SelectionState, loadedSelection: Selection) {
    const selection = state.selections[loadedSelection.id];
    Vue.set(state.selections, loadedSelection.id, {
      ...selection,
      ...loadedSelection,
      items: [],
    });
    Vue.set(state.selectionItems, loadedSelection.id, loadedSelection.items.map(i => ({ ...i, itemId: i.itemId || i.item.id })));
    state.itemsInSelectionId.addMultiple(loadedSelection.id, loadedSelection.items.map(i => i.itemId || i.item.id));
  },
  loadSelectionsSuccess(state: SelectionState, loadedSelections: Selection[]) {
    for (const selection of loadedSelections) {
      const updatedSelection = state.selections[selection.id];
      Vue.set(state.selections, selection.id, { ...selection, ...updatedSelection, items: [] });
      if (selection.items) {
        Vue.set(state.selectionItems, selection.id, selection.items.map(i => ({ ...i, itemId: i.itemId || i.item.id })));
        state.itemsInSelectionId.addMultiple(selection.id, selection.items.map(i => i.itemId || i.item.id));
      }
    }
  },
  applySelectionChanges(state: SelectionState, {
    selectionId,
    changes,
    itemChangeSet,
    storeChangeSets = true,
  }: { selectionId: string, changes: Selection, itemChangeSet: ChangeSet<SelectionItem>, storeChangeSets?: boolean }) {
    if (changes && storeChangeSets) {
      const changeSet = {
        updates: [{
          id: selectionId,
          ...changes,
        }],
      };
      state.selectionChangeSet = (state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet);
    }
    if (changes) {
      const oldSelection = state.selections[selectionId];
      Vue.set(state.selections, selectionId, { ...oldSelection, ...changes });
    }
    if (itemChangeSet) {
      const itemsToBeRemoved = itemChangeSet.removals?.length ? [...(state.selectionItems[selectionId] || [])].filter(i => itemChangeSet.removals.includes(i.id)).map(i => i.itemId || i.item.id) : [];
      let originalSelectionItems = [...(state.selectionItems[selectionId] || [])];
      if (itemChangeSet.removals?.length || itemChangeSet.inserts?.length) {
        originalSelectionItems = originalSelectionItems.filter(i => !(itemChangeSet.removals?.includes(i.id) || itemChangeSet.inserts?.some(insertedItem => insertedItem.id === i.id)));
      }
      Vue.set(state.selectionItems, selectionId, itemChangeSet
        ? [...originalSelectionItems.map(i => {
            const update = itemChangeSet.updates ? itemChangeSet.updates.find(u => u.id === i.id) : null;
            return update
              ? {
                  ...i,
                  ...update,
                  item: {
                    ...i.item,
                    ...update.item,
                  },
                  position: {
                    ...i.position,
                    ...update.position,
                  },
                }
              : i;
          }),
          ...(itemChangeSet.inserts ? itemChangeSet.inserts : []),
          ]
        : state.selectionItems[selectionId]);
      let mergedChangeSet = state.selectionItemChangeSet[selectionId];
      if (storeChangeSets) {
        mergedChangeSet = mergedChangeSet ? mergeChangeSet(mergedChangeSet, itemChangeSet) : itemChangeSet;
      }
      state.selectionItemChangeSet[selectionId] = mergedChangeSet;
      if (itemChangeSet.inserts?.length) {
        state.itemsInSelectionId.addMultiple(selectionId, itemChangeSet.inserts.map(i => i.itemId || i.item.id));
      }
      if (itemsToBeRemoved) {
        state.itemsInSelectionId.deleteMultiple(itemsToBeRemoved);
      }
    }
    if (itemChangeSet?.inserts?.length !== 0 || itemChangeSet?.removals?.length !== 0) {
      const oldSelection = state.selections[selectionId];
      Vue.set(state.selections, selectionId, { ...oldSelection, itemCount: state.selectionItems[selectionId]?.length ?? 0 });
    }
  },
  addSelection(state: SelectionState, { selection, buildChangeSet = true }: { selection: Selection; buildChangeSet: boolean; }) {
    const oldSelection = state.selections[selection.id];
    const selectionItemCount = selection ? selection.itemCount : 0;
    const newSelectionItemCount = selection.itemCount ? selection.itemCount : selection?.items.length;
    const itemCount = oldSelection ? oldSelection.itemCount + selectionItemCount : newSelectionItemCount;
    Vue.set(state.selections, selection.id, { ...oldSelection, ...selection, itemCount });
    if (selection.items) {
      Vue.set(state.selectionItems, selection.id, selection.items);
    }
    if (buildChangeSet) {
      const newSelectionProperties = _cloneDeep(selection);
      delete newSelectionProperties.items;
      delete newSelectionProperties.owner;
      const changeSet: ChangeSet<Selection> = {
        inserts: [{
          ...newSelectionProperties,
        }],
      };
      state.selectionChangeSet = state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet;
      if (selection.items) {
        state.selectionItemChangeSet[selection.id] = {
          inserts: selection.items.map((i) => ({ id: i.id, itemId: i.itemId || i.item.id, position: i.position } as SelectionItem)),
        };
      }
    }
  },
  saveSelectionSuccess(state: SelectionState, updatedSelection: Selection) {
    state.flaggedForDelete = {};
    const oldSelection = state.selections[updatedSelection.id];
    Vue.set(state.selections, updatedSelection.id, { ...oldSelection, ...updatedSelection });
    Vue.set(state.selectionItems, updatedSelection.id, [...state.selectionItems[updatedSelection.id].filter(item => !updatedSelection.items.some(i => i.id === item.id)), ...updatedSelection.items]);
    state.itemsInSelectionId.addMultiple(updatedSelection.id, updatedSelection.items.map(i => i.itemId || i.item.id));
  },
  // Only for instant 'visual' pre selection on click (intent of selecting an item in folder view,
  // if a double click is detected 'deSelectItem' is called with the second click,
  // if not it is properly added to the selection via 'addItemToSelection')
  selectItem(state: SelectionState, { item, actor }: { item: ItemWithPosition, actor: User | Guest }) {
    item = _cloneDeep(item);
    const selectedBy = 'id' in actor ? { id: actor.id, username: actor.username } : { id: null, username: actor.username };
    Vue.set(state.globalSelectedItems, item.id, { selectedBy: [selectedBy] });
  },
  deSelectItem(state: SelectionState, item: ItemWithPosition) {
    item = _cloneDeep(item);
    Vue.delete(state.globalSelectedItems, item.id);
    state.globalSelectedItemsOrdered = state.globalSelectedItemsOrdered
      .filter(i => i.id !== item.id)
      .map((i, idx) => {
        i.position.order = idx;
        return i;
      });
  },
  flagItemsForDelete(state: SelectionState, itemIds: string[]) {
    const flaggedForDelete = {};
    const globalSelectedItems = { ...state.globalSelectedItems };
    itemIds.forEach(id => {
      flaggedForDelete[id] = true;
      delete globalSelectedItems[id];
    });
    state.globalSelectedItems = globalSelectedItems;
    state.flaggedForDelete = flaggedForDelete;
  },
  // When we delete items from a folder, these items may have been used in one or multiple selection, so we search all selections for those items and filter them
  removeFolderItemsFromSelections(state: SelectionState, itemIds: string[]) {
    const impactedSelections = [];
    const deletedItemsMap = new Map();
    for (const itemId of itemIds) {
      const selectionsWithItem = state.itemsInSelectionId.get(itemId);
      if (selectionsWithItem) {
        impactedSelections.push(...selectionsWithItem);
        for (const selectionId of selectionsWithItem) {
          state.itemsInSelectionId.get(selectionId).delete(itemId);
        }
      }
      deletedItemsMap.set(itemId, itemId);
      state.itemsInSelectionId.delete(itemId);
    }
    for (const selectionId of impactedSelections) {
      Vue.set(state.selectionItems, selectionId, state.selectionItems[selectionId]?.filter(i => {
        return !deletedItemsMap.has(i.itemId || i.item.id);
      })
        .sort(sortItemsByOrderAsc)
        .map((i, idx) => {
          i.position.order = idx;
          return i;
        }));
    }
    const globalSelectedItems = { ...state.globalSelectedItems };
    for (const selectionItemId of itemIds) {
      delete globalSelectedItems[selectionItemId];
    }
    state.globalSelectedItems = globalSelectedItems;
    state.globalSelectedItemsOrdered = state.globalSelectedItemsOrdered.filter(i => !itemIds.includes(i.itemId || i.item?.id))?.map((i, idx) => {
      i.position.order = idx;
      return i;
    });
  },
  removeItemsFromSelection(state: SelectionState, {
    items,
    selectionId,
    buildChangeSet = true,
  }: { items: ItemWithPosition[], selectionId: string, buildChangeSet: boolean }) {
    const itemChangeSet: ChangeSet<SelectionItem> = { updates: [], removals: [] };
    const globalSelectedItems = { ...state.globalSelectedItems };
    for (const item of items) {
      delete globalSelectedItems[item.id];
      if (buildChangeSet) {
        itemChangeSet.removals.push(item.id);
      }
    }
    state.globalSelectedItems = globalSelectedItems;
    state.globalSelectedItemsOrdered = state.globalSelectedItemsOrdered
      .filter(i => !items.some(removedItem => removedItem.id === i.id))
      .map((i, idx) => {
        i.position.order = idx;
        return i;
      });
    state.showSelectedItems = true;
    Vue.set(state.selectionItems, selectionId, state.selectionItems[selectionId]
      .filter(selectionItem => {
        const isRemoved = items.some(i => selectionItem.id === i.id);
        if (isRemoved) {
          state.itemsInSelectionId.delete(selectionItem.itemId || selectionItem.item.id);
        }
        return !isRemoved;
      })
      .map((selectionItem, idx) => {
        selectionItem.position = {
          ...selectionItem.position,
          order: idx,
        };
        if (buildChangeSet) {
          itemChangeSet.updates.push({
            id: selectionItem.id,
            position: {
              order: idx,
            },
          });
        }
        return selectionItem;
      }));
    const oldSelection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, {
      ...oldSelection,
      itemCount: state.selectionItems[selectionId]?.length ?? 0,
      modified: moment(Date.now()).toISOString(),
    });
    if (buildChangeSet) {
      state.selectionItemChangeSet[selectionId] = state.selectionItemChangeSet[selectionId]
        ? mergeChangeSet(state.selectionItemChangeSet[selectionId], itemChangeSet)
        : itemChangeSet;
    }
  },
  addItemsToSelection(state: SelectionState, {
    items,
    selectionId,
    buildChangeSet = true,
  }: { items: SelectionItem[], selectionId: string, actor: User | Guest, buildChangeSet: boolean }) {
    const itemChangeSet: ChangeSet<SelectionItem> = { inserts: [] };
    if (buildChangeSet) {
      items.forEach(item => {
        itemChangeSet.inserts.push(item);
      });
    }
    state.selectionItemChangeSet[selectionId] = buildChangeSet
      ? (state.selectionItemChangeSet[selectionId] ? mergeChangeSet(state.selectionItemChangeSet[selectionId], itemChangeSet) : itemChangeSet)
      : state.selectionItemChangeSet[selectionId];
    Vue.set(state.selectionItems, selectionId, [...state.selectionItems[selectionId], ...items.map(i => ({ ...i, itemId: i.itemId || i.item.id }))]);
    const oldSelection = state.selections[selectionId];
    Vue.set(state.selections, selectionId,
      {
        ...oldSelection,
        modified: moment(Date.now()).toISOString(),
        itemCount: state.selectionItems[selectionId]?.length ?? 0,
        saved: false,
        showSelectedItems: true,
        items: [],
      });
    state.itemsInSelectionId.addMultiple(selectionId, items.map(i => i.itemId || i.item.id));
  },
  addItemsToGlobalSelection(state: SelectionState, { items, actor }: { items: ItemWithPosition[], actor: User | Guest }) {
    const selectionOwner: Owner = 'id' in actor
      ? {
          id: actor.id,
          username: actor.username,
        }
      : { username: actor.username };
    const selectedBy: Owner[] = [selectionOwner];
    const globalSelectedItems = { ...state.globalSelectedItems };
    items.forEach(item => {
      globalSelectedItems[item.id] = { selectedBy };
    });
    state.globalSelectedItems = globalSelectedItems;
    state.globalSelectedItemsOrdered = [...state.globalSelectedItemsOrdered.filter(i => !items.some(insertedItem => insertedItem.id === i.id)), ...items]
      .map((i, idx) => {
        i.position.order = idx;
        return i;
      });
  },
  applyGlobalSelectionChanges(state: SelectionState, { changeSet, actor }: { changeSet: ChangeSet<ItemWithPosition>, actor: User | Guest }) {
    const selectionOwner: Owner = 'id' in actor
      ? {
          id: actor.id,
          username: actor.username,
        }
      : { username: actor.username };
    const selectedBy: Owner[] = [selectionOwner];
    let globalSelectedItemsOrdered = [...state.globalSelectedItemsOrdered];
    const globalSelectedItems = { ...state.globalSelectedItems };
    if (changeSet.inserts) {
      for (const item of changeSet.inserts) {
        globalSelectedItems[item.id] = { selectedBy };
        globalSelectedItemsOrdered.push({ ...item });
      }
    }
    if (changeSet.updates) {
      globalSelectedItemsOrdered = globalSelectedItemsOrdered.map(i => {
        const updateItem = changeSet.updates.find(updated => updated.id === i.id);
        return updateItem ? { ...i, ...updateItem, position: { ...i.position, ...updateItem.position } } : i;
      });
    }
    if (changeSet.removals) {
      for (const itemId of changeSet.removals) {
        delete globalSelectedItems[itemId];
      }
      globalSelectedItemsOrdered = globalSelectedItemsOrdered.filter(i => changeSet.removals.includes(i.id));
    }
    state.globalSelectedItems = globalSelectedItems;
    state.globalSelectedItemsOrdered = globalSelectedItemsOrdered;
  },
  initializeSelectedColors(state: SelectionState, currentUser: User) {
    const selectedColors = new Map<string, string>();
    if (currentUser && currentUser.id) {
      selectedColors.set(currentUser.id, DEFAULT_USER_SELECTION_COLOR);
    }
    Object.values(state.selections)
      .reduce((selectionOwners: string[], s: Selection) => {
        return !s.owner || selectionOwners.includes(s.owner.id) ? selectionOwners : [...selectionOwners, s.owner.id];
      }, [])
      .forEach((uniqueSelectionOwner, idx) => {
        if (idx < COLOR_PALETTE.length && !selectedColors.has(uniqueSelectionOwner)) {
          selectedColors.set(uniqueSelectionOwner, COLOR_PALETTE[idx]);
        }
      });
    state.selectedColors = selectedColors;
  },
  dismissGlobalSelection(state: SelectionState) {
    state.globalSelectedItems = {};
    state.globalSelectedItemsOrdered = [];
  },
  dismissItemsFromGlobalSelection(state: SelectionState, items: Item[]) {
    const globalSelectedItems = { ...state.globalSelectedItems };
    items.forEach(item => {
      delete globalSelectedItems[item.id];
    });
    const itemIds = items.map(i => i.id);
    state.globalSelectedItemsOrdered = state.globalSelectedItemsOrdered.filter(i => !itemIds.includes(i.item.id));
    state.globalSelectedItems = globalSelectedItems;
  },
  setSelectionChangeSet(state: SelectionState, changeSet: ChangeSet<Selection>) {
    state.selectionChangeSet = changeSet;
  },
  setSelectionItemChangeSet(state: SelectionState, { selectionId, changeSet }: { selectionId: string; changeSet: ChangeSet<SelectionItem>; }) {
    state.selectionItemChangeSet[selectionId] = changeSet;
  },
  applyItemChanges(state: SelectionState, { selectionId, itemChanges }: { selectionId, itemChanges: Map<string, Item> }) {
    state.selectionItems[selectionId] = state.selectionItems[selectionId]?.map(i => {
      if (itemChanges.has(i.itemId)) {
        i.item = itemChanges.get(i.itemId);
      }
      return i;
    });
  },
  applyItemChangesToGlobalSelection(state: SelectionState, itemChanges: Map<string, Item>) {
    state.globalSelectedItemsOrdered = state.globalSelectedItemsOrdered.map(i => {
      if (itemChanges.has(i.itemId)) {
        i.item = itemChanges.get(i.itemId);
      }
      return i;
    });
  },
  removeSelection: (state: SelectionState, { id, buildChangeSet = true }: { id: string; buildChangeSet: boolean; }) => {
    Vue.delete(state.selections, id);
    const globalSelectedItems = { ...state.globalSelectedItems };
    state.selectionItems[id]?.forEach(i => {
      delete globalSelectedItems[i.id];
    });
    state.globalSelectedItems = globalSelectedItems;
    state.globalSelectedItemsOrdered = [];
    state.itemsInSelectionId.delete(id);
    Vue.delete(state.selectionItems, id);
    if (buildChangeSet) {
      const changeSet: ChangeSet<Selection> = {
        removals: [id],
      };
      state.selectionChangeSet = state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet;
    }
  },
  setSelectionShared(state: SelectionState, selectionId: string) {
    const selection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, {
      ...selection,
      isShared: true,
    });
  },
  deleteSharedLinkSuccess(state: SelectionState, { selectionId }: { selectionId: string }) {
    const selection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, {
      ...selection,
      isShared: false,
    });
  },
  assetAdded(state: SelectionState, asset: Asset) {
    Object.entries(state.selectionItems).forEach(([selectionId, i]) => {
      i.forEach(item => {
        if ((item.itemId === asset.itemId || item.item?.id === asset.itemId) && !item.item.assets.some(a => a.id === asset.id)) {
          const oldSelection = state.selections[selectionId];
          Vue.set(state.selections, selectionId, { sizeInCloud: (parseInt(oldSelection.sizeInCloud, 10) + asset.size).toString() });
          Vue.set(state.selectionItems, selectionId, {
            ...item,
            item: {
              ...item.item,
              assets: [...item.item.assets, asset],
            },
          });
        }
      });
    });
  },
  setSelectionName(state: SelectionState, { name, selectionId, buildChangeSet = true }: { name: string, selectionId: string, buildChangeSet: boolean }) {
    const oldSelection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, { ...oldSelection, name });
    if (buildChangeSet) {
      const changeSet: ChangeSet<Selection> = {
        updates: [{
          id: selectionId,
          name,
        }],
      };
      state.selectionChangeSet = state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet;
    }
  },
  setSelectionSubtitle(state: SelectionState, { subtitle, selectionId, buildChangeSet = true }: { subtitle: string, selectionId: string, buildChangeSet: boolean }) {
    const oldSelection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, { ...oldSelection, subtitle });
    if (buildChangeSet) {
      const changeSet: ChangeSet<Selection> = {
        updates: [{
          id: selectionId,
          subtitle,
        }],
      };
      state.selectionChangeSet = state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet;
    }
  },
  setSelectionDescription(state: SelectionState, { description, selectionId, buildChangeSet = false }: { description: string, selectionId: string, buildChangeSet: boolean }) {
    const oldSelection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, { ...oldSelection, description });
    if (buildChangeSet) {
      const changeSet: ChangeSet<Selection> = {
        updates: [{
          id: selectionId,
          description,
        }],
      };
      state.selectionChangeSet = state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet;
    }
  },
  setSelectionViewType(state: SelectionState, { viewType, selectionId, buildChangeSet = false }: { viewType: ViewType, selectionId: string, buildChangeSet: boolean }) {
    const oldSelection = state.selections[selectionId];
    Vue.set(state.selections, selectionId, { ...oldSelection, viewType });
    if (buildChangeSet) {
      const changeSet: ChangeSet<Selection> = {
        updates: [{
          id: selectionId,
          viewType,
        }],
      };
      state.selectionChangeSet = state.selectionChangeSet ? mergeChangeSet(state.selectionChangeSet, changeSet) : changeSet;
    }
  },
  addSnapshotImageFromOrigin(state: SelectionState, data: SnapshotImageCreatedEventData) {
    const oldSelection = state.selections[data.selectionId];
    Vue.set(state.selections, data.selectionId, {
      ...oldSelection,
      snapshotImages: [data as SnapshotImage, ...oldSelection.snapshotImages.filter(oi => oi.id !== data.id)],
    });
  },
};

import moment from 'moment';
// @ts-ignore
import * as Vibrant from 'node-vibrant';
import _cloneDeep from 'lodash.clonedeep';
import {
  Asset,
  AssetWithFolderTag,
  getLargestAsset,
  getLargestAssetBelowWidth,
  itemHasAssetVersions,
  ORIGINAL_ASSET_VERSION,
  RAW_ASSET_VERSION,
  transformToUnit
} from '~/models/Asset';
import Folder from '~/models/Folder';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { MutantContentItems, MutantContentView, MutantContentViewType } from '~/models/views/MutantContentView';
import { ObjectId } from '~/models/ObjectId';
import Selection from '~/models/selection/Selection';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { RootState } from '~/store/state';
import {
  CloudState,
  FilterOption,
  FolderTagFilter,
  MutantWindow,
  ReviewFilter,
  TagState,
  ViewSortingOption
} from '~/store/cloud/state';
import { CloudObject } from '~/models/cloud/CloudObject';
import { CloudObjectFilter } from '~/models/cloud/CloudObjectFilter';
import { UnitSize } from '~/models/UnitSize';
import { ViewType } from '~/models/views/ViewType';
import { GlobalSelection } from '~/models/selection/GlobalSelection';
import { FolderItem } from '~/models/item/FolderItem';
import { FileOption, SelectedItemsOption } from '~/models/DownloadOptions';
import { byLargestBelowWidth } from '~/models/asset/filters/byLargestBelowWidth';
import { AssetList } from '~/models/asset/AssetList';
import { ItemListBuilder } from '~/models/item/ItemListBuilder';
import { byIncludesVersions } from '~/models/asset/filters/byIncludesVersions';
import { AssetFilter } from '~/models/asset/AssetFilter';
import { combineORFilters } from '~/models/filters/combineORFilters';
import { combineANDFilters } from '~/models/filters/combineANDFilters';
import { byIsThumbnail } from '~/models/asset/filters/byIsThumbnail';
import { ItemList } from '~/models/item/ItemList';
import { byHasAssets } from '~/models/item/filters/byHasAssets';
import { byOnlyLargest } from '~/models/asset/filters/byOnlyLargest';
import { byContainsOfflineAsset } from '~/models/item/filters/byContainsOfflineAsset';
import { CloudObjectBuilder } from '~/models/cloud/CloudObjectBuilder';

export interface RatingsFileTypes {
  raws: number,
  originals: number,
  total: number,
}

export enum OverviewType {
  ALL = 'ALL',
  FILTERED = 'FILTERED',
  ORIGINALS = 'ORIGINALS',
  SELECTED = 'SELECTED',
  UPLOAD_PENDING = 'UPLOAD_PENDING',
}

export interface InfoBadgeData {
  type: OverviewType;
  title: string;
  count: number;
  size: string;
  color?: 'orange' | 'yellow' | 'grey' | 'green';
}

export type ItemCountOverviewMap = Record<OverviewType, InfoBadgeData>;

export default {
  folderTagFilter: (state: CloudState): FolderTagFilter => {
    return state.registeredWindows[ViewIdentifier.MAIN_VIEW].folderTagFilter;
  },
  folderTagFilterHasEntries: (_state: CloudState, getters: any): boolean => {
    return getters.folderTagFilter?.state !== TagState.CLEAR_TAGS;
  },
  reviewFilter: (state: CloudState): ReviewFilter => {
    return state.registeredWindows[ViewIdentifier.MAIN_VIEW].reviewFilter;
  },
  aggregatedFilterOptions: (_state: CloudState, getters: any): {
    reviewFilter: ReviewFilter,
    folderTagFilter: FolderTagFilter,
  } => {
    return {
      reviewFilter: getters.reviewFilter,
      folderTagFilter: getters.folderTagFilter,
    };
  },
  reviewFilterHasEntries: (state: CloudState): boolean => {
    return state.registeredWindows[ViewIdentifier.MAIN_VIEW].reviewFilter.length > 0 && !state.registeredWindows[ViewIdentifier.MAIN_VIEW].reviewFilter.includes(FilterOption.NO_FILTER);
  },
  filterHasEntries: (_state: CloudState, getters: any): boolean => {
    return getters.reviewFilterHasEntries || getters.folderTagFilterHasEntries;
  },
  isReviewFilterActive: (state: CloudState): boolean => {
    return state.registeredWindows[ViewIdentifier.MAIN_VIEW].isReviewFilterActive;
  },
  countsForRating: (_state: CloudState, getters: any) => (rating: FilterOption): RatingsFileTypes => {
    return getters.buildRatingMapsForMainView[rating];
  },
  offCenterItemsOpacity: (state: CloudState): string => {
    switch (state.illuminationGrade) {
      case 0:
        return '0';
      case 1:
        return '0.1';
      case 2:
        return '0.3';
      case 3:
        return '1';
    }
  },
  buildRatingMapsForMainView: (state: CloudState, getters: any, _rootState: RootState, rootGetters): { [key: string]: RatingsFileTypes } => {
    const ratingMap: { [key: string]: RatingsFileTypes } = {};
    const view = getters.view(ViewIdentifier.MAIN_VIEW);
    const window = state.registeredWindows[ViewIdentifier.MAIN_VIEW];
    if (view.objectIds?.length > 0) {
      Object.values(FilterOption)
        .forEach((rating) => {
          if (rating <= FilterOption.FIVE_STARS) {
            const objectId = view.objectIds[0].toUuid();
            const correctGetter: (id: string) => ItemWithPosition[] = view.contentType === MutantContentViewType.SELECTION ? rootGetters['selection/selectionItemsById'] : rootGetters['folder/folderItemsById'];
            const noRating = (item: ItemWithPosition, rating: FilterOption | string) => item.rating === rating || (item.rating == null && rating === FilterOption.NO_RATING);
            const filterByVersionAndRating = (assetVersion: number, rating) => i => itemHasAssetVersions(i, [assetVersion]) && noRating(i, rating);
            const itemsWithTagFilter = applyTagFilter(correctGetter(objectId), window.folderTagFilter);
            ratingMap[rating] = {
              raws: itemsWithTagFilter.filter(filterByVersionAndRating(RAW_ASSET_VERSION, rating))?.length ?? 0,
              originals: itemsWithTagFilter.filter(filterByVersionAndRating(ORIGINAL_ASSET_VERSION, rating))?.length ?? 0,
              total: itemsWithTagFilter.filter(i => i.rating === rating || noRating(i, rating))?.length ?? 0,
            };
          }
        });
      ratingMap[FilterOption.NO_FILTER] = Object.values(ratingMap)
        .reduce((previousValue, currentValue) => {
          return {
            raws: previousValue.raws + currentValue.raws,
            originals: previousValue.originals + currentValue.originals,
            total: previousValue.total + currentValue.total,
          };
        }, {
          raws: 0,
          originals: 0,
          total: 0,
        });
      ratingMap[FilterOption.ORIGINALS] = {
        raws: 0,
        originals: ratingMap[FilterOption.NO_FILTER].originals,
        total: 0,
      };
      ratingMap[FilterOption.RAWS] = {
        raws: ratingMap[FilterOption.NO_FILTER].raws,
        originals: 0,
        total: 0,
      };
    }
    return ratingMap;
  },
  countTagWithFilter: (state: CloudState, _getters: any, rootState: RootState, rootGetters: any) => (tagId: string): RatingsFileTypes => {
    const window = state.registeredWindows[ViewIdentifier.MAIN_VIEW];
    return applyReviewAndTagFilters(rootState.folder.folderItems, window, (i) => i?.item?.folderTagId === tagId, rootGetters['selection/globalSelectionItems']);
  },
  countNoTagWithFilter: (state: CloudState, _getters: any, rootState: RootState, rootGetters: any): RatingsFileTypes => {
    const window = state.registeredWindows[ViewIdentifier.MAIN_VIEW];
    return applyReviewAndTagFilters(rootState.folder.folderItems, window, (i) => i?.item != null && i?.item?.folderTagId == null, rootGetters['selection/globalSelectionItems']);
  },
  countAllTagWithFilter: (state: CloudState, _getters: any, rootState: RootState, rootGetters: any): RatingsFileTypes => {
    const window = state.registeredWindows[ViewIdentifier.MAIN_VIEW];
    return applyReviewAndTagFilters(rootState.folder.folderItems, window, (_i) => true, rootGetters['selection/globalSelectionItems']);
  },
  itemCountOverview: (_state: CloudState, getters: any) => (type: OverviewType): InfoBadgeData => {
    return getters.buildItemCountOverviewMap[type];
  },
  itemCountOverviewList: (_state: CloudState, getters: any) => (types: OverviewType[]): InfoBadgeData[] => {
    return types.map(type => getters.buildItemCountOverviewMap[type]);
  },
  buildItemCountOverviewMap: (_state: CloudState, getters: any, _rootState: RootState, rootGetters: any): ItemCountOverviewMap => {
    const totalItems = getters.itemsForCloudObject(getters.currentCloudObject);
    const selectedItems = rootGetters['selection/globalSelectionItemsInMainView'];
    const filteredItems = getters.currentViewItems(ViewIdentifier.MAIN_VIEW);
    const uploadPendingItems = rootGetters['file/uploadPendingItems'];
    const totalItemList = new ItemListBuilder().withItems(totalItems).withAssetFilter(byOnlyLargest).build();
    const filteredItemList = new ItemListBuilder().withItems(filteredItems).withAssetFilter(byOnlyLargest).build();
    const selectedItemList = new ItemListBuilder().withItems(selectedItems).withAssetFilter(byOnlyLargest).build();
    const uploadPendingItemList = new ItemListBuilder().withItems(uploadPendingItems).build();

    const originalItemList = new ItemListBuilder().withItems(totalItems).withFilter(byHasAssets)
      .withAssetFilter(byIncludesVersions([ORIGINAL_ASSET_VERSION]))
      .build();
    return {
      [OverviewType.ALL]: {
        title: 'ITEMS',
        type: OverviewType.ALL,
        count: totalItemList.items.length,
        size: totalItemList.assetList.sizeToString,
        color: 'grey',
      },
      [OverviewType.FILTERED]: {
        title: 'FILTERED',
        type: OverviewType.FILTERED,
        count: filteredItemList.items.length,
        size: filteredItemList.assetList.sizeToString,
        color: 'orange',
      },
      [OverviewType.ORIGINALS]: {
        type: OverviewType.ORIGINALS,
        title: 'ORIGINAL FILES',
        count: originalItemList.items.length,
        size: originalItemList.assetList.sizeToString,
        color: 'green',
      },
      [OverviewType.SELECTED]: {
        type: OverviewType.SELECTED,
        title: 'SELECTED',
        count: selectedItemList.items.length,
        size: selectedItemList.assetList.sizeToString,
        color: 'yellow',
      },
      [OverviewType.UPLOAD_PENDING]: {
        type: OverviewType.UPLOAD_PENDING,
        title: 'UPLOAD PENDING',
        count: uploadPendingItemList.items.length,
        size: uploadPendingItemList.assetList.sizeToString,
        color: 'orange',
      },
    };
  },
  window: (state: CloudState) => (windowId: string): MutantWindow => {
    return _cloneDeep(state.registeredWindows[windowId]);
  },
  defaultViewByObjectId: (_state: CloudState, getters: any) => (objectId: ObjectId): ViewType => {
    return getters.cloudObject(objectId).object?.viewType ?? ViewType.MOSAIC;
  },
  cloudObjectForView: (_state: CloudState, getters: any) => (viewIdentifier: ViewIdentifier): CloudObject<Folder | Selection> => {
    return getters.cloudObject(getters.view(viewIdentifier).objectIds[0]);
  },
  cloudObject: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters) => (objectId: ObjectId): CloudObject<Folder | Selection> => {
    const uuid = objectId?.toUuid();
    if (!uuid) {
      return null;
    }
    return objectId.isSelectionId
      ? new CloudObject<Folder | Selection>(objectId, rootGetters['selection/selections'][uuid])
      : new CloudObject<Folder | Selection>(objectId, rootGetters['folder/folders'][uuid]);
  },
  largestAssetsForCloudObject: (_state: CloudState, getters: any) => (cloudObject: CloudObject<Selection | Folder>): Asset[] => {
    return getters.itemsForCloudObject(cloudObject)
      .map(item => getLargestAsset(item.item.assets))
      .filter(asset => !!asset);
  },
  assetsForCloudObjectAndVersion: (_state: CloudState, getters: any) => (cloudObject: CloudObject<Selection | Folder>, version: number): AssetWithFolderTag[] => {
    return getters.itemsForCloudObject(cloudObject)
      .map(item => {
        const asset = item.item.assets.find(a => a.version === version);
        return asset ? { ...asset, folderTagId: item.item.folderTagId } : null;
      })
      .filter(asset => !!asset);
  },
  cloudObjects: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters: any) => (activeFilter: CloudObjectFilter): CloudObject<Folder|Selection>[] => {
    const folders: CloudObject<Folder>[] = Object.values(rootGetters['folder/folders']).map((f: Folder) => { return new CloudObject<Folder>(ObjectId.fromFolderId(f.id), f); });
    const selections: CloudObject<Selection>[] = Object.values(rootGetters['selection/selections']).map((s: Selection) => { return new CloudObject<Selection>(ObjectId.fromSelectionId(s.id), s); });
    const cloudObjects: CloudObject<Folder|Selection>[] = [...folders, ...selections];
    return new CloudObjectBuilder().withCloudObjects(cloudObjects).withFilters(activeFilter.filterType).withSorting(activeFilter.sortType).build();
  },
  hasObjectSelectedItems: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters: any) => (objectId: ObjectId) => {
    return objectId?.isSelectionId ? rootGetters['selection/hasSelectedItems'](objectId?.toUuid()) : rootGetters['folder/hasSelectedItems'](objectId?.toUuid());
  },
  foldersItems: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters: any) => {
    return rootGetters['folder/foldersWithItems'].map(folder => {
      if (folder.itemCount) {
        return folder.itemCount;
      }
      return folder.items ? folder.items.length : 0;
    }).reduce((a, b) => a + b, 0);
  },
  isOwnerOfView: (_state: CloudState, getters: any, _rootState: RootState, rootGetters) => (windowId: string): boolean => {
    const mainView = getters.window(windowId);
    if (mainView?.viewIds.length === 1) {
      const objectId = mainView.viewIds[0];
      if (objectId.isFolderId) {
        return rootGetters['folder/isFolderOwner'](objectId.toUuid());
      }
      if (objectId.isSelectionId) {
        return rootGetters['selection/isSelectionOwner'](objectId.toUuid());
      }
    }
    return true;
  },
  assetSizeForVersion: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters: any) => (cloudObject: CloudObject<Folder | Selection>, version: number, includeOfflineItems = false): UnitSize => {
    return cloudObject?.isFolder
      ? rootGetters['folder/folderSizeInCloud'](cloudObject?.object?.id, version, includeOfflineItems)
      : rootGetters['selection/sizeInCloudById'](cloudObject?.object?.id, version, includeOfflineItems);
  },
  accumulatedSizeLargestAssets: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters: any) => (cloudObject: CloudObject<Folder | Selection>): UnitSize => {
    return cloudObject?.isFolder
      ? rootGetters['folder/folderSizeBiggestAssets'](cloudObject?.object?.id)
      : rootGetters['selection/sizeInCloudByIdBiggestAssets'](cloudObject?.object?.id);
  },
  thumbnailsSizeForWidth: (_state: CloudState, getters: any, _rootState: RootState) => (cloudObject: CloudObject<Folder | Selection>, width: number): UnitSize => {
    return transformToUnit(getters.assetsForCloudObjectAndWidth(cloudObject, width).reduce((previousValue, currentValue) => previousValue + currentValue.size, 0));
  },
  assetsForCloudObjectAndWidth: (_state: CloudState, getters: any) => (cloudObject: CloudObject<Folder | Selection>, width: number): AssetWithFolderTag[] => {
    return getters.itemsForCloudObject(cloudObject)
      .map(i => {
        const asset = getLargestAssetBelowWidth(i.item, width);
        return asset ? { ...asset, folderTagId: i.item.folderTagId } : null;
      })
      .filter(a => !!a);
  },
  currentFolder: (state: CloudState, getters: any, _rootState: RootState, rootGetters) => (windowId: string): Folder | null => {
    if (getters.currentMainView.isSingleFolderView) {
      const window = state.registeredWindows[windowId];
      return rootGetters['folder/folderById'](window.viewIds[0].toUuid());
    }
    return null;
  },
  isMainViewObjectShared: (_state: CloudState, getters: any, _rootState: RootState, rootGetters: any): boolean => {
    const currentObjectId = getters.currentMainView.objectIds[0];
    if (currentObjectId?.isFolderId) {
      return rootGetters['folder/folders'][currentObjectId.toUuid()]?.isShared ?? false;
    } else if (currentObjectId?.isSelectionId) {
      return rootGetters['selection/selections'][currentObjectId.toUuid()]?.isShared ?? false;
    } else {
      return false;
    }
  },
  viewMap: (state: CloudState, _getters: any, _rootState: RootState, rootGetters: any): { [key in string]: MutantContentView } => {
    const obj = {};
    Object.values(ViewIdentifier)
      .forEach(windowId => {
        const window = state.registeredWindows[windowId];
        if (window != null) {
          const contentType = buildViewType(window.viewIds);
          const viewIds = window.viewIds;
          const view = buildView(windowId, contentType, viewIds, rootGetters['folder/folders'][viewIds[0]?.toUuid()], rootGetters['selection/selections'][viewIds[0]?.toUuid()], rootGetters['selection/globalSelection']);
          view.setViewOptions(window.viewOptions);
          view.setNavigationMode(window.isInNavigationMode);
          obj[windowId] = view;
        }
      });
    return obj;
  },
  view: (_state: CloudState, getters: any, _rootState: RootState, _rootGetters: any) => (windowId: string): MutantContentView => {
    return getters.viewMap[windowId];
  },
  viewObjectId: (_state: CloudState, getters: any) => (viewId: ViewIdentifier): string => {
    return getters.view(viewId)?.objectIds[0];
  },
  isFilteredButHasItems: (state: CloudState, getters: any, _rootState: RootState, rootGetters: any) => (windowId: string): boolean => {
    const window: MutantWindow = state.registeredWindows[windowId];
    const objectId = window.viewIds[0];
    if (objectId != null) {
      const items = objectId.isSelectionId ? rootGetters['selection/selectionItemsById'](objectId.toUuid()) : rootGetters['folder/folderItemsById'](objectId.toUuid());
      if (items?.length > 0 && window.isReviewFilterActive && getters.currentViewItems(windowId)?.length === 0) {
        return true;
      }
    }
    return false;
  },
  activeViewHasItemsWithFolderTags: (state: CloudState, getters: any): boolean => {
    return getters.currentViewItems(state.activeWindowId)?.some(item => !!item.item.folderTagId);
  },
  viewItemsMap: (state: CloudState, _getters: any, _rootState: any, rootGetters: any): { [key in string]: MutantContentItems } => {
    const obj = {};
    Object.values(ViewIdentifier)
      .forEach(windowId => {
        const window = state.registeredWindows[windowId];
        if (window != null) {
          obj[windowId] = {
            items: buildViewItems(window.viewIds, {
              filterForActiveSelections: window.filterForActiveSelections,
              reviewFilter: window.reviewFilter,
              folderTagFilter: window.folderTagFilter,
              isReviewFilterActive: window.isReviewFilterActive,
              colorFilter: window.colorFilter,
              sortedBy: window.sortedBy,
            }, rootGetters),
          };
        }
      });
    return obj;
  },
  currentCloudObjectId: (state: CloudState, getters: any, _rootState: RootState, _rootGetters: any): ObjectId => {
    return getters.view(state.activeWindowId).objectIds[0];
  },
  currentCloudObject: (_state: CloudState, getters: any, _rootState: RootState, _rootGetters: any): CloudObject<Folder | Selection> | undefined => {
    const objectId = getters.currentCloudObjectId;
    let result;
    if (objectId != null) {
      result = getters.cloudObject(objectId);
    }
    return result;
  },
  itemsForCloudObject: (_state: CloudState, _getters: any, _rootState: RootState, rootGetters: any) => (cloudObject: CloudObject<Folder | Selection>): ItemWithPosition[] => {
    if (cloudObject?.object != null) {
      return cloudObject?.isFolder
        ? rootGetters['folder/folderItemsById'](cloudObject.object.id)
        : rootGetters['selection/selectionItemsById'](cloudObject?.object?.id);
    }
    return [];
  },
  currentMainView: (_state: CloudState, getters: any): MutantContentView => {
    return getters.viewMap[ViewIdentifier.MAIN_VIEW];
  },
  currentViewItems: (_state: CloudState, getters: any) => (windowId: string): ItemWithPosition[] => {
    return getters.viewItemsMap[windowId]?.items || [];
  },
  viewHasItems: (_state: CloudState, getters: any) => (windowId: string): boolean => {
    return getters.viewItemsMap[windowId]?.items?.length > 0;
  },
  viewIsEmpty: (_state: CloudState, getters: any) => (windowId: string): boolean => {
    return getters.viewItemsMap[windowId]?.items?.length === 0;
  },
  viewItemsBySelectedItemsOption: (_state: CloudState, getters: any, _rootState: RootState, rootGetters: any) => (windowId: string, selectedItemsOption: SelectedItemsOption): ItemWithPosition[] => {
    if (selectedItemsOption === SelectedItemsOption.FILTERED) {
      return getters.currentViewItems(windowId);
    }
    if (selectedItemsOption === SelectedItemsOption.SELECTED) {
      return rootGetters['selection/globalSelectionItems'];
    }
    return getters.itemsForCloudObject(getters.currentCloudObject);
  },
  viewItemsByDownloadOptions: (_state: CloudState, getters: any) => ({ windowId, fileOptions, selectedItemsOption, thumbnailWidth }: { windowId: string, fileOptions: FileOption[], selectedItemsOption: SelectedItemsOption, thumbnailWidth: number }): ItemList => {
    if (!fileOptions.length) {
      return new ItemList();
    }
    return new ItemListBuilder()
      .withItems(getters.viewItemsBySelectedItemsOption(windowId, selectedItemsOption))
      .withFilter(byHasAssets)
      .withAssetFilter(combineORFilters(...extractFiltersFromFileOptions(fileOptions, thumbnailWidth)))
      .build();
  },
  viewAssetsByDownloadOptions: (_state: CloudState, getters: any) => (data: { windowId: string, fileOptions: FileOption[], selectedItemsOption: SelectedItemsOption, thumbnailWidth: number }): AssetList<AssetWithFolderTag> => {
    return getters.viewItemsByDownloadOptions(data).assetListWithFolderTags;
  },
  viewAssetsByVersion: (_state: CloudState, getters: any) => (windowId: string, versions: number[]): Asset[] => {
    return new ItemListBuilder()
      .withItems(getters.currentViewItems(windowId))
      .withAssetFilter(byIncludesVersions(versions))
      .build()
      .assetList
      .assets;
  },
  currentViewDownloadSize: (_state: CloudState, getters: any) => (windowId: string, version = ORIGINAL_ASSET_VERSION): UnitSize => {
    return new ItemListBuilder()
      .withItems(getters.currentViewItems(windowId))
      .withAssetFilter(byIncludesVersions([version]))
      .build()
      .assetList
      .asUnitSize;
  },
  currentCenteredItem: (state: CloudState, getters: any) => (windowId: string): ItemWithPosition => {
    return getters.currentViewItems(windowId).at(state.centeredItemInView[ViewIdentifier.MAIN_VIEW]);
  },
  getViewItemById: (_state: CloudState, getters: any) => (windowId: string, itemId: string): ItemWithPosition => {
    return getters.currentViewItems(windowId).find(i => i.itemId === itemId);
  },
};

function filterDuplicateItems(items: ItemWithPosition[]): ItemWithPosition[] {
  const resultKeys = {};
  const results = [];
  for (const item of items) {
    if (!resultKeys[item.id]) {
      results.push(item);
      resultKeys[item.id] = true;
    }
  }
  return results;
}

export function buildView(id: string, contentType: MutantContentViewType, viewIds: ObjectId[], folder: Folder, selection: Selection, globalSelection: GlobalSelection): MutantContentView {
  let viewName;
  let subtitle = '';
  const viewOwners: Set<string> = new Set();
  let viewBackgrounds: Asset[] = [];
  if (contentType === MutantContentViewType.FOLDER) {
    if (viewIds.length > 1) {
      viewName = 'MULTI FOLDER VIEW';
    } else if (viewIds.length === 0) {
      viewName = 'EMPTY VIEW';
    } else {
      viewName = folder ? folder.name : '';
      subtitle = folder ? folder.subtitle : '';
      if (folder?.owner) {
        viewOwners.add(folder.owner.id);
      }
    }
  } else if (contentType === MutantContentViewType.SELECTION) {
    if (viewIds.length > 1) {
      viewName = 'MULTI SELECTION VIEW';
    } else if (viewIds.length === 0) {
      viewName = 'EMPTY VIEW';
    } else {
      viewBackgrounds = selection?.background?.assets || [];
      viewName = selection ? selection.name : '';
      subtitle = selection ? selection.subtitle : '';
      if (selection?.owner) {
        viewOwners.add(selection.owner.id);
      }
    }
  } else if (contentType === MutantContentViewType.USER) {
    if (viewIds.length > 1) {
      viewName = 'MULTI USER VIEW';
    } else if (viewIds.length === 0) {
      viewName = 'EMPTY VIEW';
    }
  } else if (contentType === MutantContentViewType.GLOBAL_SELECTION) {
    viewName = globalSelection?.name;
    viewOwners.add(globalSelection?.owner?.id);
  } else {
    viewName = 'EMPTY VIEW';
  }
  const view = new MutantContentView(id, viewName, [...viewOwners], contentType, viewIds, subtitle);
  view.setBackgrounds(viewBackgrounds);
  return view;
}

export function buildViewType(viewIds: ObjectId[]) {
  const userIds: string[] = viewIds.map(id => id).filter(id => id.isUserId).map(id => id.toUuid());
  const folderIds: string[] = viewIds.map(id => id).filter(id => id.isFolderId).map(id => id.toUuid());
  const selectionIds: string[] = viewIds.map(id => id).filter(id => id.isSelectionId).map(id => id.toUuid());
  if (viewIds.length) {
    if (folderIds.length === viewIds.length) {
      return MutantContentViewType.FOLDER;
    }
    if (selectionIds.length === viewIds.length) {
      return MutantContentViewType.SELECTION;
    }
    if (userIds.length === viewIds.length) {
      return MutantContentViewType.USER;
    }
    if (viewIds.some(id => id.isGlobalSelection)) {
      return MutantContentViewType.GLOBAL_SELECTION;
    }
  }
  return MutantContentViewType.MISC;
}

export interface BuildViewItemsOptions {
  filterForActiveSelections?: boolean,
  reviewFilter: ReviewFilter,
  folderTagFilter: FolderTagFilter,
  isReviewFilterActive: boolean;
  colorFilter?: number[] | null,
  sortedBy?: ViewSortingOption,
}

function countPictureCategory (filteredItems: ItemWithPosition[]): RatingsFileTypes {
  return {
    raws: filteredItems?.filter(i => itemHasAssetVersions(i, [RAW_ASSET_VERSION]))?.length ?? 0,
    originals: filteredItems?.filter(i => itemHasAssetVersions(i, [ORIGINAL_ASSET_VERSION]))?.length ?? 0,
    total: filteredItems?.length ?? 0,
  };
}

function filterForColorOptions(filteredItems: ItemWithPosition[]) {
  filteredItems = filteredItems.filter((item: ItemWithPosition) => {
    if (item?.item?.colors?.length) {
      const color = JSON.parse(JSON.stringify(item.item.colors))
        .sort((a: any, b: any) => a.population > b.population ? -1 : 1)[0].rgb;
        // @ts-ignore
      return item.colors && Vibrant.Util.rgbDiff(color, state.colorFilter) < 10;
    }
    return false;
  });
  return filteredItems;
}

function extractFolderItems(folderIds: string[], rootGetters: any) {
  return folderIds.length
    ? rootGetters['folder/foldersWithItems']
      .filter(f => folderIds.includes(f.id))
      .reduce((acc: ItemWithPosition[], curr: Folder) => curr.items ? [...acc, ...curr.items] : acc, [])
    : [];
}

function extractSelectionItems(selectionIds: string[], rootGetters: any) {
  return selectionIds.length
    ? rootGetters['selection/selectionsWithItems']
      .filter(s => selectionIds.includes(s.id))
      .reduce((acc: ItemWithPosition[], curr: Selection) => curr.items ? [...acc, ...curr.items] : acc, [])
    : [];
}

function removeDuplicatedItems(userIds: string[], folderIds: string[], selectionIds: string[], globalSelectionItems, items: any[]) {
  const filteredItems: ItemWithPosition[]
    = userIds.length + folderIds.length + selectionIds.length + globalSelectionItems.length > 1
      ? filterDuplicateItems(items)
      : items;
  return filteredItems;
}

export function buildViewItems(
  viewIds: ObjectId[],
  options: BuildViewItemsOptions,
  rootGetters: any): ItemWithPosition[] {
  const userIds: string[] = viewIds.map(id => id).filter(id => id.isUserId).map(id => id.toUuid());
  const folderIds: string[] = viewIds.map(id => id).filter(id => id.isFolderId).map(id => id.toUuid());
  const selectionIds: string[] = viewIds.map(id => id).filter(id => id.isSelectionId).map(id => id.toUuid());
  const folderItems = extractFolderItems(folderIds, rootGetters);
  const selectionItems = extractSelectionItems(selectionIds, rootGetters);
  const selectedItems = rootGetters['selection/globalSelectionItems'];
  const globalSelectionItems = viewIds.some(id => id.isGlobalSelection) ? selectedItems : [];
  const items = [...folderItems, ...selectionItems, ...globalSelectionItems];
  let filteredItems = removeDuplicatedItems(userIds, folderIds, selectionIds, globalSelectionItems, items);
  if (options.colorFilter) {
    filteredItems = filterForColorOptions(filteredItems);
  }
  if (options.filterForActiveSelections) {
    filteredItems = filteredItems.filter((item: ItemWithPosition) =>
      selectedItems.some(i => i.id === item.id));
  }
  if (options?.isReviewFilterActive) {
    filteredItems = filterForFiltersAndTags(options, options?.reviewFilter, filteredItems, selectedItems);
  }
  filteredItems = sortItemsForFilter(filteredItems, options.sortedBy);
  return filteredItems;
}

function filterForFiltersAndTags(options: BuildViewItemsOptions, filter: Array<FilterOption>, filteredItems: ItemWithPosition[], globalSelectionItems: ItemWithPosition[]) {
  if (filter?.length > 0 && !filter.includes(FilterOption.NO_FILTER)) {
    filteredItems = applyReviewFilter(filteredItems, filter, globalSelectionItems);
  }
  const folderTagFilter = options?.folderTagFilter;
  if (folderTagFilter?.state === TagState.FILTER && folderTagFilter.tags.length > 0) {
    filteredItems = filteredItems.filter(i => folderTagFilter.tags.some(t => t.id === i.item.folderTagId));
  } else if (folderTagFilter.state === TagState.NO_TAGS) {
    filteredItems = filteredItems.filter(i => i.item.folderTagId == null);
  }
  return filteredItems;
}

function applyTagFilter(items: ItemWithPosition[], tagFilter: FolderTagFilter): ItemWithPosition[] {
  if (tagFilter.state.includes(TagState.NO_TAGS)) {
    return items.filter(i => i.item.folderTagId === null);
  } else if (tagFilter.state.includes(TagState.FILTER)) {
    return items.filter(i => tagFilter.tags.some(t => t.id === i.item.folderTagId));
  }
  return items;
}

function applyReviewAndTagFilters(items: { [id: string]: FolderItem[] }, window: MutantWindow, filter: (i: ItemWithPosition) => void, globalSelectionItems: ItemWithPosition[]) {
  const objectId = window.viewIds[0];
  if (objectId != null) {
    const folderItems = items[objectId.toUuid()];
    if (folderItems != null) {
      const reviewFilter = window.reviewFilter;
      if (reviewFilter != null) {
        const filteredItems = window.isReviewFilterActive ? applyReviewFilter(folderItems.filter(filter), reviewFilter, globalSelectionItems) : folderItems.filter(filter);
        return countPictureCategory(filteredItems);
      } else {
        return countPictureCategory(folderItems.filter(filter));
      }
    }
  }
  return { raws: 0, originals: 0, total: 0 };
}

function applyReviewFilter(filteredItems: ItemWithPosition[], filter: Array<FilterOption>, globalSelectionItems: ItemWithPosition[]) {
  const isRaw = (item: ItemWithPosition) => itemHasAssetVersions(item, [RAW_ASSET_VERSION]);
  const isOriginal = (item: ItemWithPosition) => itemHasAssetVersions(item, [ORIGINAL_ASSET_VERSION]);
  const isSelected = (item: ItemWithPosition) => globalSelectionItems.some(i => item.item.id === i.item.id);
  const ratingFilters = (filter, item) => filter.includes(item.rating) || (!item.rating && filter.includes(FilterOption.NO_RATING));
  if (filter.includes(FilterOption.ORIGINALS)) {
    filteredItems = filteredItems.filter(isOriginal);
  }
  if (filter.includes(FilterOption.RAWS)) {
    filteredItems = filteredItems.filter(isRaw);
  }
  if (filter.includes(FilterOption.SELECTION)) {
    filteredItems = filteredItems.filter(isSelected);
  }
  if (filter.includes(FilterOption.UPLOAD_PENDING)) {
    filteredItems = byContainsOfflineAsset(filteredItems);
  }
  if (filter.some(filterOption => filterOption >= FilterOption.NO_RATING && filterOption <= FilterOption.FIVE_STARS)) {
    filteredItems = filteredItems.filter((item: ItemWithPosition) => ratingFilters(filter, item));
  }
  return filteredItems;
}

function sortItemsForFilter(filteredItems: ItemWithPosition[], sortedBy: ViewSortingOption): ItemWithPosition[] {
  // Filter is named last modified, but first we filter for created and only if it does not exists for modified
  // reason is that user expects that this filter will sort according to last added items, maybe come up with better wording
  if (sortedBy === ViewSortingOption.LAST_MODIFIED_DESC) {
    filteredItems.sort((a, b) => {
      const dateA = moment(a.created || a.modified);
      const dateB = b.created || b.modified;
      if (dateA.isSame(dateB)) {
        return 0;
      }
      return dateA.isBefore(dateB) ? 1 : -1;
    });
  } else if (sortedBy === ViewSortingOption.LAST_MODIFIED_ASC) {
    filteredItems.sort((a, b) => {
      const dateA = moment(b.created || b.modified);
      const dateB = a.created || a.modified;
      if (dateA.isSame(dateB)) {
        return 0;
      }
      return dateA.isBefore(dateB) ? 1 : -1;
    });
  } else if (sortedBy === ViewSortingOption.ALPHABETICAL_DESC) {
    filteredItems.sort((a, b) => {
      return a.item?.assets[0]?.name?.localeCompare(b.item?.assets[0]?.name);
    });
  } else if (sortedBy === ViewSortingOption.ALPHABETICAL_ASC) {
    filteredItems.sort((a, b) => {
      return a.item?.assets[0]?.name?.localeCompare(b.item?.assets[0]?.name);
    }).reverse();
  } else {
    filteredItems.sort((a, b) => a.position?.order < b.position?.order ? -1 : 1);
  }
  return filteredItems;
}

function extractFiltersFromFileOptions(fileOptions: FileOption[], thumbnailWidth: number): AssetFilter<Asset>[] {
  const assetFilters: AssetFilter<Asset>[] = [];
  if (fileOptions.includes(FileOption.THUMBNAILS)) {
    assetFilters.push(combineANDFilters(byIsThumbnail, byLargestBelowWidth(thumbnailWidth)));
  }
  if (fileOptions.includes(FileOption.ORIGINALS) || fileOptions.includes(FileOption.RAWS)) {
    const versions = fileOptions.reduce((agg, option) => {
      if (option === FileOption.ORIGINALS) {
        agg.push(ORIGINAL_ASSET_VERSION);
      } else if (option === FileOption.RAWS) {
        agg.push(RAW_ASSET_VERSION);
      }
      return agg;
    }, []);
    assetFilters.push(byIncludesVersions(versions));
  }
  return assetFilters;
}

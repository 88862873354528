import { Asset, AssetWithFolderTag, transformToUnit } from '~/models/Asset';
import { UnitSize } from '~/models/UnitSize';

export class AssetList<T extends Asset | AssetWithFolderTag> {
  public assets: T[] = [];

  constructor(assets: T[] = []) {
    this.assets = assets;
  }

  public get asByteSize(): number {
    return this.assets.reduce((acc, asset) => acc + (asset.matchedWith != null ? asset.matchedWith.size : asset.size), 0);
  }

  public get asUnitSize(): UnitSize {
    return transformToUnit(this.asByteSize);
  }

  public get sizeToString(): string {
    return `${this.asRoundedUnitSize.size} ${this.asRoundedUnitSize.unit}`;
  }

  public get asRoundedUnitSize(): UnitSize {
    const unitSize = this.asUnitSize;
    const size = this.roundSizeByUnit(unitSize.size, unitSize.unit);
    return { ...unitSize, size };
  }

  private roundSizeByUnit(size: number, unit: string): number {
    if (unit === 'KB' || size > 100) {
      return Math.round(size);
    }
    if (unit === 'MB' && size >= 10) {
      return parseFloat(size.toFixed(1));
    }
    return size;
  }
}

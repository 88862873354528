export enum PipelineCommandType {
  STOP_PIPELINE = 'STOP_PIPELINE',
  PAUSE_PIPELINE = 'PAUSE_PIPELINE',
  CANCEL_PIPELINE = 'CANCEL_PIPELINE',
  CONTINUE_PIPELINE = 'CONTINUE_PIPELINE',
  PROCESS_FILES = 'PROCESS_FILES',
  EXTRACT_METADATA = 'EXTRACT_METADATA',
  CREATE_THUMBNAILS = 'CREATE_THUMBNAILS',
  UPLOAD_THUMBNAILS = 'UPLOAD_THUMBNAILS',
  UPLOAD_ORIGINALS = 'UPLOAD_ORIGINALS',
  UPLOAD_RAWS = 'UPLOAD_RAWS',
  COMPLETE_PROCESSING_FILES = 'COMPLETE_PROCESSING_FILES',
}

import moment from 'moment/moment';
import { FilterType, ObjectFilter, SortType } from '~/models/cloud/CloudObjectFilter';
import { AvailableCloudObject, CloudObject } from '~/models/cloud/CloudObject';
import Folder from '~/models/Folder';
import Selection from '~/models/selection/Selection';
import { combineANDFilters } from '~/models/filters/combineANDFilters';
import { Filter } from '~/models/filters/Filter';
import { combineORFilters } from '~/models/filters/combineORFilters';

type SortFunction = (o1: AvailableCloudObject, o2: AvailableCloudObject) => number;
export class CloudObjectBuilder {
  private filters: ObjectFilter[] = [];
  private cloudObjects: AvailableCloudObject[] = [];
  public DEFAULT_SORT_FUNCTION = sortModifiedAsc;
  private sortFn: SortFunction = this.DEFAULT_SORT_FUNCTION;

  withFilters(filters: FilterType[]): this {
    this.filters.push(...filters.map(filterType => this.mapFilterTypeToObjectFilter(filterType)));
    this.filters = this.sortForPriority(this.filters);
    return this;
  }

  withSorting(sortType: SortType): this {
    this.sortFn = this.mapSortTypeToFunction(sortType);
    return this;
  }

  withCloudObjects(cloudObjects: AvailableCloudObject[]): this {
    this.cloudObjects = cloudObjects;
    return this;
  }

  build(): AvailableCloudObject[] {
    return combineANDFilters(...this.andFiltered)(combineORFilters(...this.orFiltered)(this.cloudObjects)).sort(this.sortFn);
  }

  private get orFiltered(): Filter<AvailableCloudObject>[] {
    return this.filters.filter(f => f.orFiltered).map(f => f.filter);
  }

  private get andFiltered(): Filter<AvailableCloudObject>[] {
    return this.filters.filter(f => !f.orFiltered).map(f => f.filter);
  }

  private mapFilterTypeToObjectFilter = (filterType: FilterType): ObjectFilter => {
    switch (filterType) {
      case FilterType.FOLDER:
        return folderFilter;
      case FilterType.CONTACT_SHEET:
        return contactSheetFilter;
      case FilterType.SELECTION:
        return selectionFilter;
    }
  };

  private mapSortTypeToFunction = (sortType: SortType): SortFunction => {
    switch (sortType) {
      case SortType.MODIFIED_ASC:
        return sortModifiedAsc;
      case SortType.MODIFIED_DESC:
        return sortModifiedDesc;
      case SortType.ALPHABETICALLY_ASC:
        return sortAlphabeticallyAsc;
      case SortType.ALPHABETICALLY_DESC:
        return sortAlphabeticallyDesc;
      default: return sortModifiedAsc;
    }
  };

  private sortForPriority(objectFilters: ObjectFilter[]) {
    return objectFilters.sort((f1, f2) => {
      return f1.priority > f2.priority ? -1 : 1;
    });
  }
}
const folderFilter: ObjectFilter = {
  type: FilterType.FOLDER,
  filter: (a: CloudObject<Folder | Selection>[]) => a.filter(object => object.isFolder),
  orFiltered: true,
  priority: 3,
};

const selectionFilter: ObjectFilter = {
  type: FilterType.SELECTION,
  filter: (a: CloudObject<Folder | Selection>[]) => a.filter(object => object.isSelection),
  orFiltered: true,
  priority: 2,
};

const contactSheetFilter: ObjectFilter = {
  type: FilterType.CONTACT_SHEET,
  filter: (a: CloudObject<Folder | Selection>[]) => a.filter(object => object.object?.isShared),
  orFiltered: false,
  priority: 1,
};

const sortModifiedAsc = (o1: AvailableCloudObject, o2: AvailableCloudObject) => {
  if (moment(o1.object.modified).isSame(o2.object.modified)) {
    return 0;
  }
  return moment(o1.object.modified).isBefore(o2.object.modified) ? 1 : -1;
};

const sortModifiedDesc = (o1: AvailableCloudObject, o2: AvailableCloudObject) => {
  if (moment(o1.object.modified).isSame(o2.object.modified)) {
    return 0;
  }
  return moment(o1.object.modified).isBefore(o2.object.modified) ? -1 : 1;
};

const sortAlphabeticallyDesc = (o1: AvailableCloudObject, o2: AvailableCloudObject) => {
  return o1.object.name.localeCompare(o2.object.name) * -1;
};

const sortAlphabeticallyAsc = (o1: AvailableCloudObject, o2: AvailableCloudObject) => {
  return o1.object.name.localeCompare(o2.object.name);
};

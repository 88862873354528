import { ActionContext, ActionTree } from 'vuex';
import { ImageLoader } from '~/models/ImageLoader';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { RootState } from '~/store/state';
import { DetailViewState, NextDetailViewState } from '~/store/detailView/state';
import { Asset } from '~/models/Asset';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { Point } from '~/models/ComplexMath';
import { SnippetMover } from '~/models/views/SnippetMover';
import { ItemOrigin } from '~/models/item/ItemOrigin';

export enum DetailViewContentType {
  PREVIOUS = 'PREVIOUS',
  CURRENT = 'CURRENT',
  NEXT = 'NEXT'
}

type DetailViewContext = ActionContext<DetailViewState, RootState>;

const actions: ActionTree<DetailViewState, RootState> = {
  open({ commit, dispatch, rootGetters }: DetailViewContext, { currentItem, linkedViewId }: { currentItem: ItemWithPosition, linkedViewId: ViewIdentifier }) {
    const width = window.innerWidth;
    const items = rootGetters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW].items;
    const previousItem = getPreviousItem(currentItem, items);
    const nextItem = getNextItem(currentItem, items);
    commit('initAndOpenDetailView', {
      isOpen: true,
      isOpenAnimationFinished: false,
      currentItem,
      currentAsset: this.$imageLoader.getOptimalAssetByWidth(currentItem.item, width),
      linkedViewId,
      showAssetDetails: false,
    });
    dispatch('loadContent', { item: currentItem, width, type: DetailViewContentType.CURRENT });
    if (previousItem) {
      dispatch('loadContent', { item: previousItem, width, type: DetailViewContentType.PREVIOUS });
    }
    if (nextItem) {
      dispatch('loadContent', { item: nextItem, width, type: DetailViewContentType.NEXT });
    }
    this.$router.push({
      ...this.$router.currentRoute,
      query: {
        ...this.$router.currentRoute.query,
        'detail-view': 'true',
      },
    });
  },
  async loadContent({ commit }: DetailViewContext, { item, width, type }: { item: ItemWithPosition, collection: ItemWithPosition[], width: number, type: DetailViewContentType }) {
    const content = await this.$imageLoader.loadVersionByWidthInBase64(item.item, width);
    commit('setContent', { content, type });
  },
  async loadSpecificContent({ commit }: DetailViewContext, { item, asset, type }: { item: ItemWithPosition, asset: Asset, type: DetailViewContentType }) {
    const content = asset.base64 || await this.$imageLoader.loadAssetUrl(item.item, asset);
    commit('setContent', { content, type });
  },
  close(context: DetailViewContext) {
    const queryParms = this.$router.currentRoute.query;
    if (queryParms['detail-view']) {
      // only works with this weird solution of deleting key and setting it to undefined
      delete queryParms['detail-view'];
      this.$router.push({
        ...this.$router.currentRoute,
        query: {
          ...this.$router.currentRoute.query,
          'detail-view': undefined,
        },
      });
    }

    if (context.state.isOpen) {
      context.dispatch('animateSliderItemBackIntoOriginalPosition');
    }
    context.commit('closeAndResetDetailView');
  },
  async next(context: DetailViewContext) {
    const items = context.rootGetters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW].items;
    const windowWidth = context.rootState.windowWidth;
    const nextItem = getNextItem(context.state.currentItem, items);
    if (nextItem) {
      context.commit('setNextDetailViewState', await buildNextDetailViewState(this.$imageLoader, nextItem, items, windowWidth));
      context.dispatch('cloud/setCenteredItemInView', nextItem.position.order, { root: true });
      context.dispatch('cloud/highlightItem', { originView: ViewIdentifier.MAIN_VIEW, item: nextItem, targetView: ViewIdentifier.MAIN_VIEW, scroll: true }, { root: true });
    }
  },
  async previous(context: DetailViewContext) {
    const items = context.rootGetters['cloud/viewItemsMap'][ViewIdentifier.MAIN_VIEW].items;
    const windowWidth = context.rootState.windowWidth;
    const prevItem = getPreviousItem(context.state.currentItem, items);
    if (prevItem) {
      context.commit('setNextDetailViewState', await buildNextDetailViewState(this.$imageLoader, prevItem, items, windowWidth));
      context.dispatch('cloud/highlightItem', { originView: ViewIdentifier.MAIN_VIEW, item: prevItem, targetView: ViewIdentifier.MAIN_VIEW, scroll: true }, { root: true });
      context.dispatch('cloud/setCenteredItemInView', prevItem.position.order, { root: true });
    }
  },
  toggleAssetDetails(context: DetailViewContext) {
    context.commit('toggleAssetDetails');
  },
  scaleItemIntoImageSliderPosition(context) {
    const itemOrigin: ItemOrigin = context.getters.getItemOrigin;
    const isMobile = context.rootGetters.isMobile;
    const targetElement = document.getElementById(itemOrigin.elementId);
    const elementPositionLeft = itemOrigin.position.left;
    const elementPositionTop = itemOrigin.position.top;
    const topLeftPosition = new Point(elementPositionLeft, elementPositionTop);
    const centerPosition = new Point(topLeftPosition.x + itemOrigin.position.width / 2, topLeftPosition.y + itemOrigin.position.height / 2);
    const elementCopy = <HTMLElement> targetElement.cloneNode(true);
    // Remove drag icon from copy
    const dragIcon = elementCopy.getElementsByClassName('drag-icon').item(0);
    if (dragIcon != null) {
      elementCopy.getElementsByClassName('snippet__item-container').item(0).removeChild(dragIcon);
    }
    elementCopy.style.zIndex = '2000';
    elementCopy.style.transformOrigin = 'center';
    elementCopy.style.position = 'fixed';
    elementCopy.style.left = topLeftPosition.x + 'px';
    elementCopy.style.top = topLeftPosition.y + 'px';
    elementCopy.classList.add('snippet-wrapper--zoom-out');
    const snippetElement = elementCopy.getElementsByClassName('snippet')?.item(0);
    // This block removes a glitch where the element has not the intended size while animating (e.g. in horizontal view with active focus frames)
    if (snippetElement?.classList.contains('snippet--focus-frame')) {
      snippetElement.classList.remove('snippet--focus-frame');
      const mosaicItem: HTMLElement = snippetElement.getElementsByClassName('mosaic-item')?.item(0) as HTMLElement;
      if (mosaicItem) {
        mosaicItem.style.padding = '0';
      }
      const img: HTMLElement = snippetElement.getElementsByTagName('img')?.item(0) as HTMLElement;
      if (img) {
        img.style.padding = '0';
      }
    }
    document.body.appendChild(elementCopy);
    const currentElementHeight = itemOrigin.position.height;
    const currentElementWidth = itemOrigin.position.width;
    const windowDimensions = { width: window.innerWidth, height: window.innerHeight };
    const imageSliderHeight = isMobile ? windowDimensions.height : windowDimensions.height * 9 / 10;
    const imageSliderWidth = isMobile ? windowDimensions.width : windowDimensions.width * 9 / 10;
    const sizeScaleWidth = imageSliderWidth / currentElementWidth;
    const sizeScaleHeight = imageSliderHeight / currentElementHeight;
    const isOverScalingWithWidthScale = sizeScaleWidth * currentElementWidth * currentElementHeight / currentElementWidth > imageSliderHeight;
    const sizeScale = isOverScalingWithWidthScale ? sizeScaleHeight : sizeScaleWidth;
    let imagePositionInSliderTop = 0;
    if (isOverScalingWithWidthScale && !isMobile) {
      imagePositionInSliderTop = windowDimensions.height * 5 / 100;
    } else {
      imagePositionInSliderTop = (windowDimensions.height - currentElementHeight * sizeScale) / 2;
    }
    let imagePositionInSliderLeft = 0;
    if (isOverScalingWithWidthScale) {
      imagePositionInSliderLeft = (windowDimensions.width - currentElementWidth * sizeScale) / 2;
    } else if (!isMobile) {
      imagePositionInSliderLeft = windowDimensions.width * 5 / 100;
    }
    const { width, height } = context.getters.itemDimensions;
    const imagePositionInSlider = new Point(imagePositionInSliderLeft, imagePositionInSliderTop);
    const imagePositionInSliderCenter = new Point(imagePositionInSlider.x + width / 2, imagePositionInSlider.y + height / 2);
    const elementOffsetLeft = imagePositionInSliderCenter.x - centerPosition.x;
    const elementOffsetTop = imagePositionInSliderCenter.y - centerPosition.y;

    setTimeout(() => {
      elementCopy.style.transform = `translateX(${elementOffsetLeft}px) translateY(${elementOffsetTop}px) scale(${sizeScale})`;
    }, 0);
    setTimeout(() => {
      const waitForItemContentLoadedIntervalHandler = setInterval(() => {
        if (context.state.content !== '') {
          context.commit('openAnimationFinished');
          setTimeout(() => {
            elementCopy.style.opacity = '0';
          }, 450);
          setTimeout(() => {
            elementCopy.remove();
          }, 850);
          clearInterval(waitForItemContentLoadedIntervalHandler);
        }
      }, 100);
    }, 250);
  },
  animateSliderItemBackIntoOriginalPosition(context: DetailViewContext) {
    const itemOrigin = context.getters.getItemOrigin;
    // Try to find original view element position of the current slider item
    const imageSliderElements = document.getElementsByClassName('modal__item');
    const imageSliderElement = imageSliderElements[0];
    const { left, top, width, height } = context.getters.itemDimensions;
    const originalViewElement = document.getElementById(itemOrigin.elementId);
    const originalElementWidth: number = parseInt(originalViewElement.style.width.split('px')[0], 10);
    const originalElementHeight: number = parseInt(originalViewElement.style.height.split('px')[0], 10);
    const scrollOffsetTop = originalViewElement.parentElement.scrollTop;
    const scrollOffsetLeft = originalViewElement.parentElement.scrollLeft;
    const offsetToWindowTop = originalViewElement.parentElement.getBoundingClientRect().top;
    const offsetToWindowLeft = originalViewElement.parentElement.getBoundingClientRect().left;
    const originalElementLeft = parseInt(originalViewElement.style.left.split('px')[0], 10) + offsetToWindowLeft - scrollOffsetLeft;
    const originalElementTop = parseInt(originalViewElement.style.top.split('px')[0], 10) + offsetToWindowTop - scrollOffsetTop;
    const originalElementCenter = new Point(originalElementLeft + originalElementWidth / 2, originalElementTop + originalElementHeight / 2);
    // TODO: parse original element rotation value
    let rotation: any = SnippetMover.ROTATION_TRANSFORM_REGEX.exec(originalViewElement.style.transform);
    rotation = rotation ? parseInt(rotation[1], 10) : 0;
    const imageSliderTopLeftPosition = new Point(left, top);
    const imageSliderElementCenter = new Point(imageSliderTopLeftPosition.x + width / 2, imageSliderTopLeftPosition.y + height / 2);
    const elementCopy = <HTMLElement> imageSliderElement.cloneNode(true);
    elementCopy.style.zIndex = '2000';
    elementCopy.style.transformOrigin = 'center';
    elementCopy.style.position = 'fixed';
    elementCopy.style.left = left + 'px';
    elementCopy.style.top = top + 'px';
    elementCopy.style.width = width + 'px';
    elementCopy.style.height = height + 'px';
    elementCopy.style.transition = 'transform 0.3s ease-in-out, top 0.3s ease-in-out, left 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0s';
    document.body.appendChild(elementCopy);
    const sizeScaleWidth = originalElementWidth / width;
    const sizeScaleHeight = originalElementHeight / height;
    const isOverScalingWithWidthScale = sizeScaleWidth * height / width > itemOrigin.position.height;
    const sizeScale = isOverScalingWithWidthScale ? sizeScaleHeight : sizeScaleWidth;
    const elementOffsetLeft = originalElementCenter.x - imageSliderElementCenter.x;
    const elementOffsetTop = originalElementCenter.y - imageSliderElementCenter.y;
    setTimeout(() => {
      elementCopy.style.transform = `translateX(${elementOffsetLeft}px) translateY(${elementOffsetTop}px) scale(${sizeScale}) rotate(${rotation}deg)`;
    }, 0);
    setTimeout(() => {
      elementCopy.style.opacity = '0';
      setTimeout(() => {
        elementCopy.remove();
      }, 0);
    }, 500);
  },
};

export default actions;

// TODO: recycle current / next / previous item content on slide
async function buildNextDetailViewState(imageLoader: ImageLoader, currentItem: ItemWithPosition, collection: ItemWithPosition[], width: number): Promise<NextDetailViewState> {
  const previousItem = getPreviousItem(currentItem, collection);
  const nextItem = getNextItem(currentItem, collection);
  const previousContent = previousItem ? await imageLoader.loadVersionByWidthInBase64(previousItem.item, width) : '';
  const currentAsset = imageLoader.getOptimalAssetByWidth(currentItem.item, width);
  const content = await imageLoader.loadAssetUrl(currentItem.item, currentAsset);
  const nextContent = nextItem ? await imageLoader.loadVersionByWidthInBase64(nextItem.item, width) : '';
  return {
    previousContent,
    currentAsset,
    content,
    nextContent,
    currentItem,
  };
}

function getPreviousItem(item: ItemWithPosition, collection: ItemWithPosition[]) {
  return collection.find((i: ItemWithPosition) => i.position.order === item.position.order - 1) || null;
}

function getNextItem(item: ItemWithPosition, collection: ItemWithPosition[]) {
  return collection.find((i: ItemWithPosition) => i.position?.order === item.position?.order + 1) || null;
}

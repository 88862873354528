import { Shortcut } from '~/models/Shortcut';
import { ShortcutType } from '~/models/ShortcutType';

export enum ObjectIdPrefix {
  FOLDER = 'f',
  SELECTION = 's',
  ITEM = 'i',
  USER = 'u',
  GLOBAL_SELECTION = 'gs'
}

export class ObjectId {
  uuid: string;
  prefix: string;

  constructor(id: string) {
    const splitted = id?.split('_');
    if (splitted?.length !== 2) {
      throw new Error(`Invalid ObjectId ${id}, expected prefix of ${ObjectIdPrefix.FOLDER}_, ${ObjectIdPrefix.SELECTION}_, ${ObjectIdPrefix.USER}_, ${ObjectIdPrefix.ITEM}_ or ${ObjectIdPrefix.GLOBAL_SELECTION}`);
    }
    this.prefix = splitted[0];
    this.uuid = splitted[1];
  }

  public static fromUserId(uuid: string): ObjectId {
    return new ObjectId(`${ObjectIdPrefix.USER}_${uuid}`);
  }

  public static fromFolderId(uuid: string): ObjectId {
    return new ObjectId(`${ObjectIdPrefix.FOLDER}_${uuid}`);
  }

  public static forGlobalSelection(): ObjectId {
    return new ObjectId('gs_global-selection');
  }

  public static fromSelectionId(uuid: string): ObjectId {
    return new ObjectId(`${ObjectIdPrefix.SELECTION}_${uuid}`);
  }

  public static fromItemId(uuid: string): ObjectId {
    return new ObjectId(`${ObjectIdPrefix.ITEM}_${uuid}`);
  }

  public static fromShortcut(shortcut: Shortcut): ObjectId {
    switch (shortcut.type) {
      case ShortcutType.SELECTION:
        return ObjectId.fromSelectionId(shortcut.id);
      case ShortcutType.FOLDER:
        return ObjectId.fromFolderId(shortcut.id);
      case ShortcutType.USER:
        return ObjectId.fromUserId(shortcut.id);
      case ShortcutType.ITEM:
        return ObjectId.fromItemId(shortcut.id);
      case ShortcutType.GLOBAL_SELECTION:
        return ObjectId.forGlobalSelection();
      default:
        return null;
    }
  }

  public get isGlobalSelection(): boolean {
    return this.prefix === ObjectIdPrefix.GLOBAL_SELECTION;
  }

  public get isFolderId(): boolean {
    return this.prefix === ObjectIdPrefix.FOLDER;
  }

  public get isSelectionId(): boolean {
    return this.prefix === ObjectIdPrefix.SELECTION;
  }

  public get isUserId(): boolean {
    return this.prefix === ObjectIdPrefix.USER;
  }

  public get isItemId(): boolean {
    return this.prefix === ObjectIdPrefix.ITEM;
  }

  toUuid(): string {
    return this.uuid;
  }

  toString(): string {
    return `${this.prefix}_${this.uuid}`;
  }

  toShortcut(): Shortcut {
    const obj: Shortcut = {
      id: this.uuid,
    } as Shortcut;
    if (this.isFolderId) {
      obj.type = ShortcutType.FOLDER;
    } else if (this.isSelectionId) {
      obj.type = ShortcutType.SELECTION;
    } else if (this.isItemId) {
      obj.type = ShortcutType.ITEM;
    } else if (this.isGlobalSelection) {
      obj.type = ShortcutType.GLOBAL_SELECTION;
    } else if (this.isUserId) {
      obj.type = ShortcutType.USER;
    }
    return obj;
  }
}

export function parseObjectIdsFromString(ids: string): ObjectId[] {
  return ids?.split(',').map(id => new ObjectId(id)) ?? [];
}

export class BrowserCompatibilityChecker {
  public static checkForWorkerSupport(): Promise<boolean> {
    return new Promise(resolve => {
      let worker;
      try {
        if (window.Worker != null) {
          worker = new Worker('./testWorker.js', { type: 'module' });
          worker.onmessage = (event) => {
            if (event.data.success) {
              resolve(true);
            } else {
              resolve(false);
            }
            worker.terminate();
          };
          worker.onerror = (_event) => {
            resolve(false);
            worker.terminate();
          };
          worker.postMessage({});
        }
      } catch {
        resolve(false);
      }
    });
  }
}

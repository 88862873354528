import throttle from 'lodash.throttle';
import { v4 as uuid } from 'uuid';
import { Point } from '~/models/ComplexMath';
import { DragElementOffsetValues } from '~/models/views/DragElementOffsetValues';
import Item from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Snippet from '~/models/Snippet';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { EventEmitter } from '~/models/uploader/EventEmitter';

export function isTouchEvent(event: any | TouchEvent): event is TouchEvent {
  return (<TouchEvent>event).touches !== undefined;
}

export enum SnippetMoverEvent {
  MOVED_THROUGH_BOTTOM_BOUND = 'moved-through-bottom-bound',
  MOVE_START = 'move-start',
  MOVE_END = 'move-end'
}

export interface Position {
  left: number;
  top: number;
}

export interface PositionWithOptionalSize extends Position {
  width?: number;
  height?: number;
}

export interface Bounds {
  left: number;
  top: number;
  bottom: number;
  right: number;
}

export interface SnippetMoverMoveStartEvent {
  offsets: DragElementOffsetValues;
  size: {
    width: number;
    height: number;
  };
  transferType: TransferType;
  moveId: string;
  dragViewId: string;
  items: Item[];
}

export interface SnippetMoverMoveEndEvent {
  moveId: string;
  event: DragEvent;
  targetSnippet: Snippet;
  position: Position;
  positionRelative: Position;
  dragOffsets: Position;
  dragItemCenter: Position;
}

interface WindowOffsets {
  fixedLeft: number,
  fixedTop: number,
  scrollTop: number,
  scrollLeft: number,
  windowTop: number,
  windowLeft: number
}

export interface SnippetMoveProcess {
  viewId: string;
  targetSnippet: Snippet;
  targetElement: HTMLElement;
  dropPosition?: Position;
  moveItems: ItemWithPosition[];
  elementCopies: HTMLElement[];
  originalElementCopies: Map<string, HTMLElement>;
  dragElementOffsetValues: DragElementOffsetValues;
  windowOffsets: WindowOffsets;
  dragViewBounds: Bounds;
}

export class SnippetMover extends EventEmitter {
  public static ROTATION_TRANSFORM_REGEX = /rotate\(([^)]+)deg\)/;
  private moveBoundaryBottom = 0;
  private moveBoundaryRight = 0;

  constructor(private viewId: string, private viewElement: HTMLElement) {
    super();
    snippetMoverState.registeredViews.set(this.viewId, {
      viewId: this.viewId,
      viewElement: this.viewElement,
      parentViewElement: this.parentViewElement,
      snippetMover: this,
    });
  }

  public destroy() {
    snippetMoverState.registeredViews.delete(this.viewId);
  }

  public isMovingMultipleItems(moveId: string): boolean {
    return snippetMoverState.processingMoves.get(moveId).moveItems.length > 1;
  }

  public moveProcess(moveId): SnippetMoveProcess {
    return snippetMoverState.processingMoves.get(moveId);
  }

  public itemsMoved(moveId): ItemWithPosition[] {
    return snippetMoverState.processingMoves.get(moveId).moveItems;
  }

  public get itemsCurrentlyMoved(): ItemWithPosition[] {
    return Array.from(snippetMoverState.processingMoves.values()).reduce((agg: ItemWithPosition[], curr: SnippetMoveProcess) => [...agg, ...curr.moveItems], []);
  }

  setMoveBoundaryRight(moveBoundaryRight: number) {
    this.moveBoundaryRight = moveBoundaryRight;
  }

  setMoveBoundaryBottom(moveBoundaryBottom: number) {
    this.moveBoundaryBottom = moveBoundaryBottom;
  }

  stopMovingSnippets(moveId: string) {
    // TODO: look if element copies still exist and then clear them from the DOM
    snippetMoverState.processingMoves.delete(moveId);
  }

  public moveSnippets(event: DragEvent, targetSnippet: Snippet, moveItems: ItemWithPosition[], popOut: boolean, keepRelativePositions: boolean = false) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const that = this;
    const item = targetSnippet.item.itemData;
    const elementCopies = [];
    const originalElements = [];
    const originalElementCopies = new Map<string, any>();
    const randomStapleOffsets = {};
    const dragStartPosition = new Point(event.clientX, event.clientY);
    const touchElementId = `${this.viewId}_${targetSnippet.item.id}`;
    if (this.itemsCurrentlyMoved.some(item => moveItems.some(i => item.id === i.id))) {
      return;
    }
    if (event.dataTransfer) {
      event.dataTransfer.effectAllowed = 'all';
      event.dataTransfer.dropEffect = 'move';
    }
    const moveId = uuid();
    const originalTouchElement = document.getElementById(touchElementId);
    const windowOffsets = this.windowOffsets;
    const dragElementOffsetValues: DragElementOffsetValues = this.calculateDragElementOffsetValues(event, originalTouchElement, targetSnippet);
    const dragViewBounds = this.calculateDragViewBounds(originalTouchElement, dragElementOffsetValues);
    if (event.dataTransfer && event.metaKey) {
      const elementWithoutOverlay = originalTouchElement.getElementsByClassName('mosaic-item');
      // @ts-ignore
      if (elementWithoutOverlay[0].src) {
        const img: HTMLElement = document.createElement('img');
        img.id = 'temp-drag-element';
        // @ts-ignore
        img.src = elementWithoutOverlay[0].src;
        img.style.width = targetSnippet.item.viewPosition.width + 'px';
        img.style.height = targetSnippet.item.viewPosition.height + 'px';
        img.style.position = 'fixed';
        img.style.top = '-5000px';
        img.style.left = '-5000px';
        document.body.appendChild(img);
        event.dataTransfer.setDragImage(img, event.offsetX, event.offsetY);
      }
    } else if (event.dataTransfer) {
      const img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
      event.dataTransfer.setDragImage(img, 0, 0);
    }
    for (const i of moveItems) {
      const el = document.getElementById(`${this.viewId}_${i.id}`);
      if (el) {
        elementCopies.push(el.cloneNode(true));
        originalElementCopies.set(el.id, el.cloneNode());
        el.style.transition = 'opacity 0s';
        el.style.opacity = popOut ? '0.1' : '0';
        originalElements.push(el);
        randomStapleOffsets[el.id] = {
          left: this.randomInteger(-20, 40),
          top: this.randomInteger(-50, 10),
          transform: this.randomInteger(-4, 4),
        };
      }
    }
    let htmlString = '<div>';
    const itemOffsetsToDragStart = new Map<string, Point>();
    let originalZindex = 0;
    for (const element of <HTMLElement[]> Array.from(elementCopies)) {
      // Info: e.g. whatsapp supports parsing html/text from the dataTransfer object (currently whatsapp only supports parsing 1 image element at a time),
      //  others might support it, so we add as much info about all selected elements in the dataTransfer object as possible
      // TODO: evaluate adding high quality jpeg data to the dataTransfer object (instead of using the dragged element itself)
      const imageElements = element.getElementsByTagName('img');
      htmlString += Array.from(imageElements).map(el => el.outerHTML).join('');
      if (element.id !== touchElementId) {
        element.style.pointerEvents = 'none';
        element.style.zIndex = `${parseInt(element.style.zIndex, 10) + 2000}`;
        element.classList.add('snippet-wrapper--is-prepared-for-drag');
      } else {
        originalZindex = parseInt(element.style.zIndex, 10);
        element.style.zIndex = `${originalZindex + (keepRelativePositions ? 2000 : 3000)}`;
        element.classList.add('snippet-wrapper--is-drag-element');
      }
      const fixedLeftPosition = parseInt(element.style.left.split('px')[0], 10) + windowOffsets.fixedLeft;
      const fixedTopPosition = parseInt(element.style.top.split('px')[0], 10) + windowOffsets.fixedTop;
      itemOffsetsToDragStart.set(element.id, new Point(dragStartPosition.x - fixedLeftPosition, dragStartPosition.y - fixedTopPosition));
      element.style.left = fixedLeftPosition + 'px';
      element.style.top = fixedTopPosition + 'px';
      document.body.appendChild(element);
    }

    let animationEnded = false;
    setTimeout(() => {
      animationEnded = true;
    }, 400);
    let initialAnimatedTransformValuesAlreadySetForTargetElement = false;
    let dragThrottleValue = 10 + elementCopies.length * 3;
    dragThrottleValue = dragThrottleValue > 50 ? 50 : dragThrottleValue;

    const throttledDrag = elementCopies.length > 50
      ? throttle(onDrag, dragThrottleValue, {
        leading: true,
        trailing: false,
      })
      : onDrag;

    let clientX;
    let clientY;
    let leftPosition;
    let topPosition;
    let animationFrame;
    let isEventOutsideBottomBound = false;
    const defaultScale = popOut ? 1.025 : 1;
    const lastHoverElement = null;
    let positionValue = 0;
    let lastProcessedPositionValue = 0;

    function onDrag(event: DragEvent | TouchEvent) {
      if (isTouchEvent(event)) {
        if (event.changedTouches) {
          const left = event.changedTouches[0].clientX;
          const top = event.changedTouches[0].clientY;
          if (left > 0 && top > 0) {
            positionValue = left + top;
            clientX = left;
            clientY = top;
            const hoverElement = document.elementFromPoint(clientX, clientY);
            if (hoverElement) {
              if (hoverElement !== lastHoverElement) {
                hoverElement.dispatchEvent(new CustomEvent('touchenter', { detail: event }));
                if (lastHoverElement) {
                  hoverElement.dispatchEvent(new CustomEvent('touchleave', { detail: event }));
                }
              } else {
                hoverElement.dispatchEvent(new CustomEvent('touchhover', { detail: event }));
              }
            }
          }
        }
      } else {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (event.clientX > 0 && event.clientY > 0) {
          clientX = event.clientX;
          clientY = event.clientY;
          positionValue = clientX + clientY;
        }
      }
      leftPosition = clientX - dragElementOffsetValues.left;
      topPosition = clientY - dragElementOffsetValues.top;
      const isNowOutsideBottomBound = that.isEventOutsideBottom(clientY, dragViewBounds);
      if (isNowOutsideBottomBound !== isEventOutsideBottomBound) {
        that.emit(SnippetMoverEvent.MOVED_THROUGH_BOTTOM_BOUND, { outside: isNowOutsideBottomBound });
        isEventOutsideBottomBound = isNowOutsideBottomBound;
      }
    }

    function updateMoveItemPosition() {
      if (positionValue !== lastProcessedPositionValue) {
        lastProcessedPositionValue = positionValue;
        const eventOutSideBounds = that.isEventOutsideBounds(clientX, clientY, dragViewBounds);
        const scaleValue = eventOutSideBounds ? dragElementOffsetValues.scale : defaultScale;
        for (const element of <HTMLElement[]> Array.from(elementCopies)) {
          const randomOffsets = randomStapleOffsets[element.id];
          if (eventOutSideBounds) {
            element.style.opacity = '0.5';
          } else {
            element.style.opacity = '1';
          }
          if (animationEnded || keepRelativePositions) {
            element.style.position = 'fixed';
            if (element.id !== touchElementId) {
              element.classList.remove('snippet-wrapper--is-prepared-for-drag');
              element.classList.add('snippet-wrapper--is-dragged');
            }
          }
          let translateX = eventOutSideBounds ? dragElementOffsetValues.leftScaleDiff : 0;
          let translateY = eventOutSideBounds ? dragElementOffsetValues.topScaleDiff : 0;
          if (element.id === touchElementId) {
            if (!initialAnimatedTransformValuesAlreadySetForTargetElement) {
              if (popOut) {
                element.style.boxShadow = '0px 0px 25px 7px rgba(0,0,0,0.85)';
              }
              initialAnimatedTransformValuesAlreadySetForTargetElement = true;
            }
            element.style.transformOrigin = targetSnippet.item.transform ? 'center' : 'top left';
            element.style.transform = `translateX(${translateX}px) translateY(${translateY}px) ${targetSnippet.item.transform || ''} scale(${scaleValue})`;
            element.style.left = leftPosition + 'px';
            element.style.top = topPosition + 'px';
          } else if (!eventOutSideBounds && keepRelativePositions) {
            const targetPositionLeft = clientX - itemOffsetsToDragStart.get(element.id).x;
            const targetPositionTop = clientY - itemOffsetsToDragStart.get(element.id).y;
            const originalStyle = originalElementCopies.get(element.id).style;
            element.style.transformOrigin = originalStyle.transformOrigin;
            element.style.transform = originalStyle.transform;
            element.style.left = targetPositionLeft + 'px';
            element.style.top = targetPositionTop + 'px';
          } else {
            let targetPositionLeft;
            let targetPositionTop;
            if (!keepRelativePositions) {
              targetPositionLeft = leftPosition + randomOffsets.left;
              targetPositionTop = topPosition + randomOffsets.top;
              element.style.opacity = '0.5';
            } else {
              targetPositionLeft = clientX - itemOffsetsToDragStart.get(element.id).x;
              targetPositionTop = clientY - itemOffsetsToDragStart.get(element.id).y;
              translateX = translateX + itemOffsetsToDragStart.get(element.id).x - 2 * dragElementOffsetValues.leftScaleDiff + randomOffsets.left;
              translateY = translateY + itemOffsetsToDragStart.get(element.id).y - 2 * dragElementOffsetValues.topScaleDiff + randomOffsets.top;
            }
            element.style.transformOrigin = 'top left';
            element.style.transform = `translateX(${translateX}px) translateY(${translateY}px) scale(${scaleValue})`;
            element.style.left = targetPositionLeft + 'px';
            element.style.top = targetPositionTop + 'px';
          }
        }
      }
      animationFrame = requestAnimationFrame(updateMoveItemPosition);
    }

    animationFrame = requestAnimationFrame(updateMoveItemPosition);

    function onDragEnd(dragEndEvent: DragEvent | TouchEvent) {
      if (!isTouchEvent(dragEndEvent)) {
        dragEndEvent.preventDefault();
        dragEndEvent.stopImmediatePropagation();
      }
      cancelAnimationFrame(animationFrame);
      const position = isTouchEvent(dragEndEvent)
        ? {
            left: dragEndEvent.changedTouches[0]?.clientX || clientX,
            top: dragEndEvent.changedTouches[0]?.clientY || clientY,
          }
        : {
            left: dragEndEvent.clientX,
            top: dragEndEvent.clientY,
          };
      if (isTouchEvent(dragEndEvent)) {
        const hoverElement = document.elementFromPoint(position.left, position.top);
        const touchDrop = new CustomEvent('touchdrop', {
          ...dragEndEvent,
        });
        try {
          hoverElement.dispatchEvent(touchDrop);
        } catch (err) {
          console.log(err);
        }
      }
      const dragOffsets = that.isEventOutsideBounds(position.left, position.top, dragViewBounds)
        ? {
            left: dragElementOffsetValues.leftScaleDiff,
            top: dragElementOffsetValues.topScaleDiff,
          }
        : {
            left: dragElementOffsetValues.left,
            top: dragElementOffsetValues.top,
          };
      const viewId = snippetMoverState.processingMoves.get(moveId).viewId;
      const parentViewElement = document.getElementById(viewId + '-scroll-view');
      const { scrollTop: scrollOffsetTop, scrollLeft: scrollOffsetLeft } = parentViewElement;
      const { top: offsetToWindowTop, left: offsetToWindowLeft } = parentViewElement.getBoundingClientRect();
      const newWindowOffset = {
        fixedLeft: offsetToWindowLeft - scrollOffsetLeft,
        fixedTop: offsetToWindowTop - scrollOffsetTop,
        scrollTop: scrollOffsetTop,
        scrollLeft: scrollOffsetLeft,
        windowTop: offsetToWindowTop,
        windowLeft: offsetToWindowLeft,
      };
      snippetMoverState.processingMoves.set(moveId, {
        ...snippetMoverState.processingMoves.get(moveId),
        windowOffsets: newWindowOffset,
      });
      const positionRelative = {
        left: position.left - newWindowOffset.windowLeft + newWindowOffset.scrollLeft,
        top: position.top - newWindowOffset.windowTop + newWindowOffset.scrollTop,
      };
      const dragItemCenter = {
        left: positionRelative.left - dragOffsets.left + targetSnippet.width / 2,
        top: positionRelative.top - dragOffsets.top + targetSnippet.height / 2,
      };
      snippetMoverState.processingMoves.get(moveId).dropPosition = position;
      that.emit(SnippetMoverEvent.MOVE_END, {
        moveId,
        event: dragEndEvent,
        targetSnippet,
        position,
        positionRelative,
        dragOffsets,
        dragItemCenter,
      });
      const tempDragElement = document.getElementById('temp--drag-element');
      if (tempDragElement) {
        tempDragElement.remove();
      }
      document.removeEventListener('mousemove', throttledDrag);
      document.removeEventListener('touchmove', throttledDrag);
      document.removeEventListener('mouseup', onDragEnd);
      document.removeEventListener('touchend', onDragEnd);
    }

    document.addEventListener('touchmove', throttledDrag);
    document.addEventListener('mousemove', throttledDrag, false);
    document.addEventListener('touchend', onDragEnd, false);
    document.addEventListener('mouseup', onDragEnd);
    htmlString += '</div>';
    let transferType: TransferType;
    if (moveItems.length > 1) {
      transferType = TransferType.VIEW_SELECTION;
      event.dataTransfer?.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.VIEW_SELECTION);
    } else {
      transferType = TransferType.ITEM;
      event.dataTransfer?.setData(TransferOption.MUTANT_TRANSFER_TYPE, TransferType.ITEM);
      event.dataTransfer?.setData(TransferOption.MUTANT_ID, item.id);
    }
    event.dataTransfer?.setData(TransferOption.MUTANT_MOVE_ID, moveId);
    event.dataTransfer?.setData('text/html', htmlString);
    snippetMoverState.processingMoves.set(moveId, {
      viewId: this.viewId,
      moveItems,
      targetSnippet,
      targetElement: originalTouchElement,
      elementCopies,
      originalElementCopies,
      windowOffsets,
      dragElementOffsetValues,
      dragViewBounds,
    });
    this.emit(SnippetMoverEvent.MOVE_START, {
      offsets: dragElementOffsetValues,
      size: {
        width: originalTouchElement.clientWidth,
        height: originalTouchElement.clientHeight,
      },
      transferType,
      moveId,
      dragViewId: this.viewId,
      items: moveItems,
    });
  }

  public bounceItems(moveId: string) {
    const { elementCopies, originalElementCopies, windowOffsets } = snippetMoverState.processingMoves.get(moveId);
    for (const elementCopy of elementCopies) {
      const originalElementCopy = originalElementCopies.get(elementCopy.id);
      elementCopy.classList.remove('snippet-wrapper--is-dragged');
      elementCopy.classList.remove('snippet-wrapper--is-drag-element');
      elementCopy.classList.add('snippet-wrapper--is-bounced');
      elementCopy.style.transition = originalElementCopy.style.transition;
      elementCopy.style.transform = originalElementCopy.style.transform;
      elementCopy.style.transformOrigin = originalElementCopy.style.transformOrigin;
      elementCopy.style.boxShadow = originalElementCopy.style.boxShadow;
      elementCopy.style.left = parseInt(originalElementCopy.style.left.slice(0, originalElementCopy.style.left.length - 2), 10) + windowOffsets.fixedLeft + 'px';
      elementCopy.style.top = parseInt(originalElementCopy.style.top.slice(0, originalElementCopy.style.top.length - 2), 10) + windowOffsets.fixedTop + 'px';
      elementCopy.style.opacity = '0';
      elementCopy.style.zIndex = originalElementCopy.style.zIndex;
      const originalElementReference = document.getElementById(originalElementCopy.id);
      if (originalElementReference) {
        originalElementReference.style.opacity = originalElementCopy.style.opacity;
        originalElementReference.style.transition = originalElementCopy.style.transition;
      }
    }
    setTimeout(() => {
      elementCopies.forEach(elementCopy => elementCopy.remove());
      this.stopMovingSnippets(moveId);
    }, 500);
  }

  // TODO: used by moodboard
  //   - don't change item shadow when inside view-bounds
  //   - make slightly smaller when outside view-bounds (drop in effect)
  public moveIntoPosition(moveId: string, zoomPoint: { left: number, top: number }, keepRelativePositions: boolean = false, animate: boolean = false) {
    const { elementCopies, originalElementCopies, dropPosition } = snippetMoverState.processingMoves.get(moveId);
    const translateY = keepRelativePositions ? 0 : zoomPoint.top - dropPosition.top;
    const translateX = keepRelativePositions ? 0 : zoomPoint.left - dropPosition.left;
    for (const elementCopy of elementCopies) {
      const originalElementCopy = originalElementCopies.get(elementCopy.id);
      elementCopy.style.opacity = '1';
      elementCopy.style.boxShadow = originalElementCopy.style.boxShadow;
      elementCopy.classList.add('snippet-wrapper--is-sorted');
      elementCopy.style.transform = translateX || translateY
        ? `${elementCopy.style.transform} translateY(${translateY}px) translateX(${translateX}px)`
        : elementCopy.style.transform;
      const originalElementReference = document.getElementById(originalElementCopy.id);
      originalElementReference.style.opacity = '0';
    }
    if (animate) {
      setTimeout(() => {
        elementCopies.forEach(elementCopy => elementCopy.remove());
        this.stopMovingSnippets(moveId);
      }, 350);
      setTimeout(() => {
        Array.from(originalElementCopies.values()).forEach(originalElementCopy => {
          const originalElementReference = document.getElementById(originalElementCopy.id);
          originalElementReference.style.opacity = originalElementCopy.style.opacity;
          originalElementReference.style.transition = originalElementCopy.style.transition;
        });
      }, 0);
    } else {
      setTimeout(() => {
        Array.from(originalElementCopies.values()).forEach(originalElementCopy => {
          const originalElementReference = document.getElementById(originalElementCopy.id);
          originalElementReference.style.opacity = originalElementCopy.style.opacity;
          setTimeout(() => {
            originalElementReference.style.transition = originalElementCopy.style.transition;
          }, 25);
        });
        setTimeout(() => elementCopies.forEach(elementCopy => elementCopy.remove(), 0));
        this.stopMovingSnippets(moveId);
      }, 0);
    }
  }

  // used for zooming items into a given position (item gets smaller and smaller while disappearing into the given position)
  public zoomIntoPosition(moveId: string, zoomPoint: { left: number, top: number }) {
    const { elementCopies, targetElement, originalElementCopies, dropPosition, dragElementOffsetValues } = snippetMoverState.processingMoves.get(moveId);
    const hasRotation = !!targetElement.style.transform && targetElement.style.transform.includes('rotate');
    const translateY = zoomPoint.top - (dropPosition.top - (hasRotation ? dragElementOffsetValues.topScaleDiff : dragElementOffsetValues.top));
    const translateX = zoomPoint.left - (dropPosition.left - (hasRotation ? dragElementOffsetValues.leftScaleDiff : dragElementOffsetValues.left));
    for (const elementCopy of elementCopies) {
      elementCopy.classList.add('snippet-wrapper--is-dropped');
      elementCopy.style.opacity = '0';
      elementCopy.style.transform = `translateY(${translateY}px) translateX(${translateX}px) scale(0.01)`;
    }
    setTimeout(() => {
      elementCopies.forEach(elementCopy => elementCopy.remove());
      this.stopMovingSnippets(moveId);
    }, 800);
    setTimeout(() => {
      for (const originalElementCopy of Array.from(originalElementCopies.values())) {
        const originalElementReference = document.getElementById(originalElementCopy.id);
        if (originalElementReference) {
          originalElementReference.style.opacity = originalElementCopy.style.opacity;
          originalElementReference.style.transition = originalElementCopy.style.transition;
        }
      }
      this.emit('zoomed-into-position');
    }, 250);
  }

  // Used for sorting items into multiple given positions
  public sortIntoPosition(moveId: string, sortPointMap: Map<string, PositionWithOptionalSize>) {
    const { elementCopies, originalElementCopies } = snippetMoverState.processingMoves.get(moveId);
    for (const elementCopy of elementCopies) {
      const sortPoint = sortPointMap.get(elementCopy.id);
      const originalElementCopy = originalElementCopies.get(elementCopy.id);
      if (sortPoint) {
        const elementPosition = {
          left: parseInt(elementCopy.style.left.slice(0, elementCopy.style.left.length - 2), 10),
          top: parseInt(elementCopy.style.top.slice(0, elementCopy.style.top.length - 2), 10),
        };
        const translateY = sortPoint.top - elementPosition.top;
        const translateX = sortPoint.left - elementPosition.left;
        let scale = 1;
        const elementWidth = parseInt(elementCopy.style.width.slice(0, elementCopy.style.width.length - 2), 10);
        if (sortPoint.width && sortPoint.width !== elementWidth) {
          scale = sortPoint.width / elementWidth;
        }
        elementCopy.style.zIndex = '9999';
        elementCopy.style.opacity = '1';
        elementCopy.style.boxShadow = originalElementCopy.style.boxShadow;
        elementCopy.classList.add('snippet-wrapper--is-sorted');
        elementCopy.style.transformOrigin = 'left top';
        elementCopy.style.transform = `translateY(${translateY}px) translateX(${translateX}px) scale(${scale})`;
        const originalElementReference = document.getElementById(elementCopy.id);
        originalElementReference.style.opacity = '0';
      }
    }
    setTimeout(() => {
      elementCopies.forEach(elementCopy => elementCopy.remove());
      this.stopMovingSnippets(moveId);
    }, 1050);
    setTimeout(() => {
      for (const originalElementCopy of Array.from(originalElementCopies.values())) {
        const originalElementReference = document.getElementById(originalElementCopy.id);
        if (originalElementReference) {
          originalElementReference.style.opacity = originalElementCopy.style.opacity;
          originalElementReference.style.transition = originalElementCopy.style.transition;
        }
      }
    }, 750);
  }

  calculateDragViewBounds(element: HTMLElement, dragElementOffsetValues: DragElementOffsetValues) {
    const { top, left, right, bottom } = this.parentViewElement.getBoundingClientRect();
    const offsetRight = dragElementOffsetValues.width - dragElementOffsetValues.left;
    const { height, width } = element.getBoundingClientRect();
    const pixelThresholdVertical = height / 2;
    const horizontalMouseOffset = (width / 2) - offsetRight;
    return {
      top: top - pixelThresholdVertical + dragElementOffsetValues.top,
      right: right + horizontalMouseOffset - (this.moveBoundaryRight > 0 ? this.moveBoundaryRight : 0),
      bottom: (this.moveBoundaryBottom > 0 ? this.moveBoundaryBottom : bottom) + pixelThresholdVertical - (dragElementOffsetValues.height - dragElementOffsetValues.top),
      left: left + horizontalMouseOffset,
    };
  }

  public get parentViewElement(): HTMLElement {
    return document.getElementById(this.viewId + '-scroll-view');
  }

  private get windowOffsets() {
    const { scrollTop: scrollOffsetTop, scrollLeft: scrollOffsetLeft } = this.parentViewElement;
    const { top: offsetToWindowTop, left: offsetToWindowLeft } = this.parentViewElement.getBoundingClientRect();
    return {
      fixedLeft: offsetToWindowLeft - scrollOffsetLeft,
      fixedTop: offsetToWindowTop - scrollOffsetTop,
      scrollTop: scrollOffsetTop,
      scrollLeft: scrollOffsetLeft,
      windowTop: offsetToWindowTop,
      windowLeft: offsetToWindowLeft,
    };
  }

  private calculateDragElementOffsetValues(event: DragEvent, element: HTMLElement, snippet: Snippet): DragElementOffsetValues {
    const dragElementOffsetValues: DragElementOffsetValues = this.calculateSingleDragElementOffsetValues(event, element, snippet);
    dragElementOffsetValues.scaleInitial = dragElementOffsetValues.scale * 3 / 2 <= 1 ? dragElementOffsetValues.scale * 3 / 2 : dragElementOffsetValues.scale;
    dragElementOffsetValues.leftScaledInitial = dragElementOffsetValues.left * dragElementOffsetValues.scaleInitial;
    dragElementOffsetValues.topScaledInitial = dragElementOffsetValues.top * dragElementOffsetValues.scaleInitial;
    dragElementOffsetValues.leftScaleDiffInitial = dragElementOffsetValues.left - dragElementOffsetValues.leftScaledInitial;
    dragElementOffsetValues.topScaleDiffInitial = dragElementOffsetValues.top - dragElementOffsetValues.topScaledInitial;
    return dragElementOffsetValues;
  }

  private calculateSingleDragElementOffsetValues(event: DragEvent, _element: HTMLElement, snippet: Snippet): DragElementOffsetValues {
    let clientX;
    let clientY;
    if (event.clientX) {
      clientX = event.clientX;
      clientY = event.clientY;
    } else {
      clientX = (<any> event).touches[0].clientX;
      clientY = (<any> event).touches[0].clientY;
    }
    const dragElementOffsetValues: DragElementOffsetValues = <DragElementOffsetValues> {};
    const { scrollLeft, scrollTop, windowLeft, windowTop } = this.windowOffsets;
    const { width, height } = snippet.item.viewPosition;
    let { top, left } = snippet.item.viewPosition;
    left = left - scrollLeft + windowLeft;
    top = top - scrollTop + windowTop;
    const imagePosition: Point = new Point(left, top);
    const dragStartPosition: Point = new Point(clientX, clientY);
    let rotation: any = SnippetMover.ROTATION_TRANSFORM_REGEX.exec(snippet.item.transform);
    rotation = rotation ? parseInt(rotation[1], 10) : null;
    const scale = calculateDragElementScaleBasedOnOriginalWidth(width);
    dragElementOffsetValues.width = width;
    dragElementOffsetValues.height = height;
    dragElementOffsetValues.scale = scale;
    dragElementOffsetValues.left = dragStartPosition.x - imagePosition.x;
    dragElementOffsetValues.top = dragStartPosition.y - imagePosition.y;
    dragElementOffsetValues.widthScaled = width * scale;
    dragElementOffsetValues.heightScaled = height * scale;
    if (!rotation) {
      dragElementOffsetValues.leftScaled = dragElementOffsetValues.left * dragElementOffsetValues.scale;
      dragElementOffsetValues.topScaled = dragElementOffsetValues.top * dragElementOffsetValues.scale;
      dragElementOffsetValues.leftScaleDiff = dragElementOffsetValues.left - dragElementOffsetValues.leftScaled;
      dragElementOffsetValues.topScaleDiff = dragElementOffsetValues.top - dragElementOffsetValues.topScaled;
    } else {
      const origin = rotation
        ? new Point(left + width / 2, top + height / 2)
        : new Point(left, top);
      const diffX = dragStartPosition.x - origin.x;
      const diffY = dragStartPosition.y - origin.y;
      dragElementOffsetValues.leftScaleDiff = diffX * (1 - scale);
      dragElementOffsetValues.topScaleDiff = diffY * (1 - scale);
    }
    return dragElementOffsetValues;

    function calculateDragElementScaleBasedOnOriginalWidth(width: number) {
      if (width <= 100) {
        return 1;
      }
      if (width / 2 > 100 && width / 2 < 150) {
        return 0.5;
      }
      if (width / 2 >= 150) {
        return 150 / width;
      }
      return 100 / width;
    }
  }

  private isEventOutsideBounds(leftPosition: number, topPosition: number, bounds: { top: number, left: number, right: number, bottom: number }): boolean {
    return leftPosition <= bounds.left || leftPosition >= bounds.right || topPosition <= bounds.top || topPosition >= bounds.bottom;
  }

  private isEventOutsideBottom(topPosition, bounds: { bottom: number }) {
    return topPosition >= bounds.bottom;
  }

  private randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}

interface ViewDetails {
  viewId: string;
  viewElement: HTMLElement;
  parentViewElement: HTMLElement;
  snippetMover: SnippetMover;
}

interface SnippetMoverState {
  processingMoves: Map<string, SnippetMoveProcess>;
  registeredViews: Map<string, ViewDetails>;
}

const snippetMoverState: SnippetMoverState = {
  processingMoves: new Map<string, SnippetMoveProcess>(),
  registeredViews: new Map<string, ViewDetails>(),
};

export enum SuccessMessages {
  COPIED_TO_CLIPBOARD = 'Copied to clipboard',
  SERVER_REACHABLE = 'Connection to Server established!'
}
export enum WarningMessages {
  INVALID_USER_LENGTH = 'Username is too short or too long',
  EMPTY_FILE_LIST = 'No files to upload',
  UNSUPPORTED_FILES_DETECTED = 'Detected unsupported files, these files will be discarded',
  ALL_FILES_UNSUPPORTED = 'None of your files are supported. Please provide images in format of jpg, png, gif or tiff',
  FORBIDDEN_ERROR = 'You are not allowed to perform this action',
  TOTAL_FILES_LIMIT_REACHED = 'Currently we only support 1000 files per per folder or selection, files above will be discarded',
  FILE_SIZE_LIMIT_REACHED = 'Currently we only support adding files up to a total of 10 GByte per folder or selection, files above will be discarded',
  SERVER_UNREACHABLE = 'Server is currently unreachable. You can still browse your cached images. Changes wont be saved at the moment.',
  SERVER_ERROR = 'Something went wrong on our end, please try again later or contact support.',
  UPLOAD_LIMIT_EXCEEDED = 'You have reached your upload limit for this month. Please upgrade your account to continue uploading.',
  FOLDER_CONFLICT = 'Something went wrong while syncing the folder, if this issue persist please contact our support.',
  UPLOAD_PROFILE_IMAGE_TYPE_MISMATCH = 'We currently only support jpg or png for uploading a profile picture',
  UPLOAD_PROFILE_GENERIC_ERROR = 'Something went wrong while uploading your profile picture. Please try again later or contact our support.',
}

export enum InfoMessages {
  USER_NOT_FOUND = 'User was not found',
  CONTACT_ALREADY_EXISTS = 'Contact already exists',
  NO_REINDEX_WHILE_PIPELINE_IS_RUNNING = 'Please wait until all images have been indexed and try again',
  DELETION_WHILE_UPLOAD_NOT_POSSIBLE = 'Deletion while uploading is not possible',
  VERIFY_EMAIL_SUCCESS = 'Email successfully verified.',
  VERIFY_EMAIL_SUCCESS_WITH_LOGIN_PROMPT = 'Email successfully verified. Please login to continue.'
}

export type MessageType =
  SuccessMessages | WarningMessages | InfoMessages;

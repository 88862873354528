import { Plugin } from '@nuxt/types';
import JSZip from 'jszip';
import { ZipBuilder } from '~/models/file/ZipBuilder';

export interface JSZipFactory {
  createInstance: () => JSZip;
}
const zipBuilder: Plugin = (_context: any, inject: any) => {
  const jsZipFactory: JSZipFactory = {
    createInstance: (): JSZip => new JSZip(),
  };
  const zipBuilder = new ZipBuilder(jsZipFactory);
  inject('zipBuilder', zipBuilder);
};
export default zipBuilder;

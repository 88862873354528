import { BatchQueryParams, BatchUploader, UploadQueueItem } from '~/models/uploader/BatchUploader';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import { PipelineEventProcessedStatus, PipelineItem } from '~/models/PipelineItem';

export class ThumbnailBatchUploader extends BatchUploader {
  emitUploaded(batch: UploadQueueItem[], error?: Error) {
    this.emit('thumbnails-uploaded', batch.map((b: UploadQueueItem) => {
      const event: PipelineEventProcessedStatus = b.item.thumbnail != null ? { type: PipelineEventType.THUMBNAILS_UPLOADED, error } : { type: PipelineEventType.ORIGINALS_UPLOADED, error };
      return {
        item: {
          ...b.item,
          eventsProcessed: b.item.eventsProcessed?.length > 0 ? [...b.item.eventsProcessed, event] : [event],
        },
      };
    }));
  }

  protected addQueueItems(queueItems: UploadQueueItem[]): void {
    this.uploadQueue.push(...queueItems);
    this.setupWorkers();
  }

  buildBatch(): UploadQueueItem[] {
    let batchSize = 0;
    const batch = [];
    while (this.uploadQueue.length && batch.length < 10 && batchSize < 5_000_000) {
      const batchItem = this.uploadQueue.shift();
      if (batchItem != null) {
        batchSize += batchItem.item.thumbnail != null ? batchItem.item.thumbnail.file.size : batchItem.item.file.size;
        batch.push(batchItem);
      }
    }
    return batch;
  }

  buildQueryParamsForBatch(batch: UploadQueueItem[]): BatchQueryParams {
    const hasThumbnail = (item: PipelineItem) => item.thumbnail != null;
    const thumbnailSize = (item) => hasThumbnail(item) ? item.thumbnail.width + 'x' + item.thumbnail.height : item.width + 'x' + item.height;
    const version = (item) => hasThumbnail(item) ? CUSTOM_SUB_VERSION : ORIGINAL_ASSET_VERSION;
    const file = (item) => hasThumbnail(item) ? item.thumbnail.file : item.file;
    return this.buildQueryParamsForBatchCommon(batch, version, file, thumbnailSize);
  }
}

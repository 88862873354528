export function filterByConditions(arr: any[], ...filterFunctionConditions: Function[]): any[][] {
  const results = [[]];
  for (const _ of filterFunctionConditions) {
    results.push([]);
  }
  for (const value of arr) {
    let conditionMet = false;
    for (const [i, filterFunctionCondition] of filterFunctionConditions.entries()) {
      if (filterFunctionCondition(value)) {
        results[i].push(value);
        conditionMet = true;
        break;
      }
    }
    if (!conditionMet) {
      results[results.length - 1].push(value);
    }
  }
  return results;
}

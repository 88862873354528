import { v4 as uuid } from 'uuid';
import { EventEmitter } from '~/models/uploader/EventEmitter';
import { PipelineItem } from '~/models/PipelineItem';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { WebWorkerEvent } from '~/models/thumbnailer/WebWorkerEvent';

interface WebWorker { id: string; instance: Worker; isBusy: boolean; }

export enum MetadataExtractorEvent {
  ITEMS_EXTRACTED = 'metadata-extracted',
}

export class MetadataExtractor extends EventEmitter {
  private total;
  private count;
  private extractionQueue: PipelineItem[] = [];
  private maxBatchFileSize = 10_000_000;
  private maxAmountOfWorkers = navigator.hardwareConcurrency;
  private workerMap: Map<string, WebWorker> = new Map();
  private distributeExtractionIntervalHandler = null;
  private terminateWorkersAfterTimeoutHandler = null;
  private workerSupport = false;

  constructor() {
    super();
    this.count = 0;
    this.total = 0;
  }

  public get numberOfActiveWorkers(): number {
    return this.workerMap.size;
  }

  public setWorkerSupport(workerSupport: boolean) {
    this.workerSupport = workerSupport;
  };

  public addItems(items: PipelineItem[]): void {
    clearTimeout(this.terminateWorkersAfterTimeoutHandler);
    this.extractionQueue.push(...items);
    this.total += items.length;
    this.startExtracting();
  }

  private calculateMedianImageSize() {
    const fileSizes = this.extractionQueue.map(i => i.file ? i.file.size : i.raw.file.size);
    fileSizes.sort((a, b) => a > b ? -1 : 1);
    return fileSizes[Math.floor(fileSizes.length / 2)];
  }

  private sendItemsToWorker(worker) {
    if (!worker.isBusy && this.extractionQueue.length > 0) {
      worker.isBusy = true;
      worker.instance.onmessage = event => this.handleMessage(event);
      worker.instance.postMessage({ items: this.buildOptimalWorkerBatch(), id: worker.id });
    } else {
      worker.isBusy = false;
    }
  }

  private buildOptimalWorkerBatch() {
    const maxBatchSize = (this.extractionQueue.length || 1) / (this.numberOfActiveWorkers || 1);
    let batchFileSize = 0;
    const batch = [];
    // larger batches make sense for smaller files (since we need to do meaningful work in workers to be efficient and not stress the main thread too much)
    while (this.extractionQueue.length > 0 && batch.length < maxBatchSize && batchFileSize < this.maxBatchFileSize) {
      const item = this.extractionQueue.shift();
      batch.push(item);
      batchFileSize += item.file ? item.file.size : item.raw.file.size;
    }
    return batch;
  }

  private distributeExtractionLoad() {
    this.adjustWorkerCountToLoad();
    Array.from(this.workerMap.values()).filter(worker => !worker.isBusy).forEach((worker) => this.sendItemsToWorker(worker));
  }

  private sendItemsToWorkerWith(id: string) {
    const worker = this.workerMap.get(id);
    this.sendItemsToWorker(worker);
  }

  private calculateOptimalWorkerAmount() {
    const medianImageSize = this.calculateMedianImageSize();
    return medianImageSize > 1_000_000 ? this.maxAmountOfWorkers : Math.round(this.maxAmountOfWorkers / 2);
  }

  private adjustWorkerCountToLoad() {
    if (this.numberOfActiveWorkers !== this.maxAmountOfWorkers) {
      const optimalWorkerAmount = this.calculateOptimalWorkerAmount();
      const numberOfWorkersToLaunch = optimalWorkerAmount - this.workerMap.size;
      if (numberOfWorkersToLaunch > 0) {
        for (let i = 0; i < numberOfWorkersToLaunch; i++) {
          const workerId = uuid();
          this.workerMap.set(workerId, { id: workerId, instance: new Worker('./metadataExtractionWorker.js', { type: 'module' }), isBusy: false });
        }
      }
    }
  }

  private startExtracting() {
    if (this.workerSupport) {
      if (this.distributeExtractionIntervalHandler == null) {
        this.distributeExtractionIntervalHandler = setInterval(() => {
          this.distributeExtractionLoad();
        }, 500);
        this.distributeExtractionLoad();
      }
    } else {
      this.extractDimensionsWithoutWorker();
    }
  }

  private initiateWorkerTermination() {
    clearTimeout(this.terminateWorkersAfterTimeoutHandler);
    this.terminateWorkersAfterTimeoutHandler = setTimeout(() => this.terminateWorkers(), 5000);
  }

  private terminateWorkers() {
    clearInterval(this.distributeExtractionIntervalHandler);
    this.distributeExtractionIntervalHandler = null;
    this.workerMap.forEach(w => w.instance.terminate());
    this.workerMap.clear();
    this.count = 0;
    this.extractionQueue = [];
    this.total = 0;
  }

  private handleMessage = event => {
    if (event.data.type === WebWorkerEvent.WORK_COMPLETED) {
      this.sendItemsToWorkerWith(event.data.id);
    } else if (event.data.type === WebWorkerEvent.ITEM_PROCESSED) {
      const processedItem: PipelineItem = event.data.item;
      processedItem.eventsProcessed.push({ type: PipelineEventType.METADATA_EXTRACTED, error: event.data.error });
      this.emit(MetadataExtractorEvent.ITEMS_EXTRACTED, [processedItem]);
      if (this.count === this.total - 1) {
        // Work is done for all items in queue, trigger termination of workers
        this.initiateWorkerTermination();
      } else {
        this.count += 1;
      }
    }
  };

  private extractDimensionsWithoutWorker() {
    // TODO: use pica here
    throw new Error('not implemented yet');
  }
}

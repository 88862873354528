import _cloneDeep from 'lodash.clonedeep';
import { ViewItem } from '~/models/views/ViewItem';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { YOUTUBE_DEFAULT_HEIGHT, YOUTUBE_DEFAULT_WIDTH } from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';

export default class Snippet {
  public height: number;

  constructor(public width: number,
              public item: ViewItem) {
    this.height = item.viewPosition.height;
  }

  adjustHeightToWidth(newWidth: number) {
    this.width = newWidth;
  }

  // TODO: refactor method to work with a subset of snippets (currently mosaic, horizontalView and grid need the result for all snippets)
  public static sortSnippetsBySortEvent(snippets: Snippet[], sortEvent: SortItemsEvent) {
    const originalSortItems = _cloneDeep(sortEvent.items);
    const sortItems = _cloneDeep(sortEvent.items);
    const sortTargetPosition = sortEvent.position;
    const sortedItems = [];
    let nextSortItem = sortItems.shift();
    let itemsShifted = 0;
    let positionFilled = false;
    for (let i = 0; i < snippets.length; i++) {
      const snippet = snippets[i];
      if (i === sortTargetPosition) {
        if (itemsShifted > 0 && snippet.item.id !== nextSortItem?.id) {
          sortedItems.push(snippetWithOrder(snippet, sortedItems.length));
        }
        for (const sortItem of originalSortItems) {
          sortedItems.push(itemAsSnippetWithOrder(sortItem, sortedItems.length));
        }
        positionFilled = true;
        if (!itemsShifted && snippet.item.id !== nextSortItem?.id && !containsSnippet(snippet, sortedItems)) {
          sortedItems.push(snippetWithOrder(snippet, sortedItems.length));
        }
        continue;
      }
      if (snippet.item.id) {
        if (nextSortItem && snippet.item.id === nextSortItem?.id) {
          nextSortItem = sortItems.shift();
          itemsShifted++;
          continue;
        }
        if (!containsSnippet(snippet, sortedItems)) {
          sortedItems.push(snippetWithOrder(snippet, sortedItems.length));
        }
      }
    }
    if (!positionFilled) {
      for (const sortItem of originalSortItems) {
        sortedItems.push(itemAsSnippetWithOrder(sortItem, sortedItems.length));
      }
    }
    return sortedItems;
  }
}

function containsSnippet(snippet: Snippet, sortedItems: Snippet[]) {
  return sortedItems.find(s => s.item.id === snippet.item.id);
}

function snippetWithOrder(snippet: Snippet, order: number): Snippet {
  snippet.item.item.position = {
    ...snippet.item.item.position,
    order,
  };
  return snippet;
}

function itemAsSnippetWithOrder(item: ItemWithPosition, order: number): Snippet {
  const asset = item.item.assets[0];
  item.position.order = order;
  const viewItem = new ViewItem(item);
  viewItem.setViewPositionHeight(
    asset ? asset.height : YOUTUBE_DEFAULT_HEIGHT
  );
  viewItem.setViewPositionWidth(asset ? asset.width : YOUTUBE_DEFAULT_WIDTH);
  return { item: viewItem } as Snippet;
}

import Vue from 'vue';

Vue.directive('resize-observer', {
  bind(el, directive) {
    // @ts-ignore
    if (ResizeObserver) {
      // @ts-ignore
      directive.resizeObserver = new ResizeObserver(handleResizeEvent).observe(el);
    }

    // @ts-ignore
    function handleResizeEvent() {
      const { clientWidth, scrollWidth, clientHeight, scrollHeight } = el;
      const resizeEvent = new CustomEvent('resize', {
        detail: {
          width: clientWidth,
          height: clientHeight,
          scrollWidth,
          scrollHeight,
        },
      });
      el.dispatchEvent(resizeEvent);
    }
  },
  unbind(el, directive, vnode) {
    // @ts-ignore
    if (directive.resizeObserver) {
      // @ts-ignore
      directive.resizeObserver.disconnect();
    }
  },
});

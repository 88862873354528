import moment from 'moment';
import Selection from '~/models/selection/Selection';
import { SelectedItemInfo } from '~/models/SelectedItemInfo';
import { ChangeSet } from '~/models/ChangeSet';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { ManyToManyIdMap } from '~/models/utility/ManyToManyIdMap';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';

export const INITIAL_SELECTION_DEFAULT: Selection = {
  id: null,
  name: 'SELECTION',
  items: [],
  modified: moment(Date.now()).toISOString(),
  isMultiSelection: false,
  selected: true,
};

export interface SelectionState {
  selections: { [selectionId: string]: Selection };
  selectionItems: { [selectionId: string]: SelectionItem[] };
  itemsInSelectionId: ManyToManyIdMap;
  selectionChangeSet: ChangeSet<Selection>;
  selectionItemChangeSet: { [selectionId: string]: ChangeSet<SelectionItem> };
  globalSelectedItems: { [itemId: string]: SelectedItemInfo };
  globalSelectedItemsOrdered: ItemWithPosition[];
  selectedColors: Map<string, string>;
  flaggedForDelete: any;
  showSelectedItems: boolean;
}

export default (): SelectionState => ({
  selections: Object.create(null) as { [selectionId: string]: Selection },
  selectionItems: Object.create(null) as { [selectionId: string]: SelectionItem[] },
  itemsInSelectionId: new ManyToManyIdMap(),
  selectionChangeSet: null,
  selectionItemChangeSet: Object.create(null) as { [selectionId: string]: ChangeSet<SelectionItem> },
  globalSelectedItems: Object.create(null) as { [itemId: string]: SelectedItemInfo },
  globalSelectedItemsOrdered: [] as ItemWithPosition[],
  selectedColors: new Map<string, string>(),
  flaggedForDelete: {},
  showSelectedItems: true, // TODO: move to folder module
});

import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';

export interface PipelineEventProcessedStatus {
  type: PipelineEventType;
  error?: Error;
}

export const containsErroredEvent = (e: PipelineEventProcessedStatus) => e.error != null;
export const containsErroredEventType = (type:PipelineEventType) => (e: PipelineEventProcessedStatus) => e.type === type && e.error != null;
export const containsEventType = (type:PipelineEventType) => (e: PipelineEventProcessedStatus) => e.type === type;
export const containsErrorFreeEventType = (type:PipelineEventType) => (e: PipelineEventProcessedStatus) => e.type === type && e.error == null;

export interface ResizedThumbnail {
  file: File;
  base64: string;
  width: number;
  height: number;
}

export interface PipelineItem {
  file?: File;
  pipelineId: string;
  base64?: string;
  folderTagId?: string;
  raw?:{ file: File; width?: number; height?: number; };
  thumbnail?: ResizedThumbnail;
  folderId?: string;
  id?: string;
  order?: number;
  height?: number;
  width?: number;
  eventsProcessed: PipelineEventProcessedStatus[];
  eventsToProcess: PipelineCommandType[];
  replaceThumbs?: boolean;
  replaceItem?: boolean;
}

import { Vue } from 'nuxt-property-decorator';
import throttle from 'lodash.throttle';

export type SwipeEvent = {
  distanceX: number,
  distanceY: number,
  type?: 'move' | 'finish',
  duration?: number,
  direction: 'up' | 'right' | 'down' | 'left';
};

Vue.directive('swipe', {
  bind(el, directive) {
    let touchStartX = 0;
    let touchStartY = 0;
    let startTime = 0;

    function buildSwipeEvent(event): SwipeEvent {
      const touch = event.changedTouches[0];
      const touchEndX = touch.clientX;
      const touchEndY = touch.clientY;
      const deltaX = touchEndX - touchStartX;
      const deltaY = touchEndY - touchStartY;
      const distanceX = Math.abs(deltaX);
      const distanceY = Math.abs(deltaY);
      let direction;

      if (distanceX > distanceY) {
        direction = deltaX > 0 ? 'right' : 'left';
      } else {
        direction = deltaY > 0 ? 'down' : 'up';
      }
      return { distanceX, distanceY, direction };
    }

    directive.touchStart = (event) => {
      const touch = event.touches[0];
      touchStartX = touch.clientX;
      touchStartY = touch.clientY;
      startTime = Date.now();
    };
    directive.touchEnd = (event) => {
      const swipeEvent = buildSwipeEvent(event);
      const duration = Date.now() - startTime;
      directive.value({
        type: 'finish',
        duration,
        ...swipeEvent,
      });
    };
    directive.touchMove = (event) => {
      const swipeEvent = buildSwipeEvent(event);
      directive.value({
        type: 'move',
        ...swipeEvent,
      });
    };
    const throttledTouchMove = throttle(directive.touchMove, 100, { leading: true, trailing: false });
    el.addEventListener('touchstart', directive.touchStart, { passive: true });
    el.addEventListener('touchend', directive.touchEnd, { passive: true });
    el.addEventListener('touchmove', throttledTouchMove, { passive: true });
  },
  unbind(el, directive, _vnode) {
    // @ts-ignore
    el.removeEventListener('touchstart', directive.touchEnd);
    // @ts-ignore
    el.removeEventListener('touchstart', directive.touchStart);
    // @ts-ignore
    el.removeEventListener('touchmove', directive.touchMove);
  },
});

import Item from '~/models/item/Item';
import { Asset, ORIGINAL_ASSET_VERSION } from '~/models/Asset';
import { SUPPORTED_RAW_FILE_EXTENSIONS } from '~/models/FileValidator';

export interface FileMetadata {
  name: string;
  mimeType: string;
  extension: string;
  size: number;
}

export function getFileNameForAsset(asset: Asset) {
  if (asset.version < ORIGINAL_ASSET_VERSION) {
    const nameParts = asset.name.split('.');
    const nameEnding = nameParts.length > 1 ? `.${nameParts.pop()}` : '';
    return `${nameParts.join('.')}_${asset.width}x${asset.height}${nameEnding}`;
  }
  return asset.name;
}

export function getUniqueFileName(name: string, names: string[]) {
  const tempNameArr = name.split('.');
  let fileExtension = '';
  let fileNameWithoutExtension = name;
  if (tempNameArr.length > 1) {
    fileExtension = tempNameArr.pop();
    fileNameWithoutExtension = tempNameArr.join('.');
  }
  let nextInt = 1;
  let newName = name;
  while (names.some(n => n.split(' ').join('').toLowerCase() === newName.split(' ').join('').toLowerCase())) {
    newName = fileExtension !== '' ? `${fileNameWithoutExtension} (${nextInt}).${fileExtension}` : `${fileNameWithoutExtension} (${nextInt})`;
    nextInt++;
  }
  return newName;
}

export function removeFileExtension(str: string): string {
  if (str != null) {
    const dotIndex = str.lastIndexOf('.');
    return dotIndex !== -1 ? str.substring(0, dotIndex) : str;
  }
  return '';
}

export function getFileExtension(str: string): string | null {
  if (str != null) {
    const dotIndex = str.lastIndexOf('.');
    return dotIndex !== -1 ? str.substring(dotIndex + 1)?.toLowerCase() : '';
  } else {
    return '';
  }
}

export function getExtensionFromMimeType(mimeType: string) {
  return mimeType.split('/')[1];
}

export function getFileMetaData(file: File): FileMetadata {
  return {
    name: removeFileExtension(file.name),
    extension: getFileExtension(file.name),
    mimeType: file.type,
    size: file.size,
  };
}

export type isFileMatching = (itemsMap: Map<string, Item>, file: File) => boolean;
export const isRawFileMatching: isFileMatching = (itemsMap, file) => {
  const { name, extension } = getFileMetaData(file);
  return itemsMap.has(name) && SUPPORTED_RAW_FILE_EXTENSIONS.includes(extension);
};

export const isJpegFileMatching: isFileMatching = (itemsMap, file) => {
  const { name, mimeType } = getFileMetaData(file);
  return itemsMap.has(name) && mimeType === 'image/jpeg';
};

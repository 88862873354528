import Vue from 'vue';

Vue.directive('horizontal-scroll', {
  bind(el, directive) {
    const scrollSpeed = 120;
    const scrollSmoothness = 10;
    let oldDelta;
    let oldScrollLeft;
    let oldPosLeft;
    let moving = false;
    let posLeft = el.scrollLeft;
    let posTop = el.scrollTop;
    const scrollStartEvent = new Event('scrollstart');
    let animationFrame;

    // for reference and credits: https://stackoverflow.com/questions/47011055/smooth-vertical-scrolling-on-mouse-wheel-in-vanilla-javascript
    directive.wheelHandler = function horizontalScroll(e) {
      e.stopImmediatePropagation();
      el.dispatchEvent(scrollStartEvent);
      if (!moving) {
        posLeft = el.scrollLeft;
        posTop = el.scrollTop;
      }
      if (el.scrollWidth > el.offsetWidth) {
        const delta = normalizeWheelDelta(e);
        posLeft -= delta * scrollSpeed;
        posLeft = Math.max(0, Math.min(posLeft, el.scrollWidth - el.clientWidth));
        if (!moving) {
          updateScrollPosition();
        }
      } else {
        const delta = normalizeWheelDelta(e);
        posTop += -delta * scrollSpeed;
        posTop = Math.max(0, Math.min(posTop, el.scrollHeight - el.clientHeight));
        if (!moving) {
          updateScrollPositionVertical();
        }
      }
    };

    function normalizeWheelDelta(e) {
      if (e.detail) {
        if (e.wheelDelta) {
          return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1); // Opera
        }
        return -e.detail / 3; // Firefox
      }
      return e.wheelDelta / 120; // IE,Safari,Chrome
    }

    function updateScrollPosition() {
      moving = true;
      let delta = (posLeft - el.scrollLeft) / scrollSmoothness;
      el.scrollLeft += delta;
      el.dispatchEvent(new CustomEvent('scrollPosition', { bubbles: false, detail: el.scrollLeft }));
      if (el.scrollLeft <= 8) {
        el.scrollLeft = 0;
        delta = 0;
      }
      if (Math.abs(delta) > 1 && valuesDidNotChange(delta)) {
        animationFrame = requestAnimationFrame(updateScrollPosition);
      } else {
        resetValues();
        cancelAnimationFrame(animationFrame);
        moving = false;
        el.dispatchEvent(new CustomEvent('scrollfinished', { detail: delta <= 0 ? 'left' : 'right' }));
      }
      oldDelta = delta;
      oldPosLeft = posLeft;
      oldScrollLeft = el.scrollLeft;
    }

    function valuesDidNotChange(delta: number) {
      return !(oldDelta === delta && oldPosLeft === posLeft && oldScrollLeft === el.scrollLeft);
    }

    function resetValues() {
      oldDelta = 0;
      oldPosLeft = 0;
      oldScrollLeft = 0;
    }

    function updateScrollPositionVertical() {
      moving = true;
      let delta = (posTop - el.scrollTop) / scrollSmoothness;
      el.scrollTop += delta;
      if (el.scrollTop <= 8) {
        el.scrollTop = 0;
        delta = 0;
      }
      el.dispatchEvent(new CustomEvent('scrollPosition', { bubbles: false, detail: el.scrollTop }));
      if (Math.abs(delta) > 1 && valuesDidNotChange(delta)) {
        animationFrame = requestAnimationFrame(updateScrollPositionVertical);
      } else {
        el.dispatchEvent(new CustomEvent('scrollfinished', { detail: delta >= 0 ? 'bottom' : 'top' }));
        resetValues();
        cancelAnimationFrame(animationFrame);
        moving = false;
      }
      oldDelta = delta;
      oldPosLeft = posLeft;
      oldScrollLeft = el.scrollLeft;
    }

    el.addEventListener('wheel', directive.wheelHandler, { passive: true });
  },
  unbind(el, directive, vnode) {
    // @ts-ignore
    el.removeEventListener('wheel', directive.wheelHandler);
  },
});

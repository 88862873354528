import { HomepageState } from '~/store/homepage/state';

export default {
  setPlaying: (state: HomepageState, { songId, value }: { songId: string, value: boolean }) => {
    state.songs = state.songs.map(song => ({ ...song, isPlaying: song.id === songId ? value : false }));
  },
  selectSong: (state: HomepageState, songId: string) => {
    state.songs = state.songs.map(song => ({ ...song, isSelected: song.id === songId, isPlaying: song.id === songId }));
  },
  setActiveSlide: (state: HomepageState, slide: number) => {
    state.activeSlide = slide;
  },
  setInitialSlideAnimationEnded: (state: HomepageState, value: boolean) => {
    state.initialSlideAnimationEnded = value;
  },
  setActiveEntry: (state: HomepageState, entry: string) => {
    state.activeEntry = entry;
  },
  toggleActiveEntry: (state: HomepageState, entry: string) => {
    state.activeEntry = entry;
  },
  setShowSwitchButtonInFooter: (state: HomepageState, value: boolean) => {
    state.showSwitchButtonInFooter = value;
  },
};

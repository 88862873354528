import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { ItemsAddedEventData } from '~/models/socket/events/ItemsAddedEvent';
import { ResizeCompletedEventData } from '~/models/ResizeCompletedEventData';
import { SocketEvents } from '~/models/socket/SocketEvents';
import { SelectionUpdatedEventData } from '~/models/socket/events/SelectionUdpatedEventData';
import { aggregateResizeEvent } from '~/models/socket/aggregateResizeEvent';
import { aggregateItemsAddedEvent } from '~/models/socket/aggregateItemsAddedEvent';
import { aggregateSelectionUpdatedEvent } from '~/models/socket/aggregateSelectionUpdatedEvent';
import { Asset } from '~/models/Asset';
import { aggregateAssetUploadedEvent } from '~/models/socket/aggregateAssetUploadedEvent';

export type AggregatedEvents = { itemsAddedEvents: SocketEvent<ItemsAddedEventData>[]; resizeCompletedEvents: SocketEvent<ResizeCompletedEventData>[]; selectionUpdatedEvents: SocketEvent<SelectionUpdatedEventData>[]; assetUploadedEvents: SocketEvent<Asset>[]; };
export type EventToAggregate = SocketEvent<ItemsAddedEventData | ResizeCompletedEventData | SelectionUpdatedEventData | Asset>;

export function aggregateEvents(events: SocketEvent<any>[]): AggregatedEvents {
  return events
    .reduce((agg: AggregatedEvents, curr: EventToAggregate) => {
      if (curr.type === SocketEvents.ITEMS_ADDED) {
        agg = aggregateItemsAddedEvent(agg, curr as SocketEvent<ItemsAddedEventData>);
      } else if (curr.type === SocketEvents.RESIZE_COMPLETED) {
        agg = aggregateResizeEvent(agg, curr as SocketEvent<ResizeCompletedEventData>);
      } else if ([SocketEvents.ASSET_UPLOADED, SocketEvents.ASSET_REPLACED].includes(curr.type)) {
        agg = aggregateAssetUploadedEvent(agg, curr as SocketEvent<Asset>);
      } else if (curr.type === SocketEvents.SELECTION_UPDATED) {
        agg = aggregateSelectionUpdatedEvent(agg, curr as SocketEvent<SelectionUpdatedEventData>);
      }
      return agg;
    }, { itemsAddedEvents: [], resizeCompletedEvents: [], selectionUpdatedEvents: [], assetUploadedEvents: [] });
}

import { ViewType } from '~/models/views/ViewType';

export enum ViewTypeNum {
  MOSAIC = 1,
  GRID = 2,
  HORIZONTAL = 3,
  MOODBOARD = 4,
  CONTACT_SHEET = 5,
  UNKNOWN = 999,
}

export function viewTypeToViewTypeNum(viewType: ViewType) {
  switch (viewType) {
    case ViewType.MOSAIC:
      return ViewTypeNum.MOSAIC;
    case ViewType.HORIZONTAL:
      return ViewTypeNum.HORIZONTAL;
    case ViewType.GRID:
      return ViewTypeNum.GRID;
    case ViewType.MOODBOARD:
      return ViewTypeNum.MOODBOARD;
    case ViewType.CONTACT_SHEET:
      return ViewTypeNum.CONTACT_SHEET;
    default:
      return ViewTypeNum.UNKNOWN;
  }
}
export function viewTypeNumToViewType(viewType: ViewTypeNum) {
  switch (viewType) {
    case ViewTypeNum.MOSAIC:
      return ViewType.MOSAIC;
    case ViewTypeNum.HORIZONTAL:
      return ViewType.HORIZONTAL;
    case ViewTypeNum.GRID:
      return ViewType.GRID;
    case ViewTypeNum.MOODBOARD:
      return ViewType.MOODBOARD;
    case ViewTypeNum.CONTACT_SHEET:
      return ViewType.CONTACT_SHEET;
    default:
      return ViewType.MOSAIC;
  }
}

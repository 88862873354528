import { WebConfig } from '~/Config';

export default {
  playCurrentSong({ commit, getters }: { commit: any, getters: any }) {
    const currentSong = getters.currentSong;
    commit('setPlaying', { songId: currentSong.id, value: true });
  },
  stopCurrentSong({ commit, getters }: { commit: any, getters: any }) {
    const currentSong = getters.currentSong;
    commit('setPlaying', { songId: currentSong.id, value: false });
  },
  toggleCurrentSong({ getters, dispatch }: { getters: any, dispatch: any }) {
    const currentSong = getters.currentSong;
    if (currentSong.isPlaying) {
      dispatch('stopCurrentSong');
    } else {
      dispatch('playCurrentSong');
    }
  },
  playSong({ commit, dispatch, getters }: { commit: any, dispatch: any, getters: any }, songId) {
    commit('selectSong', songId);
    const currentSlide = getters.currentSong.connectedSlideNumber;
    dispatch('setActiveSlide', currentSlide);
  },
  playNextSong({ commit, getters }: { commit: any, getters: any }) {
    const nextSong = getters.nextSong;
    commit('selectSong', nextSong.id);
  },
  setActiveSlide({ commit }: { commit: any }, slide: number) {
    commit('setActiveSlide', slide);
  },
  initialSlideAnimationEnded({ commit }: { commit: any }) {
    commit('setInitialSlideAnimationEnded', true);
  },
  async preloadSlide(_context: any, slideNumber) {
    await this.$axios.get(`${WebConfig.URL}/global-background-${slideNumber}_optimized.webp`);
  },
  async downloadReader() {
    const { data: desktopVersions } = await this.$api.get('/info/applications/macos/versions');
    const latestVersion = desktopVersions.length ? desktopVersions.sort(sortByDesktopVersion)[0] : null;
    if (latestVersion) {
      window.open(`${WebConfig.API_URL}/info/applications/macos/versions/${latestVersion.version}/download`);
    }
  },
};

export interface DesktopVersion {
  gcsId: string;
  version: string;
}

export const sortByDesktopVersion = (versionA: DesktopVersion, versionB: DesktopVersion) => {
  return sortByVersion(versionA.version, versionB.version);
};

export const sortByVersion = (a: string, b: string) => {
  const semVerA = new SemanticVersion(a);
  const semVerB = new SemanticVersion(b);
  return sortBySemanticVersion(semVerA, semVerB);
};

export const sortBySemanticVersion = (a: SemanticVersion, b: SemanticVersion) => {
  return a.isLargerThan(b) ? -1 : 1;
};

export class SemanticVersion {
  values: number[];

  constructor(version: string) {
    this.values = this.parse(version);
    if (this.values.length > 3) {
      throw new Error('Invalid Semantic Version Format, A semantic version should consist of a major, minor and patch version');
    }
  }

  isLargerThan(version: SemanticVersion) {
    for (let i = 0; i < 3; i++) {
      if (this.values[i] > version.values[i]) {
        return true;
      }
      if (this.values[i] < version.values[i]) {
        return false;
      }
    }
    return false;
  }

  public get majorVersion() {
    return this.values[0];
  }

  public get minorVersion() {
    return this.values[1];
  }

  public get patchVersion() {
    return this.values[2];
  }

  private parse(version: string) {
    return version.split('.').map(v => parseInt(v, 10));
  }
}

import { Plugin } from '@nuxt/types';
import { filter } from 'rxjs';
import { Context, Inject } from '@nuxt/types/app';
import { pipelineCommands$ } from '~/models/pipelineCommands$';
import { pipelineEvents$ } from '~/models/pipelineEvents$';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { MetadataExtractor, MetadataExtractorEvent } from '~/models/MetadataExtractor';

const metadataExtractor: Plugin = (_context: Context, inject: Inject) => {
  const metadataExtractor = new MetadataExtractor();
  pipelineCommands$
    .pipe(filter(e => e.type === PipelineCommandType.EXTRACT_METADATA))
    .subscribe(event => metadataExtractor.addItems(event.items));

  metadataExtractor.on(MetadataExtractorEvent.ITEMS_EXTRACTED, (items) =>
    items.forEach(item => pipelineEvents$.next({
      type: PipelineEventType.METADATA_EXTRACTED,
      pipelineId: item.pipelineId,
      data: [item],
    })));

  inject('metadataExtractor', metadataExtractor);
};

export default metadataExtractor;

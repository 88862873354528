import { ViewItem } from '~/models/views/ViewItem';
import Snippet from '~/models/Snippet';

export default class SnippetBuilder {
  private item: ViewItem;

  constructor() {
  }

  fromItem(item: ViewItem): SnippetBuilder {
    this.item = item;
    return this;
  }

  build(): Snippet {
    return new Snippet(
      this.item.viewPosition.width,
      this.item
    );
  }
}

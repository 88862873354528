export enum UserRole {
  GUEST = 'guest',
  USER = 'user',
  ADMIN = 'admin',
  PRO_USER = 'pro_user',
  ALPHA_USER = 'alpha_user',
  BETA_USER = 'beta_user'
}

/**
 * list is ordered by role supremacy
 */
export const orderedRoles = [{
  role: UserRole.ADMIN,
  name: 'Administrator',
}, {
  role: UserRole.PRO_USER,
  name: 'PRO User',
}, {
  role: UserRole.BETA_USER,
  name: 'BETA User',
}, {
  role: UserRole.ALPHA_USER,
  name: 'ALPHA User',
}, {
  role: UserRole.USER,
  name: 'User',
}, {
  role: UserRole.GUEST,
  name: 'Guest',
},
];

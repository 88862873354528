import { AggregatedEvents } from '~/models/socket/aggregateEvents';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { Asset } from '~/models/Asset';

export function aggregateAssetUploadedEvent(agg: AggregatedEvents, curr: SocketEvent<Asset>): AggregatedEvents {
  const currentData = curr.data;
  if (agg.itemsAddedEvents.some(event => event.data.items.some(item => item.id === currentData.itemId))) {
    agg.itemsAddedEvents = agg.itemsAddedEvents.map(event => {
      if (event.data.items.some(item => item.id === currentData.itemId)) {
        event.data.items = event.data.items.map(item => {
          if (item.id === currentData.itemId) {
            item.item.assets = [...item.item.assets.filter(a => a.id !== currentData.id), currentData];
          }
          return item;
        });
      }
      return event;
    });
  } else if (agg.resizeCompletedEvents.some(event => event.data.itemId === currentData.itemId)) {
    agg.resizeCompletedEvents = agg.resizeCompletedEvents.map(event => {
      if (event.data.itemId === currentData.itemId) {
        event.data.assets = [...event.data.assets.filter(a => a.id !== currentData.id), currentData];
      }
      return event;
    });
  } else {
    agg.assetUploadedEvents.push(curr);
  }
  return agg;
}

import Item, { YOUTUBE_DEFAULT_HEIGHT, YOUTUBE_DEFAULT_WIDTH } from '~/models/item/Item';
import { UnitSize } from '~/models/UnitSize';
import { ItemType } from '~/models/item/ItemType';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';

export interface Asset {
  id?: string;
  itemId?: string;
  file?: File;
  base64?: string;
  name: string;
  mimeType: string;
  hash?: string;
  isOffline?: boolean;
  matchedWith?: Partial<Asset>;
  isMatched?: boolean;
  isEstimated?: boolean;
  size: number;
  width: number;
  height: number;
  version: number;
  created?: string;
  modified?: string;
}
export interface AssetWithFolderTag extends Asset {
  folderTagId: string;
}
export const ORIGINAL_ASSET_VERSION = 8;
export const CUSTOM_SUB_VERSION = 7;
export const RAW_ASSET_VERSION = 9;

export function getAssetVersion(item: Item, version: number): Asset {
  return getAssetByVersion(item.assets, version);
}

export function getAssetByVersion(assets: Asset[], version: number): Asset {
  return assets?.find(asset => asset.version === version);
}

export function itemHasAssetVersions(item: ItemWithPosition, versions: number[]): boolean {
  return (item?.item?.assets || []).some((asset: Asset) => versions.includes(asset.version));
}

export function getAssetVersionPriority(item: Item, versions: number[]): Asset {
  return getAssetByVersionPriority(item.assets, versions);
}

export function getAssetByVersionPriority(assets: Asset[], versions: number[]): Asset {
  for (const version of versions) {
    const asset = assets?.find(asset => asset.version === version);
    if (asset != null) {
      return asset;
    }
  }
}

export function getLargestThumbnailAsset(assets: Asset[]): Asset {
  let largestThumbnail = null;
  for (const asset of assets) {
    if ((!largestThumbnail || largestThumbnail.version < asset.version) && asset.version !== ORIGINAL_ASSET_VERSION) {
      largestThumbnail = asset;
    }
  }
  return largestThumbnail;
}

export function getLargestAsset(assets: Asset[]): Asset {
  let largestAsset = null;
  if (assets != null) {
    for (const asset of assets) {
      // We check if asset id is undefined because the asset could only exist locally, ideally we can handle this
      if ((!largestAsset || largestAsset.version < asset.version) && asset.version !== RAW_ASSET_VERSION) {
        largestAsset = asset;
      }
    }
  }
  return largestAsset;
}

export function getAspectRatio(item: Item): number {
  if (item.type === ItemType.YOUTUBE) {
    return YOUTUBE_DEFAULT_WIDTH / YOUTUBE_DEFAULT_HEIGHT;
  }
  if (item.type === ItemType.SOUNDCLOUD) {
    return YOUTUBE_DEFAULT_WIDTH / 166;
  }
  const asset = item.assets.find((a) => a.width != null);
  return asset ? asset.width / asset.height : 1;
}

export function getLargestAssetBelowWidth(item: Item, width: number): Asset {
  const asset = item?.assets
    .filter(a => a.width <= width)
    .sort((a, b) => a.width > b.width ? -1 : 1)
    .find(_ => true);
  return asset || null;
}

export function transformToUnit(sizeInBytes: number): UnitSize {
  if (sizeInBytes >= 1000000000) {
    return {
      size: parseFloat((sizeInBytes / 1000000000).toFixed(2)),
      unit: 'GB',
    };
  }
  if (sizeInBytes >= 1000000) {
    return {
      size: parseFloat((sizeInBytes / 1000000).toFixed(2)),
      unit: 'MB',
    };
  }
  if (sizeInBytes > 0) {
    return {
      size: parseFloat((sizeInBytes / 1000).toFixed(2)),
      unit: 'KB',
    };
  }
  return {
    size: 0,
    unit: 'KB',
  };
}

export function getDownloadSize(assets: Asset[]): UnitSize {
  const sizeInBytes = assets.reduce((accumulatedSize: number, asset: Asset) => {
    return accumulatedSize + asset.size;
  }, 0);
  return transformToUnit(sizeInBytes);
}

export function accumulateAssetSizeInBytes(items: ItemWithPosition[], filterByAssetVersions: number[] = [], includeOfflineItems: boolean = false): number {
  return items.reduce((accumulatedSize: number, currentItem: ItemWithPosition) => {
    return accumulatedSize
        + ((filterByAssetVersions.length
          ? currentItem.item?.assets?.filter(a => (includeOfflineItems ? true : !a.file) && filterByAssetVersions.includes(a.version))
          : currentItem.item?.assets
        ) || []).reduce((acc: number, asset: Asset) => acc + asset.size, 0);
  }, 0);
}

export function accumulateBiggestAssetsSize(items: ItemWithPosition[]): number {
  return items.reduce((accumulatedSize: number, currentItem: ItemWithPosition) => {
    return accumulatedSize + (getLargestAsset(currentItem?.item?.assets)?.size ?? 0);
  }, 0);
}

export function accumulateAssetSize(items: ItemWithPosition[], filterByAssetVersions: number[] = [], includeOfflineItems = false): UnitSize {
  const sizeInBytes = accumulateAssetSizeInBytes(items, filterByAssetVersions, includeOfflineItems);
  return transformToUnit(sizeInBytes);
}

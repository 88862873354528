import _cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { ActionContext } from 'vuex';
import { filter, takeUntil } from 'rxjs';
import { User } from '~/models/user/User';
import MagnifyView from '~/components/window/magnify/MagnifyView.vue';
import { AddExternalContentEvent } from '~/models/AddExternalContentEvent';
import { ChangeSet } from '~/models/ChangeSet';
import { Asset } from '~/models/Asset';
import { ActiveSelectionBroadcastEventData } from '~/models/socket/events/ActiveSelectionBroadcastEventData';
import { ItemsRemovedEventData } from '~/models/socket/events/ItemsRemovedEvent';
import { SelectionRemovedEventData } from '~/models/socket/events/SelectionRemovedEventData';
import { getUniqueName } from '~/models/Folder';
import { FolderItem } from '~/models/item/FolderItem';
import Item from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { PlaceItemsEvent } from '~/models/PlaceItemsEvent';
import { ObjectId } from '~/models/ObjectId';
import Position from '~/models/Position';
import Selection from '~/models/selection/Selection';
import { SelectionItem } from '~/models/selection/SelectionItem';
import { SortItemsEvent, SortItemsEventExternal } from '~/models/item/SortItemsEvent';
import { TransferOption } from '~/models/TransferOption';
import { TransferType } from '~/models/TransferType';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { ViewType } from '~/models/views/ViewType';
import { RootState } from '~/store/state';
import { ViewSortingOption } from '~/store/cloud/state';
import { SelectionState } from '~/store/selection/state';
import { Guest } from '~/models/user/Guest';
import { SelectionUpdatedEventData } from '~/models/socket/events/SelectionUdpatedEventData';
import { sortItemsByFillWithChangeSet, SortResult } from '~/models/item/sortItemsByFill';
import { SortItemsEventType } from '~/models/item/SortItemsEventType';
import { createBatchesForCollection } from '~/models/utility/createBatchesForCollection';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { InternalEventType } from '~/models/InternalEventType';
import { FileValidator, ValidationParams } from '~/models/FileValidator';
import { dispatchNotification } from '~/store/file/actions';
import { fixOrdering } from '~/models/utility/fixOrdering';
import { pipelineEvents$ } from '~/models/pipelineEvents$';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { FolderStructure } from '~/models/file/FolderStructure';
import { TaggedFile } from '~/models/tags/TaggedFile';

export interface SortGlobalSelectionEvent {
  event: SortItemsEvent;
  originalSortOrder: ViewSortingOption;
}

export interface SortSelectionEvent {
  windowId: string;
  selectionId: string,
  event: SortItemsEvent;
  originalSortOrder: ViewSortingOption;
}

type SelectionContext = ActionContext<SelectionState, RootState>;

interface CreateSelection {
  id: string;
  name: string;
  items: ItemWithPosition[];
  windowId: string;
  isReferencedToSharedLink: boolean;
  viewType: ViewType;
  sync: boolean;
}

export default {
  // Open / Close / Toggle SidePane Window
  open({
    rootState,
    commit,
  }: SelectionContext) {
    if (!rootState.cloudSidePaneOpen) {
      commit('toggleSidePane', {}, { root: true });
    }
  },
  close({ rootState, commit }: SelectionContext) {
    if (rootState.cloudSidePaneOpen) {
      commit('toggleSidePane', {}, { root: true });
    }
  },
  dismissGlobalSelection({ commit, getters }: SelectionContext) {
    commit('dismissGlobalSelection');
    const payload: ActiveSelectionBroadcastEventData = {
      destination: null,
      items: getters.globalSelectionItems,
    };
    this.$socket.emit('BROADCAST_ACTIVE_SELECTION', payload);
  },
  dismissItemsFromGlobalSelection({ commit }: SelectionContext, items: Item[]) {
    commit('dismissItemsFromGlobalSelection', items);
  },

  async synchronizeSelectionChanges({ state, commit }: SelectionContext) {
    if (state.selectionChangeSet) {
      const selectionChanges = [];
      if (state.selectionChangeSet?.inserts?.length) {
        for (const selectionToInsert of state.selectionChangeSet.inserts) {
          selectionChanges.push(this.$api.put(`/selections/${selectionToInsert.id}`, selectionToInsert));
        }
      }
      if (state.selectionChangeSet?.updates?.length) {
        for (const selectionToUpdate of state.selectionChangeSet.updates) {
          selectionChanges.push(this.$api.post(`/selections/${selectionToUpdate.id}/add-changes`, { ...selectionToUpdate }));
        }
      }
      if (state.selectionChangeSet?.removals?.length) {
        for (const selectionIdToDelete of state.selectionChangeSet.removals) {
          selectionChanges.push(this.$api.delete(`/selections/${selectionIdToDelete}`));
        }
      }
      await Promise.all(selectionChanges);
      commit('setSelectionChangeSet', null);
    }
  },
  async synchronizeRatedItemsForSharedSelection({ state }: SelectionContext, selectionId: string) {
    const itemChangeSet = state.selectionItemChangeSet[selectionId];
    if (itemChangeSet?.updates?.length) {
      const filteredItems = itemChangeSet.updates.filter(u => u.rating != null).map(u => ({
        id: u.id,
        rating: u.rating,
      }));
      if (filteredItems?.length) {
        await this.$api.post(`/selections/${selectionId}/update-items`, {
          items: filteredItems,
        });
      }
    }
  },
  async synchronizeSelectionItemChanges({ state, commit, rootState }: SelectionContext) {
    for (const [selectionId, itemChangeSet] of Object.entries(state.selectionItemChangeSet)) {
      const maxSyncBatchSize = 250;
      if (itemChangeSet) {
        const promises = [];
        let leftOverChangeSet = null;
        if (itemChangeSet?.removals?.length) {
          for (const removals of createBatchesForCollection(itemChangeSet.removals, maxSyncBatchSize)) {
            promises.push(this.$api.post(`/selections/${selectionId}/remove-items`, {
              items: removals,
            }));
          }
        }
        if (itemChangeSet?.updates?.length) {
          for (const updates of createBatchesForCollection(itemChangeSet.updates, maxSyncBatchSize)) {
            const data = { items: updates };
            promises.push(this.$api.post(`/selections/${selectionId}/update-items`, data));
          }
        }
        if (itemChangeSet?.inserts?.length) {
          const [syncedItems, unSyncedItems] = filterByConditions(itemChangeSet.inserts.map(i => {
            const obj = _cloneDeep(i);
            delete obj.item;
            return obj;
          }), (i) => rootState.folder.items.get(i.itemId)?.isSynced);
          if (unSyncedItems.length) {
            itemChangeSet.inserts = unSyncedItems;
            leftOverChangeSet = itemChangeSet;
          }
          for (const inserts of createBatchesForCollection(syncedItems, maxSyncBatchSize)) {
            promises.push(this.$api.post(`/selections/${selectionId}/add-items`, {
              items: inserts,
            }));
          }
        }
        commit('setSelectionItemChangeSet', { selectionId, changeSet: leftOverChangeSet });
        await Promise.all(promises);
      }
    }
  },
  // TODO: handle error cases, what if user is still offline or goes offline while syncing
  async synchronizeSelections({ dispatch }: SelectionContext) {
    await dispatch('synchronizeSelectionChanges');
    await dispatch('synchronizeSelectionItemChanges');
  },

  async refineItemsOfSelection({
    getters,
    dispatch,
    state,
  }: SelectionContext, data: { selectionId?: string, actor?: User | Guest }) {
    const selectionItems = getters.selectionItemsById(data.selectionId);
    const itemsToDelete = selectionItems.filter(i => !state.globalSelectedItems[i.id]);
    await dispatch('removeItemsFromSelection', { items: itemsToDelete, selectionId: data.selectionId });
  },
  async removeItemsFromSelection({
    commit,
    rootGetters,
    dispatch,
  }: SelectionContext, data: { items: SelectionItem[], selectionId?: string, actor?: User | Guest }) {
    data = {
      ...data,
      actor: data.actor || rootGetters['user/currentUser'],
    };
    if (data.selectionId) {
      this.$pipelineManager.getPipeline(data.selectionId)?.removeStalePipelineItems(data.items.map(i => i.itemId));
      commit('removeItemsFromSelection', data);
      if (rootGetters['user/isUser']) {
        await dispatch('synchronizeSelections');
      }
    } else {
      commit('dismissItemsFromGlobalSelection', data.items);
    }
  },
  async addItemsToSelection({
    commit,
    rootGetters,
    getters,
  }: SelectionContext, data: { items: ItemWithPosition[], selectionId?: string, actor?: User | Guest }) {
    data = {
      ...data,
      actor: data.actor || rootGetters['user/currentUser'],
    };
    if (data.selectionId) {
      commit('addItemsToSelection', data);
      await this.$api.post(`/selections/${data.selectionId}/add-items`, {
        items: data.items.map(i => ({
          itemId: i.item.id,
          position: i.position,
        })),
      });
    } else {
      const numberOfSelectedItems = getters.globalSelectionItems.length;
      const inserts = data.items.map((item, idx) => ({ ...item, position: { ...item.position, order: numberOfSelectedItems + idx } }));
      commit('applyGlobalSelectionChanges', { changeSet: { inserts }, actor: data.actor });
    }
  },
  async updateSelectionName({ rootGetters, commit, dispatch }: SelectionContext, {
    name,
    selectionId,
  }: { name: string, selectionId: string }) {
    commit('setSelectionName', { name, selectionId });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },
  async updateSubtitle({ rootGetters, commit, dispatch }: SelectionContext, {
    subtitle,
    selectionId,
  }: { subtitle: string, selectionId: string }) {
    commit('setSelectionSubtitle', { subtitle, selectionId });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },
  async updateSelectionViewType({ rootGetters, commit, dispatch }: SelectionContext, {
    viewType,
    selectionId,
  }: { viewType: ViewType, selectionId: string }) {
    commit('setSelectionViewType', { viewType, selectionId, buildChangeSet: true });
    dispatch('cloud/changeView', { windowId: ViewIdentifier.MAIN_VIEW, view: viewType }, { root: true });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },
  async updateDescription({ rootGetters, commit, dispatch }: SelectionContext, {
    description,
    selectionId,
  }: { description: string, selectionId: string }) {
    commit('setSelectionDescription', { description, selectionId });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },
  // Handles external files being dropped into a selection
  async uploadFilesTo({
    dispatch,
    commit,
    getters,
    rootGetters,
    rootState,
  }: SelectionContext, {
    selectionId,
    event,
    isUploadAllowed,
    pipelineCommands,
  }: { selectionId: string; event: AddExternalContentEvent; isUploadAllowed: boolean, pipelineCommands: PipelineCommandType[] }) {
    const dragEvent = event.event;
    const selectionItems = getters.selectionItemsById(selectionId);
    // @ts-ignore
    const folderId = rootGetters['folder/scrapbookId'];
    const folderStructure = await this.$fileParser.parseFileFolderStructure(folderId, dragEvent);
    const validatedFiles = await dispatch('validateFiles', { selectionId, folderStructure });
    if (folderId) {
      const itemIds = [...validatedFiles].map(_ => uuid());
      const positionMap = getters.positionMapForSelectionItems(selectionId, itemIds, event.position);
      const sortItems = itemIds
        .map((itemId, idx) => ({
          id: uuid(),
          itemId,
          position: {
            order: idx,
          },
        } as ItemWithPosition));
      const sortEvent: SortItemsEvent = new SortItemsEventExternal(
        event.position.order,
        sortItems,
        SortItemsEventType.FILL, {
          start: 0,
          end: selectionItems?.length,
        });
      const sortAddedItemIntoSelectionResult: SortResult
        = sortItemsByFillWithChangeSet(selectionItems, sortEvent);
      const mapWithSelectionItemsToAdd = new Map<string, any>();
      sortAddedItemIntoSelectionResult.inserted.forEach(i => mapWithSelectionItemsToAdd.set(i.itemId, {
        id: i.id,
        itemId: i.itemId,
        position: {
          ...positionMap.get(i.itemId),
          ...i.position,
        },
      }));
      let updates = sortAddedItemIntoSelectionResult.updated.map(i => ({
        id: i.id,
        position: {
          order: i.position.order,
        },
      }));
      const pipelineId = selectionId;
      const isEventWithRelevantItemData = e => e.type === InternalEventType.ITEM_DIMENSIONS_EXTRACTED;
      const isPipelineDone = pipelineEvents$.pipe(
        filter(e => e.type === PipelineEventType.PIPELINE_DONE)
      );
      // We are waiting for files being successfully processed as preview items and placed into a folder
      this.$internalEvents.pipe(
        takeUntil(isPipelineDone),
        filter(isEventWithRelevantItemData)
      ).subscribe(event => {
        const itemIds = event.data.filter(itemId => mapWithSelectionItemsToAdd.has(itemId));
        const itemChangeSet: ChangeSet<SelectionItem> = {
          inserts: itemIds.map(itemId => ({
            ...mapWithSelectionItemsToAdd.get(itemId),
            item: rootState.folder.items.get(itemId),
          })),
        };
        if (updates.length) {
          itemChangeSet.updates = updates;
          updates = [];
        }
        commit('applySelectionChanges', {
          selectionId,
          itemChangeSet,
        });
      });
      dispatch('file/uploadFilesTo', {
        folderId,
        files: validatedFiles,
        folderTags: folderStructure.uniqueFolderTags,
        idList: itemIds,
        pipelineId,
        isUploadAllowed,
        pipelineCommands,
      }, { root: true });
    }
  },
  validateFiles({ dispatch, rootGetters }: SelectionContext, { selectionId, folderStructure }: { selectionId: string, folderStructure: FolderStructure}): TaggedFile[] {
    const params: ValidationParams = {
      files: folderStructure.files,
      options: {
        dataLimitation: rootGetters['user/isUserWithDataLimitation'],
        sizeTaken: rootGetters['selection/selectionSizeInBytes'](selectionId),
        existingItems: rootGetters['selection/selectionItemCount'](selectionId),
      },
    };
    const { validatedFiles, messages } = FileValidator.validate(params);
    if (messages?.size > 0) {
      dispatchNotification(dispatch, messages);
    }
    return validatedFiles;
  },
  // Triggered when items or files are dropped onto a selection entry, shortcut, etc. (e.g. in the cloud menu)
  async handleDropOnSelectionEntry({ dispatch, commit, getters, rootGetters, rootState }: SelectionContext, {
    selectionId,
    event,
  }: { selectionId: string, event: DragEvent }) {
    const dragViewId = rootState.dragInfo.viewId;
    const selection = getters.selectionWithItemsById(selectionId);
    // @ts-ignore
    const files: File[] = event.dataTransfer ? event.dataTransfer.files : event.target && event.target.files;
    if (files && files.length) {
      const folderId = getters.selectionFolderReference(selectionId);
      dispatch('file/uploadFilesTo', { folderId, files }, { root: true });
      const uploadedItems: FolderItem[] = rootState.file.uploadProgress.uploadedItems;
      const changeSet: ChangeSet<Partial<SelectionItem>> = {
        inserts: uploadedItems
          .map((i, idx) => ({
            id: uuid(),
            selectionId,
            itemId: i.id,
            position: {
              order: selection.items.length + idx,
            },
            created: moment().toISOString(),
            modified: moment().toISOString(),
          })),
      };
      commit('applySelectionChanges', {
        selectionId,
        itemChangeSet: changeSet,
      });
    } else {
      const url = event.dataTransfer && event.dataTransfer.getData('URL');
      const transferType = event.dataTransfer?.getData(TransferOption.MUTANT_TRANSFER_TYPE) || rootState.dragInfo?.transferType;
      const dragItems: ItemWithPosition[] = rootState.dragInfo?.items || [];
      if (url) {
        const folderId = getters.selectionFolderReference(selectionId);
        if (folderId) {
          dispatch('file/uploadFilesTo', { folderId, files }, { root: true });
        } else {
          alert('Could not identify a matching folderId for the chosen selection. (Dropping links on empty selections is currently not supported)');
        }
        // TODO: implement url extraction for sending links in conversations
        dispatch('folder/extractUrlTo', {
          url,
          folderId,
        }, { root: true });
      } else if (transferType === TransferType.SELECTION) {
        const items: Item[] = rootGetters['selection/globalSelectionItems'];
        const updateItemsRequestData = {
          items: selection.items
            .map(i => ({
              id: i.id,
              position: {
                order: i.position.order,
              },
            })),
        };
        const addItemsRequestData = {
          items: items
            .map(i => i)
            .filter(item => !selection.items.some(i => i.id === item.id))
            .map((item, idx) => ({
              id: item.id,
              position: {
                order: selection.items.length + idx,
              },
            })),
        };
        const requestPromises = [];
        requestPromises.push(this.$api.post(`/selections/${selectionId}/add-items`, addItemsRequestData));
        requestPromises.push(this.$api.post(`/selections/${selectionId}/update-items`, updateItemsRequestData));
        const results = await Promise.all(requestPromises);
        for (const result of results) {
          commit('applySelectionChanges', {
            selectionId,
            itemChangeSet: result.data,
          });
        }
      }
      // TODO: Adjust for changeSets updates
      if (transferType === TransferType.VIEW_SELECTION || transferType === TransferType.ITEM) {
        const requestData: any = {
          name: selection.name,
          items: [
            ...selection.items
              .map(i => ({
                id: i.id,
                ...i.position,
              })),
            ...dragItems
              .map(i => i.id)
              .filter(itemId => !selection.items.some(item => item.id === itemId))
              .map((itemId, idx) => ({
                id: itemId,
                order: selection.items.length + idx,
              })),
          ],
        };
        const { data } = await this.$api.put(`/selections/${selectionId}`, requestData);
        commit('saveSelectionSuccess', data);
        if (dragViewId !== (<any> MagnifyView).VIEW_ID) {
          setTimeout(() => dispatch('dismissItemsFromGlobalSelection', dragItems), 500);
        }
      }
    }
  },

  async chooseSelection({
    commit,
    dispatch,
    rootGetters,
  }: SelectionContext, selection: Selection) {
    // load selection details
    const { data } = await this.$api.get(`/selections/${selection.id}?includes=items`);
    data.saved = true;
    commit('chooseSelection', data);
    commit('openSidePane', {}, { root: true });
    await this.$router.push({
      ...this.$router.currentRoute,
      query: { ...this.$router.currentRoute.query, selection: selection.id },
    });
    dispatch('addToRecentObjectIds', ObjectId.fromSelectionId(selection.id), { root: true });
    commit('initializeSelectedColors', rootGetters['user/currentUser']);
  },
  // Triggered by backspace in folder pane, deletes all items of the current global selection that are part of the given folder
  async deleteItemsFromFolder({
    getters,
    commit,
    dispatch,
    rootGetters,
    rootState,
  }: SelectionContext, folderId) {
    if (!rootGetters.pipelineUploadActive() || rootState.file.uploadProcess?.objectId?.toUuid() !== folderId) {
      const items: ItemWithPosition[] = getters.globalSelectionItems.filter(i => i.folderId === folderId);
      const itemIds = items.map(i => i.itemId || i.item.id);
      commit('flagItemsForDelete', itemIds);
      commit('removeFolderItemsFromSelections', itemIds);
      await dispatch('folder/removeItems', {
        folderId,
        items,
      }, { root: true });
    } else {
      await dispatch('displayNotificationForDeletionNotPossible', null, { root: true });
    }
  },
  async removeSelection({ commit, rootGetters, dispatch }: SelectionContext, selection: Selection) {
    commit('removeSelection', { id: selection.id, buildChangeSet: true });
    if (rootGetters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewIds.some((viewId: ObjectId) => viewId.toUuid() === selection.id)) {
      const filteredViewIds = rootGetters['cloud/window'](ViewIdentifier.MAIN_VIEW).viewIds.filter((viewId: ObjectId) => viewId.toUuid() !== selection.id);
      await dispatch('cloud/setViewIds', { windowId: ViewIdentifier.MAIN_VIEW, objectIds: filteredViewIds }, { root: true });
      if (filteredViewIds.length) {
        await this.$router.push({
          ...this.$router.currentRoute,
          params: { id: filteredViewIds.map(viewId => viewId.toString()).join(',') },
        });
      } else {
        await this.$router.replace({ path: '/cloud' });
      }
    }
    const selectionObjectId = ObjectId.fromSelectionId(selection.id);
    await dispatch('removeFromCustomObjectIds', selectionObjectId, { root: true });
    await dispatch('removeFromRecentObjectIds', selectionObjectId, { root: true });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },

  async loadSelection({
    commit,
    state,
    rootState,
    getters,
    rootGetters,
    dispatch,
  }: SelectionContext, selectionId: string) {
    if (selectionId && !state.selectionChangeSet?.inserts?.some(i => i?.id === selectionId)) {
      if (Object.keys(rootState.link.links).length === 0) {
        await dispatch('link/loadLinkAuth', null, { root: true });
      }
      const { data } = await this.$api.get(`/selections/${selectionId}?includes=items`);
      const selection = <Selection> data;
      const existingSelectionFromState = getters.selectionWithItemsById(selectionId);
      if (!existingSelectionFromState?.items?.length || moment(selection.modified).isAfter(existingSelectionFromState.modified)) {
        const { orderedItems, adjustedItems } = fixOrdering(selection.items);
        const items = orderedItems.map(i => i.item);
        selection.items = orderedItems;
        commit('folder/addItems', items, { root: true });
        commit('loadSelectionSuccess', selection);

        if (selection.isShared) {
          await Promise.all([
            dispatch('loadSharedLinks', selection.id),
          ]);
        }
        if (adjustedItems?.length !== 0) {
          console.error('Sorting error encountered for selection: ', selection.id);
          const itemChangeSet: ChangeSet<SelectionItem> = {
            updates: adjustedItems.map(i => ({
              id: i.id,
              position: {
                order: i.position.order,
              },
            })),
          };
          commit('applySelectionChanges', { selectionId, itemChangeSet });
          if (rootGetters['user/isUser']) {
            await dispatch('synchronizeSelections');
          }
        }
        commit('setLastKnownModified', moment(selection.modified).unix(), { root: true });
        dispatch('joinCloudObjects', { objectIds: [ObjectId.fromSelectionId(selectionId)] }, { root: true });
      }
    }
  },
  async refreshStaleSelection({ commit, getters }: any, selectionId: string) {
    const { data } = await this.$api.get(`/selections/${selectionId}?includes=items`);
    const selection = <Selection> data;
    const existingSelection = getters.selectionWithItemsById(selectionId);
    const lastModified = selection.modified;
    const recentlyChangedItems = existingSelection?.items.filter(i => moment(i.modified).isAfter(lastModified));
    // We optimistically replace all selection data with the data from the api call,
    // with exception of the items since items are the only ones that may realistically change
    // between the time waiting on and receiving of the update detection response/event
    const selectionMergedWithExistingState = existingSelection
      ? {
          ...existingSelection,
          ...selection,
          modified: recentlyChangedItems.length ? moment().toISOString() : selection.modified,
          items: [...recentlyChangedItems, ...selection.items.filter(item => !recentlyChangedItems.some(i => i.id === item.id))],
        }
      : selection;
    selection.itemCount = selectionMergedWithExistingState.items.length;
    const items: Item[] = selectionMergedWithExistingState.items.map(i => i.item);
    commit('folder/addItems', items, { root: true });
    commit('loadSelectionSuccess', selectionMergedWithExistingState);
    commit('setLastKnownModified', moment(selectionMergedWithExistingState.modified).unix(), { root: true });
  },
  async loadSelections({ commit, rootGetters }: SelectionContext) {
    // TODO: move to role based middleware
    if (!rootGetters['user/isGuest']) {
      const { data } = await this.$api.get('/selections');
      commit('loadSelectionsSuccess', data);
      commit('initializeSelectedColors', rootGetters['user/currentUser']);
    }
  },
  async loadItemPreview({ commit }: SelectionContext, selectionId: string) {
    const { data }: { data: SelectionItem[] } = await this.$api.get(`/selections/${selectionId}/items?limit=3`);
    if (data?.length) {
      commit('folder/addItems', data.map(selectionItem => selectionItem.item), { root: true });
      commit('addPreviewItems', { items: data, selectionId });
    }
  },

  selectAllItems({ commit, state, rootGetters }: SelectionContext) {
    const currentSelectedItemIds = Object.keys(state.globalSelectedItems);
    const currentUnselectedViewItems = _cloneDeep(rootGetters['cloud/currentViewItems'](ViewIdentifier.MAIN_VIEW))
      .filter(i => !currentSelectedItemIds.includes(i.id));
    const actor = rootGetters['user/currentUser'];
    commit('addItemsToGlobalSelection', {
      items: currentUnselectedViewItems,
      actor,
    });
  },

  async createSharedLink({
    getters,
    dispatch,
  }: SelectionContext, folderId: string) {
    const selectionItems = folderId ? getters.globalSelectionItemsByFolderId(folderId) : getters.globalSelectionItems;
    const selectionId = uuid();
    await dispatch('create', { id: selectionId, name: 'shared selection', items: selectionItems });
    await dispatch('link/createSharedLinkForSelection', selectionId, { root: true });
  },
  async loadSharedLinks({ commit, dispatch, getters }: SelectionContext, selectionId) {
    if (getters.isSelectionOwner(selectionId)) {
      const { data } = await this.$api.get(`/selections/${selectionId}/links`);
      if (data.length > 0) {
        commit('setSelectionShared', selectionId);
        dispatch('link/setLinkData', data[0], { root: true });
      }
    }
  },
  async create({ state, rootGetters, commit, dispatch, rootState }: SelectionContext, {
    id = uuid(),
    name = 'new selection',
    items = [],
    isReferencedToSharedLink = false,
    windowId,
    viewType,
    sync = true,
  }: CreateSelection) {
    const selection: Selection = {
      id,
      owner: { id: rootState?.user?.user?.id, username: rootState?.user?.user?.username },
      viewType: viewType ?? ViewType.HORIZONTAL,
      name: getUniqueName(name, Object.values(state.selections).map(s => s.name)),
      isReferencedToSharedLink,
      items: items.map((i, idx) => ({
        id: uuid(),
        item: rootState.folder.items.get(i.itemId || i.item.id),
        itemId: i.itemId || i.item?.id,
        position: {
          ...i.position,
          order: idx,
        },
      } as SelectionItem)),
    };
    commit('addSelection', { selection });
    if (rootGetters['user/isUser'] && sync) {
      await dispatch('synchronizeSelections');
    }
    if (windowId) {
      await dispatch('cloud/addToPane', { windowId, objectIds: [ObjectId.fromSelectionId(selection.id)], viewType }, { root: true });
    }
    dispatch('cloud/resetAllViewFilters', undefined, { root: true });
  },

  sortGlobalSelectionItems({
    commit,
    getters,
    rootState,
    rootGetters,
  }: SelectionContext, event: SortGlobalSelectionEvent) {
    const globalSelectionItems = getters.globalSelectionItems;
    const sortingResult: SortResult = sortItemsByFillWithChangeSet(
      globalSelectionItems,
      event.event
    );
    const changeSet: ChangeSet<SelectionItem> = {
      inserts: sortingResult.inserted.map(i => ({
        id: i.id,
        item: rootState.folder.items.get(i.itemId || i.item.id),
        itemId: i.itemId || i.item.id,
        position: {
          order: i.position.order,
        },
      } as SelectionItem)),
      updates: sortingResult.updated.map(i => ({
        id: i.id,
        position: {
          order: i.position.order,
        },
      })),
    };
    commit('applyGlobalSelectionChanges', { changeSet, actor: rootGetters['user/currentUser'] });
  },
  async rateItemsByItemId({ commit, dispatch, rootGetters, getters }: SelectionContext, { itemId, selectionId, rating }: { itemId: string, selectionId: string, rating: number }) {
    const itemChangeSet = {
      updates: [{
        id: itemId,
        rating,
      }],
    };
    commit('applySelectionChanges', { selectionId, itemChangeSet });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    } else if (getters.linkDataExists(selectionId)) {
      await dispatch('synchronizeRatedItemsForSharedSelection', selectionId);
    }
  },
  async rateItems({ commit, dispatch, rootGetters, getters, rootState }: SelectionContext, { selectionId, rating }: { selectionId: string, rating: number }) {
    const globalSelectionItems = rootGetters['selection/globalSelectionItems'];
    const centeredItem = rootState.cloud.highlightInfo?.item;
    let itemChangeSet: ChangeSet<Partial<FolderItem>>;
    if (globalSelectionItems.length) {
      itemChangeSet = {
        updates: globalSelectionItems.map(selectedItem => ({
          id: selectedItem.id,
          rating,
        })),
      };
    } else if (centeredItem) {
      itemChangeSet = {
        updates: [{
          id: centeredItem.id,
          rating,
        }],
      };
    }
    if (itemChangeSet) {
      commit('applySelectionChanges', { selectionId, itemChangeSet });
      if (rootGetters['user/isUser']) {
        await dispatch('synchronizeSelections');
      } else if (getters.linkDataExists(selectionId)) {
        await dispatch('synchronizeRatedItemsForSharedSelection', selectionId);
      }
    }
  },
  async sortItems({ commit, dispatch, rootGetters, getters, rootState }: SelectionContext, event: SortSelectionEvent) {
    const { selectionId } = event;
    const window = rootState.cloud.registeredWindows[event.windowId];
    const selectionItems = getters.selectionItemsByIdSorted(selectionId, window.sortedBy);
    const sortingResult: SortResult = sortItemsByFillWithChangeSet(
      selectionItems,
      event.event
    );
    const itemChangeSet: ChangeSet<Partial<SelectionItem>> = {
      inserts: sortingResult.inserted.map(i => ({
        id: i.id, // a new id was already added by the View (not optimal though), so we can safely use it here (no uuid conflict in the database)
        itemId: i.itemId || i.item.id,
        item: rootState.folder.items.get(i.itemId || i.item.id),
        position: {
          order: i.position.order,
        },
      })),
      updates: sortingResult.updated.map(i => ({
        id: i.id,
        rating: i.rating,
        position: {
          order: i.position.order,
        },
      })),
    };
    commit('applySelectionChanges', { selectionId, itemChangeSet });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },
  async updateItemPositions({
    commit,
    rootGetters,
    getters,
    dispatch,
  }: SelectionContext, data: { selectionId?: string, items: SelectionItem[] }) {
    const itemWithHighestZindex = getters.itemWithHighestZindex(data.selectionId);
    const orderedItemsByZindex = data.items
      .sort((a, b) => a.position?.zindex || b.position?.zindex < 0 || 0 ? -1 : 1);
    let zindex = (itemWithHighestZindex?.position?.zindex || 0) + 1;
    if (Number.isSafeInteger(zindex + orderedItemsByZindex.length)) {
      for (const item of orderedItemsByZindex) {
        if (itemWithHighestZindex?.id !== item.id && (item.position?.zindex || 0) < zindex) {
          item.position.zindex = zindex;
          zindex++;
        }
      }
    }
    const itemChangeSet = {
      updates: data.items.map(i => ({ id: i.id, position: i.position })),
    };
    commit('applySelectionChanges', { selectionId: data.selectionId, itemChangeSet });
    await dispatch('addToRecentObjectIds', ObjectId.fromSelectionId(data.selectionId), { root: true });
    if (rootGetters['user/isUser']) {
      await dispatch('synchronizeSelections');
    }
  },
  async moveItemsToPosition({ getters, dispatch, rootGetters }: SelectionContext, { event, selectionId }: { event: PlaceItemsEvent, selectionId: string }) {
    const items = event.items.sort((a, b) => a.position.zindex > b.position.zindex ? 1 : -1);
    const [itemsInSelection, itemsNotInSelection] = filterByConditions(items,
      i => getters.selectionContainsItem(selectionId, i.id));
    const selectionItems = getters.selectionItemsById(selectionId);
    const selectionItemsSortedByZindex = selectionItems.map(i => i).sort((a, b) => (a.position.zindex || 0) > (b.position.zindex || 0) ? -1 : 1);
    let zindex = selectionItemsSortedByZindex.length && selectionItemsSortedByZindex[0].position?.zindex ? selectionItemsSortedByZindex[0].position.zindex : 0;
    if (itemsInSelection.length) {
      await dispatch('selection/updateItemPositions', {
        selectionId,
        items: itemsInSelection
          .map(i => {
            const width = event.width || i.position.width;
            const newPosition = event.positionMap.get(i.id);
            const x = newPosition.x;
            const y = newPosition.y;
            zindex++;
            return {
              ...i,
              position: {
                ...i.position,
                x,
                y,
                width,
                rotation: i.position?.rotation || 0,
                zindex,
              },
            };
          }),
      }, { root: true });
    }
    if (itemsNotInSelection.length) {
      const startOrder = rootGetters['selection/selectionWithItemsById'](selectionId)?.items.length;
      const addItems = itemsNotInSelection.map((item, idx) => {
        const width = event.width || item.position.width;
        const newPosition = event.positionMap.get(item.id);
        const order = startOrder + idx;
        const x = newPosition.x;
        const y = newPosition.y;
        zindex++;
        return {
          ...item,
          id: uuid(),
          position: {
            ...item.position,
            order,
            x,
            y,
            width,
            rotation: 0,
            zindex,
          },
        };
      });
      await dispatch('selection/addItemsToSelection', {
        selectionId,
        items: addItems,
      }, { root: true });
    }
  },

  addToPane({ commit, dispatch }: SelectionContext, data: { windowId: string; objectIds: ObjectId[]}) {
    commit('cloud/setViewIds', data, { root: true });
    for (const objectId of data.objectIds) {
      dispatch('addToRecentObjectIds', objectId, { root: true });
    }
  },
  // TODO: Fix logic how we set background images for moodboards and adjust moodboard items to different aspect ratios
  async updateMoodboardBackground({ commit, rootState }: SelectionContext, {
    windowId,
    selectionId,
    itemId,
    backgroundId,
  }: { windowId: string; selectionId: string, itemId: string, backgroundId: string }) {
    // const currentSelection = getters.selectionById(selectionId);
    // const moodboardBackgroundBefore = currentSelection.backgroundAssets ? getLargestThumbnailAsset(currentSelection.backgroundAssets) : null;
    const window = rootState.cloud.registeredWindows[windowId];
    if (!selectionId) {
      selectionId = window.viewIds.find(id => id.isSelectionId)?.toUuid();
    }
    const requestData: any = {};
    if (itemId) {
      requestData.itemId = itemId;
    }
    if (backgroundId) {
      requestData.backgroundId = backgroundId;
    }
    const { data: backgroundAssets } = await this.$api.put(`/selections/${selectionId}/background`, requestData);
    // TODO: Make adjustment of background items optional, we don't use this for now
    // const moodboardBackgroundAfter = getLargestThumbnailAsset(backgroundAssets);
    // if (moodboardBackgroundBefore) {
    //   const viewSize = rootGetters['cloud/viewContentDimensions'];
    //   // TODO: later we need to adjust the width here as well if we want to support different Background fits for moodboard backgrounds
    //   const moodboardWidth = viewSize.width;
    //   const moodboardHeight = moodboardBackgroundBefore.height / moodboardBackgroundBefore.width * viewSize.width;
    //   const moodboardAfterHeight = moodboardBackgroundAfter.height / moodboardBackgroundAfter.width * viewSize.width;
    //   if (moodboardHeight !== moodboardAfterHeight) {
    //     const adjustItemCoordinatesToNewBackground = (item) => {
    //       let newY = item.position.y;
    //       if (newY) {
    //         const itemAsset = item.assets[0];
    //         const itemAspectRatio = itemAsset.height / itemAsset.width;
    //         const itemHeight = item.position.width * moodboardWidth * itemAspectRatio;
    //         newY = item.position.y * moodboardHeight;
    //         if (newY + itemHeight / 2 > moodboardAfterHeight) {
    //           newY = moodboardAfterHeight - itemHeight / 2;
    //         }
    //         newY = newY / moodboardAfterHeight;
    //       }
    //       return {
    //         x: item.position.x,
    //         y: newY
    //       };
    //     };
    //     const requestData = {
    //       name: currentSelection.name,
    //       items: currentSelection.items.map(i => ({
    //         id: i.id,
    //         ...i.position,
    //         ...adjustItemCoordinatesToNewBackground(i)
    //       }))
    //     };
    //     const {data} = await this.$api.put(`/selections/${selectionId}`, requestData);
    //     commit('saveSelectionSuccess', data);
    //   }
    // }
    commit('setSelectionBackground', { selectionId, background: { id: backgroundId, itemId, assets: backgroundAssets } });
  },
  async extractUrlTo({ commit, rootState, rootGetters, dispatch, getters }: SelectionContext, {
    selectionId,
    url,
    addExternalContentEvent,
  }: { selectionId: string, url: string, addExternalContentEvent: AddExternalContentEvent }) {
    const relatedFolderId = rootGetters['folder/scrapbookId'];
    const selection = getters.selectionWithItemsById(selectionId);
    if (relatedFolderId) {
      const extractedItem = await dispatch('folder/extractUrl', {
        folderId: relatedFolderId,
        url,
        originalEvent: addExternalContentEvent.event,
      }, { root: true });
      let uploadedItems: FolderItem[] = [];
      if (extractedItem) {
        uploadedItems.push(extractedItem);
      }
      if (rootState.file.uploadProgress) {
        let uploadInProgress = true;
        while (uploadInProgress) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          if (rootState.file.uploadProgress.finished) {
            uploadInProgress = false;
          }
        }
        uploadedItems = rootState.file.uploadProgress.uploadedItems;
      }
      if (uploadedItems.length) {
        const positionMap = new Map<string, Partial<Position>>();
        if (addExternalContentEvent.position) {
          let zindex = getters.highestZindexForSelection(selectionId);
          for (const item of uploadedItems) {
            zindex++;
            let randomRotation = randomInteger(-5, 5);
            if (randomRotation < 0) {
              randomRotation += 360;
            }
            positionMap.set(item.id, {
              ...addExternalContentEvent.position,
              rotation: addExternalContentEvent.position.rotation ? addExternalContentEvent.position.rotation : randomRotation,
              zindex,
            });
          }
        }
        const requestData: any = {
          name: selection.name,
          items: [
            ...selection.items
              .map(i => ({
                id: i.id,
                ...i.position,
              })),
            ...uploadedItems
              .map((i, idx) => {
                const position = positionMap.has(i.id) ? positionMap.get(i.id) : {};
                return {
                  id: i.id,
                  order: selection.items.length + idx,
                  ...position,
                };
              }),
          ],
        };
        const { data } = await this.$api.put(`/selections/${selectionId}`, requestData);
        commit('saveSelectionSuccess', data);
      }
    }
  },
  async extractUrl({ commit, rootState, rootGetters, dispatch, getters }: SelectionContext, {
    selectionId,
    url,
    originalEvent,
  }: { selectionId: string, url: string, originalEvent: DragEvent }) {
    const relatedFolderId = rootGetters['folder/scrapbookId'];
    const selection = getters.selectionWithItemsById(selectionId);
    if (relatedFolderId) {
      const extractedItem = await dispatch('folder/extractUrl', {
        folderId: relatedFolderId,
        url,
        originalEvent,
      }, { root: true });
      let uploadedItems: FolderItem[] = [];
      if (extractedItem) {
        uploadedItems.push(extractedItem);
      }
      if (rootState.file.uploadProgress) {
        let uploadInProgress = true;
        while (uploadInProgress) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          if (rootState.file.uploadProgress.finished) {
            uploadInProgress = false;
          }
        }
        uploadedItems = rootState.file.uploadProgress.uploadedItems;
      }
      if (uploadedItems.length) {
        const requestData: any = {
          name: selection.name,
          items: [
            ...selection.items
              .map(i => ({
                id: i.id,
                ...i.position,
              })),
            ...uploadedItems
              .map((i, idx) => ({
                id: i.id,
                order: selection.items.length + idx,
              })),
          ],
        };
        const { data } = await this.$api.put(`/selections/${selectionId}`, requestData);
        commit('saveSelectionSuccess', data);
      }
    }
  },

  setMoodboardBackgroundPreview({ rootState, commit }: SelectionContext, { windowId, backgroundId }: { windowId: string; backgroundId: string }) {
    const window = rootState.cloud.registeredWindows[windowId];
    if (window.id === ViewIdentifier.MAIN_VIEW) {
      const selectionId = window.viewIds.find(id => id.isSelectionId)?.toUuid();
      const background = rootState.backgrounds.find(b => b.id === backgroundId);
      if (selectionId != null && background != null) {
        commit('setSelectionBackground', { selectionId, background });
      }
    }
  },
  async createNewMoodboard({ commit, dispatch, rootGetters }: SelectionContext, windowId: string) {
    const moodboardSelectionId = uuid();
    await dispatch('create', { id: moodboardSelectionId, name: 'moodboard', viewType: ViewType.MOODBOARD });
    const defaultBackground = rootGetters.defaultMoodboardBackground;
    if (defaultBackground) {
      const { data: backgroundAssets } = await this.$api.put(`/selections/${moodboardSelectionId}/background`, { backgroundId: defaultBackground.id });
      commit('setSelectionBackground', { selectionId: moodboardSelectionId, background: { id: defaultBackground.id, assets: backgroundAssets } });
    }
    const objectId = ObjectId.fromSelectionId(moodboardSelectionId);
    await dispatch('cloud/addToPane', { windowId, objectIds: [objectId] }, { root: true });
  },
  // We need to apply changes to items in the selectionItems and global selection items state
  // This action updates all selection items that have one of the given items embedded
  applyItemDataChangesToSelectionItems({ state, commit, rootState }: any, itemIds: string[]) {
    const changesToApply: Map<string, Map<string, Item>> = new Map();
    for (const itemId of itemIds) {
      if (state.itemsInSelectionId.has(itemId)) {
        const selectionsWithItem = state.itemsInSelectionId.get(itemId);
        for (const selectionId of selectionsWithItem) {
          const changes = changesToApply.get(selectionId) || new Map();
          changes.set(itemId, rootState.folder.items.get(itemId));
          changesToApply.set(selectionId, changes);
        }
      }
    }
    for (const [key, values] of changesToApply.entries()) {
      commit('applyItemChanges', { selectionId: key, itemChanges: values });
    }
    const itemChangesForGlobalSelection = [...itemIds]
      .filter(itemId => !!state.globalSelectedItems[itemId]);
    if (itemChangesForGlobalSelection.length) {
      const itemMap = new Map();
      itemChangesForGlobalSelection.forEach(itemId => itemMap.set(itemId, rootState.folder.items.get(itemId)));
      commit('applyItemChangesToGlobalSelection', itemMap);
    }
  },
  // selection added by other user or device
  // if this is the case we add the selection to the active lane shortcuts
  handleSelectionAdded({
    commit,
    getters,
  }: SelectionContext, selection: Selection) {
    if (!getters.exists(selection.id)) {
      commit('addSelection', { selection, buildChangeSet: false });
    }
  },
  handleSelectionsUpdated({ dispatch }: any, events: SocketEvent<SelectionUpdatedEventData>[]) {
    for (const event of events) {
      dispatch('handleSelectionUpdated', event.data);
    }
  },
  async handleSelectionUpdated({ commit, getters, rootState, dispatch }: SelectionContext, eventData: SelectionUpdatedEventData) {
    if (getters.exists(eventData.id)) {
      const changes: Partial<Selection> = {};
      if (eventData.name) {
        changes.name = eventData.name;
      }
      if (eventData.subtitle) {
        changes.subtitle = eventData.subtitle;
      }
      if (eventData.description) {
        changes.description = eventData.description;
      }
      if (eventData.items?.inserts?.length) {
        const reloadItemIds = [];
        eventData.items.inserts.forEach(i => {
          const itemId = i.itemId || i.item.id;
          if (!rootState.folder.items.has(itemId)) {
            reloadItemIds.push(itemId);
          }
        });
        for (const itemId of reloadItemIds) {
          await dispatch('folder/loadItem', itemId, { root: true });
        }
        eventData.items.inserts = eventData.items.inserts.map(i => {
          const itemId = i.itemId || i.item.id;
          if (rootState.folder.items.has(itemId)) {
            i.item = rootState.folder.items.get(itemId);
          }
          return i;
        });
      }
      commit('applySelectionChanges', { selectionId: eventData.id, changes, itemChangeSet: eventData.items, storeChangeSets: false });
    }
  },
  handleSelectionRemoved({ commit, getters }: SelectionContext, { selectionId }: SelectionRemovedEventData) {
    if (getters.exists(selectionId)) {
      commit('removeSelection', { id: selectionId, buildChangeSet: false });
    }
  },
  handleItemsRemoved({ commit }: any, event: ItemsRemovedEventData) {
    commit('removeFolderItemsFromSelections', event.items);
  },
  setSelectionShared({ commit }: SelectionContext, selectionId: string) {
    commit('setSelectionShared', selectionId);
  },
  handleAssetAdded({ commit }: SelectionContext, data: Asset) {
    commit('assetAdded', data);
  },
  fixStaleItemPositions({ state, commit }: SelectionContext, selectionId: string) {
    const { adjustedItems } = fixOrdering(state.selectionItems[selectionId].slice());
    if (adjustedItems.length) {
      commit('applySelectionChanges', { selectionId, itemChangeSet: { updates: adjustedItems.map(i => ({ id: i.id, position: { order: i.position.order } })) } });
    }
  },
};
function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

import _cloneDeep from 'lodash.clonedeep';
import Snippet from '~/models/Snippet';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { SortableView } from '~/models/views/SortableView';
import { ViewType } from '~/models/views/ViewType';
import { GridBuilder } from '~/models/views/grid/GridBuilder';

export class Grid implements SortableView {
  public type = ViewType.GRID;

  constructor(
    public snippets: Snippet[],
    public width: number,
    public height: number,
    public rowHeight: number,
    public marginBetweenItems: number,
    public marginBetweenVerticalItems: number
  ) {}

  resize(newWidth: number, _newHeight: number) {
    const scaleFactor = newWidth / this.width;
    this.snippets = this.snippets.map((i) => {
      i.height = i.height * scaleFactor;
      i.width = i.width * scaleFactor;
      i.item.setViewPosition({
        width: i.item.viewPosition.width * scaleFactor,
        height: i.item.viewPosition.height * scaleFactor,
        top: i.item.viewPosition.top * scaleFactor,
        left: i.item.viewPosition.left * scaleFactor,
      });
      return i;
    });
    this.width = newWidth;
    this.height = this.height * scaleFactor;
  }

  preCalculatePartialSorting(sortItemsEvent: SortItemsEvent): Snippet[] {
    const snippets: Snippet[] = _cloneDeep(this.snippets);
    const snippetsImpactedbySort = this.calculateImpactedSnippets(snippets, sortItemsEvent);
    const partialGridStartHeight = snippetsImpactedbySort[0]?.item.viewPosition.top || 0;
    const snippetsImpactedBySortWithFinalOrderPositions = Snippet.sortSnippetsBySortEvent(snippetsImpactedbySort, sortItemsEvent);
    const partialGrid = new GridBuilder()
      .setBaseGrid(this)
      .setItems(
        snippetsImpactedBySortWithFinalOrderPositions.map((s) => s.item.item)
      )
      .buildPartialGrid(partialGridStartHeight);
    return partialGrid.snippets.filter((snippet) =>
      sortItemsEvent.items.some((i) => i.id === snippet.item.id)
    );
  }

  // Info: we currently need all (or close to all) snippets to calculate a grid for certain edge cases,
  // so we don't limit the amount of impacted snippets here for the partial grid calculation, since a performance gain
  // would be very limited either way (it's not worth it at the point of this comment).
  // It might however be worth to do more research into performance gains for partial grid calculations later on
  //
  // So for now all we do here is check if the items of the sort event are already within the grid it is sorted into
  private calculateImpactedSnippets(
    snippets: Snippet[],
    _sortItemsEvent: SortItemsEvent
  ): Snippet[] {
    return snippets;
  }
}

export interface MosaicOptions {
  minColumnWidth: number;
  maxColumnWidth: number;
  columnWidth: number;
  columnWidthInterval: number;
  margin: number;
  marginFactor: number;
  marginFactorMin: number;
  marginFactorMax: number;
  marginInterval: number;
  minColumnCount: number;
  maxColumnCount: number;
  columnCount: number;
  columnCountInterval: number;
}

const calculateMosaicMaxColumnWidth = (windowSize: number) => {
  return 100 * Math.ceil(windowSize * 2 / 100) / 2;
};

export const mosaicOptionsDefault = (): MosaicOptions => {
  return {
    minColumnWidth: 50,
    maxColumnWidth: calculateMosaicMaxColumnWidth(window.innerWidth),
    columnWidth: localStorage.getItem('mosaic.columnWidth')
      ? (
          parseInt(localStorage.getItem('mosaic.columnWidth'), 10) > calculateMosaicMaxColumnWidth(window.innerWidth)
            ? calculateMosaicMaxColumnWidth(window.innerWidth)
            : parseInt(localStorage.getItem('mosaic.columnWidth'), 10)
        )
      : 200,
    columnWidthInterval: 25,
    minColumnCount: 1,
    maxColumnCount: 18,
    columnCount: localStorage.getItem('mosaic.columnCount') ? parseInt(localStorage.getItem('mosaic.columnCount'), 10) : 8,
    columnCountInterval: 1,
    margin: 1,
    marginFactor: localStorage.getItem('mosaic.marginFactor') ? parseInt(localStorage.getItem('mosaic.marginFactor'), 10) : 1,
    marginFactorMin: 0,
    marginFactorMax: 10,
    marginInterval: 1,
  };
};

import _cloneDeep from 'lodash.clonedeep';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { SortItemsEventType } from '~/models/item/SortItemsEventType';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { sortItemsByOrderAsc } from '~/models/item/sortItemsByOrderAsc';

export interface SortItemsEvent {
  position: number,
  items: ItemWithPosition[],
  type: SortItemsEventType,
  impactRange: {start: number, end: number}
}

export class RemoveItemsEvent {
  public items: ItemWithPosition[];

  constructor(items: ItemWithPosition[]) {
    this.items = _cloneDeep(items);
  }
}

export class SortItemsEventInternal implements SortItemsEvent {
  public items: ItemWithPosition[];
  public impactRange: {start: number, end: number};

  constructor(
    public position: number,
    items: ItemWithPosition[],
    public type: SortItemsEventType) {
    this.items = _cloneDeep(items).sort(sortItemsByOrderAsc);
    let start = this.items[0].position.order;
    let end = this.items[this.items.length - 1].position.order;
    if (start > this.position) {
      start = this.position;
    } else if (this.position > end) {
      end = this.position;
    }
    this.impactRange = {
      start,
      end,
    };
  }
}

// We need to provide the impact range for items that are external to a given view,
// since in this case we can't use the position of an item to calculate the impact range (like we do for internal sort events)
export class SortItemsEventExternal implements SortItemsEvent {
  public items: ItemWithPosition[];
  public impactRange: { start: number, end: number};
  public itemsInternalToView: ItemWithPosition[] = [];
  public internalToOriginalIdMap: Map<string, string> = new Map();

  constructor(
    public position: number,
    items: ItemWithPosition[],
    public type: SortItemsEventType,
    impactRange: { start: number, end: number}
  ) {
    this.impactRange = impactRange;
    this.items = _cloneDeep(items).sort(sortItemsByOrderAsc);
  }

  public isItemInternalToView(item: ItemWithPosition): boolean {
    return this.itemsInternalToView.some(i => i.id === item.id);
  }

  public get hasItemsInternalToView(): boolean {
    return this.itemsInternalToView.length > 0;
  }

  // Since we are working with different item types (FolderItem and SelectionItem) with different sorting outcomes,
  // we need to know which ItemWithPositions are indeed internal to the given collection and which are external
  // This is relevant when we e.g. want to move a SelectionItem into a Folder.
  // If the underlying Item|FolderItem to which the SelectionItem references is already in the Folder, we need to know this for the sorting operation to be effective
  // If we want e.g. to sort a FolderItem into a Selection however, we want to add it as a new SelectionItem (copy it), so it is not important for the sorting operation in this case.
  public setItemsInternalToViewByCondition(targetViewItems: ItemWithPosition[], conditionFunction: Function) {
    const [itemsWithDataAlreadyInView, externalItems] = filterByConditions(this.items, conditionFunction);
    if (!externalItems.length) {
      this.impactRange.end = this.impactRange.start;
    }
    let startRange = this.impactRange.start;
    let endRange = this.impactRange.end;
    const internalItems = [];
    if (itemsWithDataAlreadyInView.length) {
      for (let i = 0; i < targetViewItems.length; i++) {
        const targetItem = targetViewItems[i];
        for (const sortItem of itemsWithDataAlreadyInView) {
          if (targetItem.id === sortItem.id || targetItem.id === sortItem.itemId) {
            const targetOrderPosition = i; // we need to use the index as order position here, so we can properly sort collections that are not sorted by order position
            internalItems.push({
              ...targetItem,
              position: {
                ...targetItem.position,
                order: targetOrderPosition,
              },
            });
            this.internalToOriginalIdMap.set(targetItem.id, sortItem.id); // we need to preserve the original id of the sort item for use in the drag and drop animation
            if (targetOrderPosition < startRange) {
              startRange = targetOrderPosition;
            }
            if (targetOrderPosition > endRange) {
              endRange = targetOrderPosition;
            }
          }
        }
      }
    }
    this.itemsInternalToView = internalItems;
    this.items = externalItems.concat(internalItems)
      .sort(sortItemsByOrderAsc);
    this.impactRange = {
      start: startRange,
      end: endRange,
    };
  }
}

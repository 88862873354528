import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '~/store/state';
import { ProfileState } from '~/store/profile/state';
import { ProfilePictureThumbnailer } from '~/models/thumbnailer/ProfilePictureThumbnailer';
import { ThumbnailerEvent } from '~/models/thumbnailer/ThumbnailerEvent';
import { ResizedThumbnail } from '~/models/PipelineItem';
import { UserProfile } from '~/models/user/UserProfile';
import { ActionPayload } from '~/models/VuexAdditionalTypes';
import { Notification, NotificationType } from '~/models/Notification';
import { errorMap } from '~/models/error/ErrorMap';

type ProfileContext = ActionContext<ProfileState, RootState>;

const actions: ActionTree<ProfileState, RootState> = {
  uploadProfilePicture({ rootState, rootGetters, dispatch }: ProfileContext, file: File): Promise<void> {
    if (rootState.workerSupport && !rootGetters['user/isGuest']) {
      const profilePictureThumbnailer = new ProfilePictureThumbnailer(file, rootState.workerSupport);
      return new Promise((resolve, _reject) => {
        profilePictureThumbnailer.on(ThumbnailerEvent.ITEMS_RESIZED, async (thumbnail: ResizedThumbnail) => {
          await dispatch('handleThumbnail', thumbnail);
          resolve();
        });
      });
    } else {
      dispatch<ActionPayload<Notification>>(
        {
          type: 'setNotificationMessage',
          payload: { message: rootState.workerSupport ? 'Guests can not change their profile picture' : 'Your browser or browser version is currently not supported.', type: NotificationType.ERROR, duration: 5000 },
        },
        { root: true });
    }
  },

  async handleThumbnail({ commit, dispatch, rootGetters }: ProfileContext, thumbnail: ResizedThumbnail): Promise<void> {
    if (thumbnail?.file != null) {
      const newFile = new File([thumbnail.file], `profile-picture-${rootGetters['user/currentUser']?.username ?? ''}`, { type: thumbnail.file.type, lastModified: thumbnail.file.lastModified });
      const formData = new FormData();
      formData.append('files', newFile);
      try {
        await this.$api.post('/users/me/profile/picture/upload', formData);
      } catch (error) {
        const errorKey = error.response?.data?.errorKey;
        if (errorKey != null) {
          dispatch<ActionPayload<Notification>>(
            {
              type: 'setNotificationMessage',
              payload: { message: errorMap.get(errorKey).text, type: NotificationType.ERROR, duration: 5000 },
            },
            { root: true });
        }
        return;
      }
      commit('updateProfile', { base64: thumbnail.base64, hasProfilePicture: true } as Partial<UserProfile>);
      return;
    }
    dispatch<ActionPayload<Notification>>(
      {
        type: 'setNotificationMessage',
        payload: {
          message: 'Something went wrong while creating the profile picture, please try again later or contact our support',
          type: NotificationType.ERROR,
          duration: 5000,
        },
      },
      { root: true });
  },
};

export default actions;

import { UserProfile } from '~/models/user/UserProfile';
import { ProfileState } from '~/store/profile/state';

export default {
  loadProfileSuccess(state: ProfileState, data: UserProfile) {
    state.data = { ...data };
  },
  saveProfileSuccess(state: ProfileState) {
    state.saved = true;
  },
  updateProfile(state: ProfileState, newUserProfileValues: Partial<UserProfile>) {
    state.data = { ...state.data, ...newUserProfileValues };
  },
};

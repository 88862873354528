import _debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';
import { ObjectId } from '~/models/ObjectId';
import { TransferType } from '~/models/TransferType';
import { DragElementOffsetValues } from '~/models/views/DragElementOffsetValues';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { DragInfo } from '~/models/views/DragInfo';
import { ContextMenuType } from '~/store/context/state';
import { LayoutType } from '~/models/LayoutType';
import { SocketEvent } from '~/models/socket/events/SocketEvent';
import { Background } from '~/models/views/Background';
import { BackgroundResizeCompletedEventData } from '~/models/socket/events/BackgroundResizeCompletedEventData';
import { SocketEvents } from '~/models/socket/SocketEvents';
import { OriginConnectedEventData } from '~/models/socket/events/OriginConnectedEventData';
import Folder from '~/models/Folder';
import { MessagesAddedEventData } from '~/models/socket/events/MessagesAddedEventData';
import { UserJoinedFolderEventData } from '~/models/socket/events/UserJoinedFolderEventData';
import { UserJoinedSelectionEventData } from '~/models/socket/events/UserJoinedSelectionEventData';
import { UserLeftFolderEventData } from '~/models/socket/events/UserLeftFolderEventData';
import { UserLeftSelectionEventData } from '~/models/socket/events/UserLeftSelectionEventData';
import { MessageRoutesUpdatedEventData } from '~/models/socket/events/MessageRoutesUpdatedEventData';
import { ItemsAddedEventData } from '~/models/socket/events/ItemsAddedEvent';
import {
  Asset,
  CUSTOM_SUB_VERSION,
  getAssetVersionPriority,
  getDownloadSize,
  ORIGINAL_ASSET_VERSION
} from '~/models/Asset';
import { ResizeCompletedEventData } from '~/models/ResizeCompletedEventData';
import { MetadataCompletedEventData } from '~/models/socket/events/MetadataCompletedEventData';
import { UploadProgressEventData } from '~/models/socket/events/UploadProgressEventData';
import { RootState } from '~/store/state';
import { StorageKey } from '~/models/storage/StorageKey';
import { UpdateCloudObjects } from '~/models/socket/events/UpdateCloudObjects';
import { FilterType } from '~/models/cloud/CloudObjectFilter';
import { DroppedOnViewEvent } from '~/models/DroppedOnViewEvent';
import Selection from '~/models/selection/Selection';
import { SelectionUpdatedEventData } from '~/models/socket/events/SelectionUdpatedEventData';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { SnapshotImageCreatedEventData } from '~/models/socket/events/SnapshotImageCreatedEventData';
import { ViewType } from '~/models/views/ViewType';
import { CloudMenuRoute, isValidCloudMenuRoute } from '~/models/CloudMenuRoute';
import { CloudMenuTab } from '~/models/CloudMenuTab';
import { FolderUpdatedEventData } from '~/models/socket/events/FolderUpdatedEventData';
import { aggregateEvents } from '~/models/socket/aggregateEvents';
import { SocketActions } from '~/models/socket/SocketActions';
import { Notification, NotificationId, NotificationPosition, NotificationType } from '~/models/Notification';
import { defaultState } from '~/store/helper';
import { getCookieValueFor } from '~/models/Cookie';
import { CookieKey } from '~/models/CookieKey';
import { ActionPayload, MutationPayload } from '~/models/VuexAdditionalTypes';
import { FooterTab } from '~/models/FooterTab';
import { MutantContentView } from '~/models/views/MutantContentView';
import { LANDING_PAGE_ROUTE } from '~/store/homepage/state';
import { InfoMessages, SuccessMessages } from '~/models/MessageTypes';
import { BrowserCompatibilityChecker } from '~/models/BrowserCompatibilityChecker';
import { LandingPageEntry } from '~/models/LandingPageEntry';

export type RootContext = ActionContext<RootState, RootState>;
let connectionIsBeingInitialized = false;
const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000;

export type DesktopReleaseData = {
  version: string;
  size: number;
  downloadUrl: string;
};

async function fetchReleaseData(tries: number, maxTries = 5): Promise<DesktopReleaseData> {
  if (tries < maxTries) {
    try {
      const response = await fetch('https://desktop.mutant.one/releases/latest');
      return await response.json();
    } catch (err) {
      this.$log.error(`Desktop release data could not be loaded (${tries} tries) due to`, err);
      setTimeout(() => fetchReleaseData(tries + 1), 10_000 * tries);
    }
  }
  return Promise.reject(new Error('Desktop release data could not be loaded'));
}

const actions = {
  async downloadDesktopApp({ state, dispatch }: RootContext) {
    if (state.desktopReleaseData == null) {
      await dispatch('fetchDesktopRelease');
    }
    window.location.href = state.desktopReleaseData.downloadUrl;
  },
  async fetchDesktopRelease({ commit }: RootContext) {
    try {
      const releaseData = await fetchReleaseData(1);
      commit('setDesktopReleaseData', releaseData);
    } catch (err) {
      this.$log.error(err.message);
    }
  },
  openShortcutLane({ commit, dispatch }: { state: RootState, commit: any, dispatch: any }) {
    dispatch('setCssVariableForTabLane', true);
    setTimeout(() => commit('setNavigationLaneOpen', true), 50);
  },
  closeShortcutLane({ commit, dispatch }: any) {
    dispatch('setCssVariableForTabLane', false);
    setTimeout(() => commit('setNavigationLaneOpen', false), 50);
  },
  setCssVariableForTabLane({ state }: any, isOpen: boolean) {
    let laneHeight: string;
    if (isOpen) {
      laneHeight = state.interactiveFooterHeight + 'px';
    } else {
      laneHeight = getComputedStyle(document.documentElement).getPropertyValue('--tabs-lane-height-closed');
    }
    document.documentElement.style.setProperty('--tabs-lane-height', laneHeight);
  },
  setActiveElementId({ commit }: RootContext, data: ActionPayload<string>) {
    commit<MutationPayload<string>>({
      type: 'setActiveElementId',
      payload: data.payload,
    });
  },
  async initializeApplication({ commit, dispatch, rootState, rootGetters }: RootContext) {
    commit('setMobileSpecificValues');
    const promises = [];
    promises.push(
      dispatch('initializeNotifications'),
      dispatch('initializeLayout'),
      dispatch('initializePersistedRecentObjectIds'),
      dispatch('initializeAnalytics'),
      dispatch('checkBrowserSupport')
    );
    if (this.$routeAwareness.isHomepageView && rootState.isNavigationLaneOpen) {
      promises.push(dispatch('closeShortcutLane'));
    }
    if (!rootGetters['user/isGuest']) {
      promises.push(dispatch('initializeSocketConnection'));
      if (rootState.user.userLimits == null) {
        promises.push(dispatch('user/loadUserLimits'));
      }
    }
    promises.push(dispatch('file/setThumbnailQualityConfig', rootState.file.thumbnailQualityConfig));
    await Promise.all(promises);
  },
  async initializeAnalytics({ getters, dispatch }: RootContext) {
    let user = getters['user/currentUser'];
    if (!getters['user/isGuest'] && user?.id == null) {
      await dispatch('user/loadUserDetails');
      user = getters['user/currentUser'];
    }
    if (!this.$analytics.isInitialized) {
      await this.$analytics.initialize(user.id);
    }
  },
  closeAllMenus({ dispatch }: RootContext) {
    dispatch('context/closeAllMenus');
    dispatch('closeMenu');
    dispatch('closeShortcutLane');
  },
  clearState({ dispatch }: RootContext) {
    this.replaceState(_cloneDeep(defaultState()));
    dispatch('initializeLayout');
    // On clear state the component is not newly rendered, so we need to make sure that the animation
    // property is correctly set
    dispatch('homepage/initialSlideAnimationEnded', true);
  },
  async checkBrowserSupport({ commit }: RootContext) {
    const isSupported = await BrowserCompatibilityChecker.checkForWorkerSupport();
    this.$thumbnailer.setWorkerSupport(isSupported);
    this.$metadataExtractor.setWorkerSupport(isSupported);
    commit('setWorkerSupport', isSupported);
  },
  visitHomepage({ commit, dispatch }: RootContext) {
    dispatch('context/closeAllMenus');
    commit('setNavigationLaneOpen', false);
    this.$router.push({ name: LANDING_PAGE_ROUTE });
  },
  setNotificationMessage({ commit }: RootContext, data: ActionPayload<Notification>) {
    const id = data.payload.id ?? uuid();
    commit<MutationPayload<Notification>>({
      type: 'setNotificationMessage',
      payload: {
        id,
        ...data.payload,
      },
    });
    if (data.payload.duration !== 'permanent') {
      setTimeout(() => {
        commit('disableNotificationMessage', id);
      }, data?.payload?.duration ?? 3000);
    }
  },
  displayNotificationForDeletionNotPossible({ dispatch }: RootContext) {
    dispatch('setNotificationMessage', {
      payload: {
        message: InfoMessages.DELETION_WHILE_UPLOAD_NOT_POSSIBLE,
        type: NotificationType.INFO,
        duration: 5000,
      },
    });
  },
  displayServerOnlineNotification({ dispatch }: RootContext) {
    dispatch('disableNotificationMessage', NotificationId.SERVER_UNREACHABLE);
    dispatch<ActionPayload<Notification>>(
      {
        type: 'setNotificationMessage',
        payload: { id: NotificationId.SERVER_REACHABLE, message: SuccessMessages.SERVER_REACHABLE, type: NotificationType.SUCCESS },
      },
      { root: true });
  },
  disableNotificationMessage({ commit }: RootContext, id: string) {
    commit('disableNotificationMessage', id);
  },
  // This looks rather unnecessary, but safari does not display the mobile page in fullscreen if 100vh
  // is used, so as a workaround window innerHeight is used here.
  initializeMobileSizes({ rootGetters, dispatch }: RootContext) {
    if (rootGetters.isLandscape) {
      document.documentElement.style.setProperty('--window-area-height', `${window.innerHeight}px`);
      document.documentElement.style.setProperty('--header-navigation-height', '0px');
      dispatch('setMobileHorizontal');
    } else {
      document.documentElement.style.setProperty('--header-navigation-height', '30px');
      document.documentElement.style.setProperty(
        '--window-area-height',
        `calc(${window.innerHeight}px - var(--header-navigation-height))`
      );
      dispatch('setMobileMosaic');
    }
    document.body.style.height = window.innerHeight + 'px';
    document.body.style.width = window.innerWidth + 'px';
  },
  setMobileHorizontal({ commit }: RootContext) {
    commit('cloud/setMagnifyMargin', { windowId: ViewIdentifier.MAIN_VIEW, margin: 0 });
    commit('cloud/viewChanged', { windowId: ViewIdentifier.MAIN_VIEW, view: ViewType.HORIZONTAL });
  },
  setMobileMosaic({ commit }: RootContext) {
    commit('cloud/setMosaicColumnCount', { windowId: ViewIdentifier.MAIN_VIEW, columnCount: 2, persist: false });
    commit('cloud/setMosaicMargin', { windowId: ViewIdentifier.MAIN_VIEW, margin: 18 });
    commit('cloud/viewChanged', { windowId: ViewIdentifier.MAIN_VIEW, view: ViewType.MOSAIC });
  },
  initializeSocketEventAggregation({ dispatch }: RootContext) {
    this.$log.info('initialize socket event aggregation');
    if (!this.$socketEventAggregator.eventObservable) {
      this.$socketEventAggregator.initialize();
      this.$socketEventAggregator.eventObservable.subscribe(async (events) => {
        const aggregatedEvents = aggregateEvents(events); // TODO: move this logic into the socketEventAggregator
        this.$log.info('process aggregated events', aggregatedEvents);
        if (aggregatedEvents.itemsAddedEvents.length) {
          dispatch('folder/itemsAdded', aggregatedEvents.itemsAddedEvents);
        }
        if (aggregatedEvents.resizeCompletedEvents.length) {
          await dispatch('folder/itemsResized', aggregatedEvents.resizeCompletedEvents);
        }
        if (aggregatedEvents.assetUploadedEvents.length) {
          await dispatch('folder/handleAssetsAdded', aggregatedEvents.assetUploadedEvents);
        }
        if (aggregatedEvents.selectionUpdatedEvents.length) {
          await dispatch('selection/handleSelectionsUpdated', aggregatedEvents.selectionUpdatedEvents);
        }
      });
    }
  },
  setMenusOpacity({ commit }: RootContext, opacity: number) {
    localStorage.setItem(StorageKey.MENUS_OPACITY, opacity.toString());
    commit('setMenusOpacity', opacity);
  },
  setInteractiveFooterHeight({ commit }: RootContext, height: number) {
    commit('setInteractiveFooterTab', height);
  },
  sendRecentObjectIds: _debounce(async function ({ state, getters, commit }: RootContext) {
    if (!getters['user/isGuest']) {
      try {
        await this.$api.put('/cloud-objects/recent', { objectIds: state.recentObjectIds.map(r => r.toString()) });
      } catch (error) {
        if (error?.response?.status === 400 && error?.response?.data?.message?.includes('invalid or unsupported object id format')) {
          commit('clearRecentObjectIds');
        }
      }
    }
  }, 5000),
  async sendCustomObjectIds({ state }: RootContext) {
    await this.$api.put('/cloud-objects/custom', { objectIds: state.customObjectIds.map(c => c.toString()) });
  },
  async addToCustomObjectIds({
    commit,
    dispatch,
  }: RootContext, objectId: ObjectId) {
    commit('addToCustomObjectIds', objectId);
    await dispatch('sendCustomObjectIds');
  },
  async removeFromCustomObjectIds({ commit, dispatch }: RootContext, objectId: ObjectId) {
    commit('removeFromCustomObjectIds', objectId);
    await dispatch('sendCustomObjectIds');
  },
  async addToRecentObjectIds({
    state,
    commit,
    dispatch,
    getters,
  }: RootContext, objectId: ObjectId) {
    if (objectId.isSelectionId || objectId.isFolderId) {
      if (state.recentObjectIds[0]?.toString() !== objectId.toString()) {
        commit('addToRecentObjectIds', objectId);
        await dispatch('persistRecentObjectIds');
        if (!getters['user/isGuest']) {
          await dispatch('sendRecentObjectIds');
        }
      }
    }
  },
  async addMultipleToRecentObjectIds({ dispatch }: RootContext, objectIds: ObjectId[]) {
    for (const objectId of objectIds) {
      await dispatch('addToRecentObjectIds', objectId);
    }
  },
  async removeFromRecentObjectIds({ commit, dispatch }: RootContext, objectId: ObjectId) {
    commit('removeFromRecentObjectIds', objectId);
    await dispatch('persistRecentObjectIds');
    await dispatch('sendRecentObjectIds');
  },
  persistRecentObjectIds({ state }: RootContext) {
    localStorage.setItem(StorageKey.RECENT_OBJECTS, JSON.stringify(state.recentObjectIds.map(o => o.toString())));
  },
  initializePersistedRecentObjectIds({ commit }: RootContext) {
    const stringArr = localStorage.getItem(StorageKey.RECENT_OBJECTS) ? JSON.parse(localStorage.getItem(StorageKey.RECENT_OBJECTS)) : [];
    const objectIds: ObjectId[] = stringArr.map(stringId => new ObjectId(stringId));
    commit('initializeRecentObjectIds', objectIds);
  },
  changeBaseUrl(_context: RootContext, baseUrl: string) {
    this.$api.defaults.baseURL = baseUrl;
  },
  useScreenBackground({ commit }: RootContext, backgroundId: string) {
    localStorage.setItem('screen.background', backgroundId);
    commit('useScreenBackground', backgroundId);
  },
  setMainViewHeaderHeight({ commit }: RootContext, height: number) {
    commit('setMainViewHeaderHeight', height);
    document.documentElement.style.setProperty('--window-header-height', height + 'px');
  },
  downloadDragInfo({ state, dispatch }: RootContext) {
    if (state.dragInfo) {
      const items: ItemWithPosition[] = state.dragInfo.items;
      const assets = items.map(i => getAssetVersionPriority(i.item, [ORIGINAL_ASSET_VERSION, CUSTOM_SUB_VERSION])).filter(a => a != null);
      if (assets.length) {
        dispatch('file/downloadSingleFileAssets', assets, { root: true });
        dispatch('showNotificationOnLargeAssetDownload', assets, { root: true });
      }
    }
  },
  showNotificationOnLargeAssetDownload({ dispatch }: RootContext, assets: Asset[]) {
    const twoAndHalfMb = 2500000;
    if (assets.some(a => a.size > twoAndHalfMb)) {
      const { size, unit } = getDownloadSize(assets);
      const message = assets.length > 1
        ? `${assets.length} pictures of ${size} ${unit} are being downloaded, this might take a while…`
        : `${assets.length} picture of ${size} ${unit} is being downloaded, this might take a while…`;
      dispatch<ActionPayload<Notification>>({
        type: 'setNotificationMessage',
        payload: { message, type: NotificationType.INFO, duration: 4000, position: NotificationPosition.BOTTOM_LEFT },
      },
      { root: true });
    }
  },
  droppedOnView({ commit }: RootContext, data: DroppedOnViewEvent) {
    commit('setDropViewData', data);
  },
  finishDropOnView({ commit }: RootContext) {
    commit('setDropViewData', null);
  },
  startDraggingItems({ commit }: RootContext, {
    transferType,
    size,
    offsets,
    dragViewId,
    items,
    moveId,
  }: { transferType: TransferType, size: { width: number, height: number }, moveId: string, offsets: DragElementOffsetValues, dragViewId: string, items: ItemWithPosition[] }) {
    commit('setDragSize', size);
    commit('setDragOffsets', offsets);
    const dragInfo: DragInfo = {
      transferType,
      viewId: dragViewId,
      moveId,
      items,
    };
    commit('setDragInfo', dragInfo);
  },
  stopDraggingItems({ commit }: RootContext) {
    commit('setDragSize', null);
    commit('setDragOffsets', null);
    commit('setDragInfo', null);
  },
  deleteItemsFromView({ dispatch, getters, rootGetters }: RootContext, { viewId }: {viewId: ViewIdentifier}) {
    if (getters['selection/hasItemsSelected'] && !(rootGetters.isSharedLink && !getters['user/isOwnerOfCurrentFolder'](viewId))) {
      const mutantView: MutantContentView = getters['cloud/view'](viewId);
      if (mutantView.isSingleFolderView) {
        const itemsInFolder = getters['selection/globalSelectionItemsInFolder'](mutantView.folderId);
        if (itemsInFolder?.length > 0) {
          dispatch('selection/deleteItemsFromFolder', mutantView.folderId);
        }
      }
      if (mutantView.isSingleSelectionView) {
        const itemsInSelection = getters['selection/globalSelectionItemsInCurrentSelection'](mutantView.selectionId);
        if (itemsInSelection?.length > 0) {
          dispatch('selection/removeItemsFromSelection', {
            items: itemsInSelection,
            selectionId: mutantView.selectionId,
          });
        }
      }
    }
  },
  async rateItems({ dispatch, getters }: RootContext, { viewId, rating }: {viewId: ViewIdentifier, rating: number}) {
    if (getters.currentLayout === LayoutType.REVIEW_MODE) {
      const mutantView: MutantContentView = getters['cloud/view'](viewId);
      if (mutantView.isSingleFolderView) {
        await dispatch('folder/rateItems', {
          folderId: mutantView.folderId,
          rating,
        });
      }
      if (mutantView.isSingleSelectionView) {
        await dispatch('selection/rateItems', {
          selectionId: mutantView.selectionId,
          rating,
        });
      }
    }
  },
  // If the window must be reloaded we can persist the notification
  persistNotifications(_context: RootContext, data: { message: string, type: NotificationType }) {
    localStorage.setItem(StorageKey.NOTIFICATIONS, JSON.stringify(data));
  },
  // If a notification was persisted because of a window reload we have to initialize the notifications
  // so that they are displayed after the reload
  initializeNotifications({ dispatch }: RootContext) {
    const data = JSON.parse(localStorage.getItem(StorageKey.NOTIFICATIONS));
    if (data != null) {
      localStorage.removeItem(StorageKey.NOTIFICATIONS);
      if (data.message != null && data.type != null) {
        dispatch<MutationPayload<Notification>>({ type: 'setNotificationMessage', payload: { ...data } });
      }
    }
  },
  setLayoutInitialized({ commit }: RootContext, isInitialized) {
    commit('setLayoutInitialized', isInitialized);
  },
  initializeLayout({ rootGetters, commit, dispatch, rootState }: RootContext) {
    dispatch('user/setSentryUserContext');
    const waitingForSharedLink = !!this.$router.currentRoute.query['shared-link-id'];
    if (!waitingForSharedLink) {
      commit('setLayoutInitialized', true);
      const layoutType = rootGetters.isMobile ? LayoutType.ESSENTIALS : rootState.currentLayoutType;
      // We don't want to close the shortcut lane if user reloads the application
      if (!(rootState.isNavigationLaneOpen && !rootGetters.isMobile && layoutType === LayoutType.ESSENTIALS)) {
        dispatch('setActiveLayoutType', layoutType);
      }
      if (rootState.isNavigationLaneOpen) {
        dispatch('setCssVariableForTabLane', true);
      }
    }
    if (this.$router.currentRoute.query['mail-validation-code']) {
      dispatch('user/validateMail', this.$router.currentRoute.query['mail-validation-code'], { root: true });
    }
    if (this.$router.currentRoute.query['password-reset-code']) {
      // We need to add a timeout here, since the context menu cannot be initialized too early or else it won't display due to unknown side effects.
      setTimeout(() => dispatch('context/openMenu', { type: ContextMenuType.CHANGE_PASSWORD, data: this.$router.currentRoute.query['password-reset-code'] }, { root: true }), 500);
    }
    const activeLandingPageEntry = this.$router.currentRoute.query.desktop ? LandingPageEntry.DESKTOP : LandingPageEntry.WEB;
    commit('homepage/setActiveEntry', activeLandingPageEntry, { root: true });
    if (this.$router.currentRoute.query.login) {
      setTimeout(() => dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: true } }, { root: true }), 500);
    }
    if (this.$router.currentRoute.query.signup && rootGetters['user/isGuest']) {
      setTimeout(() => dispatch('context/openMenu', { type: ContextMenuType.LOGIN, data: { showLogin: false, isSignupForDesktop: activeLandingPageEntry === LandingPageEntry.DESKTOP } }, { root: true }), 500);
    }
  },
  async setBrowseMode({ dispatch, commit }: RootContext) {
    commit('setMinimalView', false);
    await dispatch('setActiveLayoutType', LayoutType.FILMSTRIP);
    await dispatch('setActiveCloudMenuTab', CloudMenuTab.ITEM_LIST);
    dispatch('openCloudMenuRight', null, { root: true });
  },
  chooseLayoutAgain({ dispatch }: RootContext, layout: LayoutType) {
    switch (layout) {
      case LayoutType.FILMSTRIP:
        dispatch('setActiveLayoutType', LayoutType.ESSENTIALS);
        break;
      case LayoutType.FULLSCREEN:
      case LayoutType.BASIC:
        dispatch('setActiveLayoutType', LayoutType.FILMSTRIP);
        break;
    }
  },
  setActiveLayoutType({ dispatch, commit }: RootContext, layout: LayoutType) {
    commit('setLayout', layout);
    switch (layout) {
      case LayoutType.FILMSTRIP:
        dispatch('openShortcutLane');
        commit('setActiveFooterTab', FooterTab.NAVIGATION);
        break;
      case LayoutType.BASIC:
        dispatch('openShortcutLane');
        break;
      case LayoutType.FULLSCREEN:
        dispatch('magnify/close');
        dispatch('selection/close');
        dispatch('closeShortcutLane');
        break;
      case LayoutType.ESSENTIALS:
        dispatch('closeShortcutLane');
        break;
      case LayoutType.REVIEW_MODE:
        commit('setMinimalView', false);
        dispatch('openShortcutLane');
        dispatch('context/closeAllMenus');
        dispatch('closeMenu');
        dispatch('closeCloudMenuRight');
        commit('setActiveFooterTab', FooterTab.NAVIGATION);
        dispatch('setActiveCloudMenuTab', CloudMenuTab.REVIEW);
        dispatch('cloud/setBackgroundOpacity', { windowId: ViewIdentifier.MAIN_VIEW, opacity: 1 });
        // For preventing a poor animation, the view change is done later, after all other layout shifts are finished
        setTimeout(() => {
          dispatch('cloud/changeView', {
            windowId: ViewIdentifier.MAIN_VIEW,
            view: ViewType.HORIZONTAL,
          });
        }, 700);
        break;
    }
  },
  chooseLayout({ state, dispatch }: RootContext, layout: LayoutType) {
    if (layout === state?.currentLayoutType) {
      dispatch('chooseLayoutAgain', layout);
    } else {
      dispatch('setActiveLayoutType', layout);
    }
  },
  registerFloatingMenu({ commit, state }: RootContext, floatingMenu: any) {
    if (state.floatingMenus.some(m => m.id === floatingMenu.id && m.minimized)) {
      commit('openFloatingMenu', floatingMenu.id);
    } else {
      commit('registerFloatingMenu', floatingMenu);
    }
  },
  minimizeFloatingMenu({ commit }: RootContext, menuId: string) {
    commit('minimizeFloatingMenu', menuId);
  },
  closeFloatingMenu({ commit }: RootContext, menuId: string) {
    commit('closeFloatingMenu', menuId);
  },
  toggleFloatingMenu({ commit, getters }: RootContext, menuId: string) {
    if (getters.isFloatingMenuOpen(menuId)) {
      commit('minimizeFloatingMenu', menuId);
    } else {
      commit('openFloatingMenu', menuId);
    }
  },
  updateFloatingMenuPosition({ commit }: RootContext, data: any) {
    commit('setFloatingMenuPosition', data);
  },
  dragLayout({ commit }: RootContext, value: boolean) {
    commit('setAdjustWindowsToDrag', false);
    if (!value) {
      setTimeout(() => commit('setLayoutDragging', value), 50);
    } else {
      commit('setLayoutDragging', value);
    }
  },
  adjustWindowsToDrag({ commit }: RootContext, value: boolean) {
    commit('setAdjustWindowsToDrag', value);
  },
  toggleAttachMenu({ state, commit }: RootContext) {
    commit('toggleAttachMenu');
    localStorage.setItem(StorageKey.MENU_FLOATING, state.isMenuFloating.toString());
  },
  toggleSidePane({ commit }: RootContext) {
    commit('toggleSidePane');
    commit('persistSidePane');
  },
  async toggleCloudMenuRightWithTab({ state, dispatch }: RootContext, tab: CloudMenuTab) {
    if (!state.cloudMenuRightOpen) {
      await dispatch('toggleCloudMenuRight');
    }
    await dispatch('setActiveCloudMenuTab', tab);
  },
  toggleCloudMenuRight({ commit }: RootContext) {
    commit('toggleCloudMenuRight');
    commit('persistCloudMenuRight');
  },
  openCloudMenuRight({ state, dispatch }: RootContext) {
    if (!state.cloudMenuRightOpen) {
      dispatch('toggleCloudMenuRight');
    }
  },
  closeCloudMenuRight({ state, dispatch }: RootContext) {
    if (state.cloudMenuRightOpen) {
      dispatch('toggleCloudMenuRight');
    }
  },
  updateSidePaneWidth({ commit }: RootContext, value: number) {
    commit('updateSidePaneWidth', value);
  },
  updateCloudMenuRightWidth({ commit }: RootContext, value: number) {
    commit('updateCloudMenuRightWidth', value);
  },
  updateCloudMenuWidth({ commit }: RootContext, value: number) {
    commit('updateCloudMenuWidth', value);
  },
  persistCloudMenu({ commit }: RootContext) {
    commit('persistCloudMenu');
  },
  persistSidePane({ commit }: RootContext) {
    commit('persistSidePane');
  },
  persistCloudMenuRight({ commit }: RootContext) {
    commit('persistCloudMenuRight');
  },
  setActiveCloudMenuTab({ commit }: RootContext, activeTab: CloudMenuTab) {
    commit('setActiveCloudMenuTab', activeTab);
    commit('persistCloudMenuRight');
  },
  openMenu({ state, commit }: RootContext, menuType: CloudMenuRoute) {
    if (isValidCloudMenuRoute(menuType)) {
      if (state.menuRoute !== menuType) {
        commit('setMenuRoute', menuType);
      }
    }
    commit('openMenu');
    commit('persistCloudMenu');
  },
  closeMenu({ commit }: RootContext) {
    commit('closeMenu');
    commit('persistCloudMenu');
  },
  openCloudMenu({ state, dispatch, commit }: RootContext) {
    if (state.menuRoute !== 'cloud') {
      commit('setMenuRoute', 'cloud');
    }
    if (!state.menuOpen) {
      dispatch('openMenu');
    }
    if (!state.isMenuExpanded) {
      commit('setMenuExpanded', true);
    }
  },
  changeMenuRoute(context: RootContext, newRoute: string) {
    context.commit('setMenuRoute', newRoute);
  },
  toggleMenu({ state, dispatch }: RootContext) {
    if (!state.menuOpen) {
      dispatch('openMenu');
    } else {
      dispatch('closeMenu');
    }
  },
  async initializeStoreFromLocalStorage({ commit, dispatch }: RootContext) {
    const expiresAt = getCookieValueFor(CookieKey.EXPIRES_AT);
    if (expiresAt) {
      // todo temporary solution to prevent that the user ends up in an error page deadlock, solution needs to be defined
      try {
        await dispatch('user/loadUserDetails');
        await dispatch('user/loadUserRelatedData');
      } catch (e) {
      }
    }
    const isUserRoleUpgradePending = localStorage.getItem(StorageKey.USER_ROLE_UPGRADE_PENDING) === 'true';
    if (isUserRoleUpgradePending) {
      await dispatch('restorePaypalSubscription');
    }
    commit('storeInitialized');
  },
  async restorePaypalSubscription(context: any) {
    let isUpgradeStillPending = true;
    // if user was upgraded in the meantime (the role changed) we set role upgrade pending to false
    if (context.getters['user/isAlphaUser']) {
      isUpgradeStillPending = false;
    } else {
      const subscriptionId = localStorage.getItem(StorageKey.USER_ROLE_UPGRADE_PENDING_FOR_SUBSCRIPTION_ID);
      // We try to upgrade the user account for the pending subscription id
      if (subscriptionId != null) {
        try {
          await context.dispatch('user/upgradeAccount', subscriptionId);
          isUpgradeStillPending = false;
        } catch {
          if (isUserRoleUpgradePendingForTooLong()) {
            isUpgradeStillPending = false;
          }
        }
      } else if (isUserRoleUpgradePendingForTooLong()) {
        isUpgradeStillPending = false;
      }
    }
    context.commit('user/setUserRoleUpgradePending', { isPending: isUpgradeStillPending });
  },
  addToEventHistory({ commit }: RootContext, event: SocketEvent<any>) {
    commit('addToEventHistory', event);
  },
  addNewBackgrounds({ commit }: RootContext, backgrounds: Background[]) {
    for (const background of backgrounds) {
      commit('addBackground', background);
    }
  },
  addResizedBackroundAssets({ commit }: RootContext, data: BackgroundResizeCompletedEventData) {
    commit('addBackgroundAssets', data);
  },
  // TODO: we need these backgrounds for moodboards
  async loadBackgrounds({ state, commit, getters }: RootContext) {
    const { data } = await this.$api.get('/backgrounds');
    commit('setBackgrounds', data);
    if (!state.screenBackground || !state.backgrounds.some(background => background.id === state.screenBackground)) {
      const defaultBackground = getters.defaultBackground;
      if (defaultBackground) {
        commit('useScreenBackground', defaultBackground.id);
      }
    }
  },
  addSnapshotImageFromOrigin({ commit }: RootContext, data: SnapshotImageCreatedEventData) {
    if (data.selectionId) {
      commit('selection/addSnapshotImageFromOrigin', data);
    }
  },
  queueEvent({ commit }: RootContext, event: SocketEvent<any>) {
    commit('setLastKnownModified', Date.now());
    this.$socketEventAggregator.eventObservable.next(event);
  },
  async joinCloudObjects({ state, dispatch }: RootContext, { objectIds, includeObjectsModifiedSince = true }: { objectIds: ObjectId[]; includeObjectsModifiedSince: boolean }) {
    this.$log.info('join cloud objects', objectIds.map(id => id.toString()));
    if (objectIds.length) {
      await dispatch('waitForSocketConnection', 30000);
      for (const objectId of objectIds) {
        const data: any = {
          objectId: objectId.toString(),
        };
        if (includeObjectsModifiedSince && state.lastKnownModified != null) {
          data.includeObjectsModifiedSince = state.lastKnownModified;
        }
        this.$socket.emit(SocketActions.JOIN_CLOUD_OBJECT, data);
      }
    }
  },
  async waitForSocketConnection({ state }: RootContext, maxWaitTime: number = 25000) {
    const requestTimeout = 200;
    const iterations = maxWaitTime / requestTimeout;
    let waitedForSocketCount = 0;
    while (!state.isConnectedToWebsocket && waitedForSocketCount < iterations) {
      await new Promise(resolve => setTimeout(resolve, requestTimeout));
      waitedForSocketCount++;
    }
  },
  initializeSocketConnection({
    state,
    getters,
    commit,
    dispatch,
  }: RootContext) {
    if (!this.$socket.connected && !connectionIsBeingInitialized) {
      connectionIsBeingInitialized = true;
      setTimeout(() => connectionIsBeingInitialized = false, 5000);
      this.$socket.on(SocketEvents.MUTANT_ORIGIN_CONNECTED, (event: SocketEvent<OriginConnectedEventData>) => {
        if (event.origin !== state.originId) {
          // TODO: list different connected devices / browser tabs
        }
      });
      this.$socket.on(SocketEvents.STALE_OBJECTS, async (event: SocketEvent<string[]>) => {
        this.$log.info('stale objects event received', event);
        await dispatch('cloud/loadStaleObjects', event.data.map(id => new ObjectId(id)));
      });
      this.$socket.on(SocketEvents.SELECTION_ADDED, async (event: SocketEvent<Selection>) => {
        if (event.origin !== state.originId) {
          this.$log.info('selection added event received', event);
          await dispatch('selection/handleSelectionAdded', event.data);
        }
        // TODO: Add event handling for "new selections" indicator in cloud navigation
        // await context.store.dispatch('folder/handleSelectionAdded', data);
      });
      this.$socket.on(SocketEvents.SELECTION_UPDATED, (event: SocketEvent<SelectionUpdatedEventData>) => {
        if (event.origin !== state.originId) {
          this.$log.info('selection updated event received', event);
          event.type = SocketEvents.SELECTION_UPDATED;
          dispatch('queueEvent', event);
        }
        // TODO: Add event handling for "updated selections" indicator in cloud navigation
        // await context.store.dispatch('folder/handleSelectionUpdated', data);
      });
      this.$socket.on(SocketEvents.SELECTION_REMOVED, async (event: SocketEvent<any>) => {
        if (event.origin !== state.originId) {
          this.$log.info('selection removed event received', event);
          await dispatch('selection/handleSelectionRemoved', event.data);
        }
        // TODO: Remove selection from folderSelections should cloud navigation be active
        // await context.store.dispatch('folder/handleSelectionRemoved', data);
      });
      // TODO: Currently this only supports name change, should implement changeSet on api side
      // so more properties can be patched
      this.$socket.on(SocketEvents.ITEMS_CHANGED, async (event: SocketEvent<Folder>) => {
        if (event.origin !== state.originId) {
          for (const item of event.data?.items) {
            this.$log.info('items changed event was triggered', event);
            if (item.item?.name != null) {
              await dispatch('cloud/updateItemNameInternally', { itemId: item?.item?.id, name: item?.item?.name }, { root: true });
            }
          }
        }
      });
      this.$socket.on(SocketEvents.FOLDER_ADDED, async (event: SocketEvent<Folder>) => {
        if (event.origin !== state.originId) {
          this.$log.info('folder added event received', event);
          await dispatch('folder/folderAdded', event.data, { root: true });
        }
      });
      this.$socket.on(SocketEvents.FOLDER_UPDATED, async (event: SocketEvent<FolderUpdatedEventData>) => {
        if (event.origin !== state.originId) {
          this.$log.info('folder updated event received', event);
          await dispatch('folder/handleFolderUpdated', event.data);
        }
      });
      this.$socket.on(SocketEvents.FOLDER_REMOVED, async (event: SocketEvent<string>) => {
        if (event.origin !== state.originId) {
          this.$log.info('folder removed event received', event);
          await dispatch('folder/removeFolder', { folderId: event.data, sync: false }, { root: true });
        }
      });
      this.$socket.on(SocketEvents.MESSAGES_ADDED, async (event: SocketEvent<MessagesAddedEventData>) => {
        if (event.origin !== state.originId) {
          this.$log.info('messages added event received', event);
          await dispatch('user/messagesAddedToConversation', event.data, { root: true });
        }
      });
      this.$socket.on(SocketEvents.USER_JOINED_FOLDER, (event: SocketEvent<UserJoinedFolderEventData>) => {
        if (event.origin !== state.originId && event.data.userId !== getters['user/currentUser'].id) {
          this.$log.info('user joined folder event received', event);
          commit('folder/addActiveUserToFolder', { origin: event.origin, event: event.data });
        }
      });
      this.$socket.on(SocketEvents.USER_JOINED_SELECTION, (event: SocketEvent<UserJoinedSelectionEventData>) => {
        if (event.origin !== state.originId && event.data.userId !== getters['user/currentUser'].id) {
          this.$log.info('user joined selection event received', event);
          commit('selection/addActiveUserToSelection', { origin: event.origin, event: event.data });
        }
      });
      this.$socket.on(SocketEvents.USER_LEFT_FOLDER, (event: SocketEvent<UserLeftFolderEventData>) => {
        if (event.origin !== state.originId && event.data.userId !== getters['user/currentUser'].id) {
          this.$log.info('user left folder event received', event);
          commit('folder/removeActiveUserFromFolder', { origin: event.origin, event: event.data });
        }
      });
      this.$socket.on(SocketEvents.USER_LEFT_SELECTION, (event: SocketEvent<UserLeftSelectionEventData>) => {
        if (event.origin !== state.originId && event.data.userId !== getters['user/currentUser'].id) {
          this.$log.info('user left selection event received', event);
          commit('selection/removeActiveUserFromSelection', { origin: event.origin, event: event.data });
        }
      });
      this.$socket.on(SocketEvents.EMAIL_VERIFIED, async (event: SocketEvent<never>) => {
        if (event.origin !== state.originId) {
          this.$log.info('user email was verified, refresh token and user limits');
          await this.$tokenRefresher.refreshAuthToken();
          await dispatch('user/loadUserLimits');
        }
      });
      this.$socket.on(SocketEvents.USER_ROLE_UPDATED, async () => {
        this.$log.info('user roles have changed ');
        await dispatch('user/refreshToken');
        commit('user/setUserRoleUpgradePending', { isPending: false });
      });
      this.$socket.on(SocketEvents.MESSAGE_ROUTES_UPDATED, (event: SocketEvent<MessageRoutesUpdatedEventData>) => {
        if (event.origin !== state.originId) {
          this.$log.info('message route added event received', event);
          commit('user/setMessageRoutes', event.data.routes);
        }
      });
      this.$socket.on(SocketEvents.ITEMS_ADDED, (event: SocketEvent<ItemsAddedEventData>) => {
        this.$log.info('items added event received', event);
        event.type = SocketEvents.ITEMS_ADDED;
        dispatch('queueEvent', event);
      });
      this.$socket.on(SocketEvents.ASSET_UPLOADED, (event: SocketEvent<Asset>) => {
        this.$log.info('asset uploaded event received', event);
        event.type = SocketEvents.ASSET_UPLOADED;
        dispatch('queueEvent', event);
      });
      this.$socket.on(SocketEvents.ASSET_REPLACED, (event: SocketEvent<Asset>) => {
        this.$log.info('asset replaced event received', event);
        event.type = SocketEvents.ASSET_REPLACED;
        this.$imageLoader.clearCachedAsset(event.data);
        dispatch('queueEvent', event);
      });
      this.$socket.on(SocketEvents.RESIZE_COMPLETED, (event: SocketEvent<ResizeCompletedEventData>) => {
        this.$log.info('resize completed event received', event);
        event.type = SocketEvents.RESIZE_COMPLETED;
        dispatch('queueEvent', event);
      });
      this.$socket.on(SocketEvents.METADATA_COMPLETED, (event: SocketEvent<MetadataCompletedEventData>) => {
        this.$log.info('metadata completed event received', event);
        event.type = SocketEvents.METADATA_COMPLETED;
        dispatch('queueEvent', event);
      });
      this.$socket.on(SocketEvents.CONTACT_STATUS_CHANGED, (data: { userId, status }) => {
        dispatch('user/updateContactOnlineStatus', data);
      });
      this.$socket.on(SocketEvents.UPLOAD_PROGRESS, (event: SocketEvent<UploadProgressEventData>) => {
        this.$log.info('upload progress event received', event);
        dispatch('file/setExternalUploadProgress', event.data);
      });
      this.$socket.on(SocketEvents.CLOUD_OBJECTS_UPDATED, (event: SocketEvent<UpdateCloudObjects>) => {
        if (event.origin !== state.originId) {
          this.$log.info('update cloud objects', event);
          if (event.data.type === FilterType.RECENT) {
            commit('initializeRecentObjectIds', event.data.objectIds.map(o => new ObjectId(o)));
          } else if (event.data.type === FilterType.CUSTOM) {
            commit('initializeCustomObjectIds', event.data.objectIds.map(o => new ObjectId(o)));
          }
        }
      });
      this.$socket.on(SocketEvents.SNAPSHOT_IMAGE_CREATED, (event: SocketEvent<SnapshotImageCreatedEventData>) => {
        this.$log.info('snapshot image created event received', event);
        dispatch('addSnapshotImageFromOrigin', event.data);
      });
    }
    if (!this.$socket.connected) {
      this.$socket.connect();
    }
  },
  cleanSocket() {
    if (this.$socket) {
      this.$socket.offAny();
      this.$socket.disconnect();
    }
  },
};

function isUserRoleUpgradePendingForTooLong() {
  const upgradePendingSince = localStorage.getItem(StorageKey.USER_ROLE_UPGRADE_PENDING_SINCE);
  const upgradePendingSinceParsed = upgradePendingSince ? parseInt(upgradePendingSince) : null;
  // if the update process is stuck for too long we unstuck the user after 30 minutes
  return upgradePendingSinceParsed == null || upgradePendingSinceParsed + THIRTY_MINUTES_IN_MS < Date.now();
}

export function isDeviceMobileOrTablet() {
  let check = false;
  (function(a) {
    // eslint-disable-next-line
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
      check = true;
    }
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function createError(error: any) {
  if (error) {
    if (error.response) {
      if (error.response?.data?.message) {
        return error.response.data;
      }
      return {
        message: 'Unknown error',
        status: error.response?.statusCode,
      };
    }
    return {
      message: error,
      status: 500,
    };
  }
}

export default actions;

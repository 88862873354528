import { AggregatedEvents } from '~/models/socket/aggregateEvents';
import { ItemsAddedEventData } from '~/models/socket/events/ItemsAddedEvent';
import { SocketEvent } from '~/models/socket/events/SocketEvent';

export function aggregateItemsAddedEvent(agg: AggregatedEvents, curr: SocketEvent<ItemsAddedEventData>): AggregatedEvents {
  if (agg.itemsAddedEvents.some(event => event.data.folderId === curr.data.folderId)) {
    agg.itemsAddedEvents = agg.itemsAddedEvents.map(event => {
      if (event.data.folderId === curr.data.folderId) {
        event.data.items = event.data.items.concat(curr.data.items);
      }
      return event;
    });
  } else {
    agg.itemsAddedEvents.push(curr);
  }
  return agg;
}

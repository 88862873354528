import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ItemList } from '~/models/item/ItemList';
import { AssetListBuilder } from '~/models/asset/AssetListBuilder';
import { Asset, AssetWithFolderTag } from '~/models/Asset';

import { Filter } from '~/models/filters/Filter';

export class ItemListBuilder {
  private items: ItemWithPosition[];
  private assetFilters: Filter<Asset | AssetWithFolderTag>[] = [];
  private filters: Filter<ItemWithPosition>[] = [];

  withItems(items: ItemWithPosition[]): this {
    this.items = items;
    return this;
  }

  withFilter(filter: Filter<ItemWithPosition>): this {
    this.filters.push(filter);
    return this;
  }

  withAssetFilter(filter: Filter<Asset | AssetWithFolderTag>) {
    this.assetFilters.push(filter);
    return this;
  }

  build() {
    return new ItemList(
      this.filters
        .reduce((acc, filter) => filter(acc), this.items
          ?.map(item => ({
            ...item,
            item: {
              ...item.item,
              assets: new AssetListBuilder()
                .withAssets(item.item?.assets)
                .withFilters(this.assetFilters)
                .build()
                .assets,
            },
          })) ?? [])
    );
  }
}

import { FileState, Progress, UploadProcessStatus } from '~/store/file/state';
import { QualityConfiguration } from '~/models/thumbnailer/Thumbnailer';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';
import { CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import { FileProcessingPipeline, PipelineInfoLabel } from '~/models/pipeline/FileProcessingPipeline';
import { ItemListBuilder } from '~/models/item/ItemListBuilder';
import { convertPipelineItemToFolderItem } from '~/store/file/actions';
import { RootState } from '~/store/state';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ItemList } from '~/models/item/ItemList';
import { byUsesSubstituteForMissingVersions } from '~/models/asset/filters/byUsesSubstituteForMissingVersions';
import { byHasAssets } from '~/models/item/filters/byHasAssets';
import { byIsNotPresentInMap } from '~/models/asset/filters/byIsNotPresentInMap';
import { byIncludesVersionsOrSubstitutes } from '~/models/asset/filters/byIncludesVersionsOrSubstitutes';
import { buildVersionSubstituteMap } from '~/models/asset/buildVersionSubstituteMap';
import { byIsOffline } from '~/models/asset/filters/byIsOffline';
import { byIsMatched } from '~/models/asset/filters/byIsMatched';
import { byIncludesVersions } from '~/models/asset/filters/byIncludesVersions';
import { byIsSynced } from '~/models/asset/filters/byIsSynced';

export type ItemsMatchedDetails = {
  matchedCount: number;
  matchedCountUploaded: number;
  matchedCountRawFiles: number;
  matchedCountRawFilesUploaded: number;
  matchedSize: string,
  matchedSizeUploaded: string;
  matchedSizeIsEstimated: boolean;
};

export const ESTIMATED_CUSTOM_THUMBNAIL_SIZE = 150_000;
export const ESTIMATED_ORIGINAL_SIZE = 1_000_000;

export const ESTIMATED_RAW_SIZE = 25_000_000;

const getters = {
  isUploadSizeExceedingUploadLimit: (_state: FileState, _getters: any, rootState: RootState) => (uploadSizePending: number): boolean => {
    if (!rootState.user.userLimits) {
      return false;
    }
    if (rootState.user.userLimits.isUploadLimitExceeded) {
      return true;
    }
    return uploadSizePending + rootState.user.userLimits.uploadVolumeUsed > rootState.user.userLimits.uploadVolumeLimit;
  },
  uploadProcessItems: (state: FileState, _getters: any, _rootState: RootState, rootGetters: any) => (versions: number[]): ItemList => {
    if (!state.uploadProcess?.objectId) {
      return new ItemList();
    }
    const viewItemsByIdMap = new Map((rootGetters['folder/folderItemsById'](state.uploadProcess.objectId.toUuid())).map(i => [i.id, i]));
    const items = (state.uploadProcess?.items || []).map(i => viewItemsByIdMap.has(i.id) ? viewItemsByIdMap.get(i.id) : convertPipelineItemToFolderItem(i)) as ItemWithPosition[];
    const itemsAreStillBeingIndexed = items.some(i => !viewItemsByIdMap.has(i.id) || i.item.assets.some(a => a.matchedWith != null));
    let builder = new ItemListBuilder().withItems(items);
    if (state.uploadProcess?.status === UploadProcessStatus.INDEXING && itemsAreStillBeingIndexed) {
      const substituteAssets = [];
      if (versions.includes(CUSTOM_SUB_VERSION)) {
        substituteAssets.push({ version: CUSTOM_SUB_VERSION, size: ESTIMATED_CUSTOM_THUMBNAIL_SIZE, isOffline: true });
      }
      if (versions.includes(ORIGINAL_ASSET_VERSION)) {
        substituteAssets.push({ version: ORIGINAL_ASSET_VERSION, size: ESTIMATED_ORIGINAL_SIZE, isOffline: true });
      }
      builder = builder.withAssetFilter(byUsesSubstituteForMissingVersions(substituteAssets));
    }
    return builder
      .withAssetFilter(byIncludesVersionsOrSubstitutes(buildVersionSubstituteMap(versions)))
      .build();
  },
  selectedPipelineCommands: (state: FileState) => (isUploadAllowed): PipelineCommandInstruction[] => {
    const commands: PipelineCommandInstruction[] = [{ type: PipelineCommandType.CREATE_THUMBNAILS, infoLabel: PipelineInfoLabel.CREATE_THUMBNAILS, waitsForCompletion: false }];
    if (isUploadAllowed) {
      state.pipelineOptions.steps.forEach(step => {
        if (step.isActive) {
          commands.push(step);
        }
      });
    }
    return commands;
  },
  isFileProgress: (state: FileState): boolean => {
    return (state.uploadProgress != null && state.uploadProgress.percentTransferred !== 100)
      || (state.downloadProgress != null && state.downloadProgress.percentTransferred !== 100)
      || (state.externalUploadProgress != null && state.externalUploadProgress.progressInPercent !== 100);
  },
  itemsUploaded: (state: FileState, getters: any, _rootState: RootState): ItemList => {
    const uploadedAssetMap = getters.uploadPendingItems.reduce((assetMap: Map<string, boolean>, item: ItemWithPosition) => {
      item.item.assets.forEach(asset => assetMap.set(asset.id, true));
      return assetMap;
    }, new Map());
    return new ItemListBuilder()
      .withItems(getters.uploadProcessItems(FileProcessingPipeline.commandsToVersions(state.uploadProcess?.pipelineOptions ?? [])).items)
      .withFilter(byHasAssets)
      .withAssetFilter(byIsNotPresentInMap(uploadedAssetMap))
      .build();
  },
  itemsUploadedWithVersion: (state: FileState, getters: any, _rootState: RootState) => (versions: number[]): ItemList => {
    const uploadedAssetMap = getters.uploadPendingItems.reduce((assetMap: Map<string, boolean>, item: ItemWithPosition) => {
      item.item.assets.forEach(asset => assetMap.set(asset.id, true));
      return assetMap;
    }, new Map());
    return new ItemListBuilder()
      .withItems(getters.uploadProcessItems(FileProcessingPipeline.commandsToVersions(state.uploadProcess?.pipelineOptions ?? [])).items)
      .withFilter(byHasAssets)
      .withAssetFilter(byIncludesVersions(versions))
      .withAssetFilter(byIsNotPresentInMap(uploadedAssetMap))
      .build();
  },
  uploadPendingItems: (state: FileState, getters: any, _rootState: RootState): ItemWithPosition[] => {
    if (!state.uploadProcess) {
      return [];
    }
    const relevantAssetVersions = FileProcessingPipeline.commandsToVersions(state.uploadProcess.status === UploadProcessStatus.INDEXING ? FileProcessingPipeline.UPLOAD_COMMANDS : state.uploadProcess.pipelineOptions);
    return new ItemListBuilder()
      .withItems(getters.uploadProcessItems(relevantAssetVersions).items)
      .withFilter(byHasAssets)
      .withAssetFilter(byIsOffline)
      .build()
      .items;
  },
  itemsMatchedDetails: (state: FileState, _getters: any, _rootState: RootState, rootGetters: any): ItemsMatchedDetails => {
    if (state.matchingProcess?.matchedItems?.length > 0) {
      const matchedItemList = new ItemListBuilder()
        .withItems(rootGetters['folder/folderItemsById'](state.matchingProcess.objectId.toUuid()))
        .withFilter(byHasAssets)
        .withAssetFilter(byIsMatched)
        .build();
      const matchedItems = matchedItemList.items;
      const matchedCount = matchedItems.length;
      const matchedItemsUploaded = new ItemListBuilder()
        .withItems(matchedItems)
        .withFilter(byHasAssets)
        .withAssetFilter(byIsSynced)
        .build();
      const matchedRawFiles = new ItemListBuilder()
        .withItems(matchedItems)
        .withFilter(byHasAssets)
        .withAssetFilter(byIncludesVersions([RAW_ASSET_VERSION]))
        .build();
      const matchedRawFilesUploaded = new ItemListBuilder()
        .withItems(matchedItemsUploaded.items)
        .withFilter(byHasAssets)
        .withAssetFilter(byIncludesVersions([RAW_ASSET_VERSION]))
        .build();
      return {
        matchedCount,
        matchedCountUploaded: matchedItemsUploaded.items.length,
        matchedCountRawFiles: matchedRawFiles.items.length,
        matchedCountRawFilesUploaded: matchedRawFilesUploaded.items.length,
        matchedSize: matchedItemList.assetList.sizeToString,
        matchedSizeUploaded: matchedItemsUploaded.assetList.sizeToString,
        matchedSizeIsEstimated: matchedItemList.assetList.assets.some(asset => asset.matchedWith?.isEstimated),
      };
    }
    return {
      matchedCount: 0,
      matchedCountUploaded: 0,
      matchedCountRawFiles: 0,
      matchedCountRawFilesUploaded: 0,
      matchedSize: '0 KB',
      matchedSizeUploaded: '0 KB',
      matchedSizeIsEstimated: true,
    };
  },
  activeProgress: (state: FileState): Progress => {
    return state.uploadProgress && !state.uploadProgress.finished ? state.uploadProgress : state.downloadProgress;
  },
  thumbnailQualityConfig: (state: FileState): QualityConfiguration => {
    return state.thumbnailQualityConfig;
  },
  isActiveUploadThumbnailOption: (state: FileState): boolean => {
    return state.pipelineOptions.steps.some(step => step.type === PipelineCommandType.UPLOAD_THUMBNAILS && step.isActive);
  },
  isActiveUploadOriginalsOption: (state: FileState): boolean => {
    return state.pipelineOptions.steps.some(step => step.type === PipelineCommandType.UPLOAD_ORIGINALS && step.isActive);
  },
  isActiveUploadRawsOption: (state: FileState): boolean => {
    return state.pipelineOptions.steps.some(step => step.type === PipelineCommandType.UPLOAD_RAWS && step.isActive);
  },
};

export default getters;

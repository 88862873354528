import { Asset, AssetWithFolderTag } from '~/models/Asset';
import { AssetList } from '~/models/asset/AssetList';

import { Filter } from '~/models/filters/Filter';

export class AssetListBuilder<T extends Asset | AssetWithFolderTag> {
  private assets: T[] = [];
  private filters: Filter<T>[] = [];

  withAssets(assets: T[] = []): this {
    this.assets = this.assets.concat(assets);
    return this;
  }

  withFilter(filter: Filter<T>): this {
    this.filters.push(filter);
    return this;
  }

  withFilters(filters: Filter<T>[]): this {
    this.filters.push(...filters);
    return this;
  }

  build(): AssetList<T> {
    return new AssetList<T>(this.filters.reduce((acc, filter) => filter(acc), this.assets));
  }
}

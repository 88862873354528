import { MutantContentViewType } from '~/models/views/MutantContentView';

export enum ShortcutType {
  GLOBAL_SELECTION = 'GLOBAL_SELECTION',
  SELECTION = 'SELECTION',
  FOLDER = 'FOLDER',
  USER = 'USER',
  ITEM = 'ITEM',
  DOWNLOAD = 'DOWNLOAD'
}

export function shortcutTypeToContentType(shortcutType: ShortcutType) {
  switch (shortcutType) {
    case ShortcutType.SELECTION:
      return MutantContentViewType.SELECTION;
    case ShortcutType.GLOBAL_SELECTION:
      return MutantContentViewType.GLOBAL_SELECTION;
    case ShortcutType.FOLDER:
      return MutantContentViewType.FOLDER;
    case ShortcutType.USER:
      return MutantContentViewType.USER;
    default:
      return MutantContentViewType.MISC;
  }
}

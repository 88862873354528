import { BackgroundFit } from '~/models/views/BackgroundFit';
import { MutantView } from '~/models/views/MutantView';
import Snippet from '~/models/Snippet';
import { ViewType } from '~/models/views/ViewType';
import { MoodboardBuilder } from '~/models/views/moodboard/MoodboardBuilder';

export class Moodboard implements MutantView {
  public type = ViewType.MOODBOARD;

  constructor(
    public snippets: Snippet[],
    public width: number,
    public height: number,
    public backgroundFit: BackgroundFit,
    public backgroundDimensions: { width: number; height: number },
    public viewOffset: { top: number; left: number }
  ) {}

  setBackgroundFit(backgroundFit: BackgroundFit) {
    this.backgroundFit = backgroundFit;
    this.resize(this.width, this.height);
  }

  calculatePercentageWidthOnBoard(width: number) {
    const { width: backgroundWidth }
      = MoodboardBuilder.calculateNormalizedBackgroundDimensions(
        {
          width: this.width,
          height: this.height,
        },
        this.backgroundDimensions,
        this.backgroundFit
      );
    return width / backgroundWidth;
  }

  calculatePercentagePositionOnBoard(position: { x: number; y: number }) {
    const { width, height }
      = MoodboardBuilder.calculateNormalizedBackgroundDimensions(
        {
          width: this.width,
          height: this.height,
        },
        this.backgroundDimensions,
        this.backgroundFit
      );
    const x = (position.x - this.viewOffset.left) / width;
    const y = (position.y - this.viewOffset.top) / height;
    return {
      x,
      y,
    };
  }

  resize(newWidth: number, newHeight: number) {
    const { width, height, offsetTop, offsetLeft }
      = MoodboardBuilder.calculateNormalizedBackgroundDimensions(
        {
          width: newWidth,
          height: newHeight,
        },
        this.backgroundDimensions,
        this.backgroundFit
      );
    this.snippets = this.snippets.map((snippet) => {
      const snippetHeightToWidthRatio = snippet.height / snippet.width;
      const snippetToItemRatio
        = snippet.width / snippet.item.viewPosition.width;
      const itemHeightToWidthRatio
        = snippet.item.viewPosition.height / snippet.item.viewPosition.width;
      const snippetWidth = width * snippet.item.itemPosition.width;
      snippet.item.setViewPosition({
        width: snippetWidth,
        height: snippetWidth * itemHeightToWidthRatio,
        top: snippet.item.itemPosition.y * height + offsetTop,
        left: snippet.item.itemPosition.x * width + offsetLeft,
      });
      snippet.width = snippet.item.viewPosition.width * snippetToItemRatio;
      snippet.height = snippet.height * snippetHeightToWidthRatio;
      return snippet;
    });
    this.width = newWidth;
    this.height = newHeight;
    this.viewOffset = { left: offsetLeft, top: offsetTop };
  }
}

import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Snippet from '~/models/Snippet';
import SnippetBuilder from '~/models/views/SnippetBuilder';
import { ViewItem } from '~/models/views/ViewItem';
import {
  YOUTUBE_DEFAULT_HEIGHT,
  YOUTUBE_DEFAULT_WIDTH
} from '~/models/item/Item';
import { Grid } from '~/models/views/grid/Grid';
import { getAspectRatio } from '~/models/Asset';

export class GridBuilder {
  private marginBetweenItems: number = 0;
  private marginBetweenVerticalItems = 0;
  private items: ItemWithPosition[] = [];
  private rowHeight: number = 200;
  private viewWidth: number = 500;
  private rowHeightThreshold = 0;
  private rowHeightThresholdPercentage = 10;
  private isContactSheet: boolean = false;

  constructor() {}

  public setItems(items: ItemWithPosition[]): GridBuilder {
    this.items = items;
    return this;
  }

  public setContactSheet(isContactSheet: boolean) {
    this.isContactSheet = isContactSheet;
    return this;
  }

  public setRowHeight(rowHeight: number): GridBuilder {
    this.rowHeight = rowHeight;
    this.rowHeightThreshold = rowHeight * this.rowHeightThresholdPercentage / 100;
    return this;
  }

  public setMarginBetweenItems(margin: number): GridBuilder {
    this.marginBetweenItems = margin;
    if (margin > 0) {
      this.marginBetweenVerticalItems = margin * 0.7;
    }
    return this;
  }

  public setViewWidth(gridWidth: number): GridBuilder {
    this.viewWidth = gridWidth;
    return this;
  }

  public setBaseGrid(grid: Grid): GridBuilder {
    this.setViewWidth(grid.width);
    this.setRowHeight(grid.rowHeight);
    this.setMarginBetweenItems(grid.marginBetweenItems);
    return this;
  }

  public build(): Grid {
    return this.buildPartialGrid(0);
  }

  public buildPartialGrid(startHeight: number): Grid {
    const snippets: Snippet[] = [];
    let rowItems: ItemWithPosition[] = [];
    let currentGridHeightTotal = startHeight;
    for (const item of this.items) {
      // Grid size with another item (because of the added margin there's less room for all items)
      let rowWidthForItemCount = this.calculateRowWidth(
        this.viewWidth,
        rowItems.length + 1
      );
      // the current "best fit" row height for the row with its items
      let currentRowHeight = this.calculateRowHeight(
        [...rowItems, item],
        rowWidthForItemCount
      );
      if (
        rowItems.length
        && currentRowHeight < this.rowHeight - this.rowHeightThreshold
      ) {
        rowWidthForItemCount = this.calculateRowWidth(
          this.viewWidth,
          rowItems.length
        );
        currentRowHeight = this.calculateRowHeight(rowItems, rowWidthForItemCount);
        let adjustedRow = rowItems.map((item) =>
          this.adjustItemSize(item, currentRowHeight)
        );
        const adjustedRowWidth
          = adjustedRow.reduce(
            (a, b) => a + b.viewPosition.width + this.marginBetweenItems,
            0
          ) + this.marginBetweenItems;
        // TODO: if adjustedRowWidth is way larger or smaller, distribute the value across all images that are affected by rounding errors
        if (adjustedRowWidth > rowWidthForItemCount) {
          adjustedRow = adjustedRow.map((r) => {
            r.setViewPositionWidth(
              r.viewPosition.width
                - (adjustedRowWidth - this.viewWidth) / adjustedRow.length
            );
            return r;
          });
        }
        if (adjustedRowWidth < rowWidthForItemCount) {
          adjustedRow = adjustedRow.map((r) => {
            r.setViewPositionWidth(
              r.viewPosition.width
                + (this.viewWidth - adjustedRowWidth) / adjustedRow.length
            );
            return r;
          });
        }
        let leftPosition = this.marginBetweenItems;
        adjustedRow.forEach((item) => {
          item.setViewPositionTop(currentGridHeightTotal);
          item.setViewPositionLeft(leftPosition);
          leftPosition += item.viewPosition.width + this.marginBetweenItems;
        });
        currentGridHeightTotal
          += currentRowHeight + this.marginBetweenVerticalItems;
        snippets.push(
          ...adjustedRow.map((i) => new SnippetBuilder().fromItem(i).build())
        );
        rowItems = [item];
      } else {
        rowItems.push(item);
      }
    }
    if (rowItems.length) {
      // calculate best fit for leftover items
      let leftOverRowHeight = this.calculateRowHeight(rowItems, this.calculateRowWidth(this.viewWidth, rowItems.length));
      if (leftOverRowHeight > this.rowHeight + this.rowHeightThreshold) {
        leftOverRowHeight = this.rowHeight;
      }
      const adjustedRow = rowItems.map((item) => this.adjustItemSize(item, leftOverRowHeight));
      let leftPosition = this.marginBetweenItems;
      adjustedRow.forEach((item) => {
        item.setViewPositionTop(currentGridHeightTotal);
        item.setViewPositionLeft(leftPosition);
        leftPosition += item.viewPosition.width + this.marginBetweenItems;
      });
      snippets.push(
        ...adjustedRow.map((i) => new SnippetBuilder().fromItem(i).build())
      );
      currentGridHeightTotal = currentGridHeightTotal + leftOverRowHeight;
    }
    return new Grid(
      snippets,
      this.viewWidth,
      currentGridHeightTotal,
      this.rowHeight,
      this.marginBetweenItems,
      this.marginBetweenVerticalItems
    );
  }

  private adjustItemSize(
    item: ItemWithPosition,
    currentRowHeight: number
  ): ViewItem {
    const itemWithSizes: ViewItem = new ViewItem(item);
    if (this.isContactSheet) {
      itemWithSizes.setViewPositionWidth(currentRowHeight);
    } else if (itemWithSizes.itemData.type === 2) {
      itemWithSizes.setViewPositionWidth(
        (currentRowHeight * YOUTUBE_DEFAULT_WIDTH) / YOUTUBE_DEFAULT_HEIGHT
      );
    } else {
      const aspectRatio = getAspectRatio(itemWithSizes.itemData);
      let width = currentRowHeight * aspectRatio;
      if (width > this.viewWidth) {
        width = this.viewWidth;
        currentRowHeight = width / aspectRatio;
      }
      itemWithSizes.setViewPositionWidth(width);
    }
    itemWithSizes.setViewPositionHeight(currentRowHeight);
    return itemWithSizes;
  }

  private calculateRowWidth(gridSize: number, itemsInRow: number): number {
    return gridSize - (itemsInRow + 1) * this.marginBetweenItems;
  }

  private calculateRowHeight(row: ItemWithPosition[], gridSize: number) {
    let widthFactor = 0;
    row.forEach((item: ItemWithPosition) => {
      widthFactor += getAspectRatio(item.item);
    });
    return gridSize / widthFactor;
  }
}

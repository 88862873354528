import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { AssetList } from '~/models/asset/AssetList';
import { Asset, AssetWithFolderTag } from '~/models/Asset';

export class ItemList {
  public items: ItemWithPosition[];

  constructor(items: ItemWithPosition[] = []) {
    this.items = items;
  }

  public get assetListWithFolderTags(): AssetList<AssetWithFolderTag> {
    return new AssetList(this.items.reduce((acc, item) => acc.concat(item.item.assets.map(a => ({ ...a, folderTagId: item.item.folderTagId }))), []));
  }

  public get assetList(): AssetList<Asset> {
    return new AssetList(this.items.reduce((acc, item) => acc.concat(item.item.assets), []));
  }
}

import Vue from 'vue';
import { Contact } from '~/models/user/Contact';
import { LinkState, OpenSharedLinkMenuPayload, SharedLinkAccessToken, SharedLinkOptions } from '~/store/link/state';
import { ObjectId } from '~/models/ObjectId';

export default {
  setLinkData(state: LinkState, data: { objectId: string, owner: Contact, options: SharedLinkOptions, created: string, accessId: string, accessToken: SharedLinkAccessToken }) {
    Vue.set(state.links, data.objectId, {
      accessId: data.accessId,
      owner: data.owner,
      objectId: new ObjectId(data.objectId),
      options: data.options,
      created: data.created,
    });
  },
  deleteLinkSuccess(state: LinkState, objectId: string) {
    Vue.delete(state.links, objectId);
  },
  openSharedLinkMenu(state: LinkState, data: OpenSharedLinkMenuPayload) {
    state.menu = {
      ...data,
      isOpen: true,
    };
  },
  closeSharedLinkMenu(state: LinkState) {
    state.menu = {
      isOpen: false,
      selectionId: null,
      folderId: null,
      position: null,
    };
  },
};

import { ObjectId } from '~/models/ObjectId';
import Selection from '~/models/selection/Selection';
import Folder from '~/models/Folder';

export class CloudObject<T extends Folder | Selection> {
  constructor(private _objectId: ObjectId, private _object: T) {
  }

  get id(): string {
    return this._objectId.toUuid();
  }

  get object(): T {
    return this._object;
  }

  get objectId() {
    return this._objectId;
  }

  public get isSelection(): boolean {
    return this.objectId.isSelectionId;
  }

  public get isFolder(): boolean {
    return this.objectId.isFolderId;
  }
}

export type AvailableCloudObject = CloudObject<Selection | Folder>;

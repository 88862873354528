export enum ViewType {
  CONTACT_SHEET = 'CONTACT_SHEET',
  HORIZONTAL = 'HORIZONTAL',
  MOSAIC = 'MOSAIC',
  GRID = 'GRID',
  MOODBOARD = 'MOODBOARD',
}

export function isValidViewType(viewType: string) {
  return Object.keys(ViewType).includes(viewType);
}

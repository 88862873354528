import { ViewSortingOption } from '~/store/cloud/state';
import { MagnifyState, MagnifyViewContent } from '~/store/magnify/state';

export default {
  updateMagnifyHeight: (state: MagnifyState, height) => {
    state.height = height;
  },
  toggleView: (state: MagnifyState) => {
    state.isOpen = !state.isOpen;
  },
  setViewContent: (state: MagnifyState, viewContent: MagnifyViewContent) => {
    state.viewContent = viewContent;
  },
  setMargin: (state: MagnifyState, margin: number) => {
    state.options.margin = margin;
  },
  setMarginFactor: (state: MagnifyState, marginFactor) => {
    state.options.marginFactor = marginFactor;
  },
  setBottomOffset: (state: MagnifyState, bottomOffset: number) => {
    state.bottomOffset = bottomOffset;
  },
  setSortingOption: (state: MagnifyState, sortingOption: ViewSortingOption) => {
    state.sortedBy = sortingOption;
  },
};

import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { BackgroundFit } from '~/models/views/BackgroundFit';
import Snippet from '~/models/Snippet';
import { ViewItem } from '~/models/views/ViewItem';
import SnippetBuilder from '~/models/views/SnippetBuilder';
import { getAspectRatio } from '~/models/Asset';
import { Moodboard } from '~/models/views/moodboard/Moodboard';
import Item from '~/models/item/Item';

export class MoodboardBuilder {
  private items: ItemWithPosition[] = [];
  private viewWidth: number = 500;
  private viewHeight: number = 400;
  private backgroundDimensions: { width: number; height: number } = null;
  private useFullSnippetHeight: boolean = false;
  private backgroundFit: BackgroundFit = BackgroundFit.CONTAIN;

  constructor() {}

  public setItems(items: ItemWithPosition[]): MoodboardBuilder {
    this.items = items;
    return this;
  }

  public setBackgroundFit(backgroundFit: BackgroundFit): MoodboardBuilder {
    this.backgroundFit = backgroundFit;
    return this;
  }

  public setBackgroundDimensions(backgroundDimensions: {
    width: number;
    height: number;
  }): MoodboardBuilder {
    this.backgroundDimensions = backgroundDimensions;
    return this;
  }

  public setViewWidth(viewWidth: number): MoodboardBuilder {
    this.viewWidth = viewWidth;
    return this;
  }

  public setViewHeight(viewHeight: number): MoodboardBuilder {
    this.viewHeight = viewHeight;
    return this;
  }

  public withFullSnippetHeight(value: boolean): MoodboardBuilder {
    this.useFullSnippetHeight = value;
    return this;
  }

  public build(): Moodboard {
    const moodboard: Snippet[] = [];
    this.backgroundDimensions = this.backgroundDimensions
      ? this.backgroundDimensions
      : {
          width: this.viewWidth,
          height: this.viewHeight,
        };
    const { width, height, offsetTop, offsetLeft }
      = MoodboardBuilder.calculateNormalizedBackgroundDimensions(
        {
          width: this.viewWidth,
          height: this.viewHeight,
        },
        this.backgroundDimensions,
        this.backgroundFit
      );
    // Prefill first row of columns
    for (let i = 0; i < this.items.length; i++) {
      const item: ViewItem = new ViewItem(this.items[i]);
      if (item.isPositioned) {
        item.setZindex(item.item.position.zindex);
        let rotation = item.item.position.rotation;
        if (rotation && rotation > 180) {
          rotation = rotation - 360;
        }
        item.setTransform(rotation ? `rotate(${rotation}deg)` : '');
        const itemWidth = item.itemPosition.width * width;
        item.setViewPosition({
          left: item.itemPosition.x * width + offsetLeft,
          top: item.itemPosition.y * height + offsetTop,
          width: itemWidth,
          height: this.getScaledItemHeight(item.itemData, itemWidth),
        });
        const snippet = new SnippetBuilder().fromItem(item).build();
        moodboard.push(snippet);
      }
    }
    return new Moodboard(
      moodboard,
      this.viewWidth,
      this.viewHeight,
      this.backgroundFit,
      this.backgroundDimensions,
      {
        left: offsetLeft,
        top: offsetTop,
      }
    );
  }

  // We calculate the background fit and adjust width / height to build a normalized square out of the moodboard background which is the base for all positioning (x, y) and width placements on the moodboard
  public static calculateNormalizedBackgroundDimensions(
    viewDimensions: { width: number; height: number },
    backgroundDimensions: { width: number; height: number },
    backgroundFit: BackgroundFit
  ) {
    const aspectRatio
      = backgroundDimensions.width / backgroundDimensions.height;
    let height = viewDimensions.width / aspectRatio;
    if (backgroundFit === BackgroundFit.CONTAIN) {
      if (height > viewDimensions.height) {
        height = viewDimensions.height;
      }
    } else if (backgroundFit === BackgroundFit.COVER) {
      if (height < viewDimensions.height) {
        height = viewDimensions.height;
      }
    }
    const width = aspectRatio * height;
    let offsetTop = viewDimensions.height - height;
    offsetTop = offsetTop > 0 ? offsetTop / 2 : 0;
    let offsetLeft = viewDimensions.width - width;
    offsetLeft = offsetLeft > 0 ? offsetLeft / 2 : 0;
    return {
      width,
      height,
      offsetLeft,
      offsetTop,
    };
  }

  private getScaledItemHeight(item: Item, scaleWidth: number): number {
    return scaleWidth / getAspectRatio(item);
  }
}

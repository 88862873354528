import { Contact } from '~/models/user/Contact';
import { ObjectId } from '~/models/ObjectId';

export interface SharedLinkAccessToken {
  token: string;
  expiresIn: number;
}

export interface SharedLinkOptions {
  layoutType: number;
  viewType: number;
  mosaicSpacing: number;
  gridSpacing: number;
  horizontalSpacing: number;
  rowHeight: number;
  columnCount: number;
}

export interface OpenSharedLinkMenuPayload {
  selectionId?: string;
  folderId?: string;
  accessId?: string;
  accessCode?: string;
  position: {
    left: number;
    top: number;
  };
}

export interface SharedLinkMenuData {
  isOpen: boolean;
  selectionId?: string;
  folderId?: string;
  position: {
    left: number;
    top: number;
  };
}

export interface LinkData {
  objectId: ObjectId;
  accessId: string;
  owner: Contact;
  created: string;
  options: SharedLinkOptions;
  type: string;
}

export interface LinkState {
  showShareOverlay: boolean;
  menu: SharedLinkMenuData;
  links: { [objectId: string]: LinkData }
}

export interface SharedLink {
  accessId: string;
  accessToken?: string;
  accessCode?: string;
  created?: string;
  id: string
}

export default (): LinkState => ({
  showShareOverlay: false,
  menu: {
    isOpen: false,
    position: null,
  },
  links: Object.create(null) as { [objectId: string]: LinkData},
});

export enum SocketEvents {
  CONNECT = 'connect',
  CONNECT_ERROR = 'connect_error',
  CONNECT_TIMEOUT = 'connect_timeout',
  DISCONNECT = 'disconnect',
  ERROR = 'error',
  RECONNECT = 'reconnect',
  RECONNECT_ATTEMPT = 'reconnect_attempt',
  RECONNECT_ERROR = 'reconnect_error',
  RECONNECT_FAILED = 'reconnect_failed',
  CONNECTION_ESTABLISHED = 'CONNECTION_ESTABLISHED',
  ITEMS_MOVED = 'ITEMS_MOVED',
  ITEM_REMOVED = 'ITEM_REMOVED',
  ITEMS_REMOVED = 'ITEMS_REMOVED',
  ITEM_MOVED_POSITION = 'ITEM_MOVED_POSITION',
  USER_JOINED_FOLDER = 'USER_JOINED_FOLDER',
  USER_JOINED_SELECTION = 'USER_JOINED_SELECTION',
  USER_LEFT_FOLDER = 'USER_LEFT_FOLDER',
  USER_LEFT_SELECTION = 'USER_LEFT_SELECTION',
  USER_ROLE_UPDATED = 'USER_ROLE_UPDATED',
  FOLDER_JOINED = 'FOLDER_JOINED',
  FOLDER_LEFT = 'FOLDER_LEFT',
  ITEM_ADDED = 'ITEM_ADDED',
  ITEMS_ADDED = 'ITEMS_ADDED',
  ITEMS_CHANGED = 'ITEMS_CHANGED',
  FOLDER_ADDED = 'FOLDER_ADDED',
  FOLDER_UPDATED = 'FOLDER_UPDATED',
  FOLDER_REMOVED = 'FOLDER_REMOVED',
  ASSET_UPLOADED = 'ASSET_UPLOADED',
  ASSET_REPLACED = 'ASSET_REPLACED',
  SELECTION_UPDATED = 'SELECTION_UPDATED',
  SELECTION_ADDED = 'SELECTION_ADDED',
  SELECTION_REMOVED = 'SELECTION_REMOVED',
  MESSAGES_ADDED = 'MESSAGES_ADDED',
  MUTANT_ORIGIN_CONNECTED = 'MUTANT_ORIGIN_CONNECTED',
  ACTIVE_SELECTION_BROADCASTED = 'ACTIVE_SELECTION_BROADCASTED',
  ITEM_HIGHLIGHTED = 'ITEM_HIGHLIGHTED',
  MESSAGE_ROUTES_UPDATED = 'MESSAGE_ROUTES_UPDATED',
  CONTACT_STATUS_CHANGED = 'CONTACT_STATUS_CHANGED',
  RESIZE_COMPLETED = 'RESIZE_COMPLETED',
  METADATA_COMPLETED = 'METADATA_COMPLETED',
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  CLOUD_OBJECTS_UPDATED = 'CLOUD_OBJECTS_UPDATED',
  SNAPSHOT_IMAGE_CREATED = 'SNAPSHOT_IMAGE_CREATED',
  STALE_OBJECTS = 'STALE_OBJECTS',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
}

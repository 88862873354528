import {
  BROWSER_SUPPORTED_IMAGE_TYPES,
  SUPPORTED_FILE_EXTENSIONS,
  SUPPORTED_IMAGE_TYPES
} from '~/models/SupportedImageTypes';
import { MessageType, WarningMessages } from '~/models/MessageTypes';
import { TaggedFile } from '~/models/tags/TaggedFile';
import { getFileMetaData } from '~/models/File';

const accumulateTotalSize = (accumulatedSize: number, file: File) => accumulatedSize + file.size;

export const SUPPORTED_RAW_MIME_TYPES = new Set([
  'image/x-sony-arw',
  'image/x-canon-cr2',
  'image/x-canon-crw',
  'image/x-kodak-dcr',
  'image/x-adobe-dng',
  'image/x-epson-erf',
  'image/x-kodak-k25',
  'image/x-kodak-kdc',
  'image/x-minolta-mrw',
  'image/x-nikon-nef',
  'image/x-olympus-orf',
  'image/x-pentax-pef',
  'image/x-fujifilm-raf',
  'image/x-panasonic-raw',
  'image/x-sony-sr2',
  'image/x-sony-srf',
  'image/x-sigma-x3f',
]);

export const SUPPORTED_RAW_FILE_EXTENSIONS = ['cr2', 'dng', 'arw', 'x3f', 'crw', 'dcr', 'erf', 'k25', 'kdc', 'mrw', 'nef', 'orf', 'pef', 'raf', 'raw', 'sr2', 'srf'];

interface ValidationResult {
  messages: Set<MessageType>
  validatedFiles: TaggedFile[],
}
export interface ValidationParams {
  files: TaggedFile[],
  options?: {
    dataLimitation: boolean,
    sizeTaken: number,
    existingItems: number
  }
}

export class FileValidator {
  public static FILE_AMOUNT_LIMIT = 1000;
  public static FILE_SIZE_LIMIT = 10_000_000_000; // 10 Gbyte
  public static validate({ files, options }: ValidationParams): ValidationResult {
    if (!files?.length) {
      return {
        validatedFiles: [],
        messages: new Set(),
      };
    }
    // eslint-disable-next-line prefer-const
    let { validatedFiles, messages } = FileValidator.validateAndRemoveUnsupportedFiles(files);
    if (options?.dataLimitation) {
      const result = FileValidator.validateAndRemoveLeftOverFiles(options.sizeTaken, options.existingItems, validatedFiles);
      validatedFiles = result.validatedFiles;
      result.messages.forEach(m => messages.add(m));
    }
    return {
      validatedFiles,
      messages,
    };
  }

  public static validateAndRemoveUnsupportedFiles(files: TaggedFile[]): ValidationResult {
    const messages = new Set<MessageType>();
    if (FileValidator.allFilesUnsupported(files)) {
      messages.add(WarningMessages.ALL_FILES_UNSUPPORTED);
      files = [];
    } else if (FileValidator.someFilesUnsupported(files)) {
      messages.add(WarningMessages.UNSUPPORTED_FILES_DETECTED);
      files = FileValidator.removeUnsupportedFiles(files);
    }
    return {
      validatedFiles: files,
      messages,
    };
  }

  public static validateAndRemoveLeftOverFiles(sizeTaken: number, existingItems: number, files: TaggedFile[]): ValidationResult {
    const messages = new Set<MessageType>();
    if (FileValidator.isFileCountLimitReached(files, existingItems)) {
      messages.add(WarningMessages.TOTAL_FILES_LIMIT_REACHED);
      files = files.slice(0, FileValidator.FILE_AMOUNT_LIMIT - existingItems);
    }
    // Currently not in use, but might be needed in the future
    /* else if (FileValidator.isFileSizeExceedingFolderMaxSize(files, sizeTaken)) {
      messages.add(WarningMessages.FILE_SIZE_LIMIT_REACHED);
      files = FileValidator.removeLeftOverFiles(files, sizeTaken);
    } */
    return {
      validatedFiles: files,
      messages,
    };
  }

  public static isTypeSupported(file: File): boolean {
    return Object.values<string>(SUPPORTED_IMAGE_TYPES).includes(file?.type) || FileValidator.isFileExtensionSupported(file);
  }

  public static isBrowserSupportedImageType(file): boolean {
    const { mimeType } = getFileMetaData(file);
    return BROWSER_SUPPORTED_IMAGE_TYPES.includes(mimeType);
  };

  private static isFileExtensionSupported(file: File) {
    const fileExtension = file.name?.split('.')[1]?.toLowerCase();
    return fileExtension != null && SUPPORTED_FILE_EXTENSIONS.includes(fileExtension);
  }

  public static isFileCountLimitReached(files: TaggedFile[], existingItems = 0): boolean {
    return files.length > FileValidator.FILE_AMOUNT_LIMIT - existingItems;
  }

  public static isFileSizeExceedingFolderMaxSize(files: File[], sizeTaken = 0): boolean {
    const sizeInBytes = files.reduce(accumulateTotalSize, 0);
    return sizeTaken + sizeInBytes >= FileValidator.FILE_SIZE_LIMIT;
  }

  public static removeLeftOverFiles(files: File[], sizeTaken = 0): File[] {
    let bytes = 0;
    const index = files.findIndex((file) => {
      bytes += file.size;
      return bytes >= FileValidator.FILE_SIZE_LIMIT - sizeTaken;
    });
    return files.slice(0, index);
  }

  public static removeUnsupportedFiles(files: TaggedFile[]): TaggedFile[] {
    return files.filter(file => FileValidator.isTypeSupported(file.file));
  }

  public static someFilesUnsupported(files: TaggedFile[]): boolean {
    return files.some(file => !FileValidator.isTypeSupported(file.file));
  }

  public static allFilesUnsupported(files: TaggedFile[]): boolean {
    return files.every(file => !FileValidator.isTypeSupported(file.file));
  }

  private static hasRawFileExtension(file) {
    const fileExtension = file.name.split('.')[1]?.toLowerCase();
    return SUPPORTED_RAW_FILE_EXTENSIONS.includes(fileExtension);
  }

  public static isRawFile(file: File): boolean {
    return SUPPORTED_RAW_MIME_TYPES.has(file.type) || FileValidator.hasRawFileExtension(file);
  }
}

export enum PipelineEventType {
  PIPELINE_PROGRESS = 'PIPELINE_PROGRESS',
  PIPELINE_DONE = 'PIPELINE_DONE',
  METADATA_EXTRACTED = 'METADATA_EXTRACTED',
  METADATA_EXTRACTED_PROGRESS = 'METADATA_EXTRACTED_PROGRESS',
  METADATA_EXTRACTED_DONE = 'METADATA_EXTRACTED_DONE',
  THUMBNAILS_CREATED = 'THUMBNAILS_CREATED',
  THUMBNAILS_CREATED_PROGRESS = 'THUMBNAILS_CREATED_PROGRESS',
  THUMBNAILS_CREATED_DONE = 'THUMBNAILS_CREATED_DONE',
  THUMBNAILS_UPLOADED = 'THUMBNAILS_UPLOADED',
  THUMBNAILS_UPLOADED_PROGRESS = 'THUMBNAILS_UPLOADED_PROGRESS',
  THUMBNAILS_UPLOADED_DONE = 'THUMBNAILS_UPLOADED_DONE',
  ORIGINALS_UPLOADED = 'ORIGINALS_UPLOADED',
  ORIGINALS_UPLOADED_DONE = 'ORIGINALS_UPLOADED_DONE',
  ORIGINALS_UPLOADED_PROGRESS = 'ORIGINALS_UPLOADED_PROGRESS',
  RAWS_UPLOADED = 'RAWS_UPLOADED',
  RAWS_UPLOADED_DONE = 'RAWS_UPLOADED_DONE',
  RAWS_UPLOADED_PROGRESS = 'RAWS_UPLOADED_PROGRESS',
}

import { ContextMenuState, ContextMenuType } from '~/store/context/state';

export default {
  openMenu(state: ContextMenuState, { type, data, event }: { type: ContextMenuType, data: any, event: any }) {
    state.menus = state.menus.some(m => m.type === type)
      ? state.menus.map(m => m.type === type
        ? {
            ...m,
            event,
            data,
            isOpen: true,
          }
        : m)
      : [...state.menus, {
          isOpen: true,
          type,
          event,
          data,
        }];
  },
  updateMenuData(state: ContextMenuState, data: { isOpen: boolean; data: any; type: ContextMenuType; }) {
    state.menus = state.menus.map(m => m.type === data.type
      ? {
          ...m,
          isOpen: data.isOpen ?? m.isOpen,
          data: data.data,
        }
      : m);
  },
  closeMenu(state: ContextMenuState, type: ContextMenuType) {
    state.menus = state.menus.map(m => m.type === type ? { ...m, isOpen: false } : m);
  },
  toggleMenu(state: ContextMenuState, { type, data, event }: { type: ContextMenuType, data: any, event: any }) {
    state.menus = state.menus.some(m => m.type === type)
      ? state.menus.map(m => m.type === type
        ? {
            ...m,
            event,
            data,
            isOpen: !m.isOpen,
          }
        : m)
      : [...state.menus, {
          isOpen: true,
          type,
          event,
          data,
        }];
  },
  increaseContextMenuOrderZindex: (state: ContextMenuState) => {
    state.currentZindex++;
  },
};

import state, { RootState } from '~/store/state';
import contextState from '~/store/context/state';
import fileState from '~/store/file/state';
import homepageState from '~/store/homepage/state';
import cloudState from '~/store/cloud/state';
import magnifyState from '~/store/magnify/state';
import linkState from '~/store/link/state';
import selectionState from '~/store/selection/state';
import userState from '~/store/user/state';
import folderState from '~/store/folder/state';
import detailViewState from '~/store/detailView/state';

export const defaultState = (): RootState => ({
  ...state(),
  context: contextState(),
  file: fileState(),
  homepage: homepageState(),
  cloud: cloudState(),
  magnify: magnifyState(),
  link: linkState(),
  selection: selectionState(),
  user: userState(),
  folder: folderState(),
  detailView: detailViewState(),
});

export function isSharedLinkUrl() {
  return new URLSearchParams(window.location.search).has('shared-link-id')
  || window.location.search.includes('shared');
}

export function getCssVariableValue(cssVariableName: string): number {
  return parseInt(getComputedStyle(document.documentElement).getPropertyValue(cssVariableName)?.slice(0, -2) ?? '0', 10);
}

import { Plugin } from '@nuxt/types';
import _throttle from 'lodash.throttle';
import { io } from 'socket.io-client';
import { SocketEvents } from '~/models/socket/SocketEvents';
import { SocketEventAggregator } from '~/models/socket/SocketEventAggregator';
import { WebConfig } from '~/Config';
import { ExtendedContext } from '~/plugins/ExtendedContext';

const socketPlugin: Plugin = ({ $tokenRefresher, store, app, $log }: ExtendedContext, inject: any) => {
  const websocketUrl: string | undefined = WebConfig.WS_URL;
  const connectionOptions: any = {
    transports: ['polling', 'websocket'],
    extraHeaders: {
      'user-origin-id': store.state.originId,
      'include-objects-modified-since': store.state.lastKnownModified,
    },
    query: {
      'client-id': 'mutant-web',
    },
    path: '/socket.io',
    withCredentials: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: Infinity,
    reconnectionDelayMax: 15000,
    timeout: 20000,
    randomizationFactor: 0.5,
    autoConnect: false,
    upgrade: true,
    rememberUpgrade: false,
  };
  const handleSocketError = async (err) => {
    store.commit('setSocketConnection', false);
    if (!app.router.currentRoute.query.baseUrl) {
      if (socket && websocketUrl) {
        if (err.message === 'jwt expired') {
          const sharedLinkAccessId = store.getters['link/linkDataForCurrentCloudObject']?.accessId;
          await $tokenRefresher.refreshExpiredTokens(sharedLinkAccessId);
          // @ts-ignore
          socket.io.opts.extraHeaders = {
            ...socket.io.opts.extraHeaders,
            'user-origin-id': store.state.originId,
          };
          socket.close();
          socket.open();
        }
      }
    }
  };
  const handleSocketErrorThrottled = _throttle(handleSocketError, 2000);

  const socket = io(websocketUrl, connectionOptions);

  socket.on(SocketEvents.ERROR, (err: any) => {
    $log.log('error from socket', err);
    handleSocketErrorThrottled(err);
  });
  socket.on(SocketEvents.RECONNECT_ERROR, (err: any) => {
    $log.log('reconnect_error', err);
    handleSocketErrorThrottled(err);
  });
  socket.on(SocketEvents.CONNECT_ERROR, (err: any) => {
    $log.log('connect_error', err);
    handleSocketErrorThrottled(err);
  });
  socket.on(SocketEvents.DISCONNECT, (err: any) => {
    $log.log('disconnected from socket. Reconnecting...', err);
    handleSocketErrorThrottled(err);
  });
  socket.on(SocketEvents.RECONNECT_FAILED, (err: any) => {
    $log.log('reconnect for socket failed. Retrying...', err);
    handleSocketErrorThrottled(err);
  });
  socket.on(SocketEvents.CONNECT_TIMEOUT, (err: any) => {
    $log.log('connect timeout... retry connecting to socket server', err);
    handleSocketErrorThrottled(err);
  });
  socket.on(SocketEvents.CONNECT, () => {
    // TODO: establish shared link view joins
    $log.log('connection established for user');
    // TODO: implement api v2 change detection for user
    store.dispatch('initializeSocketEventAggregation');
    store.commit('setSocketConnection', true);
    const objectId = store.getters['cloud/currentCloudObject']?.objectId;
    if (objectId) {
      store.dispatch('joinCloudObjects', { objectIds: [objectId], includeLastKnownModified: false }, { root: true });
    }
  });
  socket.on(SocketEvents.RECONNECT, () => {
    $log.log('reconnected');
  });
  inject('socketEventAggregator', new SocketEventAggregator());
  inject('socket', socket);
};

export default socketPlugin;

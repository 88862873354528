import { BackgroundFit } from '~/models/views/BackgroundFit';
import { GridOptions, gridOptionsDefault } from '~/models/views/grid/GridOptions';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { MagnifyOptions, magnifyOptionsDefault } from '~/models/MagnifyOptions';
import { MosaicOptions, mosaicOptionsDefault } from '~/models/views/mosaic/MosaicOptions';
import { ObjectId } from '~/models/ObjectId';
import { isValidViewType, ViewType } from '~/models/views/ViewType';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';
import { NavigationInstruction } from '~/models/views/NavigationInstruction';
import { ContactSheetOptions, contactSheetOptionsDefault } from '~/models/views/contactSheet/ContactSheetOptions';
import { StorageKey } from '~/models/storage/StorageKey';
import { FolderTag } from '~/models/tags/FolderTag';

export function convertMagnifyUserMarginFactorToInternal(userMarginFactor: number): number {
  return MARGIN_FACTOR_GROWTH * userMarginFactor * MAGNIFY_MARGIN_FACTOR_MULTIPLICATOR;
}

export function convertMosaicUserMarginFactorToInternal(userMarginFactor: number): number {
  return MARGIN_FACTOR_GROWTH * userMarginFactor * MOSAIC_MARGIN_FACTOR_MULTIPLICATOR;
}

export function convertGridUserMarginFactorToInternal(userMarginFactor: number): number {
  return MARGIN_FACTOR_GROWTH * userMarginFactor * GRID_MARGIN_FACTOR_MULTIPLICATOR;
}

export const MARGIN_FACTOR_GROWTH = 1.75;
export const MOSAIC_MARGIN_FACTOR_MULTIPLICATOR = 0.01;
export const GRID_MARGIN_FACTOR_MULTIPLICATOR = 0.01;
export const MAGNIFY_MARGIN_FACTOR_MULTIPLICATOR = 0.01;

export interface HighlightInfo {
  item: ItemWithPosition;
  originView: string;
  targetView: string;
  scroll?: boolean;
}

export enum TagState {
  'CLEAR_TAGS' = 'CLEAR TAGS',
  'NO_TAGS' = 'NO TAGS',
  'FILTER' = 'FILTER'
}

export interface FolderTagFilter {
  state: TagState,
  tags: FolderTag[],
}

export enum ViewSortingOption {
  ALPHABETICAL_ASC = 'ALPHABETICAL_ASC',
  ALPHABETICAL_DESC = 'ALPHABETICAL_DESC',
  LAST_MODIFIED_DESC = 'LAST_MODIFIED_DESC',
  LAST_MODIFIED_ASC = 'LAST_MODIFIED_ASC',
  CUSTOM_ORDER = 'CUSTOM_ORDER'
}

export interface ViewOptions {
  activeViewType: ViewType;
  mosaic: MosaicOptions;
  magnify: MagnifyOptions;
  grid: GridOptions;
  contactSheet: ContactSheetOptions;
}

export enum FilterOption {
  NO_RATING = 0,
  ONE_STAR = 1,
  TWO_STARS = 2,
  THREE_STARS = 3,
  FOUR_STARS = 4,
  FIVE_STARS = 5,
  NO_FILTER = 6,
  ORIGINALS = 7,
  RAWS = 8,
  SELECTION = 9,
  UPLOAD_PENDING = 10,
}
export const STAR_RATING = [
  FilterOption.ONE_STAR,
  FilterOption.TWO_STARS,
  FilterOption.THREE_STARS,
  FilterOption.FOUR_STARS,
  FilterOption.FIVE_STARS,
];
export type ReviewFilter = Array<FilterOption>;

export interface MutantWindow {
  id: string;
  viewIds: ObjectId[];
  viewOptions: ViewOptions;
  colorFilter: number[] | null;
  sortedBy: ViewSortingOption;
  reviewFilter: ReviewFilter;
  folderTagFilter: FolderTagFilter;
  isReviewFilterActive: boolean;
  filterForActiveSelections: boolean;
  backgroundOpacity: number;
  elementsOpacity: number;
  backgroundFit: BackgroundFit;
  isInNavigationMode: boolean;
}

export const viewOptionsDefault = () => {
  return {
    activeViewType: getCurrentViewType(),
    grid: gridOptionsDefault(),
    mosaic: mosaicOptionsDefault(),
    magnify: magnifyOptionsDefault(),
    contactSheet: contactSheetOptionsDefault(),
  };
};

function getCurrentViewType(): ViewType {
  const documentURI = new URL(window.location.href).searchParams;
  const view = documentURI.get('view');
  if (view && typeof view === 'string' && isValidViewType(view.toUpperCase())) {
    return view.toUpperCase() as ViewType;
  } else if (window.location.href.includes('shared')) {
    return ViewType.HORIZONTAL;
  }
  return ViewType.MOSAIC;
}

export const viewOptionsDefaultNavigationLane = () => {
  const defaults = viewOptionsDefault();
  defaults.magnify = {
    margin: 10,
    marginFactor: 10,
    marginFactorMin: 0,
    marginFactorMax: 10,
    marginInterval: 1,
  };
  return defaults;
};

export const mutantWindowDefault = (id: ViewIdentifier) => {
  return {
    id,
    viewIds: [],
    viewOptions: viewOptionsDefault(),
    colorFilter: null,
    reviewFilter: [],
    folderTagFilter: { state: TagState.CLEAR_TAGS, tags: [] } as FolderTagFilter,
    sortedBy: localStorage.getItem(StorageKey.SORTED_BY) ? ViewSortingOption[localStorage.getItem(StorageKey.SORTED_BY)] : ViewSortingOption.CUSTOM_ORDER,
    filterForActiveSelections: false,
    isReviewFilterActive: false,
    backgroundOpacity: initializeBackgroundOpacity(id),
    elementsOpacity: initializeElementsOpacity(id),
    backgroundFit: BackgroundFit.CONTAIN,
    isInNavigationMode: false,
  };
};

function initializeElementsOpacity(viewId: string) {
  if (viewId === ViewIdentifier.MAIN_VIEW) {
    return localStorage.getItem(StorageKey.MAIN_VIEW_ELEMENTS_OPACITY) ? parseFloat(localStorage.getItem(StorageKey.MAIN_VIEW_ELEMENTS_OPACITY)) : 0.5;
  } else if (viewId === ViewIdentifier.SIDE_PANE) {
    return localStorage.getItem(StorageKey.SIDE_PANE_ELEMENTS_OPACITY) ? parseFloat(localStorage.getItem(StorageKey.SIDE_PANE_ELEMENTS_OPACITY)) : 0.5;
  }
}

function initializeBackgroundOpacity(viewId: ViewIdentifier) {
  let opacity = 1;
  if (viewId === ViewIdentifier.MAIN_VIEW) {
    opacity = localStorage.getItem(StorageKey.MAIN_VIEW_BACKGROUND_OPACITY) ? parseFloat(localStorage.getItem(StorageKey.MAIN_VIEW_BACKGROUND_OPACITY)) : 0.8;
  } else if (viewId === ViewIdentifier.SIDE_PANE) {
    opacity = localStorage.getItem(StorageKey.SIDE_PANE_BACKGROUND_OPACITY) ? parseFloat(localStorage.getItem(StorageKey.SIDE_PANE_BACKGROUND_OPACITY)) : 0.5;
  }
  document.documentElement.style.setProperty(`--${viewId.toLowerCase()}-background-opacity`, opacity.toString());
  return opacity;
}

export interface CloudState {
  registeredWindows: { [key in ViewIdentifier]?: MutantWindow; };
  highlightInfo: HighlightInfo | null;
  showInImmersionMode: boolean;
  illuminationGrade: number;
  centeredItemInView: { [key in ViewIdentifier]?: number };
  navigationInstruction: NavigationInstruction | null;
  activeWindowId: ViewIdentifier;
}

export default (): CloudState => ({
  registeredWindows: {
    [ViewIdentifier.MAIN_VIEW]: mutantWindowDefault(ViewIdentifier.MAIN_VIEW),
    [ViewIdentifier.SIDE_PANE]: mutantWindowDefault(ViewIdentifier.SIDE_PANE),
    [ViewIdentifier.GLOBAL_SELECTION_VIEW]: {
      ...mutantWindowDefault(ViewIdentifier.GLOBAL_SELECTION_VIEW),
      viewIds: [ObjectId.forGlobalSelection()],
      viewOptions: { ...viewOptionsDefault(), activeViewType: ViewType.HORIZONTAL },
    },
    [ViewIdentifier.NAVIGATION_VIEW]: {
      ...mutantWindowDefault(ViewIdentifier.NAVIGATION_VIEW),
      viewOptions: { ...viewOptionsDefaultNavigationLane(), activeViewType: ViewType.HORIZONTAL },
      isInNavigationMode: true,
    },
    [ViewIdentifier.OVERLAY_VIEW]: {
      ...mutantWindowDefault(ViewIdentifier.OVERLAY_VIEW),
      viewOptions: { ...viewOptionsDefault(), activeViewType: ViewType.HORIZONTAL },
    },
  },
  highlightInfo: null as HighlightInfo,
  showInImmersionMode: localStorage.getItem(StorageKey.MAIN_IMMERSION_MODE) ? localStorage.getItem(StorageKey.MAIN_IMMERSION_MODE) === 'true' : true,
  illuminationGrade: localStorage.getItem(StorageKey.MAIN_ILLUMINATION_GRADE) ? parseInt(localStorage.getItem(StorageKey.MAIN_ILLUMINATION_GRADE)) : 0,
  centeredItemInView: Object.create(null) as { [key in ViewIdentifier]?: number },
  navigationInstruction: null,
  activeWindowId: ViewIdentifier.MAIN_VIEW,
});

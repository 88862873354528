import { UploadProgressEventData } from '~/models/socket/events/UploadProgressEventData';
import { FileState, MatchingProcess, Progress, UploadProcess } from '~/store/file/state';
import { QualityConfiguration } from '~/models/thumbnailer/Thumbnailer';
import { PipelineCommandInstruction } from '~/models/pipeline/PipelineCommandInstruction';
import { StorageKey } from '~/models/storage/StorageKey';
import { MatchingResult } from '~/models/file/FileMatcher';

export default {
  setPipelineSteps: (state: FileState, pipelineSteps: PipelineCommandInstruction[]) => {
    state.pipelineOptions = {
      ...state.pipelineOptions,
      steps: pipelineSteps,
    };
    localStorage.setItem(StorageKey.PIPELINE_OPTIONS, JSON.stringify(state.pipelineOptions));
  },
  setExternalUploadProgress: (state: FileState, progress: UploadProgressEventData) => {
    state.externalUploadProgress = progress;
  },
  prepareUpload: (state: FileState) => {
    state.currentlyPreparingUpload = true;
  },
  uploadPrepared: (state: FileState) => {
    state.currentlyPreparingUpload = false;
  },
  setUploadProcess: (state: FileState, value: UploadProcess) => {
    state.uploadProcess = value;
  },
  setMatchingProcess: (state: FileState, value: MatchingProcess) => {
    state.matchingProcess = value;
  },
  setMatchedItemsAndOptions: (state: FileState, { pipelineOptions, pipelineItems }: MatchingResult) => {
    state.matchingProcess = {
      ...state.matchingProcess,
      pipelineOptions,
      matchedItems: pipelineItems,
    };
  },
  removeItemsFromPipeline: (state: FileState, itemIds: string[]) => {
    if (state.uploadProcess?.items != null) {
      state.uploadProcess.items = state.uploadProcess.items.filter(i => !itemIds.includes(i.id));
    }
  },
  addFilesForMatchingToUploadProcess: (state: FileState, addedData: MatchingProcess) => {
    state.matchingProcess = {
      ...state?.matchingProcess,
      hasRawFiles: state?.matchingProcess?.hasRawFiles || addedData.hasRawFiles,
      filesForMatching: [...state.matchingProcess?.filesForMatching ?? [], ...addedData.filesForMatching],
    };
  },
  updateUploadProgress: (state: FileState, progress: Progress) => {
    if (state.uploadProgress) {
      state.uploadProgress = {
        ...state.uploadProgress,
        ...progress,
      };
      if (state.uploadProgress.errors && progress.errors) {
        state.uploadProgress.errors = [...state.uploadProgress.errors, ...progress.errors];
      }
    } else {
      state.uploadProgress = progress;
    }
  },
  abortUploadSuccess: (state: FileState) => {
    state.abortNextUpload = false;
    state.uploadProgress = {
      ...state.uploadProgress,
      aborted: true,
    };
  },
  dismissUpload: (state: FileState) => {
    state.uploadProgress = null;
  },
  abortUpload: (state: FileState) => {
    state.abortNextUpload = true;
  },
  abortDownloadSuccess: (state: FileState) => {
    state.abortNextDownload = false;
    state.downloadProgress = {
      ...state.downloadProgress,
      aborted: true,
    };
  },
  dismissDownload: (state: FileState) => {
    state.downloadProgress = null;
  },
  updateDownloadProgress: (state: FileState, downloadProgress: Progress) => {
    const existingErrors = state.downloadProgress ? state.downloadProgress.errors : [];
    const newErrors = downloadProgress.errors ? downloadProgress.errors : [];
    state.downloadProgress = {
      ...state.downloadProgress,
      ...downloadProgress,
      errors: [...existingErrors, ...newErrors],
    };
  },
  abortDownload: (state: FileState) => {
    state.abortNextDownload = true;
  },
  thumbnailQualityConfig: (state: FileState, qualityConfiguration: QualityConfiguration) => {
    state.thumbnailQualityConfig = { ...state.thumbnailQualityConfig, ...qualityConfiguration };
    localStorage.setItem(StorageKey.THUMBNAIL_CONFIG, JSON.stringify(state.thumbnailQualityConfig));
  },
};

import { Asset } from '~/models/Asset';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ViewIdentifier } from '~/models/views/ViewIdentifier';

export interface DetailViewState {
  isOpen: boolean;
  isOpenAnimationFinished: boolean
  previousContent: string;
  content: string;
  nextContent: string;
  currentItem: ItemWithPosition | null;
  currentAsset: Asset | null;
  showAssetDetails: boolean;
  linkedViewId: ViewIdentifier;
}
export type NextDetailViewState = Omit<DetailViewState, 'isOpen' | 'showAssetDetails' | 'linkedViewId' | 'isOpenAnimationFinished'>;

export const INITIAL_STATE: DetailViewState = {
  isOpen: false,
  isOpenAnimationFinished: false,
  previousContent: '',
  content: '',
  nextContent: '',
  currentItem: null,
  currentAsset: null,
  showAssetDetails: false,
  linkedViewId: null,
};

export default (): DetailViewState => INITIAL_STATE;

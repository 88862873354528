import { Component, Vue, Watch } from 'nuxt-property-decorator';
import throttle from 'lodash.throttle';

@Component({})
export class ResizableWindow extends Vue {
  private throttledResizeHandler = null;
  private resizeObserver: ResizeObserver;
  private cachedWindowSize: { width: number; height: number } = null;

  constructor() {
    super();
  }

  mounted() {
    this.throttledResizeHandler = throttle(this.handleResize, 200, {
      leading: true,
    });
    this.registerResizeObserver();
    this.handleResize();
  }

  beforeDestroy() {
    this.resizeObserver?.unobserve(this.$el);
  }

  private registerResizeObserver() {
    // @ts-ignore
    if (ResizeObserver) {
      // @ts-ignore
      this.resizeObserver = new ResizeObserver(this.throttledResizeHandler);
      this.resizeObserver?.observe(this.$el);
    }
  }

  public reRegisterResizeObserver() {
    this.resizeObserver?.observe(this.$el);
  }

  public unregisterResizeObserver() {
    this.resizeObserver?.unobserve(this.$el);
  }

  public get isLayoutDragged(): boolean {
    return this.$store.state.isLayoutDragged;
  }

  public get isAdjustWindowsToDrag(): boolean {
    return this.$store.state.adjustWindowsToDrag;
  }

  public currentSize() {
    const height = this.$el?.clientHeight;
    const width = this.$el?.clientWidth;
    return { width, height };
  }

  private handleResize() {
    if (!this.cachedWindowSize || this.isAdjustWindowsToDrag) {
      this.emitSize();
    }
  }

  private emitSize() {
    const currentSize = this.currentSize();
    if (currentSize.width > 0 && currentSize.height > 0) {
      this.$emit('size', this.currentSize());
    }
  }

  @Watch('isLayoutDragged', { immediate: false })
  watchLayoutDragged(isDraggedNew, isDraggedOld) {
    if (isDraggedNew !== isDraggedOld) {
      if (isDraggedNew) {
        const currentSize = this.currentSize();
        this.cachedWindowSize = currentSize.width > 0 ? this.currentSize() : this.cachedWindowSize;
      } else {
        this.cachedWindowSize = null;
      }
      this.emitSize();
    }
  }
}

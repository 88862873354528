import { Plugin } from '@nuxt/types';
import JSZip from 'jszip';

const jsZip: Plugin = (_context: any, inject: any) => {
  inject('jsZip', {
    createInstance: (): JSZip => new JSZip(),
  });
};
export default jsZip;

export interface JSZipFactory {
  createInstance: () => JSZip;
}

import { PipelineEventProcessedStatus, PipelineItem } from '~/models/PipelineItem';
import * as FileUtil from '~/models/File';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { PipelineEventType } from '~/models/pipeline/PipelineEventType';
import { CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import { FileValidator } from '~/models/FileValidator';
import { TaggedFile } from '~/models/tags/TaggedFile';

interface FileMatchingParams {
  pipelineId: string,
  existingItems: ItemWithPosition[],
  filesForMatching: File[],
  replaceExistingFiles: boolean,
  pipelineOptions: PipelineCommandType[],
}

export interface MatchingResult {
  pipelineOptions: PipelineCommandType[],
  pipelineItems: PipelineItem[],
}
export class FileMatcher {
  static match({ pipelineId, existingItems, filesForMatching, pipelineOptions, replaceExistingFiles }: FileMatchingParams): PipelineItem[] {
    if (pipelineOptions.length === 0) {
      return [];
    }
    const filesForMatchingMap = FileMatcher.buildFileMap(filesForMatching);
    return existingItems
      .filter(item => filesForMatchingMap.has(FileUtil.removeFileExtension(item.item.name)) && FileMatcher.itemMatchesWithPipelineOptions(item, pipelineOptions, replaceExistingFiles))
      .map(item => FileMatcher.buildPipelineItemForMatchedFiles(pipelineId, item, filesForMatchingMap.get(FileUtil.removeFileExtension(item.item.name)), pipelineOptions, replaceExistingFiles));
  }

  static ignoreExistingFiles(existingItems: ItemWithPosition[], filesForMatching: TaggedFile[]): TaggedFile[] {
    const existingNameMap = FileMatcher.buildExistingNameMap(existingItems);
    return filesForMatching
      .filter((file) => !existingNameMap.has(file.file.name));
  }

  static getDuplicateFiles(existingItems: ItemWithPosition[], filesForMatching: TaggedFile[]): Map<string, string> {
    const filesForMatchingMap = FileMatcher.buildFileMap(filesForMatching.map(f => f.file));
    return new Map(existingItems
      .filter(item => filesForMatchingMap.has(FileUtil.removeFileExtension(item.item.name)))
      .map(i => ([i.item.name, i.item.id])));
  }

  private static buildPipelineItemForMatchedFiles(pipelineId: string, item: ItemWithPosition, files: File[], pipelineOptions: PipelineCommandType[], replaceExistingFiles: boolean): PipelineItem {
    const pipelineItem = FileMatcher.buildPipelineItem(pipelineId, item, replaceExistingFiles, pipelineOptions);
    files.forEach(file => {
      if (FileValidator.isRawFile(file)) {
        pipelineItem.raw = { file };
      } else if (FileValidator.isBrowserSupportedImageType(file)) {
        pipelineItem.file = file;
      }
    });
    return pipelineItem;
  }

  private static itemMatchesWithPipelineOptions(item: ItemWithPosition, pipelineOptions: PipelineCommandType[], replaceExistingFiles: boolean): boolean {
    if (replaceExistingFiles) {
      return true;
    }
    return !this.pipelineOptionsToVersions(pipelineOptions).every(version => item.item.assets.some(asset => asset.version === version));
  }

  private static buildExistingNameMap(items: ItemWithPosition[]): Set<string> {
    return new Set(items.map(item => item.item.name));
  }

  private static buildFileMap(files: File[]): Map<string, File[]> {
    const result = new Map<string, File[]>();
    files.forEach(file => {
      const { name } = FileUtil.getFileMetaData(file);
      if (result.has(name)) {
        result.set(name, [...result.get(name), file]);
      } else {
        result.set(name, [file]);
      }
    });
    return result;
  }

  private static pipelineOptionsToVersions (pipelineOptions: PipelineCommandType[]) {
    return pipelineOptions.map(option => {
      if (option === PipelineCommandType.UPLOAD_ORIGINALS) {
        return ORIGINAL_ASSET_VERSION;
      } else if (option === PipelineCommandType.UPLOAD_THUMBNAILS) {
        return CUSTOM_SUB_VERSION;
      } else if (option === PipelineCommandType.UPLOAD_RAWS) {
        return RAW_ASSET_VERSION;
      }
      return ORIGINAL_ASSET_VERSION;
    });
  }

  private static buildPipelineItem(pipelineId: string, item: ItemWithPosition, replaceThumbs: boolean, commands: PipelineCommandType[]) {
    const eventsProcessed = FileMatcher.getEventsProcessed(item);
    const doesNotOverwriteExistingOriginal = !commands.includes(PipelineCommandType.UPLOAD_THUMBNAILS)
      || (commands.includes(PipelineCommandType.UPLOAD_THUMBNAILS) && !eventsProcessed.some(e => e.type === PipelineEventType.ORIGINALS_UPLOADED));
    const pipelineItem: PipelineItem = {
      folderId: item.item.folderId,
      pipelineId,
      id: item.item.id,
      eventsProcessed: replaceThumbs ? [] : eventsProcessed,
      replaceThumbs: replaceThumbs && doesNotOverwriteExistingOriginal && (commands.includes(PipelineCommandType.UPLOAD_ORIGINALS) || commands.includes(PipelineCommandType.UPLOAD_THUMBNAILS)),
      eventsToProcess: commands,
    };
    return pipelineItem;
  }

  private static getEventsProcessed(item: ItemWithPosition) {
    const originalAsset = item.item.assets.find(a => a.version === ORIGINAL_ASSET_VERSION);
    const thumbnailAsset = item.item.assets.find(a => a.version === CUSTOM_SUB_VERSION);
    const rawAsset = item.item.assets.find(a => a.version === RAW_ASSET_VERSION);
    const eventsProcessed: PipelineEventProcessedStatus[] = [];
    if (thumbnailAsset || originalAsset) {
      if (thumbnailAsset && !thumbnailAsset.isOffline) {
        eventsProcessed.push({ type: PipelineEventType.THUMBNAILS_UPLOADED });
      }
    }
    if (originalAsset && !originalAsset.isOffline) {
      eventsProcessed.push({ type: PipelineEventType.ORIGINALS_UPLOADED });
    }
    if (rawAsset && !rawAsset.isOffline) {
      eventsProcessed.push({ type: PipelineEventType.RAWS_UPLOADED });
    }
    return eventsProcessed;
  }
}

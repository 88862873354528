import { ItemWithPosition } from '~/models/item/ItemWithPosition';

export class ResizeItemEvent {
  constructor(
    public item: ItemWithPosition,
    public width: number,
    public rotation: number,
    public x: number,
    public y: number) {
  }
}

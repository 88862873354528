import Vue from 'vue';
import _cloneDeep from 'lodash.clonedeep';
import { Asset, CUSTOM_SUB_VERSION, ORIGINAL_ASSET_VERSION, RAW_ASSET_VERSION } from '~/models/Asset';
import { ItemsRemovedEventData } from '~/models/socket/events/ItemsRemovedEvent';
import { MetadataCompletedEventData } from '~/models/socket/events/MetadataCompletedEventData';
import { UserJoinedFolderEventData } from '~/models/socket/events/UserJoinedFolderEventData';
import { UserLeftFolderEventData } from '~/models/socket/events/UserLeftFolderEventData';
import Folder from '~/models/Folder';
import { FolderItem } from '~/models/item/FolderItem';
import Item from '~/models/item/Item';
import { ResizeCompletedEventData } from '~/models/ResizeCompletedEventData';
import { FolderState } from '~/store/folder/state';
import { ViewType } from '~/models/views/ViewType';
import { ChangeSet, mergeChangeSet } from '~/models/ChangeSet';
import { PipelineItem } from '~/models/PipelineItem';
import { convertPipelineItemToFolderItem } from '~/store/file/actions';
import { FolderTag } from '~/models/tags/FolderTag';
import { filterByConditions } from '~/models/utility/filterByConditions';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';
import { FileProcessingPipeline } from '~/models/pipeline/FileProcessingPipeline';
import { AssetListBuilder } from '~/models/asset/AssetListBuilder';
import { byIncludesVersions } from '~/models/asset/filters/byIncludesVersions';
import { ESTIMATED_CUSTOM_THUMBNAIL_SIZE, ESTIMATED_ORIGINAL_SIZE, ESTIMATED_RAW_SIZE } from '~/store/file/getters';
import { Owner } from '~/models/selection/Owner';

export default {
  addActiveUserToFolder(
    state: FolderState,
    { origin, event }: { origin: string; event: UserJoinedFolderEventData }
  ) {
    const folder = state.folders[event.folderId];
    Vue.set(state.folders, event.folderId,
      {
        ...folder,
        activeUsers: folder.activeUsers
          ? [...folder.activeUsers, { origin, id: event.userId }]
          : [
              {
                origin,
                id: event.userId,
              },
            ],
      });
  },
  removeActiveUserFromFolder(
    state: FolderState,
    { origin, event }: { origin: string; event: UserLeftFolderEventData }
  ) {
    const folder = state.folders[event.folderId];
    Vue.set(state.folders, event.folderId,
      {
        ...folder,
        activeUsers: folder.activeUsers.filter(
          (u) => !(u.id === event.userId && u.origin === origin)
        ),
      });
  },
  updateNameSuccess(
    state: FolderState,
    { folderId, name }: { folderId: string; name: string }
  ) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        name,
      });
  },
  updateSubtitleSuccess(
    state: FolderState,
    { folderId, subtitle }: { folderId: string; subtitle: string }
  ) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        subtitle,
      });
  },
  updateDescriptionSuccess(
    state: FolderState,
    { folderId, description }: { folderId: string; description: string }
  ) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        description,
      });
  },
  updateViewType(
    state: FolderState,
    { folderId, viewType }: { folderId: string; viewType: ViewType }
  ) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        viewType,
      });
  },
  createFolder: (state: FolderState, folder: Folder) => {
    Vue.set(state.folders, folder.id,
      {
        ...folder,
        itemCount: 0,
      });
  },
  setFolderTagsSynced: (
    state: FolderState,
    { folderId, tagIds }: { folderId: string, tagIds: string[] }
  ) => {
    const allTags = state.folders[folderId]?.tags?.map(t => { return tagIds.includes(t.id) ? { ...t, isSynced: true } : t; }) ?? [];
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId, { ...folder, tags: allTags });
  },
  setFolderIsSynced: (
    state: FolderState,
    { isSynced, folderId }: { isSynced: boolean; folderId: string }
  ) => {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        isSynced,
      });
  },
  loadFolder: (state: FolderState) => {
    state.isLoading = true;
  },
  loadFoldersSuccess: (state: FolderState, folders: Folder[]) => {
    for (const folder of folders) {
      if (folder.items) {
        for (const folderItem of folder.items) {
          const item = folderItem.item;
          if (item) {
            state.items.set(item.id, Object.freeze({ ...item, isSynced: true }));
          }
        }
        Vue.set(state.folderItems, folder.id, folder.items);
      }
      Vue.set(state.folders, folder.id, { ...folder, tags: folder.tags?.map(t => ({ ...t, isSynced: true })) ?? [], items: [], isSynced: true });
    }
  },
  setFolderItemChangeSet(state: FolderState, { folderId, changeSet }: { folderId: string; changeSet: ChangeSet<FolderItem>; }) {
    state.folderItemChangeSets[folderId] = changeSet;
  },
  loadFolderSuccess: (state: FolderState, folder: Folder) => {
    folder.isSynced = true;
    const oldFolder = state.folders[folder.id];
    Vue.set(state.folders, folder.id,
      {
        ...oldFolder,
        ...folder,
        tags: [...oldFolder?.tags ?? [], ...folder.tags?.filter(t => !oldFolder?.tags.some(ft => ft.id === t.id)).map(t => ({ ...t, isSynced: true })) ?? []],
        itemCount: folder.itemCount ?? folder.items?.length,
        items: [],
      });
    const folderItems = folder.items
      .map((item) => {
        const syncedItem = {
          ...item,
          itemId: item.itemId || item.item.id,
          item: {
            ...item.item,
            isSynced: true,
          },
        };
        state.items.set(syncedItem.itemId, Object.freeze(syncedItem.item));
        return syncedItem;
      });
    Vue.set(state.folderItems, folder.id, folderItems);
    state.isLoading = false;
  },
  removeFolder: (state: FolderState, removedFolderId: string) => {
    Vue.delete(state.folders, removedFolderId);
    state.folderItems[removedFolderId] = [];
  },
  buildRawItemChanges: (state: FolderState, { itemId, changeSet }: { itemId: string, changeSet: ChangeSet<Item> }) => {
    state.rawItemsChangeSets[itemId] = state.rawItemsChangeSets[itemId] != null ? mergeChangeSet(state.rawItemsChangeSets[itemId], changeSet) : changeSet;
  },
  setRawItemChangeSet: (state: FolderState, { itemId, changeSet }: { itemId: string, changeSet: ChangeSet<Item> }) => {
    state.rawItemsChangeSets[itemId] = changeSet;
  },
  removeRawItemChangeSet: (state: FolderState) => {
    state.rawItemsChangeSets = {};
  },
  setFolderTagsForFolder: (state: FolderState, { folderId, folderTags }: { folderId: string, folderTags: FolderTag[] }) => {
    if (folderId != null && folderTags?.length > 0) {
      const folder = state.folders[folderId];
      const newFolderTags = folderTags.filter(t => !folder.tags.some(ft => ft.id === t.id));
      Vue.set(state.folders, folderId, { ...folder, tags: folder?.tags != null ? [...folder.tags, ...newFolderTags] : [...folderTags] });
    }
  },
  addToConflictIds: (state: FolderState, id: string) => {
    state.conflictIds.push(id);
  },
  applyItemChanges: (state: FolderState, changeSet: ChangeSet<Item>) => {
    if (changeSet.removals) {
      for (const itemId of changeSet.removals) {
        state.items.delete(itemId);
      }
    }
    if (changeSet.inserts) {
      for (const item of changeSet.inserts) {
        state.items.set(item.id, Object.freeze(item));
      }
    }
    if (changeSet.updates) {
      for (const updatedItem of changeSet.updates) {
        state.items.set(updatedItem.id, Object.freeze({ ...state.items.get(updatedItem.id), ...updatedItem }));
      }
    }
  },
  applyFolderChanges: (state: FolderState, { name, changeSet, folderId, deviceId, isSynced = false, persistChangeSet = true }: { name: string; deviceId: string; changeSet: ChangeSet<FolderItem>; folderId: string; isSynced: boolean; persistChangeSet: boolean; }) => {
    let itemChangeSet = state.folderItemChangeSets[folderId];
    if (isSynced) {
      itemChangeSet = null;
    } else if (changeSet != null && persistChangeSet) {
      // We only persist the updates ChangeSet here, since removals are only persisted if they are the sole entry in the changeSet
      // !!! MUST be either or and have precedence over removal changeSet !!!
      if (changeSet.updates?.length) {
        itemChangeSet = (state.folderItemChangeSets[folderId]
          ? mergeChangeSet(itemChangeSet, { updates: changeSet.updates })
          : { updates: changeSet.updates });
        // This shall only be persisted for actual delete operations, else we completely lose the content of an item on a sorting operation
      } else if (changeSet.removals?.length) {
        itemChangeSet = (state.folderItemChangeSets[folderId]
          ? mergeChangeSet(itemChangeSet, { removals: changeSet.removals })
          : { removals: changeSet.removals });
      }
    }
    if (changeSet) {
      Vue.set(state.folderItems, folderId, [
        ...(changeSet.removals
          ? (state.folderItems[folderId] || []).filter(i => !changeSet.removals.includes(i.id))
          : state.folderItems[folderId] || [])
          .map(i => {
            const update = changeSet.updates ? changeSet.updates.find(u => u.id === i.id) : null;
            return update
              ? {
                  ...i,
                  ...update,
                  position: {
                    ...i.position,
                    ...update.position,
                  },
                }
              : i;
          }),
        ...(changeSet.inserts ? changeSet.inserts.map(i => ({ ...i, item: state.items.get(i.id) })) : []),
      ]);
    }
    // Update itemCount, name and sync in folder, but only if they are affected, otherwise all getters that depend on folders will be rebuild
    const folder = state.folders[folderId];
    if ((name != null && name !== folder.name) || deviceId != null || state.folderItems[folderId]?.length !== folder?.itemCount) {
      Vue.set(state.folders, folderId,
        {
          ...folder,
          name: name ?? folder.name,
          deviceId: deviceId ?? folder.deviceId,
          isSynced: name ? isSynced : folder.isSynced,
          itemCount: state.folderItems[folderId]?.length ?? 0,
        });
    }
    state.folderItemChangeSets[folderId] = itemChangeSet;
  },
  removeThumbnails: (state: FolderState, { folderId, items }: { folderId: string; items: PipelineItem[] }) => {
    const replaceItems = state.folderItems[folderId].map(item => {
      const pipelineItem = items.find(i => i.id === item.item.id);
      if (pipelineItem != null) {
        const existingItem = state.folderItems[folderId].find(i => i.item.id === pipelineItem.id);
        const replacedItem = { ...existingItem, item: { ...existingItem.item, assets: existingItem.item.assets.filter(a => a.version !== CUSTOM_SUB_VERSION) } };
        state.items.set(existingItem.id, replacedItem.item);
        return replacedItem;
      } else {
        return item;
      }
    });
    Vue.set(state.folderItems, folderId, replaceItems);
  },
  replaceItems: (state: FolderState, { folderId, items }: { folderId: string; items: PipelineItem[] }) => {
    const replaceItems: FolderItem[] = items.filter(p => p.replaceItem).map((pipelineItem) => {
      const folderItem = convertPipelineItemToFolderItem(pipelineItem);
      const existingItem = state.folderItems[folderId].find(i => i.item.id === folderItem.item.id);
      const mergedItem = _cloneDeep(existingItem);
      mergedItem.item.assets = folderItem.item.assets;
      state.items.set(existingItem.item.id, Object.freeze(mergedItem.item));
      return mergedItem;
    });
    if (replaceItems.length > 0) {
      Vue.set(state.folderItems, folderId, state.folderItems[folderId] ? [...state.folderItems[folderId].filter(fi => !replaceItems.some(r => fi.id === r.id)), ...replaceItems] : replaceItems);
    }
  },
  addOfflineItems: (state: FolderState, { folderId, items }: { folderId: string; items: PipelineItem[] }) => {
    if (items.length > 0) {
      const offlineItems: FolderItem[] = items.map((pipelineItem) => {
        const folderItem = convertPipelineItemToFolderItem(pipelineItem);
        state.items.set(folderItem.id, Object.freeze(folderItem.item));
        state.offlineItems.set(folderItem.id, true);
        return folderItem;
      });
      Vue.set(state.folders, folderId, { ...state.folders[folderId], itemCount: (state.folders[folderId]?.itemCount ?? 0) + offlineItems?.length });
      Vue.set(state.folderItems, folderId, state.folderItems[folderId] ? [...state.folderItems[folderId], ...offlineItems] : offlineItems);
    }
  },
  replaceAssets: (state: FolderState, { folderId, items }: { folderId: string; items: PipelineItem[] }) => {
    if (items.length > 0) {
      const offlineItems: FolderItem[] = items.map((pipelineItem) => {
        const existingItem = state.folderItems[folderId].find(i => i.id === pipelineItem.id);
        const folderItem = convertPipelineItemToFolderItem(pipelineItem);
        existingItem.item.assets = folderItem.item.assets;
        state.items.set(existingItem.id, Object.freeze(existingItem.item));
        state.offlineItems.set(existingItem.id, true);
        return existingItem;
      });
      Vue.set(state.folderItems, folderId, state.folderItems[folderId] ? [...state.folderItems[folderId].filter(f => !offlineItems.find(o => o.item.id === f.item.id)), ...offlineItems] : offlineItems);
    }
  },
  addFolderItems: (state: FolderState, { items, folderId }: { items: FolderItem[], folderId: string }) => {
    const folder = state.folders[folderId];
    const filteredItems = [
      ...(state.folderItems[folderId] || []).filter(existingItem => !items.some(item => item.id === existingItem.id)),
      ...items,
    ].map(item => ({ ...item, item: state.items.get(item.id) })); // update embedded item with latest item state
    Vue.set(state.folderItems, folderId, filteredItems);
    Vue.set(state.folders, folderId,
      {
        ...folder,
        itemCount: state.folderItems[folderId]?.length ?? 0,
      });
  },
  addItems: (state: FolderState, items: Item[]) => {
    for (const item of items) {
      state.offlineItems.delete(item.id);
      if (state.items.has(item.id)) {
        const existingItem = state.items.get(item.id);
        const offlineAssets = existingItem?.assets.filter(a => a.isOffline);
        const updatedItem = {
          ...existingItem,
          ...item,
          isSynced: true,
          // Keep base64 data for assets that are already present
          assets: item.assets?.length > 0
            ? updateOfflineAssets(existingItem, item, offlineAssets)
            : existingItem.assets,
        };
        state.items.set(existingItem.id, Object.freeze(updatedItem));
      } else {
        state.items.set(item.id, Object.freeze({ ...item, isSynced: true }));
      }
    }
  },
  itemNameChange: (state: FolderState, { itemId, name }) => {
    const originalItem = state.items.get(itemId);
    if (originalItem) {
      state.items.set(itemId, Object.freeze({
        ...originalItem,
        name,
      }));
    }
  },
  assetsAdded: (state: FolderState, assets: Asset[]) => {
    for (const asset of assets) {
      const originalItem = state.items.get(asset.itemId);
      if (originalItem) {
        const matchedAssetVersions: Set<number> = new Set(originalItem.assets.filter(a => a.isMatched).map(a => a.version));
        state.items.set(asset.itemId, Object.freeze({
          ...originalItem,
          assets: [...originalItem.assets.filter(a => a.version !== asset.version), { ...asset, isOffline: false, isMatched: matchedAssetVersions.has(asset.version), matchedWith: null }],
        }));
      }
    }
  },
  applyItemDataChangesToFolderItems: (state: FolderState, { folderId, itemChanges }: { folderId, itemChanges: Map<string, Item> }) => {
    state.folderItems[folderId] = state.folderItems[folderId]?.map(i => {
      if (itemChanges.has(i.id)) {
        i.item = itemChanges.get(i.id);
      }
      return i;
    });
  },
  // TODO: merge mutation together with asset added
  itemsResized: (state: FolderState, resizeData: ResizeCompletedEventData[]) => {
    for (const data of resizeData) {
      if (state.items.has(data.itemId)) {
        const item = state.items.get(data.itemId);
        const matchedAssetVersions: Set<number> = new Set(item.assets.filter(a => a.isMatched).map(a => a.version));
        const assets = [
          ...(data.replaced
            // TODO: due to the current lack of the pipeline to replace a custom sub version, we have to remove stale custom sub versions.
            ? item.assets.filter(asset => asset.version > CUSTOM_SUB_VERSION)
            : item.assets.filter(asset => !data.assets.some(a => a.version === asset.version))),
          ...data.assets.map((a: Asset) => {
            a.itemId = data.itemId;
            a.isOffline = false;
            a.isMatched = matchedAssetVersions.has(a.version);
            return a;
          })];
        state.items.set(data.itemId, Object.freeze({
          ...item,
          assets,
        }));
      } else {
        console.error(`item ${data.itemId} not found for assets`, data.assets, data); // should not happen anymore, keep track of this in production
      }
    }
  },
  addItemMetadata: (
    state: FolderState,
    eventData: MetadataCompletedEventData
  ) => {
    const { exif, colors } = eventData.metadata;
    const item = state.items.get(eventData.itemId);
    if (item) {
      state.items.set(eventData.itemId, Object.freeze({
        ...item,
        exif: exif || null,
        colors: colors || [],
      }));
    }
  },
  removeItemsSuccess: (
    state: FolderState,
    eventData: ItemsRemovedEventData
  ) => {
    Vue.set(state.folderItems, eventData.folderId, state.folderItems[eventData.folderId].filter(
      (item) => !eventData.items.includes(item.id)
    ));
    eventData.items.forEach((itemId) => state.items.delete(itemId));
  },
  setFolderShared(state: FolderState, folderId: string) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        isShared: true,
      });
  },
  setFolderOwner(state: FolderState, { folderId, owner }: { folderId: string, owner: Owner}) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        owner,
      });
  },
  deleteSharedLinkSuccess(
    state: FolderState,
    { folderId }: { folderId: string; }
  ) {
    const folder = state.folders[folderId];
    Vue.set(state.folders, folderId,
      {
        ...folder,
        isShared: false,
      });
  },
  clearMatchedWithFromItems(state: FolderState, { folderId, itemIds }: { folderId: string, itemIds: string[] }) {
    for (const itemId of itemIds) {
      const item = state.items.get(itemId);
      if (item) {
        const assets = item.assets.filter(a => !a.isOffline).map(a => ({ ...a, isMatched: false, matchedWith: null }));
        state.items.set(itemId, Object.freeze({ ...item, assets }));
      }
    }
    const updatedFolderItems = state.folderItems[folderId]?.map(i => ({ ...i, item: { ...i.item, assets: i.item.assets.filter(a => !a.isOffline).map(a => ({ ...a, isMatched: false, matchedWith: null })) } }));
    Vue.set(state.folderItems, folderId, updatedFolderItems);
  },
  setMatchedWithForItemAssets(state: FolderState, pipelineItems: PipelineItem[]) {
    const updatedItems = [];
    for (const pipelineItem of pipelineItems) {
      const item = state.items.get(pipelineItem.id);
      if (item) {
        const folderItem = convertPipelineItemToFolderItem(pipelineItem);
        folderItem.item.assets = new AssetListBuilder().withAssets(folderItem.item.assets).withFilter(byIncludesVersions(eventsToProcessToVersions(pipelineItem.eventsToProcess))).build().assets;
        // the assets of the conversion may not be complete since thumbnailing/metadata extraction did not happen yet,
        // we need to enrich them with estimated assets for the pipeline commands that will be completed later
        // e.g. we only have a raw file, but want to also match originals, so we need to provide a dummy estimated asset for the original
        folderItem.item.assets = enrichWithEstimatedAssetsBasedOnPipelineCommands(folderItem.item.assets, pipelineItem);
        const [existingMatchedAssets, newAssets] = filterByConditions(folderItem.item.assets, a => item.assets.some(existingAsset => existingAsset.version === a.version));
        const assets = [...item.assets.map(a => {
          const matchedWith = existingMatchedAssets.find(ma => ma.version === a.version);
          if (matchedWith) {
            return { ...a, matchedWith, isMatched: true, isEstimated: false };
          }
          return a;
        }), ...newAssets.map(a => ({ ...a, matchedWith: a, isMatched: true, isOffline: true }))];
        const updatedItem = { ...item, assets };
        state.items.set(pipelineItem.id, Object.freeze(updatedItem));
        updatedItems.push(updatedItem);
      }
    }
    if (updatedItems.length > 0) {
      const folderId = pipelineItems[0]?.folderId;
      Vue.set(state.folderItems, folderId, state.folderItems[folderId].map(fi => {
        const item = updatedItems.find(i => i.id === fi.item.id);
        if (item) {
          return { ...fi, item };
        }
        return fi;
      }));
    }
  },
};

export function enrichWithEstimatedAssetsBasedOnPipelineCommands(existingAssets: Asset[], pipelineItem: PipelineItem) {
  const estimatedAssets = [];
  for (const version of eventsToProcessToVersions(pipelineItem.eventsToProcess)) {
    if (!existingAssets.some(a => a.version === version)) {
      const realAssetSize = realAssetVersionSizeForPipelineItem(pipelineItem, version);
      const estimatedAsset = {
        version,
        size: realAssetSize || getEstimatedFileSizeForVersion(version),
        isOffline: true,
        isMatched: true,
        isEstimated: realAssetSize != null,
      };
      estimatedAssets.push({
        ...estimatedAsset,
        matchedWith: estimatedAsset,
      });
    }
  }
  return [...existingAssets, ...estimatedAssets];
}

function realAssetVersionSizeForPipelineItem(pipelineItem: PipelineItem, version: number): number | null | undefined {
  if (version === RAW_ASSET_VERSION) {
    return pipelineItem.raw?.file.size;
  }
  if (version === ORIGINAL_ASSET_VERSION) {
    return pipelineItem.file?.size;
  }
  return null;
}

export function getEstimatedFileSizeForVersion(version: number) {
  if (version === RAW_ASSET_VERSION) {
    return ESTIMATED_RAW_SIZE;
  }
  if (version === ORIGINAL_ASSET_VERSION) {
    return ESTIMATED_ORIGINAL_SIZE;
  }
  if (version === CUSTOM_SUB_VERSION) {
    return ESTIMATED_CUSTOM_THUMBNAIL_SIZE;
  }
}

function eventsToProcessToVersions(eventsToProcess: PipelineCommandType[]) {
  return eventsToProcess.filter(e => FileProcessingPipeline.UPLOAD_COMMANDS.includes(e)).map(e => {
    if (e === PipelineCommandType.UPLOAD_RAWS) {
      return RAW_ASSET_VERSION;
    }
    if (e === PipelineCommandType.UPLOAD_ORIGINALS) {
      return ORIGINAL_ASSET_VERSION;
    }
    return CUSTOM_SUB_VERSION;
  });
}

function updateOfflineAssets(existingItem: Item, updatedItem: Item, offlineAssets: Asset[]) {
  if (!offlineAssets.length) {
    return updatedItem.assets;
  }
  return [
    ...existingItem.assets.filter(existingAsset => !updatedItem.assets.some(a => a.id === existingAsset.id || a.version === existingAsset.version)),
    ...updatedItem.assets.map((a) => {
      if (a.version >= CUSTOM_SUB_VERSION) {
        const existingAsset = offlineAssets.find(existingAsset => existingAsset.version === a.version);
        if (existingAsset != null) {
          const updatedAsset = { ...existingAsset, ...a, isOffline: false };
          if (a.width == null) {
            updatedAsset.width = existingAsset.width;
            updatedAsset.height = existingAsset.height;
          }
          return updatedAsset;
        }
      }
      return a;
    })];
}

import VueLib from 'vue'

/* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
let delayedCalls = []
let SentryMock = {}

let sentryReadyResolve
let loadInitiated = false
let loadCompleted = false

let delayedGlobalErrors = []
let delayedUnhandledRejections = []
/** @param {ErrorEvent} event */
const delayGlobalError = function (event) {
  delayedGlobalErrors.push([event.message, event.filename, event.lineno, event.colno, event.error])
}
const delayUnhandledRejection = function (event) {
  delayedUnhandledRejections.push('reason' in event ? event.reason : 'detail' in event && 'reason' in event.detail ? event.detail.reason : event)
}

const vueErrorHandler = VueLib.config.errorHandler

VueLib.config.errorHandler = (error, vm, info) => {
  if (!loadCompleted) {
    if (vm) {
      vm.$sentry.captureException(error)
    }

    if (VueLib.util) {
      VueLib.util.warn(`Error in ${info}: "${error.toString()}"`, vm)
    }
    console.error(error) // eslint-disable-line no-console
  }

  if (vueErrorHandler) {
    return vueErrorHandler(error, vm, info)
  }
}

export default function SentryPlugin (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const apiMethods = ["addGlobalEventProcessor","addBreadcrumb","captureException","captureEvent","captureMessage","configureScope","getHubFromCarrier","getCurrentHub","Hub","makeMain","Scope","Session","startTransaction","setContext","setExtra","setExtras","setTag","setTags","setUser","withScope","BrowserClient","injectReportDialog","eventFromException","eventFromMessage","forceLoad","init","lastEventId","onLoad","showReportDialog","flush","close","wrap"]
  apiMethods.forEach((key) => {
    SentryMock[key] = (...args) => delayedCalls.push([key, args])
  })

  window.addEventListener('error', delayGlobalError)
  window.addEventListener('unhandledrejection', delayUnhandledRejection)

  inject('sentry', SentryMock)
  ctx.$sentry = SentryMock

  const loadSentryHook = () => attemptLoadSentry(ctx, inject)

  window.onNuxtReady(loadSentryHook)

  const sentryReadyPromise = new Promise((resolve) => {
    sentryReadyResolve = resolve
  })

  const sentryReady = () => sentryReadyPromise

  inject('sentryReady', sentryReady)
  ctx.$sentryReady = sentryReady
}

async function attemptLoadSentry (ctx, inject) {
  if (loadInitiated) {
    return
  }

  loadInitiated = true

  if (!window.$nuxt) {
    // Wait for onNuxtReady hook to trigger.

    return
  }

  await loadSentry(ctx, inject)
}

async function loadSentry (ctx, inject) {
  if (loadCompleted) {
    return
  }

  const Sentry = await import(/* webpackChunkName: 'sentry' */ '@sentry/browser')
  const { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } = await import(/* webpackChunkName: 'sentry' */ '@sentry/integrations')

  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {"dsn":"https:\u002F\u002F373678dcd96d46e9b267051fad1bdf60@o1164173.ingest.sentry.io\u002F6253090","environment":"production","debug":false,"beforeSend":(event) => {
        if (event.extra?.url != null) {
          const indexOfUrlShare = event.extra.url?.indexOf('/shared/');
          event.extra.url = event.extra.url.split('?')[0].substring(0, indexOfUrlShare !== -1 ? indexOfUrlShare + '/shared/'.length : event.extra.url.length);
        }
        if (event.request?.url != null) {
          const indexOfUrlShare = event.request.url.indexOf('/shared/');
          event.request.url = event.request.url.split('?')[0].substring(0, indexOfUrlShare !== -1 ? indexOfUrlShare + '/shared/'.length : event.request.url.length);
        }
        if (event.request?.headers?.Referer != null) {
          const indexOfHeaderShare = event.request.headers.Referer.indexOf('/shared/');
          event.request.headers.Referer = event.request.headers.Referer.split('?')[0].substring(0, indexOfHeaderShare !== -1 ? indexOfHeaderShare + '/shared/'.length : event.request.headers.Referer.length);
        }
        if (event.breadcrumbs?.some(b => b.category === 'navigation')) {
          const whiteListQueryParams = ['view', 'review-filter'];
          const maskQueryParams = (path) => {
            const urlObj = new URL(process.env.URL + path);
            const queryParams = urlObj.searchParams;
            queryParams.forEach((_value, key) => {
              if (!whiteListQueryParams.includes(key)) {
                queryParams.set(key, '***');
              }
            });
            return urlObj.toString();
          };
          event.breadcrumbs = event.breadcrumbs.map(b => {
            if (b.category === 'navigation') {
              return {
                ...b,
                data: {
                  ...b.data,
                  from: maskQueryParams(b.data.from),
                  to: maskQueryParams(b.data.to),
                },
              };
            }
            return b;
          });
        }
        return event;
      },"release":"52e716d7413b8314a8f0767d86960ee9a5c96bcf"}

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    const { default: merge } = await import(/* webpackChunkName: 'sentry' */ 'lodash.mergewith')
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]
  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  loadCompleted = true

  window.removeEventListener('error', delayGlobalError)
  window.removeEventListener('unhandledrejection', delayUnhandledRejection)
  if (delayedGlobalErrors.length) {
    if (window.onerror) {
      console.info('Reposting global errors after Sentry has loaded') // eslint-disable-line no-console
      for (const errorArgs of delayedGlobalErrors) {
        window.onerror.apply(window, errorArgs)
      }
    }
    delayedGlobalErrors = []
  }
  if (delayedUnhandledRejections.length) {
    if (window.onunhandledrejection) {
      console.info('Reposting unhandled promise rejection errors after Sentry has loaded') // eslint-disable-line no-console
      for (const reason of delayedUnhandledRejections) {
        window.onunhandledrejection(reason)
      }
    }
    delayedUnhandledRejections = []
  }
  delayedCalls.forEach(([methodName, args]) => Sentry[methodName].apply(Sentry, args))

  forceInject(ctx, inject, 'sentry', Sentry)

  sentryReadyResolve(Sentry)

  // help gc

  // Dont unset delayedCalls & SentryMock during
  // development, this will cause HMR issues

  delayedCalls = undefined
  SentryMock = undefined

  sentryReadyResolve = undefined
}

// Custom inject function that is able to overwrite previously injected values,
// which original inject doesn't allow to do.
// This method is adapted from the inject method in nuxt/vue-app/template/index.js
function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  window.$nuxt.$options[injectKey] = value
}

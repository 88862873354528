export enum DownloadOption {
  ZIP,
  SINGLE
}

export enum FileOption {
  THUMBNAILS,
  ORIGINALS,
  RAWS,
}

export enum SelectedItemsOption {
  ALL,
  SELECTED,
  FILTERED,
}

import _throttle from 'lodash.throttle';
import { isTouchEvent } from '~/models/views/SnippetMover';

export default class SliderDrag {
  private dragStartClientX: number;
  private isDragging: boolean;
  private throttleChange = _throttle(this.changeValue, 50, { trailing: true });

  constructor(private sliderId: string, private barWidth: number, private currentValue, private min: number, private max: number, private emit: (value: number) => void) {
  }

  public get width() {
    return this.barWidth / (this.max - this.min);
  }

  public clickSlider(event: PointerEvent) {
    const boundingRect = document.getElementById(this.sliderId).getBoundingClientRect();
    const xValue = event.clientX;
    const difference = xValue - boundingRect.x;
    this.findNearestValue(difference);
  }

  public findNearestValue(difference: number) {
    const newValue = Math.round(difference / this.width);
    this.throttleChange(Math.min(Math.max(this.min, newValue), this.max));
  }

  public changeValue(value: number) {
    this.currentValue = value;
    this.emit(value);
  }

  removeGhost(event: DragEvent) {
    const dragImage = document.createElement('div');
    dragImage.style.visibility = 'hidden';
    event.dataTransfer.setDragImage(dragImage, 0, 0);
    event.dataTransfer.effectAllowed = 'move';
  }

  dragStart(event: MouseEvent) {
    const boundingRect = document.getElementById(this.sliderId).getBoundingClientRect();
    event.preventDefault();
    event.stopImmediatePropagation();
    this.dragStartClientX = event.clientX;
    this.isDragging = true;

    let clientX;
    const startDragSlider = (moveEvent: DragEvent | TouchEvent) => {
      if (isTouchEvent(moveEvent)) {
        if (moveEvent.changedTouches) {
          const left = moveEvent.changedTouches[0].clientX;
          if (left > 0) {
            clientX = left;
          }
        }
      } else {
        moveEvent.preventDefault();
        moveEvent.stopImmediatePropagation();
        if (moveEvent.clientX > 0) {
          clientX = moveEvent.clientX;
        }
      }
    };
    let animationFrame;
    const adjustSliderPosition = () => {
      if (clientX && clientX > 0 && clientX <= window.innerWidth) {
        const difference = clientX - boundingRect.x;
        this.findNearestValue(difference);
      }
      animationFrame = requestAnimationFrame(adjustSliderPosition);
    };
    animationFrame = requestAnimationFrame(adjustSliderPosition);
    const endDragSlider = (moveEvent) => {
      moveEvent.preventDefault();
      moveEvent.stopImmediatePropagation();
      cancelAnimationFrame(animationFrame);
      document.removeEventListener('touchmove', startDragSlider);
      document.removeEventListener('mousemove', startDragSlider);
      document.removeEventListener('touchend', endDragSlider);
      document.removeEventListener('mouseup', endDragSlider);
      this.isDragging = false;
    };
    document.addEventListener('touchmove', startDragSlider);
    document.addEventListener('mousemove', startDragSlider, false);
    document.addEventListener('touchend', endDragSlider, false);
    document.addEventListener('mouseup', endDragSlider);
  }
}

export enum NavigationInstructionDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface NavigationInstruction {
  targetViewId: string;
  instruction: {
    direction?: NavigationInstructionDirection;
    toItemId?: string;
  }
}

export interface SongAuthor {
  name: string;
  homepage: string;
}

export interface SongInfo {
  id: string;
  title: string;
  author: SongAuthor;
  filename: string;
  isPlaying: boolean;
  isSelected: boolean;
  connectedSlideNumber: number;
}

export interface HomepageSlide {
  no: number;
  name: string;
}

export interface HomepageState {
  songs: SongInfo[];
  activeSlide: number;
  initialSlideAnimationEnded: boolean;
  slides: HomepageSlide[];
  activeEntry: string;
  showSwitchButtonInFooter: boolean;
}

const DextaMega: SongAuthor = {
  name: 'Dexta Mega',
  homepage: 'https://www.instagram.com/dextamega',
};

const ArelSchaefer: SongAuthor = {
  name: 'Arel Schaefer',
  homepage: 'https://soundcloud.com/arel_schaefer',
};

export const LANDING_PAGE_ROUTE = 'index';
export const HOME_PAGE_PATH_MOBILE = '/home';
export const HOME_PAGE_RELATED_ROUTES = ['beta', 'index'];

export default (): HomepageState => ({
  songs: [
    {
      id: '1',
      title: 'CENTA POD Pistils Opaque',
      author: DextaMega,
      filename: '/Dexta_Mega_CENTA_POD_Pistils_Opaque.mp3',
      isPlaying: false,
      isSelected: true,
      connectedSlideNumber: 1,
    },
    {
      id: '2',
      title: 'Digital Meds Generic',
      author: DextaMega,
      filename: '/Dexta_Mega_Digital_Meds_Generic.mp3',
      isPlaying: false,
      isSelected: false,
      connectedSlideNumber: 2,
    },
    {
      id: '3',
      title: 'KANDY MAN A10 MIX',
      author: DextaMega,
      filename: '/Dexta_Mega_KANDY_MAN_A10_MIX.mp3',
      isPlaying: false,
      isSelected: false,
      connectedSlideNumber: 3,
    },
    {
      id: '4',
      title: 'TEMPLE JAZZ SOFT CORE DUB',
      author: DextaMega,
      filename: '/Dexta_Mega_TEMPLE_JAZZ_SOFT_CORE_DUB.mp3',
      isPlaying: false,
      isSelected: false,
      connectedSlideNumber: 4,
    },
    {
      id: '5',
      title: 'VISION MACHINE DUB 1',
      author: DextaMega,
      filename: '/Dexta_Mega_VISION_MACHINE_DUB_1.mp3',
      isPlaying: false,
      isSelected: false,
      connectedSlideNumber: 5,
    },
  ],
  activeSlide: 12,
  initialSlideAnimationEnded: false,
  slides: [{
    no: 0,
    name: 'MUNICH',
  }, {
    no: 1,
    name: 'MUNICH',
  }, {
    no: 2,
    name: 'TOKYO',
  }, {
    no: 3,
    name: 'MADRID',
  }, {
    no: 4,
    name: 'LA',
  }, {
    no: 5,
    name: 'NEW YORK',
  }, {
    no: 6,
    name: 'LONDON',
  }, {
    no: 7,
    name: 'MAILAND',
  }, {
    no: 8,
    name: 'NUREMBERG',
  }, {
    no: 9,
    name: 'BARCELONA',
  }, {
    no: 10,
    name: 'ALPHA',
  }, {
    no: 11,
    name: 'BETA',
  }, {
    no: 12,
    name: 'BETA',
  }],
  activeEntry: null,
  showSwitchButtonInFooter: false,
});

import _cloneDeep from 'lodash.clonedeep';
import Snippet from '~/models/Snippet';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { SortableView } from '~/models/views/SortableView';
import { ViewType } from '~/models/views/ViewType';
import { HorizontalViewBuilder } from '~/models/views/horizontal/HorizontalViewBuilder';

export class HorizontalView implements SortableView {
  public type = ViewType.HORIZONTAL;

  constructor(
    public snippets: Snippet[],
    public width: number,
    public height: number,
    private itemSpacing: number,
    private itemOverlap: number
  ) {}

  resize(_newWidth: number, newHeight: number) {
    if (newHeight !== 0 && _newWidth !== 0 && this.height !== 0) {
      const scaleFactor = newHeight / this.height;
      this.snippets = this.snippets.map((snippet, index) => {
        const newSpacing = (index + 1) * (this.itemSpacing - (this.itemSpacing * scaleFactor));
        snippet.height = snippet.height * scaleFactor;
        snippet.width = snippet.width * scaleFactor;
        snippet.item.setViewPosition({
          width: snippet.item.viewPosition.width * scaleFactor,
          height: snippet.item.viewPosition.height * scaleFactor,
          top: snippet.item.viewPosition.top * scaleFactor,
          left: (snippet.item.viewPosition.left * scaleFactor) + newSpacing,
        });
        return snippet;
      });
      this.width = this.width * scaleFactor;
      this.height = newHeight;
    }
  }

  // TODO: Add support for partial horizontal view calculations instead of using the whole set of snippets
  preCalculatePartialSorting(sortItemsEvent: SortItemsEvent): Snippet[] {
    // TODO: check if position is larger than last item
    const { items: sortItems } = sortItemsEvent;
    const snippets: Snippet[] = _cloneDeep(this.snippets);
    let leftPositionStart = snippets[0]?.item?.viewPosition.left || 0;
    const sortedSnippets = Snippet.sortSnippetsBySortEvent(snippets, sortItemsEvent).map((snippet) => {
      if (sortItems.some((i) => i.id === snippet.item.id)) {
        snippet.item = HorizontalViewBuilder.adjustItemSize(snippet.item.item, this.height);
      }
      snippet.item.setViewPositionLeft(leftPositionStart);
      leftPositionStart += snippet.item.viewPosition.width + this.itemSpacing - this.itemOverlap;
      return snippet;
    });
    return sortedSnippets.filter((snippet) =>
      sortItems.some((i) => i.id === snippet.item.id)
    );
  }
}

import moment from 'moment';
import { SlimSelection } from '~/models/SlimSelection';
import { SelectionCluster } from '~/models/selection/SelectionCluster';
import Selection from '~/models/selection/Selection';

export function clusterSelectionsByDate(selections: (SlimSelection | Selection)[]): SelectionCluster[] {
  return JSON.parse(JSON.stringify(selections))
    .sort((a: (SlimSelection | Selection), b: (SlimSelection | Selection)) => moment(a.modified).isBefore(b.modified) ? 1 : -1)
    .reduce((aggregate: SelectionCluster[], selection: (SlimSelection | Selection)) => {
      if (aggregate.length) {
        const currentCluster = aggregate[aggregate.length - 1];
        if (moment(currentCluster.date).isSame(selection.modified, 'day')) {
          currentCluster.selections.push(selection);
          return aggregate;
        }
      }
      return [...aggregate, {
        name: getClusterName(selection),
        date: selection.modified,
        selectionsWithItems: [selection],
        isActive: false,
      }];
    }, []);
}
function getClusterName(selection: (SlimSelection | Selection)) {
  return moment(selection.modified).calendar(null, {
    lastWeek: 'dddd',
    lastDay: '[yesterday]',
    sameDay: '[today]',
    sameElse() {
      return 'YYYY/MM/DD';
    },
  });
}

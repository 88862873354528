import Snippet from '~/models/Snippet';
import { ViewRange } from '~/models/views/ViewRange';
import { ViewType } from '~/models/views/ViewType';

export interface MutantView {
  type: ViewType;
  snippets: Snippet[];
  height: number;
  width: number;

  resize(width: number, height: number);
}

interface ViewParams {
  downsizeViewRange?: boolean;
  viewType: ViewType;
  scrollViewElement: HTMLElement;
  previousViewRange?: ViewRange;
}

const MIN_CALCULATE_DIMENSION = 500;

export class ViewHandler {
  public static calculateViewRange({ downsizeViewRange = false, viewType, scrollViewElement, previousViewRange }: ViewParams): ViewRange {
    let itemFactor = viewType === ViewType.HORIZONTAL ? 10 : 6;
    if (downsizeViewRange) {
      itemFactor = 1;
    } else if (
      (viewType !== ViewType.HORIZONTAL && scrollViewElement.clientHeight < MIN_CALCULATE_DIMENSION)
      || (viewType === ViewType.HORIZONTAL && scrollViewElement.clientWidth < MIN_CALCULATE_DIMENSION)) {
      itemFactor = 10;
    }
    const viewRange = viewType === ViewType.HORIZONTAL
      ? {
          start: scrollViewElement.scrollLeft - scrollViewElement.clientWidth * itemFactor,
          end: scrollViewElement.clientWidth + scrollViewElement.scrollLeft + scrollViewElement.clientWidth * itemFactor,
          exactStart: scrollViewElement.scrollLeft,
          exactEnd: scrollViewElement.scrollLeft + scrollViewElement.clientWidth,
          width: scrollViewElement.clientWidth,
          height: scrollViewElement.clientHeight,
        }
      : {
          start: scrollViewElement.scrollTop - scrollViewElement.clientHeight * itemFactor,
          end: scrollViewElement.clientHeight + scrollViewElement.scrollTop + scrollViewElement.clientHeight * itemFactor,
          exactStart: scrollViewElement.scrollTop,
          exactEnd: scrollViewElement.scrollTop + scrollViewElement.clientHeight,
          width: scrollViewElement.clientWidth,
          height: scrollViewElement.clientHeight,
        };
    if (previousViewRange == null || ViewHandler.needsRecalculation(previousViewRange, viewRange)) {
      return viewRange;
    }
    return previousViewRange;
  }

  // We check if new view range is smaller than previous, so we don't need to adjust the view range
  public static needsRecalculation(viewRange: ViewRange, newViewRange: ViewRange): boolean {
    return viewRange.start >= newViewRange.start || viewRange.end <= newViewRange.end;
  }

  public static scrollRequiresRecalculationOfView({ viewType, scrollViewElement, currentViewRange }: {viewType: ViewType, scrollViewElement: HTMLElement, currentViewRange: ViewRange}): boolean {
    const currentScrollEndPosition = scrollViewElement.scrollTop + scrollViewElement.clientHeight + 1000;
    const currentScrollTopPosition = scrollViewElement.scrollTop - 1000;
    return viewType === ViewType.HORIZONTAL ? true : currentScrollEndPosition <= currentViewRange.end || currentScrollTopPosition >= currentViewRange.start;
  }
}

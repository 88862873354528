import { Middleware, NuxtError } from '@nuxt/types';
import { Context } from '@nuxt/types/app';
import { ErrorKey } from '~/models/error/ErrorKey';

/**
 * when a guest user has images in the browser and reloads page, the user will be redirected to the error page
 */
const guestMiddleware: Middleware = ({ store, route, error, from }: Context) => {
  if (route.name === 'cloud-id') {
    const sharedLinkAccessId = route?.query['shared-link-id'];
    const isGuest = store.getters['user/isGuest'];
    if (!sharedLinkAccessId && isGuest && from.name !== 'index') {
      error({ errorKey: ErrorKey.FOLDER_NOT_FOUND } as NuxtError);
    }
  }
};

export default guestMiddleware;

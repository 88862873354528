import { Vue } from 'nuxt-property-decorator';

export type MobileInteractionEventType = 'tap-event' | 'long-press-event';
export type MobileInteractionEvent = {
  event: MouseEvent;
  type: MobileInteractionEventType;
};
Vue.directive('mobile-event', {
  bind(el: HTMLElement, directive) {
    let timeout: NodeJS.Timeout;
    let longPressTriggered = false;
    let swipeGestureDetected = false;
    const duration = 800;

    directive.touchStart = (event: TouchEvent) => {
      if (event.touches.length === 1) {
        timeout = setTimeout(() => {
          longPressTriggered = true;
          directive.value({ event, type: 'long-press-event' });
        }, duration);
      }
    };

    directive.touchEnd = (event: TouchEvent) => {
      clearTimeout(timeout);
      if (!longPressTriggered && !swipeGestureDetected && event.touches.length === 0) {
        directive.value({ event, type: 'tap-event' });
      }
      longPressTriggered = false;
      swipeGestureDetected = false;
    };

    directive.touchMove = () => {
      clearTimeout(timeout);
      swipeGestureDetected = true;
    };

    el.addEventListener('touchstart', directive.touchStart, { passive: true });
    el.addEventListener('touchend', directive.touchEnd, { passive: true });
    el.addEventListener('touchmove', directive.touchMove, { passive: true });
  },
  unbind(el, directive, _vnode) {
    // @ts-ignore
    el.removeEventListener('touchstart', directive.touchStart);
    // @ts-ignore
    el.removeEventListener('touchend', directive.touchEnd);
    // @ts-ignore
    el.removeEventListener('touchmove', directive.touchMove);
  },
});

import { ItemOrigin } from '~/models/item/ItemOrigin';
import { DetailViewState } from '~/store/detailView/state';
import SnippetView from '~/components/window/view/SnippetView.vue';
import { SnippetMover } from '~/models/views/SnippetMover';
import { Asset } from '~/models/Asset';
import { sortedAssets } from '~/models/Assets';
import { RootState } from '~/store/state';

export default {
  getItemOrigin(state: DetailViewState): ItemOrigin {
    const elementId = SnippetView.getSnippetIdForView(state.linkedViewId, state.currentItem);
    const targetElement = document.getElementById(elementId);
    let rotation: any = SnippetMover.ROTATION_TRANSFORM_REGEX.exec(targetElement.style.transform);
    rotation = rotation ? parseInt(rotation[1], 10) : 0;
    return {
      elementId,
      viewId: state.linkedViewId,
      position: {
        left: targetElement.getBoundingClientRect().left,
        top: targetElement.getBoundingClientRect().top,
        width: targetElement.getBoundingClientRect().width,
        height: targetElement.getBoundingClientRect().height,
        rotation,
      },
    };
  },
  sortedAssets(state: DetailViewState): Asset[] {
    return sortedAssets(state.currentItem.item.assets);
  },
  itemDimensions(_state: DetailViewState, getters, rootState: RootState, rootGetters): { width: number, height: number, left: number, top: number } {
    const { width, height } = getters.sortedAssets[0];
    const windowHeight = rootState.windowHeight;
    const windowWidth = rootState.windowWidth;
    const maxContentHeight = rootGetters.isMobile ? windowHeight : windowHeight * 9 / 10;
    const maxContentWidth = rootGetters.isMobile ? windowWidth : windowWidth * 9 / 10;
    const sizeScaleWidth = maxContentWidth / width;
    const sizeScaleHeight = maxContentHeight / height;
    const isOverScalingWithWidthScale = sizeScaleWidth * width * height / width > maxContentHeight;
    const sizeScale = isOverScalingWithWidthScale ? sizeScaleHeight : sizeScaleWidth;
    const scaledWidth = width * sizeScale;
    const scaledHeight = height * sizeScale;
    const top = isOverScalingWithWidthScale ? windowHeight * 5 / 100 : (windowHeight - scaledHeight) / 2;
    const left = isOverScalingWithWidthScale ? (windowWidth - scaledWidth) / 2 : windowWidth * 5 / 100;
    return {
      left,
      top,
      width: scaledWidth,
      height: scaledHeight,
    };
  },
  isOpen(state: DetailViewState): boolean {
    return state.isOpen;
  },
};

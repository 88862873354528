import Item from '~/models/item/Item';
import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import Position from '~/models/Position';

export interface ViewItemPosition {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

export class ViewItem {
  public readonly id: string;
  public readonly item?: ItemWithPosition;
  public viewPosition: ViewItemPosition = {};
  public transform?: string;
  public zindex?: number;
  private inViewRange?: boolean;

  constructor(item: ItemWithPosition) {
    this.item = item;
    this.id = item.id;
    if (Number.isInteger(item.position?.zindex)) {
      this.zindex = item.position?.zindex;
    }
  }

  public get isPositioned(): boolean {
    return this.item.position?.x != null;
  }

  public get itemData(): Item {
    return this.item.item;
  }

  public get itemPosition(): Partial<Position> {
    return this.item.position;
  }

  public set isInViewRange(isInViewRange: boolean) {
    this.inViewRange = isInViewRange;
  }

  public get isInViewRange(): boolean {
    return this.inViewRange;
  }

  public setViewPosition({ top, left, width, height }): void {
    this.viewPosition = {
      top,
      left,
      width,
      height,
    };
  }

  public setViewPositionTop(top: number) {
    this.viewPosition.top = top;
  }

  public setViewPositionLeft(left: number) {
    this.viewPosition.left = left;
  }

  public setViewPositionWidth(width: number) {
    this.viewPosition.width = width;
  }

  public setViewPositionHeight(height: number) {
    this.viewPosition.height = height;
  }

  public setTransform(transform: string) {
    this.transform = transform;
  }

  public setZindex(zindex: number) {
    this.zindex = zindex;
  }
}

import _cloneDeep from 'lodash.clonedeep';
import { Asset } from '~/models/Asset';

export function sortedAssets(assets: Asset[]): Asset[] {
  return _cloneDeep(assets).sort((a, b) => {
    if (a.version === b.version) {
      if (a.width === b.width) {
        return 0;
      } else if (a.width < b.width) {
        return -1;
      } else {
        return 1;
      }
    } else if (a.version < b.version) {
      return -1;
    } else {
      return 1;
    }
  });
}

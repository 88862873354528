// eslint-disable-next-line import/named
import { bootstrap, setOptions, VueGtag } from 'vue-gtag';
import { AnalyticsOptions } from '~/models/analytics/analyticsOptions';
import { WebConfig } from '~/Config';

export class Analytics {
  private initialized = false;
  constructor (private gtag: VueGtag) {}

  get isInitialized() {
    return this.gtag != null && this.initialized;
  }

  async initialize(userId: string): Promise<void> {
    if (WebConfig.GOOGLE_ANALYTICS_ID != null) {
      let params: any = {
        page_location: '',
        page_referrer: '',
        page_title: '',
        page_path: '',
        send_page_view: false,
      };
      if (WebConfig.isDevEnvironment()) {
        params = {
          ...params,
          debug_mode: true,
        };
      }
      if (userId != null) {
        params = {
          ...params,
          user_id: userId,
        };
      }
      setOptions(
        {
          ...AnalyticsOptions,
          config: {
            id: WebConfig.GOOGLE_ANALYTICS_ID,
            params,
          },
        });
      await bootstrap();
      this.setInitialized();
    }
  }

  setUserId(userId: string) {
    const config = { user_id: userId };
    this.gtag.set(config);
  }

  private setInitialized() {
    this.initialized = true;
  }

  trackLogin() {
    if (this.isInitialized) {
      this.gtag.event('login');
    }
  }

  trackSignup() {
    if (this.isInitialized) {
      this.gtag.event('sign_up', { method: 'self' });
    }
  }

  trackPlayVideo(videoTitle: string) {
    if (this.isInitialized) {
      this.gtag.event('video_start', { video_title: videoTitle });
    }
  }

  trackEndVideo(videoTitle: string) {
    if (this.isInitialized) {
      this.gtag.event('video_complete', { video_title: videoTitle });
    }
  }

  trackProgressVideo(videoTitle: string, percent: number) {
    if (this.isInitialized) {
      this.gtag.event('video_progress', { video_title: videoTitle, video_percent: percent });
    }
  }
}

import Folder from '~/models/Folder';
import Item from '~/models/item/Item';
import { FolderItem } from '~/models/item/FolderItem';
import { ChangeSet } from '~/models/ChangeSet';

export interface FolderState {
  isLoading: boolean;
  folders: { [folderId: string]: Folder };
  folderItems: { [folderId: string]: FolderItem[] };
  items: Map<string, Item>;
  offlineItems: Map<string, boolean>;
  folderItemChangeSets: { [folderId: string]: ChangeSet<FolderItem> };
  rawItemsChangeSets: { [itemId: string]: ChangeSet<Item> },
  conflictIds: string[]
}

export default (): FolderState => ({
  isLoading: false,
  folders: Object.create(null) as { [folderId: string]: Folder },
  folderItems: Object.create(null) as { [folderId: string]: FolderItem[] },
  items: new Map<string, Item>(),
  offlineItems: new Map<string, boolean>(),
  folderItemChangeSets: Object.create(null) as { [folderId: string]: ChangeSet<FolderItem> },
  rawItemsChangeSets: Object.create(null) as { [itemId: string]: ChangeSet<Item> },
  conflictIds: [],
});

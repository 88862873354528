import { ItemWithPosition } from '~/models/item/ItemWithPosition';
import { ViewItem } from '~/models/views/ViewItem';
import { ContactSheet } from '~/models/views/contactSheet/ContactSheet';
import SnippetBuilder from '~/models/views/SnippetBuilder';
import Snippet from '~/models/Snippet';

export class ContactSheetBuilder {
  private marginBetweenItems: number = 0;
  private textSize: number = 0;
  private items: ItemWithPosition[] = [];
  private columnCount: number = 4;
  private viewWidth: number = 500;
  private minWidth: number = 100;
  private maxWidth: number = 1000;
  private maxColumnCount: number = 8;
  private minColumnCount: number = 1;

  private static VERTICAL_SPACING_FACTOR = 1.6;

  public setItems(items: ItemWithPosition[]): ContactSheetBuilder {
    this.items = items;
    return this;
  }

  public setTextSize(textSize: number): ContactSheetBuilder {
    this.textSize = textSize;
    return this;
  }

  public setColumnCount(count: number): ContactSheetBuilder {
    this.columnCount = count;
    return this;
  }

  public setMaxColumnCount(count: number): ContactSheetBuilder {
    this.maxColumnCount = count;
    return this;
  }

  public setMinColumnCount(count: number): ContactSheetBuilder {
    this.minColumnCount = count;
    return this;
  }

  public setMarginBetweenItems(margin: number): ContactSheetBuilder {
    this.marginBetweenItems = margin;
    return this;
  }

  public setViewWidth(contactSheetWidth: number): ContactSheetBuilder {
    this.viewWidth = contactSheetWidth;
    return this;
  }

  public setMinViewWidth(minWidth: number): ContactSheetBuilder {
    this.minWidth = minWidth;
    return this;
  }

  public setMaxWidth(maxWidth: number): ContactSheetBuilder {
    this.maxWidth = maxWidth;
    return this;
  }

  public setBaseContactSheet(contactSheet: ContactSheet): ContactSheetBuilder {
    this.setViewWidth(contactSheet.width);
    this.setTextSize(contactSheet.textSize);
    this.setColumnCount(contactSheet.columnCount);
    this.setMarginBetweenItems(contactSheet.marginBetweenItems);
    this.setMaxWidth(contactSheet.maxWidth);
    this.setMinViewWidth(contactSheet.minWidth);
    this.setMaxColumnCount(contactSheet.maxColumnCount);
    this.setMinColumnCount(contactSheet.minColumnCount);
    return this;
  }

  public build(): ContactSheet {
    return this.buildPartialContactSheet(0);
  }

  public buildPartialContactSheet(_startHeight: number): ContactSheet {
    const snippets: Snippet[] = [];
    const widthOfItem = ContactSheetBuilder.calculateSideWith(this.viewWidth, this.marginBetweenItems, this.columnCount);
    const heightOfSnippet = ContactSheetBuilder.calculateHeightOfSnippet(widthOfItem, this.textSize);
    const rowCount = Math.ceil(this.items.length / this.columnCount);
    let itemCount = 0;
    for (let row = 0; row < rowCount; row++) {
      for (let column = 0; column < this.columnCount; column++) {
        if (itemCount < this.items.length) {
          const item: ViewItem = new ViewItem(this.items[itemCount]);
          item.setViewPosition({
            width: widthOfItem,
            height: heightOfSnippet,
            left: (column * (widthOfItem + this.marginBetweenItems)) + this.marginBetweenItems,
            top: row * (heightOfSnippet + ContactSheetBuilder.VERTICAL_SPACING_FACTOR * this.marginBetweenItems),
          });
          itemCount++;
          snippets.push(new SnippetBuilder().fromItem(item).build());
        }
      }
    }
    return new ContactSheet(
      snippets,
      this.viewWidth,
      (heightOfSnippet + this.marginBetweenItems) * Math.ceil(this.items.length / this.columnCount),
      this.columnCount,
      this.marginBetweenItems,
      this.textSize,
      this.minWidth,
      this.maxWidth,
      this.maxColumnCount,
      this.minColumnCount
    );
  }

  public static calculateSideWith(xWidth: number, marginBetweenItems: number, columnCount: number) {
    return (xWidth - (marginBetweenItems * (columnCount + 1))) / columnCount;
  }

  public static calculateHeightOfSnippet(width: number, textSize: number) {
    return width + textSize;
  }
}

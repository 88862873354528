import { User } from '~/models/user/User';
import { Device } from '~/models/Device';
import { LoginStatus } from '~/models/LoginStatus';
import { Contact } from '~/models/user/Contact';
import { UserProfile } from '~/models/user/UserProfile';
import { MessageRoute } from '~/models/MessageRoute';
import { ChangePasswordStatus } from '~/models/ChangePasswordStatus';
import { UserDetails } from '~/models/user/UserDetails';
import { SignUpStatus } from '~/models/SignUpStatus';
import { ForgotPasswordStatus } from '~/models/user/ForgotPasswordStatus';

export interface ProfileSearch {
  searchTerm: string;
  isLoading: boolean;
  error: boolean;
  profileResults: UserProfile[];
}

export enum ValidationType {
  PASSWORD_CHANGE = 'PASSWORD_CHANGE',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
}

export interface ValidationObject {
  error: string,
}

export interface UsernameSearch {
  searchTerm: string;
  isLoading: boolean;
  isTaken: boolean;
}

export interface UserLimits {
  uploadVolumeUsed: number;
  uploadVolumeLimit: number;
  isUploadLimitExceeded: boolean;
}

export interface UserState {
  user: User | null;
  userDetails: UserDetails;
  usernameSearch: UsernameSearch;
  profiles: Map<string, UserProfile>;
  profileSearch: ProfileSearch;
  devices: Device[];
  messageRoutes: MessageRoute[];
  contacts: Contact[];
  currentConversationId: string;
  forgotPassword: ForgotPasswordStatus;
  signUp: SignUpStatus;
  login: LoginStatus;
  changePassword: ChangePasswordStatus;
  userRoleUpgradePending: boolean;
  userLimits: UserLimits;
  isReceiveContentEnabled: boolean;
}

export default (): UserState => ({
  user: null,
  userDetails: null,
  profiles: new Map<string, UserProfile>(),
  usernameSearch: {
    searchTerm: '',
    isLoading: false,
    isTaken: false,
  },
  profileSearch: {
    searchTerm: '',
    isLoading: false,
    error: null,
    profileResults: [],
  },
  devices: [],
  messageRoutes: [],
  contacts: [],
  currentConversationId: null,
  signUp: {
    isPending: false,
    error: null,
  },
  forgotPassword: null,
  login: {
    isPending: false,
    success: false,
    error: null,
  },
  changePassword: {
    error: null,
  },
  userRoleUpgradePending: false,
  userLimits: null,
  isReceiveContentEnabled: false,
});

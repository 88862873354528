export enum StorageKey {
  NOTIFICATIONS = 'notifications',
  SORTED_BY = 'folder.sortOption',
  MAIN_IMMERSION_MODE = 'main.immersiveMode',
  MAIN_ILLUMINATION_GRADE = 'main.illuminationGrade',
  MAIN_VIEW_BACKGROUND_OPACITY = 'main.view.background.opacity2', // incremented storage key to overwrite the old option, so everybody starts out with same new options until we implement a storage migration solution
  SIDE_PANE_BACKGROUND_OPACITY = 'side.pane.background.opacity',
  MAIN_VIEW_ELEMENTS_OPACITY = 'main.view.elements.opacity',
  SIDE_PANE_ELEMENTS_OPACITY = 'side.pane.elements.opacity',
  MENUS_OPACITY = 'menus.opacity',
  CLOUD_MENU = 'cloud.menu',
  SCREEN_BACKGROUND = 'screen.background',
  SIDE_PANE = 'side.pane',
  RIGHT_MENU = 'right.menu',
  MENU_FLOATING = 'menu.floating',
  SLIM_MENU_OPEN = 'slim_menu.open',
  EXPIRES_AT = 'mutant.expires_at',
  XSRF = 'mutant.xsrf',
  VERSION = 'mutant.version',
  USER = 'mutant.user',
  USER_ROLE_UPGRADE_PENDING = 'mutant.user.role.upgrade',
  USER_ROLE_UPGRADE_PENDING_SINCE = 'mutant.user.role.upgrade-pending-since',
  USER_ROLE_UPGRADE_PENDING_FOR_SUBSCRIPTION_ID = 'mutant.user.role.upgrade-id',

  SHORTCUTS_IS_OPEN = 'mutant.shortcuts.is_open',
  SHORTCUTS_HEIGHT = 'mutant.shortcuts.height',
  SHORTCUTS_SHOW_LANE_TITLE = 'mutant.shortcuts.show_lane_title',
  SHORTCUTS_BACKGROUND_OPACITY = 'mutant.shortcuts.background_opacity',
  SHORTCUTS_LANES = 'mutant.shortcuts.lanes',
  SHORTCUTS_ACTIVE_LANE = 'mutant.shortcuts.active_lane',

  RECENT_OBJECTS = 'mutant.recent_objects',
  ALPHA_SIGNUP = 'mutant.alpha_signup',

  BETA_SIGNUP_PAGE = 'mutant.beta_signup.page',

  INVITE_CODE = 'mutant.alpha.invite_code',
  INTERACTIVE_FOOTER_HEIGHT = 'interactiveFooterHeight',
  TAB_MESSAGE = 'mutant.tab_message',

  CURRENT_LAYOUT_TYPE = 'mutant.layout.type',

  SHOW_ORIGINALS = 'mutant.show.originals',
  PIPELINE_OPTIONS = 'mutant.pipeline_options',
  THUMBNAIL_CONFIG = 'mutant.thumbnail_config',

  BETA_LANDING_VISITED = 'mutant.beta_landing.visited',
  SHOW_CONFIRMATION_PROMPT_SENDING = 'mutant.show_confirmation_prompt_sending'
}

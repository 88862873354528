import _cloneDeep from 'lodash.clonedeep';
import Snippet from '~/models/Snippet';
import { SortItemsEvent } from '~/models/item/SortItemsEvent';
import { SortableView } from '~/models/views/SortableView';
import { ViewType } from '~/models/views/ViewType';
import { MosaicBuilder } from '~/models/views/mosaic/MosaicBuilder';

export class Mosaic implements SortableView {
  public type = ViewType.MOSAIC;

  constructor(
    public snippets: Snippet[],
    public width: number,
    public height: number,
    public columnCount: number,
    public marginBetweenHorizontalItems: number,
    public marginBetweenVerticalItems: number,
    public scaleWidth: number
  ) {}

  resize(newWidth: number, _newHeight: number) {
    const scaleFactor = newWidth / this.width;
    this.snippets = this.snippets.map((snippet) => {
      snippet.height = snippet.height * scaleFactor;
      snippet.width = snippet.width * scaleFactor;
      snippet.item.setViewPosition({
        width: snippet.item.viewPosition.width * scaleFactor,
        height: snippet.item.viewPosition.height * scaleFactor,
        top: snippet.item.viewPosition.top * scaleFactor,
        left: snippet.item.viewPosition.left * scaleFactor,
      });
      return snippet;
    });
    this.width = newWidth;
    this.height = this.height * scaleFactor;
  }

  preCalculatePartialSorting(sortItemsEvent: SortItemsEvent): Snippet[] {
    const snippets = _cloneDeep(this.snippets);
    const snippetsImpactedBySort: Snippet[] = this.calculateImpactedSnippets(snippets, sortItemsEvent);
    const firstImpactedSnippet: Snippet = snippetsImpactedBySort[0];
    const heightPerColumn: number[] = [];
    const columnStart = (firstImpactedSnippet?.item?.viewPosition.left || 0) > 0
      ? Math.round(firstImpactedSnippet.item.viewPosition.left / this.scaleWidth)
      : 0;
    // initialize height per Column based on the impacted snippets
    for (let i = 0; i < this.columnCount; i++) {
      const projectedColumn = i < columnStart ? i - columnStart + this.columnCount : i - columnStart;
      heightPerColumn[i] = projectedColumn < snippetsImpactedBySort.length && projectedColumn >= 0
        ? snippetsImpactedBySort[projectedColumn].item.viewPosition.top
        : 0;
    }
    const sortedSnippets = Snippet.sortSnippetsBySortEvent(snippetsImpactedBySort, sortItemsEvent);
    const partialMosaic = new MosaicBuilder()
      .setBaseMosaic(this)
      .setItems(sortedSnippets.map((s) => s.item.item))
      .buildPartialMosaic(heightPerColumn);
    return partialMosaic.snippets.filter((snippet) =>
      sortItemsEvent.items.some((i) => i.id === snippet.item.id)
    );
  }

  private calculateImpactedSnippets(snippets: Snippet[], _sortItemsEvent: SortItemsEvent): Snippet[] {
    return snippets;
  }
}

import { UploadMenuState } from '~/store/uploadMenu/state';
import { PipelineCommandType } from '~/models/pipeline/PipelineCommandType';

export default {
  togglePipelineOption(state: UploadMenuState, option: PipelineCommandType) {
    if (state.pipelineOptions.includes(option)) {
      state.pipelineOptions = state.pipelineOptions.filter(p => p !== option);
    } else {
      state.pipelineOptions = [...state.pipelineOptions, option];
    }
  },
  toggleExpandedState(state: UploadMenuState) {
    state.isExpanded = !state.isExpanded;
  },
  toggleReplaceExisingFiles(state: UploadMenuState) {
    state.replaceExisingFiles = !state.replaceExisingFiles;
    state.ignoreExisingFiles = false;
  },
  toggleIgnoreExisingFiles(state: UploadMenuState) {
    state.ignoreExisingFiles = !state.ignoreExisingFiles;
    state.replaceExisingFiles = false;
  },
  resetState(state: UploadMenuState) {
    state.ignoreExisingFiles = false;
    state.replaceExisingFiles = false;
    state.isExpanded = false;
    state.pipelineOptions = [PipelineCommandType.UPLOAD_THUMBNAILS];
  },
};
